import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import ActionSnackbar from 'Common/Snackbar/ActionSnackbar';
import { useTranslation } from 'hooks/useTranslation';
import { TMewsPayload } from 'integrations/integrationsTypes';
import React, { useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import AddCard from 'ui-library/Components/card/AdderCard';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import { FormHeader } from 'ui-library/Styled/sidebar';
import { IntegrationsFormHandle, IntegrationsFormProps } from '../IntegrationsSideDrawer';

const MEWS_CLIENT_SUPPORT_URL = `${process.env.REACT_APP_CLIENT_SUPPORT_URL}/s/article/Mews`;

type MewsIntegrationFormProps = IntegrationsFormProps & {
  onSave: (data: Omit<TMewsPayload, 'provider'>) => void;
  onClose: () => void;
};

const MewsIntegrationForm = React.forwardRef<IntegrationsFormHandle, MewsIntegrationFormProps>(
  ({ onSave, onClose }, ref) => {
    const [showExitConfirmation, setShowExitConfirmation] = useState(false);

    const { t } = useTranslation();

    const {
      handleSubmit,
      control,
      formState: { isDirty },
    } = useForm<Omit<TMewsPayload, 'provider'>>({
      mode: 'all',
      defaultValues: {},
    });

    useImperativeHandle(
      ref,
      () => ({
        submit: handleSubmit(onSave),
        close: () => {
          setShowExitConfirmation(isDirty);
          if (!isDirty) {
            onClose?.();
          }
        },
      }),
      [handleSubmit, isDirty, onClose, onSave],
    );

    return (
      <Stack m={1}>
        <AddCard
          title={t('integration_token')}
          subtitle={
            <IconButton
              color='primary'
              onClick={() => window.open(MEWS_CLIENT_SUPPORT_URL, '_blank')}
            >
              <Tooltip title={t('guide_article')}>
                <InfoOutlined />
              </Tooltip>
            </IconButton>
          }
        >
          <ActionSnackbar
            onClose={onClose}
            open={showExitConfirmation}
            setOpen={setShowExitConfirmation}
            severity='error'
            actionButtonText={t('yes_capitalized')}
            snackbarMessage={t(
              'you_have_unsaved_changes_that_will_be_lost_if_you_exit_are_you_sure_you_want_to_exit',
            )}
          />

          <FormHeader>
            {t('enter_integration_token_from_your_account', { integration_name: 'Mews' })}
          </FormHeader>

          <FormTextField
            rules={{
              required: {
                value: true,
                message: t('integration_token_required'),
              },
            }}
            field='api_token'
            label={t('integration_token')}
            control={control}
            sx={{ mb: 4 }}
          />
        </AddCard>
      </Stack>
    );
  },
);

MewsIntegrationForm.displayName = 'MewsIntegrationForm';

export default MewsIntegrationForm;
