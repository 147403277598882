import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { Loading } from 'Common/Loading';
import useCommunications, { ChannelType, CommunicationType } from 'hooks/useCommunications';
import useCommunicationsFilters from 'hooks/useCommunicationsFilters';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation, { SupportedLocale } from 'hooks/useTranslation';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  PaginatedTable,
  getSortModelByUrl,
  sortTableByUrl,
} from 'ui-library/Components/table/PaginatedTable';
import CommunicationsBlankPagePanel from './CommunicationsBlankPagePanel';
import CommunicationsTitlebar from './CommunicationsTitlebar';
import useCommunicationsColumns from './useCommunicationsColumns';

const PAGE_NUM = 0;
const PAGE_SIZE = 100;

export default function CommunicationsTable() {
  const firstLoad = useRef(true);
  const [searchString, setSearchString] = useState('');
  const communicationsFilters = useCommunicationsFilters();
  const { snackbar } = useSnackbar();
  const { t } = useTranslation();

  const channelsSelected = communicationsFilters.searchParams
    .get('channel')
    ?.split(',') as ChannelType[];

  const localesSelected = communicationsFilters.searchParams
    .get('language')
    ?.split(',') as SupportedLocale[];

  const hasFilters = channelsSelected || localesSelected;
  const [filteredCommunications, setFilteredCommunications] = useState<CommunicationType[]>();

  const {
    data: communications,
    isLoading,
    isFetching,
    communicationsRefetch,
  } = useCommunications({
    channels: channelsSelected,
    locales: localesSelected,
  });

  const handleDeleteTemplate = useCallback(async () => {
    await communicationsRefetch();
    snackbar(t('deleted'));
  }, [communicationsRefetch, snackbar, t]);

  const columns = useCommunicationsColumns(communications, handleDeleteTemplate);

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      setSearchString(searchString || '');

      if (!searchString?.length) {
        return setFilteredCommunications(communications);
      }

      const lowerCaseSearchString = searchString.toLowerCase();
      const filteredData = communications?.filter(row => {
        return row.name.toLowerCase().includes(lowerCaseSearchString);
      });

      setFilteredCommunications(filteredData);
    },
    [communications],
  );

  useEffect(() => {
    if (communications && filteredCommunications === undefined) {
      setFilteredCommunications(communications);
    }
  }, [communications, filteredCommunications]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }

    handleSearch(PAGE_NUM, PAGE_SIZE, searchString);
  }, [handleSearch, searchString]);

  const renderContent = () => {
    if (firstLoad.current && isLoading) {
      return <Loading />;
    }

    if (!hasFilters && !isLoading && !communications?.length) {
      return <CommunicationsBlankPagePanel />;
    }

    return (
      <PaginatedTable
        enableToolbar
        columns={columns}
        rows={filteredCommunications ?? []}
        initialState={tableInitialState}
        filtersToolbarProps={communicationsFilters}
        onFetch={handleSearch}
        onSortModelChange={model =>
          communicationsFilters.onFilterChange(
            sortTableByUrl(model, communicationsFilters.searchParams),
          )
        }
        sortModel={getSortModelByUrl(communicationsFilters.searchParams)}
        loading={!communicationsFilters.isSynched || (isFetching && !communications)}
      />
    );
  };

  return (
    <>
      <CommunicationsTitlebar displayAddButton={isLoading || communications?.length > 0} />

      {renderContent()}
    </>
  );
}

const tableInitialState: GridInitialStatePro = {
  sorting: {
    sortModel: [{ field: 'name', sort: 'asc' }],
  },
};
