import { companySelector } from 'company/state/companySelectors';
import * as React from 'react';
import { useAppSelector } from 'redux/hooks';
import AccountContainer from './AccountContainer';

export const ACCOUNT_TAB = 0;
export const USER_PROFILE_TAB = 1;
export const GUEST_PORTAL_TAB = 2;
export const NOTIFICATIONS_TAB = 3;

const Account = () => {
  const company = useAppSelector(companySelector());

  return <AccountContainer company={company} />;
};

export default Account;
