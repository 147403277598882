// @ts-nocheck

import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { UiState } from 'types/ui';

import makeAvailable from 'redux/reducers/ui/makeAvailable';
import memberProfile from 'redux/reducers/ui/memberProfile';
import refreshLoader from 'redux/reducers/ui/refreshLoader';

import activateUnits from 'redux/reducers/ui/activateUnits';
import codeDetail from 'redux/reducers/ui/codeDetail';
import confirmationModal from 'redux/reducers/ui/confirmationModal';
import serviceNotifications from 'redux/reducers/ui/serviceNotifications';
import unitGroup from 'redux/reducers/ui/unitGroup';

import favorites from 'redux/reducers/ui/favorite';
import lockCommands from 'redux/reducers/ui/lockCommands';
import moreAlerts from 'redux/reducers/ui/moreAlerts';
import moreCheckOuts from 'redux/reducers/ui/moreCheckOuts';
import moreFavorites from 'redux/reducers/ui/moreFavorites';
import morePastAlerts from 'redux/reducers/ui/morePastAlerts';
import moreReservations from 'redux/reducers/ui/moreReservations';
import moreUnits from 'redux/reducers/ui/moreUnits';
import overviewData from 'redux/reducers/ui/overviewData';
import resetPassword from 'redux/reducers/ui/resetPassword';
import temperatureSettings from 'redux/reducers/ui/temperatureSettings';
import { Actions } from 'types/actions';

const initialState = 0;

const propertyId: Reducer<number> = (state = initialState, action) => {
  switch (action.type) {
    case Actions.storePropertyId:
      return action.id;
    default:
      return state;
  }
};

const retrievingMoreEvents: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.retrievingMoreEvents:
      return true;
    case Actions.retrievingMoreEventsComplete:
      return false;
    default:
      return state;
  }
};
const retrievingMoreEventFailed: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.retrievingMoreEventsFailed:
      return true;
    case Actions.retrievingMoreEventsComplete:
      return false;
    default:
      return state;
  }
};

const showEditIntercomLoader: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.showUpdateIntercom:
      return true;
    case Actions.hideUpdateIntercom:
      return false;
    default:
      return state;
  }
};

const showMakeAvailableBanner: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.showMakeAvailableBanner:
      return true;
    case Actions.hideMakeAvailableBanner:
      return false;
    default:
      return state;
  }
};

const showRefreshLoader: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.toggleRefreshLoader:
      return state;
    default:
      return state;
  }
};

const showAddGroup: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddGroup:
      return !state;
    default:
      return state;
  }
};

const showAddTeamMember: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddTeamMember:
      return !state;
    default:
      return state;
  }
};

const teamMemberType: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.toggleAddTeamMember:
      return state;
    default:
      return state;
  }
};

const showAddProperty: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddProperty:
      return !state;
    default:
      return state;
  }
};

const showAddIntercom: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntercom:
      return !state;
    default:
      return state;
  }
};

const showEditIntercom: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleEditIntercom:
      return !state;
    default:
      return state;
  }
};

const showAddGuestyIntegration: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntegrationGuesty:
      return !state;
    default:
      return state;
  }
};

const showAddHostawayIntegration: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntegrationHostaway:
      return !state;
    default:
      return state;
  }
};

const showAddMewsIntegration: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntegrationMews:
      return !state;
    default:
      return state;
  }
};

const showAddAvantioIntegration: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntegrationAvantio:
      return !state;
    default:
      return state;
  }
};

const showAddKigoIntegration: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntegrationKigo:
      return !state;
    default:
      return state;
  }
};
const showAddVRSIntegration: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntegrationVRScheduler:
      return !state;
    default:
      return state;
  }
};
const showAddHostfullyIntegration: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntegrationHostfully:
      return !state;
    default:
      return state;
  }
};
const showAddDirectIntegration: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntegrationDirect:
      return !state;
    default:
      return state;
  }
};
const showAddLightmakerIntegration: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddIntegrationLightmaker:
      return !state;
    default:
      return state;
  }
};
const propertyDetailId: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.setPropertyId:
      return action.id;
    default:
      return state;
  }
};

const propertyDetailTabIndex: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.changePropertyDetailTab:
      return action.tabIndex;
    default:
      return state;
  }
};

const netatmoFilterIndex: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.changeNetatmoIndexTab:
      return action.tabIndex;
    default:
      return state;
  }
};

const noiseAwareFilterIndex: Reducer<number> = (state = 0, action) => {
  switch (action.type) {
    case Actions.changeNoiseAwareIndexTab:
      return action.tabIndex;
    default:
      return state;
  }
};

const addUnitToUnitGroup: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddUnitToUnitGroup:
      return !state;
    case Actions.hideAddUnitToUnitGroup:
      return false;
    default:
      return state;
  }
};

const addMemberToTeam: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddMemberToTeam:
      return !state;
    default:
      return state;
  }
};

const activityFeed: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleActivityFeed:
      return !state;
    default:
      return state;
  }
};
const masterActivityFeed: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleMasterActivityFeed:
      return !state;
    default:
      return state;
  }
};
const viewPastUnitAlerts: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleViewPastUnitAlerts:
      return !state;
    default:
      return state;
  }
};
const addGroupsToMember: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddGroupToMember:
      return !state;
    case Actions.hideAddGroupsToMember:
      return false;
    default:
      return state;
  }
};

const addUnitsToMember: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddUnitsToMember:
      return !state;
    case Actions.hideAddUnitsToMember:
      return false;
    default:
      return state;
  }
};

const addTeamsToMember: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddTeamToMember:
      return !state;
    default:
      return state;
  }
};

const addGroupsToUnit: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddGroupsToUnit:
      return !state;
    case Actions.hideAddGroupsToUnit:
      return false;
    default:
      return state;
  }
};

const addNewReservation: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleAddNewReservation:
      return !state;
    case Actions.hideAddReservation:
      return false;
    default:
      return state;
  }
};

const showSidebar: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleMobileSidebar:
      return !state;
    default:
      return state;
  }
};
const guestyPropertyImportComplete: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.guestyPropertyImportComplete:
      return action.message;
    default:
      return state;
  }
};

const cloudbedsPropertyImportComplete: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.cloudbedsPropertyImportComplete:
      return action.message;
    default:
      return state;
  }
};

const myVRPropertyImportComplete: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.myVRPropertyImportComplete:
      return action.message;
    default:
      return state;
  }
};

const hostAwayPropertyImportComplete: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.hostawayPropertyImportComplete:
      return action.message;
    default:
      return state;
  }
};

const mewsPropertyImportComplete: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.mewsPropertyImportComplete:
      return action.message;
    default:
      return state;
  }
};

const streamlinePropertyImportComplete: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.streamlinePropertyImportComplete:
      return action.message;
    default:
      return state;
  }
};

const hostfullyPropertyImportComplete: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.hostfullyPropertyImportComplete:
      return action.message;
    default:
      return state;
  }
};

const showMemberProfileOptions: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case Actions.toggleMemberProfileOptions:
      return !state;
    default:
      return state;
  }
};
const settingTadoTemperatureAStatus: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.SettingTado:
      return action.message;
    default:
      return state;
  }
};

const showSlidebar: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.toggleSlidebar:
      return action.content;
    default:
      return state;
  }
};
const showSnackbar: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.toggleSnackbar:
      return action.content;
    default:
      return state;
  }
};

const showPanelSnackbar: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.togglePanelSnackbar:
      return action.content;
    default:
      return state;
  }
};

const timeRefresedhUnitMembersTable: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case Actions.refreshUnitMembersTable:
      return action.time;
    default:
      return state;
  }
};

const reducer: Reducer<UiState> = combineReducers<UiState>({
  propertyId,
  memberProfile,
  codeDetail,
  showRefreshLoader,
  showMakeAvailableBanner,
  showAddGroup,
  showAddTeamMember,
  teamMemberType,
  showAddProperty,
  showAddIntercom,
  showEditIntercom,
  showAddGuestyIntegration,
  showAddHostawayIntegration,
  showAddMewsIntegration,
  showAddAvantioIntegration,
  showAddKigoIntegration,
  showAddVRSIntegration,
  showAddHostfullyIntegration,
  showAddDirectIntegration,
  showAddLightmakerIntegration,
  showSlidebar,
  propertyDetailId,
  serviceNotifications,
  confirmationModal,
  propertyDetailTabIndex,
  activateUnits,
  addUnitToUnitGroup,
  addMemberToTeam,
  unitGroup,
  activityFeed,
  addGroupsToMember,
  addUnitsToMember,
  addTeamsToMember,
  addGroupsToUnit,
  addNewReservation,
  moreUnits,
  moreAlerts,
  moreReservations,
  overviewData,
  temperatureSettings,
  showSidebar,
  showMemberProfileOptions,
  guestyPropertyImportComplete,
  cloudbedsPropertyImportComplete,
  myVRPropertyImportComplete,
  hostAwayPropertyImportComplete,
  mewsPropertyImportComplete,
  streamlinePropertyImportComplete,
  hostfullyPropertyImportComplete,
  settingTadoTemperatureAStatus,
  refreshLoader,
  lockCommands,
  showEditIntercomLoader,
  resetPassword,
  retrievingMoreEvents,
  retrievingMoreEventFailed,
  makeAvailable,
  masterActivityFeed,
  favorites,
  moreFavorites,
  morePastAlerts,
  viewPastUnitAlerts,
  netatmoFilterIndex,
  noiseAwareFilterIndex,
  moreCheckOuts,
  showSnackbar,
  showPanelSnackbar,
  timeRefresedhUnitMembersTable,
});

export default reducer;
