import { Box, Divider, SxProps, Typography } from '@mui/material';
import React from 'react';

export interface DashboardWidgetProps {
  title?: string;
  header?: JSX.Element;
  children?: React.ReactNode;
}

// for the related component, see
// https://github.com/operto/neo/blob/main/packages/ui/src/lib/widgets/Widget.tsx
const OneDashboardWidget = ({ title, header, children }: DashboardWidgetProps) => {
  const bodyContainerStyles: SxProps = {
    height: title ? 'calc(100% - 64px)' : '100%', // account for header height
    overflowY: 'auto',
  };

  return (
    <Box sx={widgetBaseStyles}>
      {title && (
        <>
          <Box sx={widgetHeaderStyles}>
            <Typography variant={'subtitle-sm-600'}>{title}</Typography>
          </Box>
          <Divider />
        </>
      )}
      {header && (
        <>
          {header}
          <Divider />
        </>
      )}
      <Box sx={bodyContainerStyles}>{children}</Box>
    </Box>
  );
};

const widgetBaseStyles: SxProps = {
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'accentBackground',
  height: '400px',
  width: '100%',
  boxShadow: '1',
  overflowX: 'hidden',
  overflowY: 'hidden',
  borderRadius: '16px',
};

const widgetHeaderStyles: SxProps = {
  paddingY: '20px',
  paddingX: '16px',
};

export default OneDashboardWidget;
