import { DataObjectOutlined } from '@mui/icons-material';
import { Box, Button, Stack, SxProps, TextField } from '@mui/material';
import ConfirmLeaveDialog, { CommNavigationType } from 'Common/Dialog/ConfirmLeaveDialog';
import LanguageSelector from 'Common/LanguageSelector/LanguageSelector';
import { companySelector } from 'company/state/companySelectors';
import DOMPurify from 'dompurify';
import useCommunications from 'hooks/useCommunications';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import InsertVariablesMenu from 'Pages/Variables/Common/InsertVariablesMenu';
import Prism, { Grammar } from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Editor from 'react-simple-code-editor';
import { isAdmin } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import { TextInsert } from 'ui-library/Helpers/textHelper';
import { EmailTemplateType, MAX_SUBJECT_LENGTH } from './CommunicationsEmailTemplatePage';
import './SourceCodeEditorStyles.css';

const CommunicationsEmailTemplateSourceCode = ({
  templateData = {},
  subjectError,
  onChange,
  showSubjectRequired,
  isTemplateDirty,
}: {
  templateData: EmailTemplateType;
  subjectError: boolean;
  onChange: (template: EmailTemplateType) => void;
  showSubjectRequired?: boolean;
  isTemplateDirty: boolean;
}) => {
  const { t, languages } = useTranslation();
  const navigate = useNavigate();
  const company = useAppSelector(companySelector());
  const { data: communications } = useCommunications();
  const { isFeatureEnabled } = useAppFeatures();
  const isCustomVariablesEnabled =
    isAdmin() || isFeatureEnabled('communicationsCustomVariablesInEmail', company.id);

  const { body, id, locale, subject } = templateData;
  const characterCount = subject?.length || 0;

  const communicationLocales = communications?.find(c => c.id === id)?.locales ?? [];
  const locales = [...new Set([...communicationLocales, locale])];

  const missingLocales = languages.filter(l => !locales.includes(l.locale));
  const arrayMissingLocales = missingLocales?.map(l => l.locale);

  const boxRef = useRef<HTMLDivElement>(null);
  const [currentRef, setCurrentRef] = useState<HTMLTextAreaElement | null>(null);
  const [currentButtonLabelCursorPos, setCurrentButtonLabelCursorPos] = useState(0);
  const [dialogState, setDialogState] = useState<CommNavigationType | undefined>(undefined);

  const handleLanguageChange = (newLocale: string, type: 'create' | 'edit') => {
    if (isTemplateDirty) {
      setDialogState({ language: newLocale, navigationType: type });
    } else {
      handleLanguageNavigation(newLocale, type);
    }
  };

  const handleLanguageNavigation = (selectedLanguage: string, type: 'create' | 'edit') => {
    navigate(
      `/communications/${type}/email/${id}/${selectedLanguage !== 'en' ? selectedLanguage : ''}`,
    );
  };

  const handleConfirmDialogSubmit = () => {
    if (dialogState) {
      handleLanguageNavigation(dialogState.language, dialogState.navigationType);
    }
    setDialogState(undefined);
  };

  const handleCursorPosition = (
    e:
      | React.MouseEvent<HTMLTextAreaElement, MouseEvent>
      | React.FocusEvent<HTMLTextAreaElement, Element>,
  ) => {
    const cursorPos = (e.target as HTMLTextAreaElement).selectionStart;
    setCurrentButtonLabelCursorPos(cursorPos);
    setCurrentRef(e.target as HTMLTextAreaElement);
    e.preventDefault();
  };

  const handleVariableButtonClick = () => {
    onChange({
      ...templateData,
      body: TextInsert(body, '*{{Enter Variable Name}}*', currentButtonLabelCursorPos),
    });
    setCurrentButtonLabelCursorPos(
      currentButtonLabelCursorPos + '*{{Enter Variable Name}}*'.length,
    );
    setCurrentRef(currentRef);
  };

  const handleVariableInsert = (newText: string, position: number) => {
    const { boxDiv, textarea, scrollTop } = getTextAreaAndBoxScrollPosition();

    onChange({ ...templateData, body: newText });
    setCurrentButtonLabelCursorPos(position);
    setCurrentRef(currentRef);

    restoreBoxScrollAndTextAreaCursorPosition(boxDiv, textarea, scrollTop, position);
  };

  function getTextAreaAndBoxScrollPosition() {
    const boxDiv = boxRef.current;
    const textarea = currentRef as HTMLTextAreaElement;
    const scrollTop = boxDiv.scrollTop;
    return { boxDiv, textarea, scrollTop };
  }

  function restoreBoxScrollAndTextAreaCursorPosition(
    boxDiv: HTMLDivElement,
    textarea: HTMLTextAreaElement,
    scrollTop: number,
    position: number,
  ) {
    // After inserting a replacement variable, the textarea content is fully updated.
    // Using setTimeout ensures the DOM has finished rendering the updates before
    // restoring the scroll position and cursor, preventing timing issues.
    setTimeout(() => {
      boxDiv.scrollTop = scrollTop;
      textarea?.setSelectionRange(position, position);
      textarea.focus();
    }, 0);
  }

  const highlightWithLineNumbers = (input: string, grammar: Grammar, language: string) =>
    Prism.highlight(input, grammar, language)
      .split('\n')
      .map(
        (line: string, i: number) =>
          `<span class='editorLineNumber' style='background-color: #F6F7FB; color: #00000099; height: 100%;'>${
            i + 1
          }</span>${line}`,
      )
      .join('\n');

  // A hack to add padding with the line number color to the top of the editor
  const renderColoredPaddingBar = () => (
    <span
      className='padding-bar'
      style={{
        height: '10px',
        backgroundColor: '#F6F7FB',
        display: 'block',
        width: '40px',
      }}
    ></span>
  );

  return (
    <>
      <Stack
        direction='row'
        spacing={3}
        sx={{ backgroundColor: 'white', padding: '24px', borderRadius: '16px' }}
      >
        <Box sx={{ width: '250px' }}>
          <LanguageSelector
            label={t('language_version')}
            value={locale}
            onChange={selectedLocale => {
              handleLanguageChange(selectedLocale, 'edit');
            }}
            onSelectNewLanguage={selectedLocale => {
              handleLanguageChange(selectedLocale, 'create');
            }}
            disableNewLanguage={!id}
            languageList={locales}
            newLanguageList={arrayMissingLocales}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          <TextField
            value={subject}
            onChange={e => {
              onChange({ ...templateData, subject: e.target.value });
            }}
            label={t('email_subject')}
            fullWidth
            required
            error={subjectError}
            data-testid='email-subject'
            helperText={
              showSubjectRequired
                ? t('subject_is_required')
                : `${characterCount}/${MAX_SUBJECT_LENGTH}`
            }
            sx={{
              '& > p': {
                textAlign: showSubjectRequired ? 'left' : 'right',
              },
            }}
          />
        </Box>
      </Stack>

      <Stack direction={'row'} sx={previewEditorStackStyles}>
        <Box sx={previewBoxStyles}>
          <Box
            style={{ height: '100%', overflow: 'scroll', width: '650px', padding: '24px' }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
          />
        </Box>

        <Stack sx={editorStackStyles}>
          <Button onClick={handleVariableButtonClick} sx={variableButtonStyles}>
            <DataObjectOutlined />
            {t('add_variable')}
          </Button>

          <Box
            ref={boxRef}
            sx={{
              overflow: 'scroll',
              border: '1px solid #08163e3b',
              borderRadius: '4px',
            }}
          >
            {renderColoredPaddingBar()}
            <Editor
              value={body}
              onValueChange={code => onChange({ ...templateData, body: code })}
              highlight={code => highlightWithLineNumbers(code, Prism.languages.html, 'html')}
              padding={10}
              textareaId='codeArea'
              className='editor'
              onBlur={e => handleCursorPosition(e as React.FocusEvent<HTMLTextAreaElement>)}
              onClick={e =>
                handleCursorPosition(e as React.MouseEvent<HTMLTextAreaElement, MouseEvent>)
              }
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: '16px',
              }}
              data-testid='source-code-editor'
            />
          </Box>
        </Stack>

        <InsertVariablesMenu
          anchorEl={currentRef}
          targetText={body}
          onVariableSelectedUpdate={handleVariableInsert}
          currentCursorPosition={currentButtonLabelCursorPos}
          isCustomVariablesEnabled={isCustomVariablesEnabled}
        />

        <ConfirmLeaveDialog
          open={!!dialogState}
          onClose={() => setDialogState(undefined)}
          onSubmit={handleConfirmDialogSubmit}
        />
      </Stack>
    </>
  );
};

export default CommunicationsEmailTemplateSourceCode;

const previewEditorStackStyles: SxProps = {
  padding: '24px 0px',
  gap: '24px',
  justifyContent: 'space-between',
  display: 'flex',
  flex: 1,
  height: '100%',
  width: '100%',
};

const previewBoxStyles: SxProps = {
  border: 4,
  borderColor: 'rgba(8, 22, 62, 0.23)',
  backgroundColor: 'white',
  justifyContent: 'center',
  height: '100%',
};

const editorStackStyles: SxProps = {
  padding: '24px',
  display: 'flex',
  backgroundColor: 'white',
  border: 1,
  borderRadius: '16px',
  borderColor: 'rgba(21, 41, 122, 0.12)',
  gap: '24px',
  width: '100%',
};

const variableButtonStyles: SxProps = {
  border: '1px solid #155EFF',
  textTransform: 'none',
  width: 'fit-content',
  padding: '8px 22px',
  gap: '8px',
  borderRadius: '6px',
  fontWeight: 700,
  letterSpacing: '0.46px',
};
