import { ICompany } from 'company/companyType';
import { updateCompany } from 'company/state/companyAction';
import { logger } from 'lib/logger';
import React from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { CompanyForm } from './CompanyForm';

export interface BusinessInfoContainerProps {
  company: ICompany;
}

const BusinessInfoContainer = ({ company }: BusinessInfoContainerProps) => {
  const dispatch = useAppDispatch();

  const handleSaveBusinessInfo = async (data: ICompany) => {
    let payload;

    try {
      await dispatch(updateCompany(data));
      payload = {
        message: 'Company information updated successfully',
        variant: SnackbarVariant.SUCCESS,
      };
    } catch (error) {
      logger.error(error);
      payload = {
        message: 'An error occured while saving the company information',
        variant: SnackbarVariant.ERROR,
      };
    }

    dispatch(toggleSnackbar(SnackbarTypes.OPEN, payload));
  };

  return (
    <>
      <CompanyForm company={company} onSubmit={handleSaveBusinessInfo} />
    </>
  );
};

export default BusinessInfoContainer;
