import { Box, Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { useAutomate } from 'hooks/useAutomate';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';

export interface AutomateDeleteConfirmDialogProps {
  workflowId: string;
  onClose: () => void;
  onSuccess: () => void;
  onFailure: (error: Error) => void;
}

const AutomateDeleteConfirmDialog = ({
  workflowId,
  onClose,
  onSuccess,
  onFailure,
}: AutomateDeleteConfirmDialogProps) => {
  const { deleteWorkflow, automateRefetch } = useAutomate();
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const title = t('delete_automate_dialog_title');
  const text = t('delete_automate_dialog_text');

  const handleCloseDialog = () => {
    onClose();
  };

  const handleSubmitDialog = async () => {
    setIsDeleting(true);
    setError(null);

    try {
      await deleteWorkflow(workflowId);
      await automateRefetch();

      handleCloseDialog();
      onSuccess();
    } catch (err) {
      setError(t('delete_automate_deleting_failure_text'));
      onFailure(err);
    } finally {
      setIsDeleting(false);
    }
  };

  // dialog styles
  const rootStyles = { '& div[role=dialog]': { maxWidth: '444px', borderRadius: '10px' } };
  const contentStyles = { padding: '8px 24px 8px 24px', display: 'flex', flexDirection: 'row' };
  const actionStyles = {
    padding: '8px 16px 16px 8px',
    '& button': { textTransform: 'capitalize' },
  };

  return (
    <ConfirmDialog
      open={true}
      onCancel={handleCloseDialog}
      onSubmit={handleSubmitDialog}
      disabled={isDeleting}
      submitButtonText={t('delete')}
      title={title}
      loading={isDeleting}
      contentStyles={contentStyles}
      rootStyles={rootStyles}
      actionsStyles={actionStyles}
    >
      {error ? (
        // Render only the error message if there's an error
        <Typography color='error'>{error}</Typography>
      ) : (
        <Box>
          <Typography variant='body1' gutterBottom>
            {text}
          </Typography>
        </Box>
      )}
    </ConfirmDialog>
  );
};

export default AutomateDeleteConfirmDialog;
