import axios from 'axios';
import { IntegrationPayload, TIntegrationPayload } from 'integrations/integrationsTypes';

const api = process.env.REACT_APP_API_URL;
const PMSapi = process.env.REACT_APP_PMS_API;

export const integrateWithGuesty = (token: string) =>
  axios.post(`${PMSapi}/guesty/auth`, { token });
export const integrateWithMyVR = (code: string, companyId: number) => {
  const params = [`companyId=${companyId}`, `code=${code}`].join('&');
  return axios.post(`${PMSapi}/my-vr/auth?${params}`);
};
export const integrateWithHostAway = (accountId: string, apiKey: string) =>
  axios.post(`${PMSapi}/host-away/auth`, {
    clientId: accountId,
    clientSecret: apiKey,
  });

export const integrateWithMews = (token: string) => axios.post(`${PMSapi}/mews/auth`, { token });

export const disableIntegration = (integrationId: number) =>
  axios.put(`${api}/integrations/${integrationId}/disable`);

export const integrateWithCloudbeds = (code: string, companyId: number) => {
  const params = [`companyId=${companyId}`, `code=${code}`].join('&');
  return axios.post(`${PMSapi}/cloud-beds/auth?${params}`);
};

export const importIntegrationUnits = (IntegrationId: number) =>
  axios.post(`${api}/integrations/${IntegrationId}/import-properties`);
export const enableIntegration = (integrationId: number) =>
  axios.put(`${api}/integrations/${integrationId}/enable`);

export const reenableCloudBedIntegration = (companyId: number) =>
  axios.post(`${PMSapi}/${companyId}/cloud-beds/reenable`);

export const integrateWithAvantio = (companyId: number, username: string, password: string) =>
  axios.post(`${api}/v2/pms/avantio/integration`, {
    companyId,
    webserviceUsername: username,
    webservicePassword: password,
  });

export const integrateWithKigo = (
  companyId: number,
  apiKey: string,
  apiSecret: string,
  apiToken: string,
) =>
  axios.post(`${api}/v2/pms/kigo/integration`, {
    companyId,
    clientApiKey: apiKey,
    clientApiSecret: apiSecret,
    apiToken,
  });

export const integrateWithVRS = (companyId: number) =>
  axios.post(`${api}/v2/pms/vrs/integration`, {
    companyId,
  });

export const integrateWithHostfully = (companyId: number, apiKey: string, apiSecret: string) =>
  axios.post(`${api}/v2/pms/hostfully/integration`, {
    companyId,
    clientApiKey: apiKey,
    clientApiSecret: apiSecret,
  });

export const integrateWithDirect = (companyId: number, apiKey: string, apiSecret: string) =>
  axios.post(`${api}/v2/pms/direct/integration`, {
    companyId,
    clientApiKey: apiKey,
    clientApiSecret: apiSecret,
  });

export const integrateWithLightmaker = (companyId: number, apiKey: string, apiSecret: string) =>
  axios.post(`${api}/v2/pms/lightmaker/integration`, {
    companyId,
    clientApiKey: apiKey,
    clientApiSecret: apiSecret,
  });

export const integrateWithStripe = (payload: {
  publishable_key: string;
  token: string;
  enabled: boolean;
}) => axios.post(`${api}/v2/integrations/payments/stripe`, payload);

export const updateStripe = (payload: {
  publishable_key: string;
  token: string;
  enabled: boolean;
}) => axios.patch(`${api}/v2/integrations/payments/stripe`, payload);

export const getStripe = () => axios.get(`${api}/v2/integrations/payments/stripe`);

/**  Integrations */
type TIntegratePayload = {
  provider: string;
  code?: string;
  api_token?: string;
};

export const getIntegrations = () => axios.get<IntegrationPayload>(`${api}/v2/integrations`);

export const postIntegration = async (payload: TIntegratePayload | TIntegrationPayload) => {
  const response = await axios.post(`${api}/integrations`, payload);
  return response.data?.data?.id;
};
