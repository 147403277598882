import { Variable } from '@operto/variables-shared';
import { IPage } from 'redux/guestportal';

export const VARIABLE_BRACKET_LENGTH = 3;
export const MAX_VARIABLE_NAME_LENGTH = 40;

export enum VariableValueStatus {
  DISABLED = 'disabled',
  MISSING_SOME_VALUE = 'missing_some_value',
  MISSING_ALL_VALUE = 'missing_all_value',
}

export interface PositionAndTextObject {
  position: [number, number];
  text: string;
}

//TODO figureout why zod infered type is not autosuggesting
export type VariableProperty = {
  name: string;
  id: number;
  enabled: boolean;
  value: string;
  group: string;
  deactivated?: boolean;
};

export const getCustomVariableById = (variableId: string, customVariables: Variable[]) =>
  customVariables.find(variable => variable.id === variableId);

export const getAllVariables = (customVariables: Variable[], systemVariables: Variable[]) => {
  if (!customVariables || !systemVariables) return [];
  const allVariables = [...customVariables, ...systemVariables];
  return allVariables;
};

export const findVariableTextFromPosition = (mainText: string, position: [number, number]) => {
  return mainText.slice(position[0] + VARIABLE_BRACKET_LENGTH, position[1]);
};

export const getAllVariableNamesList = (variables: Variable[]) => {
  return variables.map((variable: Variable) => variable.name);
};

export const getTotalVariablePropertiesCount = (variable: Variable) => {
  return Object.keys(variable.properties).length;
};

export const getTotalShownVariablePropertiesCount = (variable: Variable) => {
  return Object.keys(variable.properties).filter(
    (propertyId: string) => variable.properties[propertyId].enabled,
  ).length;
};

export const getTotalAvailablePropertiesCount = (
  currentProperties: Record<string, { name: string }>,
  allAvailable: Record<string, { name: string }>,
) => {
  return Object.keys({ ...currentProperties, ...allAvailable }).length;
};

export const hasAllPropertiesWithEmptyValue = (variable: Variable) => {
  if (variable.type === 'system') return false;
  return Object.keys(variable.properties)
    .map((propertyId: string) => variable.properties[propertyId].value)
    .every((value: string) => value === '');
};

export const hasVariableAllEmptyValuesForProperties = (customVariables: Variable[]) => {
  let isAllEmpty = true;
  customVariables.forEach((variable: Variable) => {
    isAllEmpty = hasAllPropertiesWithEmptyValue(variable);
  });
  return isAllEmpty;
};

export const getPropertiesForCustomVariablesSet = (
  variableId: string,
  customVariables: Variable[],
): Record<number, VariableProperty> => {
  if (!variableId) {
    return;
  }

  return customVariables.find(variable => variable.id === variableId)?.properties;
};

export const hasSomePropertiesWithEmptyValue = (variable: Variable) => {
  if (variable.type === 'system') return false;
  return Object.keys(variable.properties)
    .map((propertyId: string) => variable.properties[propertyId].value)
    .some((value: string) => value === '');
};

export const hasVariableSomeEmptyValuesForProperties = (customVariables: Variable[]) => {
  let isSomeEmpty = true;
  customVariables.forEach((variable: Variable) => {
    isSomeEmpty = hasSomePropertiesWithEmptyValue(variable);
  });
  return isSomeEmpty;
};

export const getVariableStatusById = (variableId: string, customVariables: Variable[]) => {
  const variable = customVariables.find(variable => variable.id === variableId);
  if (!variable) {
    return '';
  }
  if (!variable?.enabled) {
    return VariableValueStatus.DISABLED;
  }

  if (hasAllPropertiesWithEmptyValue(variable)) {
    return VariableValueStatus.MISSING_ALL_VALUE;
  }

  if (hasSomePropertiesWithEmptyValue(variable)) {
    return VariableValueStatus.MISSING_SOME_VALUE;
  }

  return '';
};

export const findAllPositionsAndTexts = (mainText: string) => {
  const startRegex = /\*{{/g;
  const endRegex = /}}\*/g;
  const startMatches = [...mainText.matchAll(startRegex)];
  const endMatches = [...mainText.matchAll(endRegex)];

  const allPositionsAndTexts: PositionAndTextObject[] = [];

  if (startMatches.length === endMatches.length) {
    for (let idx = 0; idx < startMatches.length; idx++) {
      allPositionsAndTexts.push({
        position: [startMatches[idx].index, endMatches[idx].index],
        text: findVariableTextFromPosition(mainText, [
          startMatches[idx].index,
          endMatches[idx].index,
        ]),
      });
    }
  }
  return allPositionsAndTexts;
};

export const isPageOwnInvalidVariable = (page?: IPage, customVariables?: Variable[]): boolean => {
  if (!page || !customVariables) return false;
  const titleVariables = findAllPositionsAndTexts(page.title);
  const subtitleVariables = findAllPositionsAndTexts(page.subtitle);
  const descriptionVariables = findAllPositionsAndTexts(page.description);

  // name of all variables in a page
  const allVariablesNames = [...titleVariables, ...subtitleVariables, ...descriptionVariables].map(
    variable => variable.text,
  );

  let isInvalid = false;
  // all custom variables in page
  const customVariablesInPage = customVariables.filter(variable =>
    allVariablesNames.includes(variable.name),
  );

  customVariablesInPage.forEach(variable => {
    // check if any of the custom variables in page has empty value
    if (hasSomePropertiesWithEmptyValue(variable)) {
      isInvalid = true;
    }
    // check if any of the custom variables in page is disabled
    if (!variable.enabled) {
      isInvalid = true;
    }
  });

  return isInvalid;
};

/*
 * This function is used to generate a duplicate name for a variable.
 * It takes in the original name and a list of existing names and returns a duplicate name.
 * Example: test -> test copy -> test copy2 -> test copy3 -> ...
 *
 * @param string   name          - The original name of the variable
 * @param string[] existingNames - A list of existing names of variables
 * @returns A duplicate name for the variable same way as above example
 */
export const generateDuplicateName = (name: string, existingNames: string[]): string => {
  const FIRST_DIGIT_AFTER_FIRST_COPY = '2';
  const LEGTH_OF_COPY = 4;

  // Check if the original name already exists
  if (!existingNames.includes(name)) {
    return name + ' copy';
  }

  // Find the last "copy" suffix in the name
  let lastCopyIndex = -1;
  for (let i = name.length - 1; i >= 0; i--) {
    if (name.slice(i).startsWith('copy')) {
      lastCopyIndex = i;
      break;
    }
  }

  let newName = '';

  if (lastCopyIndex === -1) {
    // No "copy" suffix found, so add " copy"
    newName = name + ' copy';
  } else {
    // Extract the number from the "copy{number}" suffix
    const suffix = name.slice(lastCopyIndex);
    let number = parseInt(suffix.slice(LEGTH_OF_COPY));

    if (isNaN(number)) {
      // The suffix is not a number, so add "2"
      newName = name + FIRST_DIGIT_AFTER_FIRST_COPY;
    } else {
      // Increment the number and create the new suffix
      number++;
      const newSuffix = 'copy' + number;
      newName = name.slice(0, lastCopyIndex) + newSuffix;
    }
  }

  // Check if the new name conflicts with existing names and recursively generate a new name if necessary
  if (existingNames.includes(newName)) {
    return generateDuplicateName(newName, existingNames);
  } else {
    return newName;
  }
};

export const roundToNearestWholeNumber = (percentage: number) => {
  // use a custom function to handle undefined edge case
  if (!percentage) return 0;
  return Math.round(percentage);
};

export const htmlStringToLines = (htmlString: string) => {
  const elementArray = htmlString.split('\n');
  const result = elementArray.filter(line => !(line === '<ul>' || line === '</ul>'));

  return result;
};
