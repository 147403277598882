import { getCurrentMember } from 'member/state/memberActions';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';

export const UNIT_CODES = 'Unit Codes';
export const ASSOCIATE_ENTRIES = 'Associated Entries';
export const ADDITIONAL_DEVICES = 'Connected Locks';

const UnitAccessContainer = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCurrentMember());
  }, [dispatch]);

  return <Outlet />;
};

export default UnitAccessContainer;
