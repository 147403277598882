import { ErrorOutline } from '@mui/icons-material';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { companySelector } from 'company/state/companySelectors';
import { formatRelativeDate } from 'helper/date';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import { LockFilterType } from 'lock/lockType';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import {
  DeviceLockViewModel,
  UnitViewModel,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdGroupsQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdLastSyncRequestedAtQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdLocksQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsQuery,
  usePostAccessCompatibilityAssignLockMutation,
  usePostAccessCompatibilityLegacyCompanyIdSyncLocksMutation,
  usePostAccessCompatibilityUnassignLocksMutation,
} from 'services/novaApi';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { TableCell } from 'ui-library/Components/table/TableCell';
import LockAssignPropertyDropdown from './LockAssignPropertyDropdown';
import { LocksTitleBar } from './LocksTitleBar';
import { UnassignUnitDialog } from './UnassignUnitDialog';

export interface LocksTableProps {
  filterType: LockFilterType;
  menu?: React.ReactNode;
}

interface PagedResult<T> {
  data: T[];
  skip: number;
  take: number;
  total_count: number;
}

type BaseCheckboxProps = CheckboxProps & {
  disabled: boolean;
  selectedRows: number[];
  legacy_lock_id: number | null;
};

const INITIAL_LOCKS_STATE: PagedResult<DeviceLockViewModel> = {
  data: [],
  skip: 0,
  take: 100,
  total_count: 0,
};

const INITIAL_UNITS_STATE: PagedResult<UnitViewModel> = {
  data: [],
  skip: 0,
  take: 10,
  total_count: 0,
};

const LocksTable = ({ menu }: LocksTableProps) => {
  const company = useAppSelector(companySelector());
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const companyId = company?.id;

  const [locksPagination, setLocksPagination] = useState({
    pageNum: INITIAL_LOCKS_STATE.skip,
    numPerPage: INITIAL_LOCKS_STATE.take,
    keyword: '',
  });

  const [unitsPagination, setUnitsPagination] = useState({
    pageNum: INITIAL_UNITS_STATE.skip,
    numPerPage: INITIAL_UNITS_STATE.take,
    keyword: '',
  });

  const [unitsData, setUnitsData] = useState<UnitViewModel[]>([]); // Store all loaded units
  const [locksData, setLocksData] = useState<DeviceLockViewModel[]>([]); // Store locks data locally

  const { t } = useTranslation();
  const { snackbar } = useSnackbar();

  const [assignLock] = usePostAccessCompatibilityAssignLockMutation();
  const [unassignLocks] = usePostAccessCompatibilityUnassignLocksMutation();

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [locksBeingUnassigned, setLocksBeingUnassigned] = useState<number[]>([]);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]); // Track selected group IDs

  const {
    data: locks = INITIAL_LOCKS_STATE,
    isLoading: isLocksLoading,
    isFetching: isLocksFetching,
    refetch,
  } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdLocksQuery({
    legacyCompanyId: companyId,
    skip: locksPagination.pageNum * locksPagination.numPerPage,
    take: locksPagination.numPerPage,
    keyword: locksPagination.keyword,
  });

  const {
    data: units = INITIAL_UNITS_STATE,
    isLoading: isUnitsLoading,
    isFetching: isUnitsFetching,
    refetch: refetchUnits,
  } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsQuery({
    legacyCompanyId: companyId,
    skip: unitsPagination.pageNum,
    take: unitsPagination.numPerPage,
    keyword: unitsPagination.keyword,
    includeInGroups: selectedGroups.length ? selectedGroups : undefined,
  });

  const { data: groupsData } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdGroupsQuery({
    legacyCompanyId: companyId,
  });

  const [doSync] = usePostAccessCompatibilityLegacyCompanyIdSyncLocksMutation();

  const { data, refetch: refetchLastSyncedAt } =
    useGetAccessCompatibilityOnboardingByLegacyCompanyIdLastSyncRequestedAtQuery({
      legacyCompanyId: companyId,
    });

  const [lastSyncedAt, setLastSyncedAt] =
    useState<string | null>(data?.LastSyncRequestedAt) ?? null;

  const [hasSynced, setHasSynced] = useState(false);

  const handleSync = useCallback(async () => {
    try {
      await doSync({ legacyCompanyId: companyId });
      await refetchLastSyncedAt();
      await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for the sync to complete in Nova before refetching
      await refetch();
      if (hasSynced) {
        snackbar(t('sync_success_snackbar_text'));
      }
    } catch (error) {
      if (hasSynced) {
        snackbar(t('sync_error_snackbar_text'));
      }
    }
  }, [doSync, companyId, refetchLastSyncedAt, refetch, hasSynced, snackbar, t]);

  useEffect(() => {
    if (hasSynced) return;
    handleSync();
    setHasSynced(true);
  }, [handleSync, hasSynced]);

  const areAllCheckboxesDisabled = useCallback(() => {
    return locksData.every(lock => !lock.unit_id);
  }, [locksData]);

  useEffect(() => {
    if (data?.LastSyncRequestedAt) {
      setLastSyncedAt(data.LastSyncRequestedAt);
    }
  }, [data, setLastSyncedAt, refetchLastSyncedAt]);

  useEffect(() => {
    if (
      selectedGroups.length ||
      unitsPagination.keyword ||
      unitsPagination.pageNum !== INITIAL_UNITS_STATE.skip
    ) {
      refetchUnits();
    }
  }, [selectedGroups, unitsPagination.pageNum, unitsPagination.keyword, refetchUnits]);

  const handleLocksFetch = useCallback(
    (pageNum: number, numPerPage: number, searchValue?: string) => {
      setLocksPagination({ pageNum, numPerPage, keyword: searchValue || '' });
    },
    [],
  );

  const handleGroupSelect = (groupId: number) => {
    setSelectedGroups(
      prevGroups =>
        prevGroups.includes(groupId)
          ? prevGroups.filter(id => id !== groupId) // Remove group if it's already selected
          : [...prevGroups, groupId], // Add group if it's not selected
    );

    setUnitsPagination(prev => ({
      ...prev,
      pageNum: INITIAL_UNITS_STATE.skip,
      keyword: '',
    }));
  };

  useEffect(() => {
    if (!selectedGroups.length) {
      setUnitsData([]);
    }
  }, [selectedGroups]);

  const loadMoreUnits = () => {
    if (isUnitsLoading) return;

    if (unitsData.length >= units.total_count) return;

    setUnitsPagination(prev => {
      return {
        ...prev,
        pageNum: prev.pageNum + prev.numPerPage,
      };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (!value) return;
      setUnitsPagination(prev => ({
        ...prev,
        pageNum: INITIAL_UNITS_STATE.skip,
        keyword: value,
      }));
    }, 300),
    [],
  );

  const handleSearchChange = (value: string) => {
    setSearchKeyword(value);
    setUnitsPagination(prev => ({
      ...prev,
      pageNum: INITIAL_UNITS_STATE.skip,
      keyword: value,
    }));
    setUnitsData([]);
    debouncedSearch(value);
  };

  useEffect(() => {
    if (
      unitsPagination.pageNum === INITIAL_UNITS_STATE.skip &&
      (unitsPagination.keyword || selectedGroups.length)
    ) {
      setUnitsData(units.data || []);
      return;
    }
    setUnitsData(prevUnits => {
      const newUnitIds = new Set(units.data.map(unit => unit.unit_id));
      const filteredPrevUnits = prevUnits.filter(unit => !newUnitIds.has(unit.unit_id));
      return [...filteredPrevUnits, ...units.data];
    });
  }, [selectedGroups.length, units.data, unitsPagination.keyword, unitsPagination.pageNum]);

  useEffect(() => {
    if (locks?.data) {
      setLocksData(prevLocks =>
        locksPagination.keyword
          ? locks.data
          : uniqBy([...prevLocks, ...locks.data], 'legacy_lock_id'),
      );
    }
  }, [locks?.data, locksPagination.keyword, locksPagination.pageNum]);

  const assignLockTimeouts: Record<number, NodeJS.Timeout> = {};

  const handleAssignLock = (lockId: number, unitId: number, unitName: string) => {
    clearTimeout(assignLockTimeouts[lockId]);

    // Update the UI immediately
    setLocksData(prevLocks =>
      prevLocks.map(lock =>
        lock.legacy_lock_id === lockId ? { ...lock, unit_id: unitId, unit_name: unitName } : lock,
      ),
    );
    snackbar(`${t('lock_assigned_to')} '${unitName}'`, undoAction(lockId, unitId), 6000);

    // Disable the assigned unit in the units list
    setUnitsData(prevUnits =>
      prevUnits.map(unit => (unit.unit_id === unitId ? { ...unit, is_lock_assigned: true } : unit)),
    );

    assignLockTimeouts[lockId] = setTimeout(async () => {
      try {
        if (locksBeingUnassigned.includes(lockId)) {
          return;
        }
        await assignLock({
          assignLock: {
            legacyLockId: lockId,
            legacyPropertyId: unitId,
          },
        }).unwrap();

        delete assignLockTimeouts[lockId];
      } catch (error) {
        const errorMessage = error.data || t('error_assigning_lock');
        const systemMismatch = 'Mismatch in lock assignment status between nova and legacy systems';
        setTimeout(() => {
          if (errorMessage.includes(systemMismatch)) {
            snackbar(t('error_assigning_lock_system_mismatch'), null, 6000);
          } else {
            snackbar(`${t('error_assigning_lock')} '${unitName}'`, null, 6000);
          }
        }, 1000); // Delay the snackbar to show after re-render and prevent snackbar reset
        logger.error(error);
        setLocksData(prevLocks =>
          prevLocks.map(lock =>
            lock.legacy_lock_id === lockId ? { ...lock, unit_id: null, unit_name: null } : lock,
          ),
        );
        setUnitsData(prevUnits =>
          prevUnits.map(unit =>
            unit.unit_id === unitId ? { ...unit, is_lock_assigned: false } : unit,
          ),
        );
        setSelectedRows(prevSelectedRows =>
          prevSelectedRows.filter(selectedId => selectedId !== lockId),
        );
      }
    }, 5000);
  };

  const handleUndo = (lockId: number, unitId: number) => {
    clearTimeout(assignLockTimeouts[lockId]);

    setLocksData(prevLocks =>
      prevLocks.map(lock =>
        lock.legacy_lock_id === lockId ? { ...lock, unit_id: null, unit_name: null } : lock,
      ),
    );

    // Disable the assigned unit in the units list
    setUnitsData(prevUnits =>
      prevUnits.map(unit =>
        unit.unit_id === unitId ? { ...unit, is_lock_assigned: false } : unit,
      ),
    );
  };

  const undoAction = (lockId: number, unitId: number) => (
    <Button
      color='secondary'
      size='small'
      data-testid='undo-assign-lock-button'
      sx={{ color: 'white', textTransform: 'none', fontWeight: 'bold' }}
      onClick={() => {
        handleUndo(lockId, unitId);
        snackbar(t('snackbar_undoing'), null);
        setTimeout(() => {
          snackbar(t('snackbar_undone'), null);
        }, 3000);
      }}
    >
      {t('snackbar_undo_button')}
    </Button>
  );

  const renderAccountInfo = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return (
      <Tooltip
        title={
          <div>
            {row.provider_account_name}
            <br />
            {row.provider_account_user_email}
          </div>
        }
      >
        <div>
          <TableCell
            title={row.provider_account_name}
            description={row.provider_account_user_email}
          ></TableCell>
        </div>
      </Tooltip>
    );
  };

  const renderLockName = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return (
      <Box display='flex' alignItems='center' gap={1}>
        {!row.is_active && (
          <Tooltip title={t('tooltip_inactive_lock', { lock_provider: row.provider_account_name })}>
            <ErrorOutline sx={{ color: '#D32F2F', fontSize: 24 }} />
          </Tooltip>
        )}
        <TableCell title={row.lock_friendly_name} description={`ID ${row.legacy_lock_id}`} />
      </Box>
    );
  };

  const renderPropertyName = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    const isUnassigning = locksBeingUnassigned.includes(row.legacy_lock_id);

    if (isUnassigning) {
      return (
        <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
          <CircularProgress size={24} />
        </Box>
      );
    }

    if (row.unit_id) {
      return <TableCell title={row.unit_name} description={`ID ${row.unit_id}`} />;
    }

    return (
      <LockAssignPropertyDropdown
        data-testid='lock-assign-property-dropdown'
        unitList={unitsData}
        onLoadMore={loadMoreUnits}
        hasMore={units.total_count > unitsData.length}
        isLoading={isUnitsLoading}
        isFetching={isUnitsFetching}
        onAssign={(unitId, unitName) => {
          if (row.legacy_lock_id) {
            handleAssignLock(row.legacy_lock_id, unitId, unitName);
          }
        }}
        searchKeyword={searchKeyword}
        onSearchChange={handleSearchChange}
        groupList={[...(groupsData?.groups || [])]}
        selectedGroups={selectedGroups}
        onGroupSelect={handleGroupSelect}
      />
    );
  };

  const renderConnectivity = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {row.is_online ? (
          <CloudDoneOutlinedIcon sx={{ color: 'rgb(46, 125, 50, 1)', marginRight: 1 }} />
        ) : (
          <CloudOffOutlinedIcon sx={{ color: 'rgba(211, 47, 47, 1)', marginRight: 1 }} />
        )}
        <TableCell
          title={row.is_online ? 'Online' : 'Offline'}
          sx={{
            color: row.is_online ? 'rgb(46, 125, 50, 1)' : 'rgba(211, 47, 47, 1)',
            marginRight: 1,
          }}
        />
      </Box>
    );
  };

  const renderLocksNameHeader = () => {
    return (
      <Box display='flex' alignItems='center'>
        <Typography sx={headerStyles}>Lock</Typography>
        <Tooltip title={t('tooltip_lock_column_header')}>
          <IconButton size='small'>
            <HelpOutlineIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const renderUnitHeader = () => {
    return (
      <Box display='flex' alignItems='center'>
        <Typography sx={headerStyles}>Unit</Typography>
        <Tooltip title={t('tooltip_unit_column_header')}>
          <IconButton size='small'>
            <HelpOutlineIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const columnsDef: GridColDef<DeviceLockViewModel>[] = [
    {
      field: 'provider_account_name',
      headerName: 'Account',
      flex: 1,
      renderCell: renderAccountInfo,
      sortable: false,
    },
    {
      field: 'lock_friendly_name',
      headerName: 'Lock',
      renderHeader: renderLocksNameHeader,
      flex: 1,
      renderCell: renderLockName,
      sortable: false,
    },
    {
      field: 'unit_id',
      headerName: 'Unit',
      flex: 1,
      renderCell: renderPropertyName,
      renderHeader: renderUnitHeader,
      sortable: false,
    },
    {
      field: 'is_online',
      headerName: 'Connectivity',
      flex: 1,
      renderCell: renderConnectivity,
      sortable: false,
    },
  ];

  const handleSelectionChange = (newSelection: number[]) => {
    setSelectedRows(newSelection);
  };

  const handleUnassignLocks = () => {
    setDialogOpen(true);
  };

  const handleCancelUnassign = () => {
    setDialogOpen(false);
  };

  const handleConfirmUnassign = async () => {
    setLocksBeingUnassigned(selectedRows);

    try {
      const lockIdsToUnassign = selectedRows
        .map(row => {
          const lockToUnassign = locksData.find(lock => lock.legacy_lock_id === row);
          return lockToUnassign ? lockToUnassign.legacy_lock_id : null;
        })
        .filter(id => id !== null);

      await unassignLocks({
        unassignLocks: { legacyLockIds: lockIdsToUnassign },
      }).unwrap();

      // Update locksData and unitsData after successful unassignment
      setLocksData(prevLocks =>
        prevLocks.map(lock =>
          lockIdsToUnassign.includes(lock.legacy_lock_id)
            ? { ...lock, unit_id: null, unit_name: null }
            : lock,
        ),
      );

      setUnitsData(prevUnits =>
        prevUnits.map(unit => {
          const isUnassigned = lockIdsToUnassign.some(
            lockId =>
              locksData.find(lock => lock.legacy_lock_id === lockId)?.unit_id === unit.unit_id,
          );

          return isUnassigned ? { ...unit, is_lock_assigned: false } : unit;
        }),
      );

      snackbar(
        selectedRows.length === 1
          ? t('single_unassign_snackbar_success_text')
          : `${selectedRows.length} ${t('multiple_unassign_snackbar_success_text')}`,
      );

      setSelectedRows([]);
    } catch (error) {
      if (error && error.status === 400) {
        const errorMessage = error.data;
        const errorMessageUninstalling = 'Locks are already in the process of being unassigned';
        if (errorMessage.includes(errorMessageUninstalling)) {
          snackbar(t('unassign_locks_error_snackbar_text_uninstalling'));
        } else {
          snackbar(t('unassign_locks_error_snackbar_text'));
        }
      }
      logger.error(error);
    } finally {
      setLocksBeingUnassigned([]);
      setDialogOpen(false);
    }
  };

  const toolbarActions = (
    <>
      {menu}
      <Button
        size='medium'
        variant='contained'
        color='primary'
        name='unassign'
        data-testid='unassign-locks-button'
        onClick={handleUnassignLocks}
        sx={{ textTransform: 'none' }}
        disabled={selectedRows.length <= 0}
      >
        {t('unassign_locks_button')} ({selectedRows.length})
      </Button>
      <Button
        size='medium'
        variant='outlined'
        startIcon={<SyncIcon />}
        sx={{ ml: 2, textTransform: 'none', mr: 2 }}
        onClick={handleSync}
      >
        {t('sync_now_button')}
      </Button>
      <Text.BodySmallSemiBold>
        {lastSyncedAt &&
          t('last_synced_with_time', {
            time: formatRelativeDate(lastSyncedAt, browserTimezone ?? 'UTC'),
          })}
      </Text.BodySmallSemiBold>
    </>
  );

  const BaseCheckboxWithTooltip = React.forwardRef<HTMLDivElement, BaseCheckboxProps>(
    (props, ref) => {
      const { disabled, ...rest } = props;
      const shouldDisable = rest['inputProps']['aria-label'] === 'Select all rows';

      return (
        <Tooltip
          title={
            disabled || (shouldDisable && areAllCheckboxesDisabled())
              ? t('tooltip_disabled')
              : t('tooltip_select')
          }
        >
          <div ref={ref}>
            <Checkbox
              {...rest}
              disabled={disabled || (shouldDisable && areAllCheckboxesDisabled())}
            />
          </div>
        </Tooltip>
      );
    },
  );

  BaseCheckboxWithTooltip.displayName = 'BaseCheckboxWithTooltip';
  return (
    <>
      <Box sx={{ marginBottom: '-36px' }}>
        <LocksTitleBar handleReloadOnChange={refetch} />
      </Box>
      <PaginatedTable
        enableToolbar
        title={toolbarActions}
        rows={locksData}
        rowCount={locks.total_count}
        loading={isLocksLoading || isLocksFetching || isLocksLoading}
        onFetch={handleLocksFetch}
        columns={columnsDef}
        getRowId={row => row.legacy_lock_id}
        sortingMode='server'
        checkboxSelection
        selectionModel={selectedRows}
        onSelectionModelChange={handleSelectionChange}
        isRowSelectable={(params: GridRowParams) => !!params.row.unit_id}
        components={{
          BaseCheckbox: BaseCheckboxWithTooltip,
        }}
      />

      <UnassignUnitDialog
        open={isDialogOpen}
        onCancel={handleCancelUnassign}
        onUnassign={handleConfirmUnassign}
        loading={locksBeingUnassigned.length > 0}
      />
    </>
  );
};

const headerStyles = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '23.94px',
  letterSpacing: '0.17px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
};

export default LocksTable;
