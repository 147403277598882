import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { TitlebarRoot, TitlebarTitleIcon } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { currentUserSelector } from 'user/state/userSelectors';

const OneDashboardTitlebar = () => {
  const { t } = useTranslation();
  const user = useAppSelector(currentUserSelector());
  const name = user?.name;

  return (
    <TitlebarRoot>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Box>
          <TitlebarTitleIcon>
            <DashboardOutlinedIcon />
          </TitlebarTitleIcon>
        </Box>

        <Stack>
          <Typography variant={'h2-700'}>{t('dashboard')}</Typography>
          <Typography variant={'h4-300'}>{t('hi_name', { name: name || t('there') })}</Typography>
        </Stack>
      </Stack>
    </TitlebarRoot>
  );
};

export default OneDashboardTitlebar;
