import Typography from '@mui/material/Typography';
import { logger } from 'lib/logger';
import React from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';
import { cancelPmsReservation } from 'reservation/state/reservationActions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import ConfirmDialog from './ConfirmDialog';

export interface RevokeReservationDialogProps {
  onClose: () => void;
  open: boolean;
  reservation: IReservation;
}

const RevokeReservationDialog = ({ onClose, open, reservation }: RevokeReservationDialogProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleRevokeReservaton = async () => {
    try {
      setLoading(true);
      await dispatch(cancelPmsReservation(reservation.id));
      onClose();
    } catch (error) {
      logger.error(`revoking reservation: ${reservation.id}, error: ${error}`);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Error Revoking Reservation',
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
    setLoading(false);
  };

  return (
    <ConfirmDialog
      open={open}
      title='Revoke Reservation'
      onCancel={onClose}
      submitButtonText='Revoke'
      submitButtonColor='error'
      onSubmit={handleRevokeReservaton}
      loading={loading}
    >
      <Typography variant='body1'>Are you sure you want to revoke this reservation?</Typography>
    </ConfirmDialog>
  );
};

export default RevokeReservationDialog;
