import MenuVerticalIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, SxProps } from '@mui/material';
import Menu from '@mui/material/Menu';
import DeleteReservationDialog from 'Common/Dialog/DeleteReservationDialog';
import RegrantReservationDialog from 'Common/Dialog/RegrantReservationDialog';
import RevokeReservationDialog from 'Common/Dialog/RevokeReservationDialog';
import SecurityDepositDialog from 'Common/Dialog/SecurityDepositDialog';
import BottomSlidebar from 'Common/Slidebar/BottomSlidebar';
import { ProviderTypes } from 'company/companyType';
import { companySelector } from 'company/state/companySelectors';
import { IPaymentInfo, PaymentStatus } from 'guest/guestType';
import { useChekinIntegration } from 'hooks/Chekin/useChekinIntegration';
import { useVerification } from 'hooks/useVerification';
import { IntegrationsApiSystem } from 'integrations/integrationsTypes';
import { findEnabledIntegrationsApiSystemSelector } from 'integrations/state/integrationsSelectors';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { toggleSlidebar, toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  IReservation,
  VERIFICATION_STATUS_TYPE,
  VerificationDocumentType,
} from 'reservation/reservationType';
import { manuallyOverrideVerification } from 'reservation/state/reservationActions';
import { SlidebarType, SnackbarTypes, SnackbarVariant } from 'types/ui';
import ReservationActionMenuItems from './ReservationActionMenuItems';

export interface ReservationDropdownDrawerProps {
  reservation: IReservation | null;
  showEdit?: boolean;
  accessCode?: string;
  paymentInfo?: IPaymentInfo;
  verifyOnClick?: () => void;
  onReload?: () => void;
  verifyDisabled?: boolean;
  filterType?: string;
  onEdit?: () => void;
  isFillLight?: boolean;
}

const ReservationDropdownDrawer = ({
  reservation,
  showEdit,
  accessCode,
  verifyOnClick,
  onReload,
  filterType,
  verifyDisabled,
  onEdit,
  paymentInfo,
  isFillLight,
}: ReservationDropdownDrawerProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const company = useAppSelector(companySelector());
  const reservationCodeStatus = reservation?.code_status;
  const isPaymentSystemEnabled = useAppSelector(
    findEnabledIntegrationsApiSystemSelector(IntegrationsApiSystem.PAYMENT),
  );
  const [openActionBar, setOpenActionBar] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openRevoke, setOpenRevoke] = useState(false);
  const [openRegrant, setOpenRegrant] = useState(false);
  const [openSD, setOpenSD] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(reservation?.verification_status);
  const { isEnabled: isChekinEnabled } = useChekinIntegration();
  const { updateVerificationStatus } = useVerification();
  const isDocumentReady =
    isChekinEnabled() &&
    (reservation.verification_status === VERIFICATION_STATUS_TYPE.CONFIRMED ||
      reservation.verification_status == VERIFICATION_STATUS_TYPE.FLAGGED);

  const iconButton: SxProps = {
    color: isFillLight ? 'text.white' : 'text.primary',
  };

  // Will move this to env later ugh need to add it into circle config as well
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onReservationEditClick = () => {
    if (onEdit) {
      onEdit();
    } else {
      dispatch(
        toggleSlidebar(SlidebarType.EDIT_RESERVATIONS, {
          reservationId: reservation.id, // ensure this is IReservation type, some component passes IGuest
          filterType,
        }),
      );
    }

    handleClose();
  };

  const closeDialog = () => {
    setOpenCancel(false);
    setOpenRevoke(false);
    setOpenRegrant(false);

    handleClose();
  };
  const handleClose = () => {
    if (typeof onReload !== 'undefined') {
      onReload();
    }
    setAnchorEl(null);
  };

  const onCopyClick = (name: string) => {
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Copied ${name} value.`,
        variant: SnackbarVariant.INFO,
      }),
    );
    handleClose();
  };

  const onVerifyClick = () => {
    verifyOnClick();
    setAnchorEl(null);
  };

  const onManualConfirmClick = () => {
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: 'Manually updating guest verificaiton.',
        variant: SnackbarVariant.INFO,
      }),
    );

    if (isChekinEnabled()) {
      updateVerificationStatus({
        companyId: company?.id.toString(),
        reservationId: reservation?.external_id,
        guestId: 'primary',
        status: 'MANUALLY_VERIFIED',
      });
    } else {
      dispatch(manuallyOverrideVerification(reservation?.id)).then(() => {
        setAnchorEl(null);
        setVerificationStatus(VERIFICATION_STATUS_TYPE.MANUALLY_CONFIRMED);
        onReload();
      });
    }
  };

  const onManualSecurityDepositClick = () => {
    setOpenSD(true);
  };
  const onMessageClicked = (reservation: IReservation) => {
    navigate(`/messenger/${company?.id}-${reservation.id}`);
  };

  const onSendGPLinkClick = (reservation: IReservation, gpLink: string) => {
    dispatch(
      toggleSlidebar(SlidebarType.SEND_GP_LINK, {
        reservationId: reservation.id, // ensure this is IReservation type, some component passes IGuest
        gpLink,
      }),
    );

    handleClose();
  };

  const onVerificationDocumentClick = async (documentType: VerificationDocumentType) => {
    navigate(`/guests/documents/${reservation?.external_id}/${documentType}`);
  };

  const disableManualOverride =
    verificationStatus === VERIFICATION_STATUS_TYPE.CONFIRMED ||
    verificationStatus === VERIFICATION_STATUS_TYPE.MANUALLY_CONFIRMED ||
    verificationStatus === VERIFICATION_STATUS_TYPE.CONFIRMED_BY_FLOW ||
    reservation.verification_status === VERIFICATION_STATUS_TYPE.MANUALLY_CONFIRMED ||
    reservation.verification_status === VERIFICATION_STATUS_TYPE.CONFIRMED;

  const isVerificationProviderConfirmable = useMemo(
    () =>
      (company.verificationProvider !== ProviderTypes.NONE &&
        company.verificationProvider !== ProviderTypes.AUTOHOST) ||
      isChekinEnabled(),
    [company.verificationProvider, isChekinEnabled],
  );

  const disableManualSD =
    paymentInfo?.status !== PaymentStatus.DECLINED &&
    paymentInfo?.status !== PaymentStatus.PENDING &&
    paymentInfo?.status !== PaymentStatus.MANUALLY_ACCEPTED;
  const SDLabel = paymentInfo?.notes
    ? 'Edit Manual Security Deposit'
    : 'Manually Accept Security Deposit';

  const reservationActionMenuItemsProperties = {
    reservation,
    showEdit,
    verifyOnClick,
    verifyDisabled,
    isVerificationProviderConfirmable,
    isDocumentReady,
    disableManualOverride,
    isPaymentSystemEnabled,
    SDLabel,
    disableManualSD,
    accessCode,
    reservationCodeStatus,
    setOpenRegrant,
    setOpenRevoke,
    setOpenCancel,
    onReservationEditClick,
    onSendGPLinkClick,
    onMessageClicked,
    onVerifyClick,
    onManualConfirmClick,
    onManualSecurityDepositClick,
    onVerificationDocumentClick,
    onCopyClick,
  };

  return (
    <Box sx={dropDownDrawerContainer}>
      <IconButton
        sx={iconButton}
        size='small'
        onClick={isMobile ? () => setOpenActionBar(!openActionBar) : handleClick}
        data-testid='reservationDropDownButton'
      >
        <MenuVerticalIcon />
      </IconButton>
      {reservation?.source === 'dashboard' && (
        <DeleteReservationDialog
          reservation={reservation}
          open={openCancel}
          onClose={closeDialog}
        />
      )}
      {reservation && (
        <>
          <RevokeReservationDialog
            reservation={reservation}
            open={openRevoke}
            onClose={closeDialog}
          />
          <RegrantReservationDialog
            reservation={reservation}
            open={openRegrant}
            onClose={closeDialog}
          />
        </>
      )}
      {isPaymentSystemEnabled && reservation && paymentInfo && openSD && (
        <SecurityDepositDialog
          paymentInfo={paymentInfo}
          reservation={reservation}
          open={openSD}
          onClose={() => {
            // we don't wnat to call close dialog because it refreshes the rows
            setOpenSD(false);
            setAnchorEl(null);
          }}
        />
      )}
      {isMobile ? (
        <BottomSlidebar
          anchor='bottom'
          open={openActionBar}
          setOpen={() => {
            setOpenActionBar(!openActionBar);
          }}
          element={<ReservationActionMenuItems {...reservationActionMenuItemsProperties} />}
        />
      ) : (
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <ReservationActionMenuItems {...reservationActionMenuItemsProperties} />
        </Menu>
      )}
    </Box>
  );
};

const dropDownDrawerContainer: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export default ReservationDropdownDrawer;
