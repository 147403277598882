import { createStripeConnectAccountLink, updateCompanyContext } from 'api/paymentAPI';
import Stripe from 'assets/img/integrations/stripe.svg';
import { companySelector } from 'company/state/companySelectors';
import { enabledIfVariablesInitialized } from 'helper/reactHookEnabledHelper';
import { useGetCompanyContext } from 'hooks/usePayments';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { userSelector } from 'user/state/userSelectors';
import { IntegrationsCard } from '../IntegrationsCard';
import StripeAccountIdForm from './StripeAccountIdForm';

const STRIPE_INFO_URL = `${process.env.REACT_APP_CLIENT_SUPPORT_URL}/s/article/how-to-enable-upsells#EnableStripeConnect`;

export const StripeConnectIntegrationCard = () => {
  const { t } = useTranslation();
  const company = useSelector(companySelector());
  const companyId: string = company.id.toString();
  const [isConnected, setIsConnected] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const dispatch = useAppDispatch();

  const [isAccountIdFormOpen, setIsAccountIdFormOpen] = useState(false);
  const [accountId, setAccountId] = useState('');

  const [isRedirectLoading, setIsRedirectLoading] = useState(false);

  const { accessToken } = useAppSelector(userSelector());

  const { data: companyContext, isLoading: isCompanyContextLoading } = useGetCompanyContext(
    {
      params: { companyId },
      queries: { withOnboardingStatus: 'true' },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      ...enabledIfVariablesInitialized(accessToken, companyId),
      refetchOnWindowFocus: false,
    },
  );

  const getActionTitle = () => {
    if (isConnected && !isEnabled) {
      return t('edit');
    }

    if (isConnected && isEnabled) {
      return t('resync');
    }

    return undefined;
  };

  const openStripeAccountLink = useCallback(
    async (stripeAccountId: string) => {
      try {
        const accountLinkResponse = await createStripeConnectAccountLink(stripeAccountId);
        const url = accountLinkResponse.data.data.url;
        window.open(url, '_self');
      } catch (error) {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: t('stripe_account_onboarding_link_error'),
            variant: SnackbarVariant.ERROR,
          }),
        );
        setIsEnabled(false);
        setIsConnected(false);
        setIsRedirectLoading(false);
      }
    },
    [dispatch, t],
  );

  useEffect(() => {
    setIsConnected(Boolean(companyContext?.data?.stripe?.accountId));
    setIsEnabled(companyContext?.data?.stripe?.isAccountSetupComplete === true);
  }, [companyContext]);

  useEffect(() => {
    if (!!accountId) {
      setIsRedirectLoading(true);
      updateCompanyContext(company.id, { stripe: { accountId } });
      openStripeAccountLink(accountId);
    }
  }, [accountId, company.id, openStripeAccountLink]);

  return (
    <>
      <StripeAccountIdForm
        open={isAccountIdFormOpen}
        accountId={companyContext?.data?.stripe?.accountId ?? ''}
        handleSetAccountId={accountIdUserInput => {
          setIsAccountIdFormOpen(false);
          setAccountId(accountIdUserInput);
        }}
        handleCancel={() => setIsAccountIdFormOpen(false)}
      />
      <IntegrationsCard
        title='Stripe Connect'
        connected={isConnected}
        enabled={isEnabled}
        loading={isRedirectLoading || isCompanyContextLoading}
        actionTitle={getActionTitle()}
        onInfoClick={() => window.open(STRIPE_INFO_URL, '_blank')}
        onActionClick={() => setIsAccountIdFormOpen(true)}
      >
        <Stripe />
      </IntegrationsCard>
    </>
  );
};
