import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as FullStory from '@fullstory/browser';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { RootContainer, ThemeProvider } from '@operto/ui';
import { OpertoThemeProvider } from '@operto/ui-library';
import { OpertoLogger } from 'Logger/logger';
import Masquerade from 'Pages/Masquerade/Masquerade';
import { AuthComponent } from 'auth/AuthComponent';
import { ICompany } from 'company/companyType';
import { getCompanyInformation } from 'company/state/companyAction';
import { getCurrentCompany } from 'company/state/companySelectors';
import GA4React from 'ga-4-react';
import { ClientContext, streamChatClient } from 'helper/streamChatHelper';
import useCognitoAuthFlow from 'hooks/useCognitoToken';
import { useSharedLocalStorageReceiver } from 'hooks/useSharedLocalStorageReceiver';
import { getIntegrations } from 'integrations/state/integrationsActions';
import { useAnalytics } from 'lib/analytics';
import { RELEASE_STAGE, isProduction } from 'lib/constants';
import { IMember } from 'member/memberType';
import { getCurrentMember, getMembers } from 'member/state/memberActions';
import { getSelf } from 'member/state/memberSelectors';
import { getProperties } from 'property/state/propertyActions';
import React, { useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { isAdmin } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { store } from 'redux/store';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import { AppRoutes } from './AppRoutes';
import { OpertoTheme } from './OpertoTheme';

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO_LICENSE_KEY || '');

const ga4react = new GA4React(
  'G-EYN26B8313',
  {
    debug_mode: !isProduction,
    /* ga custom config, optional */
  },
  [
    /* additional code, optional */
  ],
  5000 /* timeout, optional, defaults is 5000 */,
);
ga4react.initialize().catch(() => {
  // handle error
});

if (isProduction && !isAdmin()) {
  hotjar.initialize(2410892, 6);
}

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
  releaseStage: RELEASE_STAGE,
  onError: report => {
    if (FullStory.isInitialized()) {
      report.addMetadata('fullstory', { urlAtTime: FullStory.getCurrentSessionURL(true) });
    }
  },
});
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

interface ExternalRedirectProps {
  to: string;
}

const ExternalRedirect = ({ to }: ExternalRedirectProps): null => {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  // Render nothing while redirecting
  return null;
};

const App = () => {
  useAnalytics();
  const dispatch = useAppDispatch();
  const isCognitoAuthenticated = useCognitoAuthFlow();
  const loggedInMember: UserState = useAppSelector(userSelector());
  const currentMember: IMember = useAppSelector(getSelf());
  const currentCompany: ICompany = useAppSelector(getCurrentCompany());
  const [clientObject, setClientObject] = useState({
    streamChatClient,
    clientReady: false,
    clientUnreadCount: 0,
  });

  useSharedLocalStorageReceiver();

  useEffect(() => {
    if (isCognitoAuthenticated) {
      history.replaceState({}, null, '/');
    }
  }, [isCognitoAuthenticated]);

  useEffect(() => {
    dispatch(getCurrentMember());
    dispatch(getCompanyInformation());
    dispatch(getIntegrations());
    dispatch(getProperties());
    dispatch(getMembers());
  }, [dispatch]);

  useEffect(() => {
    type StreamResponseData = {
      me: {
        total_unread_count: number;
      };
    };

    const setupClient = async () => {
      const streamUserId = loggedInMember?.getStreamUser?.id;
      if (!streamUserId) {
        return;
      }

      const name = currentMember?.name;
      const bearerToken = loggedInMember?.getStreamUser?.token;
      try {
        if (streamChatClient?.user) {
          await streamChatClient.disconnectUser();
        }

        const data = await streamChatClient.connectUser(
          {
            id: streamUserId,
            name,
          },
          bearerToken,
        );

        const result = data as StreamResponseData;
        clientObject.clientUnreadCount = result?.me?.total_unread_count as number;
        setClientObject({ ...clientObject, clientReady: true });
      } catch (err) {
        OpertoLogger.Log(err);
      }
    };

    setupClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInMember?.getStreamUser?.id]);

  useEffect(() => {
    // get company every three minutes to check if the token is expired or not
    const id = setInterval(() => {
      dispatch(getCompanyInformation(currentCompany));
    }, 180000);
    return () => {
      clearInterval(id);
    };
  }, [currentCompany, dispatch]);

  return (
    <ErrorBoundary>
      <ClientContext.Provider value={{ clientObject, setClientObject }}>
        <AppRoutes />
      </ClientContext.Provider>
    </ErrorBoundary>
  );
};

//TODO: delete LoginPage, ForgotPasswordPage, SetPassword
const AppRouter = () => {
  return (
    <RootContainer>
      <Provider store={store}>
        <StyledComponentsThemeProvider theme={OpertoTheme}>
          <OpertoThemeProvider theme={OpertoTheme}>
            <ThemeProvider>
              <Router>
                <Routes>
                  <Route
                    path='/login'
                    element={<ExternalRedirect to={process.env.REACT_APP_AMPLIFY_APP_AUTH_URL} />}
                  />
                  <Route
                    path='/forgot'
                    element={<ExternalRedirect to={process.env.REACT_APP_AMPLIFY_APP_AUTH_URL} />}
                  />
                  <Route
                    path='/reset-password'
                    element={<ExternalRedirect to={process.env.REACT_APP_AMPLIFY_APP_AUTH_URL} />}
                  />
                  <Route path='/masquerade' element={<Masquerade />} />
                  <Route
                    path='/*'
                    element={
                      <AuthComponent store={store}>
                        <App />
                      </AuthComponent>
                    }
                  />
                </Routes>
              </Router>
            </ThemeProvider>
          </OpertoThemeProvider>
        </StyledComponentsThemeProvider>
      </Provider>
    </RootContainer>
  );
};

export default AppRouter;
