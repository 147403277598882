import Typography from '@mui/material/Typography';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { ICode } from 'code/codeType';
import { deleteBackupCode } from 'code/state/codeAction';
import * as React from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

export interface DeleteBackupCodeDialogProps {
  onCancel: () => void;
  open?: boolean;
  code: ICode;
  propertyId: number;
  onSubmit: (codeId: number) => void;
}

const DeleteBackupCodeDialog = ({
  onCancel,
  code,
  propertyId,
  onSubmit,
  open = true,
}: DeleteBackupCodeDialogProps) => {
  const dispatch = useAppDispatch();

  const handleDeleteBackupCode = () => {
    onCancel();
    onSubmit(code.id);
    dispatch(deleteBackupCode(code.id, Number(propertyId)))
      .then(() => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Successfully deleted',
            variant: SnackbarVariant.SUCCESS,
          }),
        );
      })
      .catch(err => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: err?.message,
            variant: SnackbarVariant.ERROR,
          }),
        );
      });
  };

  return (
    <ConfirmDialog
      open={open}
      title='Delete Backup Code'
      onCancel={onCancel}
      submitButtonText='Delete'
      submitButtonColor='error'
      onSubmit={handleDeleteBackupCode}
    >
      <Typography variant='body1'>Are you sure you want to delete this Backup Code?</Typography>
    </ConfirmDialog>
  );
};

export default DeleteBackupCodeDialog;
