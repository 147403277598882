import { DataObjectOutlined } from '@mui/icons-material';
import { Box, Button, Stack, SxProps, TextField, Typography } from '@mui/material';
import { theme } from '@operto/ui';
import ConfirmLeaveDialog, { CommNavigationType } from 'Common/Dialog/ConfirmLeaveDialog';
import InsertVariablesMenu from 'Pages/Variables/Common/InsertVariablesMenu';
import { companySelector } from 'company/state/companySelectors';
import useCommunications from 'hooks/useCommunications';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import { TextInsert } from 'ui-library/Helpers/textHelper';
import LanguageSelector from '../../../Common/LanguageSelector/LanguageSelector';
import { MAX_SMS_LENGTH, SMSTemplateType } from './CommunicationsSMSTemplatePage';

export default function CommunicationsSMSTemplateEdit({
  template,
  onChange,
  isTemplateDirty,
}: {
  template?: SMSTemplateType;
  onChange: (template: SMSTemplateType) => void;
  isTemplateDirty: boolean;
}) {
  const { t, languages } = useTranslation();
  const navigate = useNavigate();
  const company = useAppSelector(companySelector());
  const { isFeatureEnabled } = useAppFeatures();

  const { data: communications } = useCommunications();
  const isCustomVariablesEnabled =
    isAdmin() || isFeatureEnabled('communicationsCustomVariablesInSms', company.id);

  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [currentRef, setCurrentRef] = useState<HTMLButtonElement | HTMLInputElement | null>(
    inputRef.current,
  );
  const [currentButtonLabelCursorPos, setCurrentButtonLabelCursorPos] = useState(0);
  const [textOverflowed, setTextOverflowed] = useState(false);
  const [dialogState, setDialogState] = useState<CommNavigationType | undefined>(undefined);

  const { locale, id, message } = template || {};
  const characterCount = message?.length || 0;

  const communicationLocales = communications?.find(c => c.id === id)?.locales ?? [];
  const locales = [...new Set([...communicationLocales, locale])];

  const missingLocales = languages.filter(l => !locales.includes(l.locale));
  const arrayMissingLocales = missingLocales?.map(l => l.locale);

  const handleLanguageChange = (newLocale: string, type: 'create' | 'edit') => {
    if (isTemplateDirty) {
      setDialogState({ language: newLocale, navigationType: type });
    } else {
      handleLanguageNavigation(newLocale, type);
    }
  };

  const handleLanguageNavigation = (selectedLanguage: string, type: 'create' | 'edit') => {
    navigate(
      `/communications/${type}/sms/${id}/${selectedLanguage !== 'en' ? selectedLanguage : ''}`,
    );
  };

  const handleConfirmDialogSubmit = () => {
    if (dialogState) {
      handleLanguageNavigation(dialogState.language, dialogState.navigationType);
    }
    setDialogState(undefined);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMessage = e.target.value;

    if (newMessage.length > MAX_SMS_LENGTH) {
      setTextOverflowed(true);
    } else {
      setTextOverflowed(false);
    }

    onChange({ ...template, message: newMessage });
  };

  const handleCursorPosition = () => {
    setCurrentRef(inputRef.current);
    setCurrentButtonLabelCursorPos(inputRef?.current?.selectionEnd);
  };

  const handleVariableInsert = (newText: string, position: number) => {
    const { textarea, scrollTop } = getTextAreaAndScrollPosition();
    onChange({ ...template, message: newText });
    restoreTextAreaScrollAndCursorPosition(textarea, scrollTop, position);
  };

  const handleVariableButtonClick = () => {
    setCurrentRef(buttonRef.current);
    onChange({
      ...template,
      message: TextInsert(message, '*{{Enter Variable Name}}*', currentButtonLabelCursorPos),
    });
  };

  function getTextAreaAndScrollPosition() {
    const textarea = currentRef as HTMLInputElement;
    const scrollTop = textarea?.scrollTop ?? 0;
    return { textarea, scrollTop };
  }

  function restoreTextAreaScrollAndCursorPosition(
    textarea: HTMLInputElement,
    scrollTop: number,
    position: number,
  ) {
    // After inserting a replacement variable, the textarea content is fully updated.
    // Using setTimeout ensures the DOM has finished rendering the updates before
    // restoring the scroll position and cursor, preventing timing issues.
    setTimeout(() => {
      if (!textarea) {
        return;
      }
      textarea.scrollTop = scrollTop;
      textarea.setSelectionRange(position, position);
      textarea.focus();
    }, 0);
  }

  useEffect(() => {
    if (inputRef.current) {
      setCurrentRef(inputRef.current);
    }
  }, []);

  return (
    <Stack sx={rootStyles}>
      <Box sx={languageSelectorContainerStyles}>
        <LanguageSelector
          label={t('language_version')}
          value={locale}
          onChange={selectedLocale => {
            handleLanguageChange(selectedLocale, 'edit');
          }}
          onSelectNewLanguage={selectedLocale => {
            handleLanguageChange(selectedLocale, 'create');
          }}
          disableNewLanguage={!id}
          languageList={locales}
          newLanguageList={arrayMissingLocales}
        />
      </Box>

      <Stack flexDirection={'row'} alignItems={'center'}>
        <Typography variant='body-sm-400' flex={1}>
          {t('communications_sms_description')}
        </Typography>

        <Button onClick={handleVariableButtonClick} sx={variableButtonStyles}>
          <DataObjectOutlined />
          {t('add_variable')}
        </Button>
      </Stack>

      <TextField
        inputProps={{ 'data-testid': 'message-field' }}
        inputRef={inputRef}
        sx={[textStyles, textOverflowed || !message ? textOverflowStyles : {}]}
        value={message}
        onChange={handleTextChange}
        multiline
        fullWidth
        rows={10}
        helperText={
          message ? `${characterCount}/${MAX_SMS_LENGTH} ${t('characters')}` : t('fill_out_field')
        }
        onBlur={handleCursorPosition}
        onClick={handleCursorPosition}
      />

      <InsertVariablesMenu
        anchorEl={currentRef}
        targetText={message}
        onVariableSelectedUpdate={handleVariableInsert}
        currentCursorPosition={currentButtonLabelCursorPos}
        isCustomVariablesEnabled={isCustomVariablesEnabled}
      />

      <ConfirmLeaveDialog
        open={!!dialogState}
        onClose={() => setDialogState(undefined)}
        onSubmit={handleConfirmDialogSubmit}
      />
    </Stack>
  );
}

const rootStyles: SxProps = {
  flexDirection: 'column',
  padding: '40px 24px',
  backgroundColor: 'white',
  flex: 1,
  borderRadius: '10px',
  border: '1px solid var(--Light-Other-Divider, rgba(21, 41, 122, 0.12))',
  gap: '16px',
};

const textStyles = {
  margin: 0,
  flex: 1,
  '& > div': { padding: '8px 12px' },
  '& > div, & > div > textarea': {
    height: '100%!important', // Fix for text area not taking full height, workaround for MUI
  },
  '& > p': {
    textAlign: 'right',
  },
};

const textOverflowStyles = {
  '& > div': { border: `1px solid ${theme.palette.error.main}` },
  '& > p': { color: theme.palette.error.main },
};

const languageSelectorContainerStyles: SxProps = { width: '250px', margin: 0 };

const variableButtonStyles: SxProps = {
  border: '1px solid #155EFF',
  textTransform: 'none',
  width: 'fit-content',
  padding: '8px 22px',
  gap: '8px',
  borderRadius: '6px',
  fontWeight: 700,
  letterSpacing: '0.46px',
};
