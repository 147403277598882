// @ts-nocheck
import {
  Box,
  Collapse,
  Grid,
  Tab as MuiTab,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material/';
import Tabs from '@mui/material/Tabs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SettingCard } from '@operto/ui-library';
import { OpertoTheme } from 'App/OpertoTheme';
import { ColorPicker } from 'Common/ColorPicker';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import GPPreviewContainer from 'Pages/GuestPortal/Preview/GPPreviewContainer';
import { addDays, fromUnixTime } from 'date-fns';
import isSameDay from 'date-fns/isSameDay';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ISection } from 'redux/guestportal';
import styled from 'styled-components';
import ScriptDropDownMenu from 'ui-library/Components/drop-down/ScriptDropDownMenu';
import TabTransition from 'ui-library/Components/transitions/TabTransition';
import { TextInsert } from 'ui-library/Helpers/textHelper';
import { PageContentContainer } from 'ui-library/Styled/containers';
import { SideDrawer } from '../../../../Common/Drawer/SideDrawer';
import ImageEditor from '../../Common/GPImageEditor';

enum SectionDetailsFormTabItem {
  Edit = 0,
  Settings = 1,
  Preview,
}

const SectionNameCard: React.FC<SectionFormProps> = props => {
  const { section, onSave } = props;
  const guideInputRef = useRef();
  const [currentCursorPos, setCurrentCursorPos] = useState(0);

  if (section === undefined) {
    return null;
  }

  return (
    <SettingCard
      title='Guide Name'
      subtitle="Set this guide's name as it will appear in your menu."
    >
      <TextContainer>
        <TextField
          inputRef={guideInputRef}
          style={{ width: '50%', marginTop: 30, paddingRight: '10px' }}
          onBlur={() => setCurrentCursorPos(guideInputRef?.current?.selectionEnd)}
          label='Guide Name'
          placeholder="Enter guide's name"
          variant='outlined'
          margin='normal'
          fullWidth
          value={section.section}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onSave({
              ...section,
              section: e.target.value,
            })
          }
        />
        <ScriptDropDownMenu
          marginTop={21}
          onTextInsert={(text: string) => {
            onSave({ ...section, section: TextInsert(section.section, text, currentCursorPos) });
          }}
        />
      </TextContainer>
    </SettingCard>
  );
};

const SectionStatusCard: React.FC<SectionFormProps> = props => {
  const { section, onSave } = props;
  const { propertyId } = useParams();
  if (section === undefined) {
    return null;
  }

  return (
    <SettingCard title='Guide Status' subtitle="Set your Guide's status">
      <Grid container style={{ marginTop: 20, alignItems: 'center' }}>
        <Grid item>
          <Switch
            onChange={(e, value) => onSave({ ...section, published: value })}
            checked={section?.published}
          />
        </Grid>
        <Grid item>
          <Typography>Publish Guide</Typography>
        </Grid>
      </Grid>
      {!propertyId && (
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item>
            <Switch
              onChange={(e, value) => onSave({ ...section, featured: value })}
              checked={section?.featured}
            />
          </Grid>
          <Grid item>
            <Typography>Featured on Home Page</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container style={{ marginBottom: 20, alignItems: 'center' }}>
        <Grid item>
          <Switch
            onChange={(e, value) => onSave({ ...section, scheduled: value })}
            checked={section?.scheduled}
          />
        </Grid>
        <Grid item>
          <Typography>Schedule</Typography>
        </Grid>
      </Grid>
    </SettingCard>
  );
};

const SectionScheduleCard: React.FC<SectionFormProps> = props => {
  const { section, onSave } = props;
  if (section === undefined) {
    return null;
  }

  const scheduleStart = section?.scheduled_start
    ? fromUnixTime(section.scheduled_start)
    : new Date();
  let scheduleEnd = section?.scheduled_end ? fromUnixTime(section.scheduled_end) : new Date();

  if (isSameDay(scheduleStart, scheduleEnd)) {
    // if the server set 2 identical time stamps that means this hasn't been set
    scheduleEnd = addDays(scheduleEnd, 2);
  }

  return (
    <SettingCard
      title='Schedule This Guide'
      subtitle='Select the time period that this page will be published in.'
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container style={{ paddingTop: '20px' }}>
          <DatePicker
            disableToolbar
            disabled={!section?.scheduled}
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            label='Start Date'
            value={scheduleStart}
            onChange={date =>
              onSave({
                ...section,
                scheduled_start: date?.valueOf() / 1000,
              })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            renderInput={params => <TextField {...params} />}
          />
          <Box marginX={3} />
          <DatePicker
            disableToolbar
            disabled={!section?.scheduled}
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            label='End Date'
            value={scheduleEnd}
            onChange={date =>
              onSave({
                ...section,
                scheduled_end: date?.valueOf() / 1000,
              })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>
    </SettingCard>
  );
};

const SectionColorCard: React.FC<SectionFormProps> = props => {
  const { section, onSave } = props;
  if (section === undefined) {
    return null;
  }

  return (
    <SettingCard
      title='Guide Color'
      subtitle='Set your primary color.'
      description='If you do not set a Guide color, your default Brand color will be set this Guide Color.'
    >
      <Box m={4}>
        <Typography align='left'>Click to set the color</Typography>
        <ColorPicker
          value={section.section_color}
          onChange={newValue => onSave({ ...section, section_color: newValue })}
        />
      </Box>
    </SettingCard>
  );
};

const SectionCoverImageCard: React.FC<SectionFormProps> = props => {
  const { section, onSave } = props;
  if (section === undefined) {
    return null;
  }
  return (
    <SettingCard
      title='Guide Cover Image'
      subtitle="Set this Guide's cover image that appears above your Guide's Chapters"
    >
      <ImageContainer>
        <ImageEditor
          ratio='2:3'
          image={section.section_cover ?? ''}
          onImageChange={(url: string) => onSave(url)}
        />
      </ImageContainer>
    </SettingCard>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const TabsContainer = styled(PageContentContainer)`
  display: flex;
`;
type SectionFormContainerProps = SectionFormProps & {
  children?: React.ReactNode;
  onTabClick: () => void;
  activeTab: SectionDetailsFormTabItem;
  onSave: () => void;
  onClose: () => void;
};
export const SectionFormContainer: React.FC<SectionFormContainerProps> = props => {
  const { section, children, onSave, onTabClick, activeTab, onClose } = props;
  if (section === undefined) {
    return null;
  }
  return (
    <>
      <RightSideTopbar
        title={section?.section?.length > 0 ? section?.section : 'New Guide Name'}
        handleClose={onClose}
        page={section}
        onSave={onSave}
      />

      <Paper style={{ backgroundColor: 'white', borderRadius: 0 }} elevation={2} square>
        <Tabs
          value={activeTab}
          onChange={(event: React.ChangeEvent, newValue: number) => onTabClick(newValue)}
          sx={{ '& .MuiTab-root.Mui-selected': { color: OpertoTheme.activeTextColor } }}
        >
          <TabLabel label='Edit' />
          <TabLabel label='Settings' />
          <TabLabel label='Preview' />
        </Tabs>
      </Paper>
      <TabsContainer isMobile={false}>{children}</TabsContainer>
    </>
  );
};

export type SectionFormProps = {
  section: ISection;
  onSave: (data: ISection) => void;
  onClose: () => void;
};

export const GuidesForm = ({ section, onSave, onClose }: SectionFormProps) => {
  const [sectionCopy, setSectionCopy] = useState<ISection>(section);
  const [activeTab, setActiveTab] = useState<SectionDetailsFormTabItem>(
    SectionDetailsFormTabItem.Edit,
  );

  useEffect(() => {
    if (!sectionCopy) {
      setSectionCopy(section);
    }

    return () => {
      if (!section) {
        setSectionCopy(undefined);
      }
    };
  }, [section, sectionCopy]);

  return (
    <SideDrawer open={section !== undefined} onClose={onClose} data-testid='sideDrawar'>
      <SectionFormContainer
        section={sectionCopy}
        onSave={onSave}
        onTabClick={tab => setActiveTab(tab)}
        activeTab={activeTab}
        onClose={onClose}
      >
        <TabTransition show={activeTab === SectionDetailsFormTabItem.Edit}>
          <>
            <SectionNameCard section={sectionCopy} onSave={setSectionCopy} />
            <SectionColorCard section={sectionCopy} onSave={setSectionCopy} />
            <SectionCoverImageCard
              section={sectionCopy}
              onSave={(url: string) => setSectionCopy({ ...sectionCopy, section_cover: url })}
            />
          </>
        </TabTransition>
        <TabTransition show={activeTab === SectionDetailsFormTabItem.Settings}>
          <>
            <SectionStatusCard section={sectionCopy} onSave={setSectionCopy} />
            <Collapse in={sectionCopy?.scheduled}>
              <SectionScheduleCard section={sectionCopy} onSave={setSectionCopy} />
            </Collapse>
          </>
        </TabTransition>
        <TabTransition show={activeTab === SectionDetailsFormTabItem.Preview}>
          <GPPreviewContainer />
        </TabTransition>
      </SectionFormContainer>
    </SideDrawer>
  );
};

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TabLabel = styled(MuiTab)`
  && {
    text-transform: capitalize;
    padding-left: 50px;
    padding-right: 50px;
  }
`;
