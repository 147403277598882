import { trpc } from '@operto/trpc-client';
import { VerificationStatus } from '@operto/verifications-shared';
import { companySelector } from 'company/state/companySelectors';
import { useAppFeatures } from 'lib/app-features';
import { logger } from 'lib/logger';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import useTranslation from './useTranslation';

/**
 * Custom hook to manage company verification providers.
 *
 * @returns {Object} An object containing functions to fetch, upsert, and refetch the company verification provider.
 *
 * @function getCompanyVerificationProvider - Fetches the verification provider for a company.
 * @function upsertCompanyProvider - Upserts the verification provider for a company.
 */
export function useVerification() {
  const company = useAppSelector(companySelector());
  const { isFeatureEnabled } = useAppFeatures();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  /**
   * Fetches the verification provider for a company.
   *
   * @returns An object containing the verification provider data, loading status, success status, error status, and any error encountered.
   *
   * @property {boolean} isLoading - Indicates if the query is currently loading.
   * @property {boolean} isSuccess - Indicates if the query was successful.
   * @property {boolean} isError - Indicates if there was an error with the query.
   * @property {Error | null} error - The error encountered during the query, if any.
   * @property {CompanyProvider} data - The verification provider data returned by the query.
   */
  const getCompanyVerificationProvider = () => {
    const { isLoading, isFetching, isSuccess, isError, error, data, refetch } =
      trpc.verifications.getCompanyProvider.useQuery(
        {
          id: company.id,
        },
        {
          retry: 1,
          enabled: isFeatureEnabled('verification', company.id),
        },
      );

    if (isError && error?.data?.code != 'NOT_FOUND') {
      logger.error('Error fetching company provider', error);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: error.message,
          variant: SnackbarVariant.ERROR,
        }),
      );
    }

    return {
      provider: data,
      error,
      refetch,
      isSuccess,
      isLoading,
      isFetching,
      isError,
    };
  };

  const verificationStatusMutation = trpc.verifications.updateVerificationStatus.useMutation();
  /**
   * updates the verification status for a reservation.
   *
   * @param {string} reservationId - The reservation ID to update the verification status for.
   * @param {string} guestId - The guest ID to update the verification status for.
   * @param {string} status - The status to update the verification status to.
   * @returns {Promise<void>} A promise that resolves when the update operation is complete.
   * @throws Will throw an error if the update fails.
   */
  const updateVerificationStatus = async ({
    reservationId,
    companyId,
    guestId,
    status,
  }: {
    reservationId: string;
    companyId: string;
    guestId: string;
    status: VerificationStatus;
  }) => {
    try {
      await verificationStatusMutation?.mutateAsync({
        reservationId,
        companyId,
        guestId,
        status,
      });

      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: t('manually_confirmed'),
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    } catch (error) {
      logger.error('Error updating verification status', error);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: error.message,
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  const upsertCompanyProviderMutation = trpc.verifications.upsertCompanyProvider.useMutation();
  /**
   * Upserts the company provider with the given enabled status.
   *
   * @param {boolean} enabled - The status to enable or disable the company provider.
   * @returns {Promise<void>} A promise that resolves when the upsert operation is complete.
   */
  const upsertCompanyProvider = async (enabled: boolean) => {
    try {
      await upsertCompanyProviderMutation.mutateAsync({
        id: company.id,
        enabled: enabled,
        email: company.email,
        provider: 'chekin',
      });
    } catch (error) {
      logger.error('Error upserting company provider', error);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: error.message,
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  /**
   * Fetches the verification statuses for the given reservation IDs.
   *
   * @param {string[]} reservationIds - An array of reservation IDs to fetch the verification statuses for.
   * @returns {object} An object containing data, error, refect, isSuccess, isLoading, is Fetching, isError.
   */
  const getVerificationsStatusByReservationIds = (reservationIds: string[]) => {
    const { isLoading, isFetching, isSuccess, isError, error, data, refetch } =
      trpc.verifications.getVerificationStatuses.useQuery(
        {
          reservationIds,
        },
        {
          retry: 1,
          enabled: isFeatureEnabled('verification', company.id) && reservationIds.length > 0,
        },
      );

    if (isError) {
      logger.error('Error fetching verifications status', error);
    }

    return {
      data,
      error,
      refetch,
      isSuccess,
      isLoading,
      isFetching,
      isError,
    };
  };

  const syncCompanyProviderMutation = trpc.verifications.syncCompanyProvider.useMutation();
  /**
   * Synchronizes the company provider by invoking the `syncCompanyProviderMutation`.
   * If an error occurs during the synchronization, it logs the error and displays a snackbar notification.
   *
   * @async
   * @function syncCompanyProvider
   * @returns {Promise<void>} A promise that resolves when the synchronization is complete.
   * @throws Will throw an error if the synchronization fails.
   */
  const syncCompanyProvider = async () => {
    try {
      await syncCompanyProviderMutation.mutateAsync({
        id: company.id,
      });
    } catch (error) {
      logger.error('Error syncing company provider', error);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: error.message,
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  /**
   * get verification documents (ID and Photo) for a guest in the reservation
   *
   * @param {string} reservationId - reservation ID to fetch the verification document for.
   * @returns {object} An object containing data, error, refect, isSuccess, isLoading, is Fetching, isError.
   */
  const getVerificationDocuments = ({
    reservationExternalId,
  }: {
    reservationExternalId: string;
  }) => {
    const { isLoading, isFetching, isSuccess, isError, error, data, refetch } =
      trpc.verifications.getVerificationDocuments.useQuery(
        {
          reservationId: reservationExternalId,
        },
        {
          enabled: isFeatureEnabled('verification', company.id) && !!reservationExternalId,
        },
      );

    if (isError) {
      logger.error('Error fetching verification document', error);
    }

    return {
      verificationDocuments: data,
      error,
      refetch,
      isSuccess,
      isLoading,
      isFetching,
      isError,
    };
  };

  return {
    getCompanyVerificationProvider,
    upsertCompanyProvider,
    getVerificationsStatusByReservationIds,
    syncCompanyProvider,
    updateVerificationStatus,
    getVerificationDocuments,
  };
}
