// @ts-nocheck

import { Box, Button, Typography } from '@mui/material';
import { SettingCard } from '@operto/ui-library';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { getCompanyInformation } from 'company/state/companyAction';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { isAdmin, toggleSnackbar } from 'redux/actions/ui';
import { useGuestPortal } from 'redux/guestportal';
import { useAppDispatch } from 'redux/hooks';
import styled from 'styled-components';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { PageHeader } from 'ui-library/Styled/containers';

type GlobalProps = {
  active?: boolean;
};

const NoGuestPortalPage: React.FC<GlobalProps> = () => {
  const guestPortal = useGuestPortal();

  const [confirmEnableGuestPortalDialog, setConfirmEnableGuestPortalDialog] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleEnableGP = async () => {
    const success = await guestPortal.enableGuestPortal();
    if (success) {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `Successfully enabled company's Guest Portal`,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
      dispatch(getCompanyInformation()).then(() => {
        navigate('/guest-portal');
      });
    }
  };
  return (
    <>
      <PageHeader>Guest Portal Not Enabled</PageHeader>
      <SettingCard>
        <Box m={4} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <Typography align='center' variant='h5'>
            Ready to take your guest experience to the next level?
          </Typography>
          <img
            src={'/img/operto-guest.svg'}
            style={{ height: '30%', width: '30%', margin: '24px auto' }}
          />
          <ReactPlayer
            url={'https://vimeo.com/641192973'}
            controls
            width='75%'
            style={{ margin: 'auto', padding: 'auto' }}
          />
          <Prompt1 align='center' variant='subtitle1'>
            Contact <b>Client Success</b> to schedule a demo.
          </Prompt1>
          <ButtonContainer>
            <PreviewPortalButton
              variant='contained'
              color='primary'
              onClick={() => {
                window.open('mailto:support@operto.com');
              }}
            >
              Contact Us
            </PreviewPortalButton>
            {isAdmin() && (
              <PreviewPortalButton
                variant='contained'
                color='primary'
                onClick={() => setConfirmEnableGuestPortalDialog(true)}
              >
                Enable GP
              </PreviewPortalButton>
            )}
          </ButtonContainer>
        </Box>
      </SettingCard>

      {confirmEnableGuestPortalDialog && (
        <ConfirmDialog
          open={confirmEnableGuestPortalDialog}
          title='Enable Guest Portal?'
          submitButtonText='Activate'
          onSubmit={() => {
            handleEnableGP();
            setConfirmEnableGuestPortalDialog(false);
          }}
          onCancel={() => setConfirmEnableGuestPortalDialog(false)}
        >
          <Typography variant='body2'>
            Select Activate to enable this Guest Portal or Cancel to return to the screen.
          </Typography>
        </ConfirmDialog>
      )}
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Prompt1 = styled(Typography)`
  && {
  }
`;

const PreviewPortalButton = styled(Button)`
  && {
    margin-bottom: 8px;
    max-width: 180px;
    align-self: center;
    background-color: ${props => props.theme.palette.primary};
  }
`;

export default NoGuestPortalPage;
