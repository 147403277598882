import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Stack } from '@mui/material';
import { getCurrentCompany } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import TabBar, { TabBarProps, TabItems } from 'ui-library/Components/tabBar/TabBar';
import GuestPortalGlobalPreview from './GuestPortalGlobalPreview';

export enum GuestPortalTabItem {
  Global = 0,
  Content = 1,
  Branding = 1.1,
  Address = 1.2,
  HostDetails = 1.3,
  GlobalText = 1.4,
  Translation = 1.5,
  Guides = 2,
  Featured = 3,
  Settings = 4,
  None = 5,
}

export enum GP_URLS {
  ASSIGNED_UNITS = '/guest-portal',
  CONTENT = '/guest-portal/content',
  BRANDING = '/guest-portal/content/branding',
  ADDRESS = '/guest-portal/content/address',
  HOSTDETAILS = '/guest-portal/content/host-details',
  GLOBALTEXT = '/guest-portal/content/global-text',
  TRANSLATION = '/guest-portal/content/translation',
  GUIDES = '/guest-portal/guides',
  SETTINGS = '/guest-portal/settings',
  GUIDES_PAGES = '/guest-portal/guides/:guideId/pages',
  GUIDES_CATEGORIES = '/guest-portal/guides/:guideId/categories',
  GUIDES_ORDERING = '/guest-portal/guides/:guideId/ordering',
  FEATURED = '/guest-portal/featured',
  NONE = '/guest-portal/none',
}

export const GuestPortal = () => {
  const company = useAppSelector(getCurrentCompany());
  const [activeTab, setActiveTab] = useState<GuestPortalTabItem>(GuestPortalTabItem.Global);
  const [gpOpen, setGpOpen] = useState(false);
  const onGlobalGpClose = () => {
    setGpOpen(false);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (company.guestPortalStatus?.length > 0 && company?.guestPortalStatus === 'inactive') {
      navigate('/guest-portal/none');
    }
  }, [company.guestPortalStatus, navigate]);

  useEffect(() => {
    const { pathname } = location;
    if (
      pathname.includes(GP_URLS.CONTENT) ||
      pathname.includes(GP_URLS.BRANDING) ||
      pathname.includes(GP_URLS.ADDRESS) ||
      pathname.includes(GP_URLS.HOSTDETAILS) ||
      pathname.includes(GP_URLS.TRANSLATION)
    ) {
      setActiveTab(GuestPortalTabItem.Content);
    } else if (pathname.includes(GP_URLS.GUIDES)) {
      setActiveTab(GuestPortalTabItem.Guides);
    } else if (pathname.includes(GP_URLS.FEATURED)) {
      setActiveTab(GuestPortalTabItem.Featured);
    } else if (pathname.includes(GP_URLS.SETTINGS)) {
      setActiveTab(GuestPortalTabItem.Settings);
    } else if (pathname.includes(GP_URLS.NONE)) {
      setActiveTab(GuestPortalTabItem.None);
    } else {
      setActiveTab(GuestPortalTabItem.Global);
    }
  }, [location]);

  const tabItems = [
    { label: 'Units', value: GuestPortalTabItem.Global, isDropDown: false },
    {
      label: 'Content',
      value: GuestPortalTabItem.Content,
      isDropDown: true,
      childItems: [
        { label: 'Branding', value: GuestPortalTabItem.Branding },
        {
          label: 'Address',
          value: GuestPortalTabItem.Address,
        },
        {
          label: 'Host Details',
          value: GuestPortalTabItem.HostDetails,
        },
        {
          label: 'Global Text',
          value: GuestPortalTabItem.GlobalText,
        },
        {
          label: 'Translation',
          value: GuestPortalTabItem.Translation,
        },
      ],
    },
    { label: 'Guides', value: GuestPortalTabItem.Guides, isDropDown: false },
    { label: 'Featured', value: GuestPortalTabItem.Featured, isDropDown: false },
    {
      label: 'Settings',
      value: GuestPortalTabItem.Settings,
      isDropDown: false,
      icon: <SettingsIcon />,
    },
  ];

  const setTabIndex = (tabValue: number) => {
    // truncate decimal points to detect which tab you are
    const integerTabValue = Math.floor(tabValue);

    setActiveTab(integerTabValue);
    selectFilter(integerTabValue);
  };

  const selectFilter = (index: number) => {
    switch (index) {
      case GuestPortalTabItem.Guides:
        navigate(GP_URLS.GUIDES);
        break;
      case GuestPortalTabItem.Content:
        navigate(GP_URLS.BRANDING);
        break;
      case GuestPortalTabItem.Branding:
        navigate(GP_URLS.BRANDING);
        break;
      case GuestPortalTabItem.Address:
        navigate(GP_URLS.ADDRESS);
        break;
      case GuestPortalTabItem.HostDetails:
        navigate(GP_URLS.HOSTDETAILS);
        break;
      case GuestPortalTabItem.GlobalText:
        navigate(GP_URLS.GLOBALTEXT);
        break;
      case GuestPortalTabItem.Translation:
        navigate(GP_URLS.TRANSLATION);
        break;
      case GuestPortalTabItem.Settings:
        navigate(GP_URLS.SETTINGS);
        break;
      case GuestPortalTabItem.Featured:
        navigate(GP_URLS.FEATURED);
        break;
      case GuestPortalTabItem.None:
        navigate(GP_URLS.NONE);
        break;
      case GuestPortalTabItem.Global:
        navigate(GP_URLS.ASSIGNED_UNITS);
        break;
      default:
        navigate(GP_URLS.NONE);
        break;
    }
  };

  const active = activeTab !== GuestPortalTabItem.None;

  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <TabBar
          tabItems={tabItems as TabItems[]}
          tabIndex={activeTab}
          active={active}
          onTabChange={setTabIndex}
          onSubTabChange={selectFilter as TabBarProps['onSubTabChange']}
        />
        <Button variant='outlined' disabled={!active} onClick={() => setGpOpen(true)}>
          {t('preview')}
        </Button>
      </Stack>
      <Outlet />
      <GuestPortalGlobalPreview isOpen={gpOpen} onClose={onGlobalGpClose} />
    </>
  );
};

export default GuestPortal;
