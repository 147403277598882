import * as React from 'react';

import {
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  SxProps,
} from '@mui/material';

import DeleteBackupCodeDialog from 'Common/Dialog/DeleteBackupCodeDialog';
import { toggleSnackbar } from 'redux/actions/ui';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

import RefreshIcon from '@mui/icons-material/Cached';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useMedia } from '@operto/ui';
import { getCodeSelector } from 'code/state/backupCodesSelector';
import { getPropertyAccessCode } from 'code/state/codeAction';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

export interface BackupCodeListItemProps {
  codeId: number;
}
const INTERVAL_TIME = 35000;
const INITIAL_TIME = 15000;
const BackupCodeListItem = ({ codeId }: BackupCodeListItemProps) => {
  const [openDeleteBackupCode, setOpenDeleteBackupCode] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const code = useAppSelector(getCodeSelector(codeId));

  const dispatch = useAppDispatch();

  const pollCodeStatus = React.useCallback(() => {
    if (isDeleting) {
      setTimeout(pollCodeStatus, INTERVAL_TIME);
      dispatch(getPropertyAccessCode(code.property_id, code.id));
    }
  }, [dispatch, code.id, code.property_id, isDeleting]);

  React.useEffect(() => {
    setIsDeleting(code?.valid_until !== null);
  }, [code?.valid_until]);

  React.useEffect(() => {
    if (isDeleting) setTimeout(pollCodeStatus, INITIAL_TIME);
  }, [isDeleting, pollCodeStatus]);

  const closeDialog = () => {
    setOpenDeleteBackupCode(false);
  };
  const handleDeleteCode = () => {
    // NOTE: no idea what this delete code should do.
    // we have handleCallback and also this?
  };

  const handleCopy = (name: string) => {
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Copied ${name} value.`,
        variant: SnackbarVariant.INFO,
      }),
    );
  };

  const { isMobile } = useMedia();
  const listItemStyles: SxProps = {
    paddingTop: isMobile ? '0px' : 'auto',
    paddingBottom: isMobile ? '0px' : 'auto',
  };

  return (
    <ListItem disableGutters sx={listItemStyles}>
      {openDeleteBackupCode && (
        <DeleteBackupCodeDialog
          code={code}
          open={openDeleteBackupCode}
          propertyId={code.property_id}
          onCancel={closeDialog}
          onSubmit={handleDeleteCode}
        />
      )}
      <ListItemText primary={`${code?.access_code} ${isDeleting ? 'deleting..' : ''} `} />
      <ListItemSecondaryAction>
        <IconButton
          sx={{
            color: 'white',
          }}
          size='small'
          disabled={code?.master_property_id !== null || isDeleting}
          onClick={() => {
            setOpenDeleteBackupCode(true);
          }}
          data-testid='backupcode-delete-button'
        >
          {isDeleting ? (
            <Box
              sx={{
                padding: '2px',
                svg: {
                  color: 'white',
                },
              }}
            >
              <CircularProgress size={22} />
            </Box>
          ) : (
            <RefreshIcon />
          )}
        </IconButton>
        <CopyToClipboard
          onCopy={() => {
            handleCopy('Access Code');
          }}
          text={code?.access_code}
          data-testid='copy-access-code'
        >
          <IconButton
            size='small'
            sx={{
              color: 'white',
            }}
          >
            <FileCopyIcon />
          </IconButton>
        </CopyToClipboard>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default BackupCodeListItem;
