import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { IntercomByPropertyIdSelector } from 'device/state/intercomSelectors';
import { Intercom } from 'intercom/intercomType';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SlidebarType } from 'types/ui';
import { SearchToolbarProps } from 'ui-library/Components/table/SearchToolbar';
import { Table, TableProps } from 'ui-library/Components/table/Table';

type IntercomAccessCodeTableProps = Pick<TableProps, 'renderHeader'> & {
  propertyId: number;
  loading?: boolean;
  searchToolbarProps?: SearchToolbarProps;
};

export const IntercomAccessCodeTable = ({
  propertyId,
  loading,
  ...rest
}: IntercomAccessCodeTableProps) => {
  const dispatch = useAppDispatch();
  const intercom = useAppSelector(IntercomByPropertyIdSelector(propertyId));
  const renderAccessMethod = (params: GridCellParams<unknown, Intercom>) => {
    const config = params.row.intercom_config;
    if (config.auto_buzz) {
      return <Text>Auto Buzz</Text>;
    }

    if (config.voice) {
      return <Text>Voice Controlled</Text>;
    }

    return <Text>Keypad Controlled</Text>;
  };

  const renderIntercomNumber = (params: GridCellParams<unknown, Intercom>) => {
    const intercom = params.row;
    const number = intercom.intercom_number.number;
    if (number) {
      return <Text>{number}</Text>;
    }

    return null;
  };

  const renderForwards = (params: GridCellParams<unknown, Intercom>) => {
    const intercom = params.row;
    const forwardNumber = intercom.intercom_config.forwards.find(forward => forward.number.length);
    if (forwardNumber) {
      return <Text>{forwardNumber.number}</Text>;
    }

    return null;
  };

  const renderEntryNumber = (params: GridCellParams<unknown, Intercom>) => {
    const intercom = params.row;
    const intercomButton = intercom.intercom_config.intercom_button;
    if (intercomButton) {
      return <Text>{intercomButton}</Text>;
    }

    return null;
  };

  const columnsDef: GridColumns<Intercom> = [
    {
      field: 'device_name',
      headerName: 'Intercom',
      flex: 1,
      renderCell: (params: GridCellParams<unknown, Intercom>) => (
        <Text>{params.row.device_name}</Text>
      ),
    },
    {
      field: 'access_method',
      headerName: 'Access Method',
      renderCell: renderAccessMethod,
      hide: isMobile,
      flex: 1,
    },
    {
      field: 'intercom_number',
      headerName: 'Intercom Number',
      renderCell: renderIntercomNumber,
      flex: 1,
    },
    {
      field: 'forwarding_number',
      headerName: 'Forwarding Number',
      hide: isMobile,
      flex: 1,
      renderCell: renderForwards,
      sortable: false,
    },
    {
      field: 'entry_number',
      headerName: 'Entry Number',
      hide: isMobile,
      flex: 1,
      renderCell: renderEntryNumber,
      sortable: false,
    },
  ];

  return (
    <Table
      loading={loading}
      getRowId={row => row?.intercom_config.id ?? row?.intercom_number.id}
      rows={intercom ? [intercom] : []}
      rowCount={1}
      columns={columnsDef}
      hideFooter
      disableColumnMenu
      onCellClick={() => dispatch(toggleSlidebar(SlidebarType.EDIT_INTERCOM, { propertyId }))}
      {...rest}
    />
  );
};
