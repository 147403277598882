import { setupAppFeatures } from '@operto/app-features';
import { isProduction } from './constants';

const NEO_GATEWAY_V2_URL = process.env.REACT_APP_NEO_GATEWAY_V2_URL;

// declare your app features here
const appFeatures = {
  churnzero: {
    enabled: isProduction,
  },
  fullstory: {
    enabled: isProduction,
  },
  groups: {
    enabled: true,
  },
  tags: {
    enabled: true,
  },
  guestTerms: {
    enabled: true,
  },
  guestProfile: {
    enabled: true,
  },
  transactionsPage: {
    enabled: false,
  },
  upsells: {
    enabled: false,
  },
  communications: {
    enabled: !isProduction,
  },
  deliveryLogs: {
    enabled: true,
  },
  locks: {
    enabled: !isProduction,
  },
  tasks: {
    enabled: !isProduction,
  },
  copilot: {
    enabled: !isProduction,
  },
  'communications-check-out-trigger': {
    enabled: !isProduction,
  },
  communicationsCustomVariablesInEmail: {
    enabled: true,
    description: 'Enables the use of custom variables in email templates.',
  },
  communicationsCustomVariablesInSms: {
    enabled: true,
    description: 'Enables the use of custom variables in SMS templates.',
  },
  verification: {
    enabled: !isProduction,
  },
  memberAccessStaffAppToggle: {
    enabled: !isProduction,
  },
  oneDashboard: {
    enabled: !isProduction,
  },
};

type AppFeatureFlags = keyof typeof appFeatures;

export const { useAppFeatures } = setupAppFeatures<AppFeatureFlags>(
  appFeatures,
  NEO_GATEWAY_V2_URL ? `${NEO_GATEWAY_V2_URL}/app-features/operations` : undefined,
);
