import { Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import HostawayLogo from 'assets/img/integrations/hostaway.png';
import { useTranslation } from 'hooks/useTranslation';
import {
  IntegrationApiProvider,
  IntegrationProvider,
  THostawayPayload,
} from 'integrations/integrationsTypes';
import {
  disableHostawayIntegration,
  enableHostawayIntegration,
  importHostawayUnits,
  integrateWithHostawaySetting,
} from 'integrations/state/integrationsActions';
import { findStatusIntegrationsApiProviderSelector } from 'integrations/state/integrationsSelectors';
import { logger } from 'lib/logger';
import React, { useRef, useState } from 'react';
import { togglePanelSnackbar, toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PanelSnackbarTypes, SnackbarTypes, SnackbarVariant } from 'types/ui';
import { IntegrationsFormHandle, IntegrationsSideDrawer } from '../IntegrationsSideDrawer';
import { PMSIntegrationsCard, PMSIntegrationsCardActionType } from '../PMSIntegrationsCard';
import HostawayIntegrationForm from './HostawayIntegrationForm';

const CUSTOMER_SUCCESS_URL = `${process.env.REACT_APP_DASHBOARD_URL}/expansion`;
const HOSTAWAY_CLIENT_SUPPORT_URL = `${process.env.REACT_APP_CLIENT_SUPPORT_URL}/s/article/Hostaway`; //host

export default function HostawayIntegrationsCard() {
  const { isEnabled, isConnected } = useAppSelector(
    findStatusIntegrationsApiProviderSelector(IntegrationApiProvider.Hostaway),
  );
  const dispatch = useAppDispatch();
  const integrationFormRef = useRef<IntegrationsFormHandle>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();
  const displayPanelSnackbar = (message?: string, type?: PanelSnackbarTypes) => {
    dispatch(
      togglePanelSnackbar(SnackbarTypes.OPEN, {
        message,
        link: CUSTOMER_SUCCESS_URL,
        type,
      }),
    );
  };

  const handleEnableDisableConfirmation = async () => {
    try {
      if (isEnabled) {
        await dispatch(disableHostawayIntegration());
      } else {
        await dispatch(enableHostawayIntegration());
      }
      displayPanelSnackbar(isEnabled ? t('disable_success_message') : t('enable_success_message'));
    } catch (e) {
      logger.error(e);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: isEnabled
            ? t('disable_error_message', { integration_name: 'Hostaway' })
            : t('enable_error_message', { integration_name: 'Hostaway' }),
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
    setIsDialogOpen(false);
  };

  const handleConnect = async (data: Omit<THostawayPayload, 'provider'>) => {
    try {
      await dispatch(integrateWithHostawaySetting(data));
      dispatch(
        togglePanelSnackbar(SnackbarTypes.OPEN, {
          link: CUSTOMER_SUCCESS_URL,
          message: t('integration_success', { integration_name: 'Hostaway' }),
          type: PanelSnackbarTypes.CONNECT,
          provider: IntegrationProvider.HostAway,
        }),
      );
      setIsDrawerOpen(false);
    } catch (e) {
      logger.error(e);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: t('integration_error', { integration_name: 'Hostaway' }),
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  const handleResync = async () => {
    try {
      await dispatch(importHostawayUnits(t));
      displayPanelSnackbar('', PanelSnackbarTypes.RESYNC);
    } catch (error) {
      logger.error(error);
      displayPanelSnackbar(t('integration_resync_error', { integration_name: 'Hostaway' }));
    }
  };

  const handleActionClick = (action: PMSIntegrationsCardActionType) => {
    switch (action) {
      case 'RE-SYNC':
        return handleResync();
      default:
        return setIsDrawerOpen(true);
    }
  };

  return (
    <>
      <PMSIntegrationsCard
        title='Hostaway'
        connected={isConnected}
        enabled={isEnabled}
        onActionClick={handleActionClick}
        onChange={() => setIsDialogOpen(true)}
        onInfoClick={() => window.open(HOSTAWAY_CLIENT_SUPPORT_URL, '_blank')}
      >
        <img src={HostawayLogo} alt='Hostaway' />
      </PMSIntegrationsCard>

      <IntegrationsSideDrawer
        open={isDrawerOpen}
        title='Hostaway'
        onClose={() => integrationFormRef.current?.close()}
        onSave={() => integrationFormRef.current?.submit()}
      >
        <HostawayIntegrationForm
          ref={integrationFormRef}
          onClose={() => setIsDrawerOpen(false)}
          onSave={handleConnect}
        />
      </IntegrationsSideDrawer>

      <ConfirmDialog
        open={isDialogOpen}
        title={
          isEnabled
            ? t('disable_confirm_title', { integration_name: 'Hostaway' })
            : t('enable_confirm_title', { integration_name: 'Hostaway' })
        }
        onCancel={() => setIsDialogOpen(false)}
        submitButtonText={isEnabled ? t('disable') : t('enable')}
        onSubmit={() => handleEnableDisableConfirmation()}
        data-testid='integration-confirm-dialog'
      >
        <Typography variant='body1'>
          {isEnabled
            ? t('disable_confirm_message', { integration_name: 'Hostaway' })
            : t('enable_confirm_message', { integration_name: 'Hostaway' })}{' '}
        </Typography>
      </ConfirmDialog>
    </>
  );
}
