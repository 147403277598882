import { Typography } from '@mui/material';
import React from 'react';
import { CustomTooltip } from '../Common/CustomTooltip';

type TextWithTooltipProps = {
  text: string;
  isWrapped?: boolean;
};
const TextWithTooltip = ({ text, isWrapped = false }: TextWithTooltipProps) => (
  <CustomTooltip
    data-testid='custom-tooltip'
    title={text}
    placement='bottom'
    leaveDelay={100}
    followCursor
    noWrap={isWrapped}
  >
    <Typography data-testid='tooltip-text' fontSize={14}>
      {text?.length > 0 ? text : '-'}
    </Typography>
  </CustomTooltip>
);

export default TextWithTooltip;
