import { Box } from '@mui/material';
import { ICompany } from 'company/companyType';
import React, { useCallback, useMemo, useState } from 'react';
import { isAdmin } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import LinkedAccounts from './LinkedAccounts/LinkedAccounts';
import AdminSettingsContainer from './TabContainers/AdminSettingsContainer';
import BusinessInfoContainer from './TabContainers/BusinessInfoContainer';
import PaymentInfoContainer from './TabContainers/PaymentContainer';
import PreferencesContainer from './TabContainers/PreferencesContainer';

export interface AccountContainerProps {
  company: ICompany;
}

export const AccountTab = {
  BusinessInfo: 0,
  PaymentInfo: 1,
  Preferences: 2,
  LinkedAccounts: 3,
  AdminSettings: 4,
} as const;

export type AccountTabType = (typeof AccountTab)[keyof typeof AccountTab];

const tabItems = [
  { label: 'Business Info', value: AccountTab.BusinessInfo },
  { label: 'Payment Info', value: AccountTab.PaymentInfo },
  { label: 'Preferences', value: AccountTab.Preferences },
  { label: 'Linked Accounts', value: AccountTab.LinkedAccounts },
  { label: 'Admin Settings', value: AccountTab.AdminSettings },
];

const AccountContainer = ({ company }: AccountContainerProps) => {
  const [tabIdx, setTabIdx] = useState<AccountTabType>(AccountTab.BusinessInfo);
  const loggedInMember: UserState = useAppSelector(userSelector());
  const activeTab = useMemo(() => {
    switch (tabIdx) {
      case AccountTab.BusinessInfo:
        return <BusinessInfoContainer company={company} />;
      case AccountTab.PaymentInfo:
        return <PaymentInfoContainer />;
      case AccountTab.Preferences:
        return <PreferencesContainer />;
      case AccountTab.LinkedAccounts:
        return <LinkedAccounts />;
      case AccountTab.AdminSettings:
        return <AdminSettingsContainer companyId={company.id} />;
      default:
        throw new Error(`No tab defined with index: ${tabIdx}`);
    }
  }, [company, tabIdx]);

  const getActiveTabs = useCallback(() => {
    let allowedTabs = tabItems;

    if (!isAdmin()) {
      allowedTabs = allowedTabs.filter(t => t.value !== AccountTab.AdminSettings);
    }
    if (!['account-owner', 'global-manager'].includes(loggedInMember.role)) {
      allowedTabs = allowedTabs.filter(i => i.value !== AccountTab.PaymentInfo);
    }

    return allowedTabs;
  }, [loggedInMember]);

  return (
    <>
      <TabBar tabItems={getActiveTabs()} tabIndex={tabIdx} onTabChange={setTabIdx} />
      <Box maxWidth='838px' marginX='auto'>
        {activeTab}
      </Box>
    </>
  );
};

export default AccountContainer;
