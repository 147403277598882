import AdditionalLocksContainer from 'Pages/Unit/TabContainers/AdditionalLocksContainer';
import GuestCodesContainer from 'Pages/Unit/TabContainers/GuestCodesContainer';
import BackupCodesTable from 'code/BackupCodesTable';
import TeamMemberCodesTable from 'code/TeamMemberCodesTable';
import { getPropertyAccessCodes } from 'code/state/codeAction';
import { getPropertyIntercom } from 'device/state/intercomActions';
import useTranslation from 'hooks/useTranslation';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import CommonDoorAccessTable from './CommonDoorAccessTable';
import { IntercomAccessCodeTable } from './IntercomAccessCodeTable';

const UnitCodesContainer = () => {
  const dispatch = useAppDispatch();
  const { propertyId: pidStr, type } = useParams();
  const propertyId = Number(pidStr);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableTitle, setTableTitle] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    switch (type) {
      case 'guests':
        setTableTitle(t('guests_codes'));
        break;
      case 'backups':
        setTableTitle(t('backup_codes'));
        break;
      case 'members':
        setTableTitle(t('member_codes'));
        break;
      case 'building-intercom':
        setTableTitle(t('building_intercom'));
        break;
      case 'common-doors':
        setTableTitle(t('common_doors'));
        break;
      case 'connected-locks':
        setTableTitle(t('connected_locks'));
        break;
      default:
        setTableTitle(t('guests_codes'));
    }
  }, [type, t]);

  useEffect(() => {
    if (type === 'building-intercom' || type === 'common-doors') {
      setLoading(true);
      dispatch(getPropertyIntercom(propertyId)).then(() => setLoading(false));
    } else {
      setLoading(true);
      dispatch(getPropertyAccessCodes(propertyId, null)).then(() => setLoading(false));
    }
  }, [dispatch, propertyId, type]);

  const tableProps = {
    propertyId,
    loading,
    searchToolbarProps: {
      title: tableTitle,
      onChange: setSearchValue,
      value: searchValue,
    },
  };

  if (type === 'members') {
    return <TeamMemberCodesTable {...tableProps} />;
  }

  if (type === 'backups') {
    return <BackupCodesTable {...tableProps} />;
  }

  if (type === 'building-intercom') {
    return <IntercomAccessCodeTable {...tableProps} />;
  }

  if (type === 'common-doors') {
    return <CommonDoorAccessTable {...tableProps} />;
  }

  if (type === 'connected-locks') {
    return <AdditionalLocksContainer {...tableProps} />;
  }

  return <GuestCodesContainer {...tableProps} />;
};

export default UnitCodesContainer;
