import { Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import guestyLogo from 'assets/img/integrations/guesty.png';
import { useTranslation } from 'hooks/useTranslation';
import {
  IntegrationApiProvider,
  IntegrationProvider,
  TGuestyPayload,
} from 'integrations/integrationsTypes';
import {
  disableGuestyIntegration,
  enableGuestyIntegration,
  importGuestyUnits,
  integrateWithGuestySetting,
} from 'integrations/state/integrationsActions';
import { findStatusIntegrationsApiProviderSelector } from 'integrations/state/integrationsSelectors';
import { logger } from 'lib/logger';
import React, { useRef, useState } from 'react';
import { togglePanelSnackbar, toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PanelSnackbarTypes, SnackbarTypes, SnackbarVariant } from 'types/ui';
import { IntegrationsFormHandle, IntegrationsSideDrawer } from '../IntegrationsSideDrawer';
import { PMSIntegrationsCard, PMSIntegrationsCardActionType } from '../PMSIntegrationsCard';
import GuestyIntegrationForm from './GuestyIntegrationForm';

const CUSTOMER_SUCCESS_URL = `${process.env.REACT_APP_DASHBOARD_URL}/expansion`;
const GUESTY_CLIENT_SUPPORT_URL = `${process.env.REACT_APP_CLIENT_SUPPORT_URL}/s/article/Guesty`;

export default function GuestyIntegrationsCard() {
  const { isEnabled, isConnected } = useAppSelector(
    findStatusIntegrationsApiProviderSelector(IntegrationApiProvider.Guesty),
  );
  const dispatch = useAppDispatch();
  const integrationFormRef = useRef<IntegrationsFormHandle>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();

  const displayPanelSnackbar = (message?: string, type?: PanelSnackbarTypes) => {
    dispatch(
      togglePanelSnackbar(SnackbarTypes.OPEN, {
        message,
        link: CUSTOMER_SUCCESS_URL,
        type,
      }),
    );
  };

  const handleEnableDisableConfirmation = async () => {
    try {
      if (isEnabled) {
        await dispatch(disableGuestyIntegration());
      } else {
        await dispatch(enableGuestyIntegration());
      }
      displayPanelSnackbar(`Successfully ${isEnabled ? 'disabled' : 'enabled'}!`);
    } catch (e) {
      logger.error(e);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `Error ${isEnabled ? 'disabling' : 'enabling'} 'Guesty'.`,
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
    setIsDialogOpen(false);
  };

  const handleConnect = async (data: Omit<TGuestyPayload, 'provider'>) => {
    try {
      await dispatch(integrateWithGuestySetting(data));
      dispatch(
        togglePanelSnackbar(SnackbarTypes.OPEN, {
          link: CUSTOMER_SUCCESS_URL,
          message:
            "You have integrated 'Guesty'. To activate your services please reach out to Customer Success team.",
          type: PanelSnackbarTypes.CONNECT,
          provider: IntegrationProvider.Guesty,
        }),
      );
      setIsDrawerOpen(false);
    } catch (e) {
      logger.error(e);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: "Error integrating 'Guesty'.",
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  const handleResync = async () => {
    try {
      await dispatch(importGuestyUnits(t));
      displayPanelSnackbar('', PanelSnackbarTypes.RESYNC);
    } catch (error) {
      logger.error(error);
      displayPanelSnackbar("Error when Re-sync 'Guesty'.");
    }
  };

  const handleActionClick = (action: PMSIntegrationsCardActionType) => {
    switch (action) {
      case 'RE-SYNC':
        return handleResync();
      default:
        return setIsDrawerOpen(true);
    }
  };

  return (
    <>
      <PMSIntegrationsCard
        title='Guesty'
        connected={isConnected}
        enabled={isEnabled}
        onActionClick={handleActionClick}
        onChange={() => setIsDialogOpen(true)}
        onInfoClick={() => window.open(GUESTY_CLIENT_SUPPORT_URL, '_blank')}
      >
        <img src={guestyLogo} alt='Guesty' />
      </PMSIntegrationsCard>

      <IntegrationsSideDrawer
        open={isDrawerOpen}
        title='Integration settings'
        onClose={() => integrationFormRef.current?.close()}
        onSave={() => integrationFormRef.current?.submit()}
      >
        <GuestyIntegrationForm
          ref={integrationFormRef}
          onClose={() => setIsDrawerOpen(false)}
          onSave={handleConnect}
        />
      </IntegrationsSideDrawer>

      <ConfirmDialog
        open={isDialogOpen}
        title={`${isEnabled ? 'Disable' : 'Enable'} 'Guesty' Integration`}
        onCancel={() => setIsDialogOpen(false)}
        submitButtonText={isEnabled ? 'Disable' : 'Enable'}
        onSubmit={() => handleEnableDisableConfirmation()}
        data-testid='integration-confirm-dialog'
      >
        <Typography variant='body1'>{`Are you sure you want to ${
          isEnabled ? 'disable' : 'enable'
        } 'Guesty' integration?`}</Typography>
      </ConfirmDialog>
    </>
  );
}
