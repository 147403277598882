import SyncIcon from '@mui/icons-material/Sync';
import IconButton from '@mui/material/IconButton';
import { GridCellParams, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import DeleteBackupCodeDialog from 'Common/Dialog/DeleteBackupCodeDialog';
import { ICode } from 'code/codeType';
import { companySelector } from 'company/state/companySelectors';
import { IMember } from 'member/memberType';
import { getSelf } from 'member/state/memberSelectors';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from 'redux/hooks';
import getMemberBackupDeletePermissioned from 'redux/selectors/getMemberBackupDeletePermissioned';
import { SearchToolbarProps } from 'ui-library/Components/table/SearchToolbar';
import { Table } from 'ui-library/Components/table/Table';
import backupCodesSelector from './state/backupCodesSelector';

export interface BackupCodesTableProps {
  propertyId: number;
  searchToolbarProps?: SearchToolbarProps;
  loading?: boolean;
}

const BackupCodesTable = ({ propertyId, loading, searchToolbarProps }: BackupCodesTableProps) => {
  const [openDeleteBackupCode, setOpenDeleteBackupCode] = React.useState(false);
  const [loadingIds, setLoadingIds] = React.useState<number[]>([]);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<GridCellParams>({} as GridCellParams);

  const backupCodes = useAppSelector(backupCodesSelector(propertyId));
  const loggedInMember: IMember = useAppSelector(getSelf());
  const { accessCodeFormat } = useAppSelector(companySelector());

  const filteredData = searchToolbarProps?.value
    ? backupCodes.filter(
        code =>
          code.access_code.includes(searchToolbarProps?.value) ||
          code.name?.toLowerCase().includes(searchToolbarProps?.value),
      )
    : backupCodes;

  const closeDialog = () => {
    setOpenDeleteBackupCode(false);
    // if it's mobile, close the bottom slider as well
    if (isMobile) {
      handleClick();
    }
  };

  const padCode = (accessCode: ICode) => {
    const code = accessCode.access_code;
    if (
      accessCodeFormat ||
      ['nuki:1', 'nuki:2', 'nuki_opener:1', 'nuki_opener:2'].includes(
        accessCode?.lock_provider || '',
      )
    ) {
      const codeLength = code.toString().length;
      if (codeLength === 4) {
        return `99${code}`;
      }
      if (codeLength === 5) {
        return `9${code}`;
      }
    }
    return code;
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteBackupCode(true);
  };

  const handleDeleteCode = (codeId: number) => {
    loadingIds.push(codeId);
    setLoadingIds(loadingIds);
  };

  const renderBackupCode = (params: GridRenderCellParams) => {
    return <div>{padCode(params.row)}</div>;
  };

  const renderBackupCodeName = (params: GridRenderCellParams) => <div>{params.row.name}</div>;

  const renderBackupCodesActions = (params: GridCellParams) => {
    const code = params.row as ICode;

    if (getMemberBackupDeletePermissioned(loggedInMember)) {
      return !loadingIds.includes(code?.id) && code?.valid_until === null ? (
        <IconButton onClick={handleOpenDeleteDialog} disabled={code?.master_property_id !== null}>
          <SyncIcon />
        </IconButton>
      ) : (
        'Deleting Code'
      );
    }

    return <div>N/A</div>;
  };

  const columnsDef: GridColumns = [
    {
      field: 'name',
      headerName: 'Code Name',
      flex: 1,
      renderCell: renderBackupCodeName,
    },
    {
      field: 'access_code',
      headerName: 'Access Code',
      flex: 1,
      renderCell: renderBackupCode,
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Actions',
      flex: 0.5,
      type: 'number',
      renderCell: renderBackupCodesActions,
      sortable: false,
    },
  ];

  const columnsDefForMobile: GridColumns = [
    {
      field: 'name',
      headerName: 'Code Name',
      flex: 1,
      renderCell: renderBackupCodeName,
    },
    {
      field: 'access_code',
      headerName: 'Access Code',
      flex: 1,
      renderCell: renderBackupCode,
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Actions',
      width: 50,
      type: 'number',
      renderCell: renderBackupCodesActions,
      sortable: false,
    },
  ];

  return (
    <>
      <Table
        pagination
        loading={loading}
        rows={filteredData}
        columns={isMobile ? columnsDefForMobile : columnsDef}
        onCellClick={row => setSelectedRow(row)}
        searchToolbarProps={searchToolbarProps}
      />
      {openDeleteBackupCode && (
        <DeleteBackupCodeDialog
          code={selectedRow.row}
          open={openDeleteBackupCode}
          propertyId={propertyId}
          onCancel={closeDialog}
          onSubmit={handleDeleteCode}
        />
      )}
    </>
  );
};

export default BackupCodesTable;
