import { Verification, VerificationStatus } from '@operto/verifications-shared';
import { IGuest } from 'guest/guestType';
import useTranslation from 'hooks/useTranslation';
import { useVerification } from 'hooks/useVerification';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { VERIFICATION_STATUS_TYPE } from 'reservation/reservationType';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

/**
 * Custom hook to manage the Chekin integration state.
 *
 * This hook provides functions to enable, disable, and sync the Chekin integration,
 * as well as to check the current status of the integration.
 *
 * @returns {Object} An object containing the following properties and functions:
 * - `isLoading` (boolean): Indicates if the integration state is currently loading.
 * - `isSuccess` (boolean): Indicates if the integration state has succeeded.
 * - `isEnabled` (function): Checks if the Chekin integration is enabled.
 * - `isConnected` (function): Checks if the Chekin integration is connected.
 * - `enable` (function): Enables the Chekin integration.
 * - `disable` (function): Disables the Chekin integration.
 * - `sync` (function): Syncs the Chekin data.
 */
export const useChekinIntegration = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getCompanyVerificationProvider, upsertCompanyProvider, syncCompanyProvider } =
    useVerification();
  const { isFetching, isSuccess, isError, error, provider, refetch } =
    getCompanyVerificationProvider();

  /**
   * Checks if the integration is connected.
   *
   * @returns {boolean} True if the integration is connected, otherwise false.
   */
  const isConnected = () => {
    return isSuccess || error?.data?.code === 'NOT_FOUND';
  };

  /**
   * Checks if the Chekin integration is enabled.
   *
   * @returns {boolean} the provider is enabled, and the provider is 'chekin'; otherwise, false.
   */
  const isEnabled = () => {
    return !isError && provider?.enabled && provider?.provider === 'chekin';
  };

  /**
   * Enables the Chekin integration
   *
   * @async
   * @function
   * @returns {Promise<void>} A promise that resolves when the integration state is updated.
   */
  const enable = async () => {
    await upsertCompanyProvider(true);
    refetch();
  };

  /**
   * Disables the Chekin integration.
   *
   * @async
   * @function disable
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const disable = async () => {
    await upsertCompanyProvider(false);
    refetch();
  };

  /**
   * Synchronizes the company properties/reservations if the integration is enabled.
   * Displays a snackbar error notification if the integration is not enabled.
   *
   * @function
   * @returns {void}
   */
  const sync = () => {
    if (isEnabled()) {
      syncCompanyProvider();
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: t('chekin_sync_enabled'),
          variant: SnackbarVariant.INFO,
        }),
      );
    } else {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: t('chekin_sync_not_enabled'),
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  /**
   * Merges verification statuses with guests based on reservation IDs.
   *
   * @param guests - An array of guest objects.
   * @param verifications - An array of verification objects.
   * @param isSuccess - A boolean indicating if the verificationStatuses fetch was successful.
   * @returns An array of guests with their verification statuses updated.
   *
   * If `isSuccess` is true, the function creates a map of verifications by `reservationId`
   * and maps over the guests to join them with their corresponding verification status.
   * If `isSuccess` is false, it returns the guests with `verification_status` set to null.
   */
  const reduceVerifications = (guests: IGuest[], verifications: Verification[]) => {
    // Create a map of verifications by reservation_id
    const verificationMap = verifications.reduce((map, verification) => {
      map[verification.reservationId] = mapVerificationStatus(verification.status);
      return map;
    }, {});

    // Map over guests and join with verification status
    return guests.map(guest => {
      return {
        ...guest,
        verification_status: verificationMap[guest.external_id] || undefined,
      };
    });
  };

  /**
   * Maps the given verification status to a corresponding value from VERIFICATION_STATUS_TYPE.
   *
   * @param status - The verification status to map. Can be one of 'NONE', 'PENDING', 'IN_REVIEW', 'FLAGGED', or 'VERIFIED'.
   * @returns The mapped verification status from VERIFICATION_STATUS_TYPE.
   */
  const mapVerificationStatus = (status: VerificationStatus) => {
    switch (status) {
      case 'NONE':
        return VERIFICATION_STATUS_TYPE.PENDING;
      case 'PENDING':
        return VERIFICATION_STATUS_TYPE.PENDING;
      case 'IN_REVIEW':
        return VERIFICATION_STATUS_TYPE.IN_REVIEW;
      case 'FLAGGED':
        return VERIFICATION_STATUS_TYPE.FLAGGED;
      case 'VERIFIED':
        return VERIFICATION_STATUS_TYPE.CONFIRMED;
      case 'MANUALLY_VERIFIED':
        return VERIFICATION_STATUS_TYPE.MANUALLY_CONFIRMED;
    }
  };

  return {
    isFetching,
    isSuccess,
    isEnabled,
    isConnected,
    enable,
    disable,
    sync,
    reduceVerifications,
  };
};
