import { Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import CloudbedsLogo from 'assets/img/integrations/cloudbeds.svg';
import { useTranslation } from 'hooks/useTranslation';
import { IntegrationApiProvider, IntegrationProvider } from 'integrations/integrationsTypes';
import {
  disableCloudbedsIntegration,
  enableCloudbedsIntegration,
  integrateWithCloudbeds,
} from 'integrations/state/integrationsActions';
import { findStatusIntegrationsApiProviderSelector } from 'integrations/state/integrationsSelectors';
import { logger } from 'lib/logger';
import React, { useEffect, useState } from 'react';
import { togglePanelSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { PanelSnackbarTypes, SnackbarTypes } from 'types/ui';
import { importCloudbedUnits } from '../../../integrations/state/integrationsActions';
import { PMSIntegrationsCard, PMSIntegrationsCardActionType } from '../PMSIntegrationsCard';
import { logo } from './useStyles';

const OPERTO_EXPANSION_PAGE = `${process.env.REACT_APP_DASHBOARD_URL}/expansion`;
const CLOUDBEDS_INFO_URL = 'https://clientsupport.operto.com/s/article/Cloudbeds';

export default function Cloudbeds() {
  const { isEnabled, isConnected } = useAppSelector(
    findStatusIntegrationsApiProviderSelector(IntegrationApiProvider.Cloudbeds),
  );

  const dispatch = useAppDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { t } = useTranslation();

  const displayPanelSnackbar = (message?: string, type?: PanelSnackbarTypes) => {
    dispatch(
      togglePanelSnackbar(SnackbarTypes.OPEN, {
        message,
        link: OPERTO_EXPANSION_PAGE,
        type,
      }),
    );
  };

  /** Handle callback of integration tile */
  useEffect(() => {
    const integrating = localStorage.getItem('integrating');

    if (integrating === IntegrationProvider.Cloudbeds) {
      localStorage.removeItem('integrating');
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code');

      if (code && !isConnected) {
        (async () => {
          try {
            await dispatch(integrateWithCloudbeds(code));

            displayPanelSnackbar(
              "You have integrated 'Cloudbeds'. To activate your services, please reach out to Customer Success team",
              PanelSnackbarTypes.CONNECT,
            );
          } catch (e) {
            logger.error(e);
            displayPanelSnackbar("Error integrating 'Cloudbeds'.");
          }
        })();
      }
    }
  });

  const handleToggle = (enabled: boolean) => {
    if (enabled) {
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleEnableConfirmation = async () => {
    try {
      await dispatch(enableCloudbedsIntegration());
      displayPanelSnackbar('Successfully enabled!');
    } catch (e) {
      logger.error(e);
      displayPanelSnackbar("Error enabling 'Cloudbeds'.");
    }
  };

  const handleEnableDisableConfirmation = async () => {
    if (isEnabled) {
      await handleDisableConfirmation();
    } else {
      await handleEnableConfirmation();
    }
    setIsDialogOpen(false);
  };

  const handleDisableConfirmation = async () => {
    try {
      await dispatch(disableCloudbedsIntegration());
      displayPanelSnackbar('Successfully disabled!');
    } catch (e) {
      logger.error(e);
      displayPanelSnackbar("Error disabling 'Cloudbeds'.");
    }
  };

  const handleConnect = async () => {
    localStorage.setItem('integrating', IntegrationProvider.Cloudbeds);

    const clientId = process.env.REACT_APP_CLOUDBEDS_CLIENT_ID as string;
    const redirectTo = process.env.REACT_APP_CLOUDBEDS_REDIRECT_TO as string;
    const oauthURL = process.env.REACT_APP_CLOUDBEDS_OAUTH as string;

    window.open(
      `${oauthURL}?response_type=code&client_id=${clientId}&redirect_uri=${redirectTo}`,
      '_self',
    );
  };

  const handleResync = async () => {
    if (isConnected && isEnabled) {
      try {
        await dispatch(importCloudbedUnits(t));
        displayPanelSnackbar('', PanelSnackbarTypes.RESYNC);
      } catch (e) {
        logger.error(e);
        displayPanelSnackbar(`Error when Re-sync 'Cloudbeds'.`);
      }
    }
  };

  const handleDeleteConfirmation = () => {
    //TODO: CLOUDBEDS: delete functionality with API, missing in API

    setIsDeleteDialogOpen(false);
  };

  const handleEdit = () => {
    window.open('https://www.cloudbeds.com/', '_blank');
  };

  const handleActionClick = (action: PMSIntegrationsCardActionType) => {
    switch (action) {
      case 'RE-CONNECT':
      case 'CONNECT':
        return handleConnect();
      case 'EDIT':
        return handleEdit();
      case 'RE-SYNC':
        return handleResync();
      default:
        break;
    }
  };

  return (
    <>
      <PMSIntegrationsCard
        title='Cloudbeds'
        connected={isConnected}
        enabled={isEnabled}
        onActionClick={handleActionClick}
        onChange={handleToggle}
        onInfoClick={() => window.open(CLOUDBEDS_INFO_URL, '_blank')}
      >
        <Typography variant='h2' sx={logo(isEnabled)}>
          <CloudbedsLogo />
        </Typography>
      </PMSIntegrationsCard>

      <ConfirmDialog
        open={isDialogOpen}
        title={`${isEnabled ? 'Disable' : 'Enable'} 'Cloudbeds' Integration`}
        onCancel={() => setIsDialogOpen(false)}
        submitButtonText={isEnabled ? 'Disable' : 'Enable'}
        onSubmit={handleEnableDisableConfirmation}
      >
        <Typography>{`Are you sure you want to ${
          isEnabled ? 'disable' : 'enable'
        } 'Cloudbeds' Integration?`}</Typography>
      </ConfirmDialog>

      <ConfirmDialog
        open={isDeleteDialogOpen}
        title={"Delete 'Cloudbeds' Integration"}
        onCancel={() => setIsDeleteDialogOpen(false)}
        submitButtonText='Delete'
        onSubmit={handleDeleteConfirmation}
        submitButtonColor='error'
      >
        <Typography>
          {
            "Are you sure you want to delete 'Cloudbeds' Integration? By deleting the integration, the associated properties will also be deleted."
          }
        </Typography>
      </ConfirmDialog>
    </>
  );
}
