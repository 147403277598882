import { GridColDef } from '@mui/x-data-grid-pro';
import { format, fromUnixTime } from 'date-fns';
import React from 'react';
import Actions, { ActionProps } from './Actions';
import EnabledUnits from './EnabledUnits';
import TextWithTooltip from './TextWithTooltip';
import VariableName from './VariableName';

export const editColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Variable Name',
    minWidth: 250,
    flex: 1,
    sortable: false,
    renderCell: VariableName,
  },
];

export const getColumns = ({
  isEdit,
  hideActionMenu,
  propertyVariableSet,
  callbacks,
}: {
  isEdit: boolean;
  hideActionMenu?: boolean;
  propertyVariableSet: Record<string, unknown>;
  callbacks: Omit<ActionProps, 'row'>;
}): GridColDef[] => {
  if (isEdit) {
    return editColumns;
  }

  return [
    {
      field: 'name',
      headerName: 'Variable Name',
      minWidth: 250,
      flex: 1,
      sortable: false,
      renderCell: VariableName,
    },
    {
      field: 'enabledUnits',
      headerName: 'Values Displayed',
      minWidth: 250,
      flex: 1,
      sortable: false,
      renderCell: params => (
        <EnabledUnits
          {...{
            ...params,
            row: {
              ...params.row,
              totalProperties: propertyVariableSet,
            },
          }}
        />
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 300,
      flex: 2,
      sortable: false,
      renderCell: params => <TextWithTooltip text={params.row.description} isWrapped={true} />,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: params => format(fromUnixTime(params.row.updatedAt), 'MM/dd/yyyy'),
    },
    {
      field: 'updatedBy',
      headerName: 'Last Updated By',
      minWidth: 200,
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      resizable: false,
      sortable: false,
      renderCell: params => {
        return (
          //TODO refactor and reduce number of callbacks
          <Actions
            row={params.row}
            allVariables={callbacks.allVariables}
            setChosenVariableOnOpenMode={callbacks.setChosenVariableOnOpenMode}
            setIsChangeStatusModalMode={callbacks.setIsChangeStatusModalMode}
            setMoreInfoRows={callbacks.setMoreInfoRows}
            setOpenMode={callbacks.setOpenMode}
            mixVariablePropertiesWithRealProperties={
              callbacks.mixVariablePropertiesWithRealProperties
            }
            updateVariableMutator={callbacks.updateVariableMutator}
            setSelectedVariableId={callbacks.setSelectedVariableId}
            setIsDeleteVariableModalMode={callbacks.setIsDeleteVariableModalMode}
            createVariableMutator={callbacks.createVariableMutator}
          />
        );
      },
      disableColumnMenu: true,
      hide: hideActionMenu,
    },
  ];
};
