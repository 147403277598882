import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import { ActionCell, LockCell } from 'Common/Tables/Properties/TableCells';
import { BatteryStatus } from 'device/deviceType';
import { devicesFromPropertyDetailSelector } from 'device/state/deviceSelector';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from 'redux/hooks';
import BatteryIcon from 'ui-library/Components/icon/BatteryIcon';
import { SearchToolbarProps } from 'ui-library/Components/table/SearchToolbar';
import { Table } from 'ui-library/Components/table/Table';
import { getDeviceName } from '../../../redux/selectors/getDeviceName';
import { IDevices } from '../../../types/properties';

interface AdditionalLocksTableProps {
  propertyId: number;
  loading?: boolean;
  searchToolbarProps?: SearchToolbarProps;
}

const AdditionalLocksContainer = ({ searchToolbarProps }: AdditionalLocksTableProps) => {
  const devices = useAppSelector(state => devicesFromPropertyDetailSelector(state));
  const renderDeviceName = (params: GridCellParams) => (
    <div style={{ textTransform: 'capitalize' }}>{getDeviceName(params.row as IDevices)}</div>
  );

  const renderSmartLockBattery = (params: GridCellParams) => {
    const isEnabled = params.row?.is_online;
    const status: BatteryStatus = params.row.battery_status;
    return params.row.battery_status && <BatteryIcon disabled={!isEnabled} status={status} />;
  };

  const renderKeyPadBattery = (params: GridCellParams) => {
    const isEnabled = params.row?.is_online;
    const status: BatteryStatus = params.row.battery_keypad_status;
    return (
      params.row.battery_keypad_status && <BatteryIcon disabled={!isEnabled} status={status} />
    );
  };

  const renderSmartLockStatus = (params: GridCellParams) => {
    if (params.row && params.row.device_name === 'SmartLock') {
      return <LockCell isLocked={params.row.is_locked} isOnline={params.row.is_online} />;
    }
    return <div />;
  };

  const renderAction = () => <ActionCell />;

  const columnsDef: GridColumns = [
    {
      field: 'name',
      headerName: 'Device Name',
      renderCell: renderDeviceName,
      flex: 1,
    },
    {
      field: 'smart-lock-2',
      headerName: 'Lock Status',
      renderCell: renderSmartLockStatus,
      sortable: false,
      flex: 1,
    },
    {
      field: 'smart-lock',
      headerName: 'Battery',
      flex: 1,
      renderCell: renderSmartLockBattery,
      sortable: false,
    },
    {
      field: 'smart-lock-3',
      headerName: 'Keypad Battery',
      flex: 1,
      renderCell: renderKeyPadBattery,
      sortable: false,
    },
  ];

  const columnsDefForMobile: GridColumns = [
    {
      field: 'name',
      headerName: 'Device Name',
      renderCell: renderDeviceName,
      flex: 1,
    },
    {
      field: 'smart-lock-2',
      headerName: 'Lock Status',
      renderCell: renderSmartLockStatus,
      sortable: false,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 50,
      renderCell: renderAction,
      sortable: false,
    },
  ];

  return (
    <Table
      hideFooter
      rows={devices}
      searchToolbarProps={searchToolbarProps}
      columns={isMobile ? columnsDefForMobile : columnsDef}
      initialState={{ pinnedColumns: { right: ['action'] } }}
    />
  );
};

export default AdditionalLocksContainer;
