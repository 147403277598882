import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import { Box, Grid, Stack, SxProps, Typography } from '@mui/material';
import { useMedia } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import OneDashboardTitlebar from './OneDashboardTitlebar';
import OneDashboardWidget from './OneDashboardWidget';

const OneDashboard = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  // TODO: [ONE] remove these upon implementation of OPN1-24715 / 24716
  const hasMessages = false;
  const hasTasks = false;

  return (
    <Box sx={{ ...containerStyles, paddingX: isDesktop ? 0 : 3 }}>
      <Box sx={contentStyles}>
        <OneDashboardTitlebar />

        <Grid container spacing={3}>
          <Grid item lg={4} md={6} sm={12}>
            <OneDashboardWidget title={t('unread_messages')}>
              {!hasMessages && (
                <Stack spacing={1} sx={noContentBodyStyles}>
                  <ChatBubbleOutlineOutlinedIcon sx={noContentIconStyles} />
                  <Typography variant='h4-700' sx={noContentTextStyles}>
                    {t('you_are_all_caught_up')}
                  </Typography>
                </Stack>
              )}
            </OneDashboardWidget>
          </Grid>

          <Grid item lg={4} md={6} sm={12}>
            <OneDashboardWidget title={t('priority_tasks')}>
              {!hasTasks && (
                <Stack spacing={1} sx={noContentBodyStyles}>
                  <PlaylistAddCheckOutlinedIcon sx={noContentIconStyles} />
                  <Typography variant='h4-700' sx={noContentTextStyles}>
                    {t('no_tasks')}
                  </Typography>
                </Stack>
              )}
            </OneDashboardWidget>
          </Grid>

          <Grid item lg={4} md={6} sm={12}>
            <OneDashboardWidget>
              <Stack spacing={1} sx={noContentBodyStyles}>
                <AddCircleOutlineOutlinedIcon sx={noContentIconStyles} />
                <Typography variant='h4-700' sx={noContentTextStyles}>
                  {t('add_widget')}
                </Typography>
              </Stack>
            </OneDashboardWidget>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const containerStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 3,
};

const contentStyles: SxProps = {
  maxWidth: '1920px',
  flex: '1 1 auto',
  flexDirection: 'column',
};

const noContentIconStyles: SxProps = {
  fontSize: 80,
  color: '#747775',
};

const noContentTextStyles: SxProps = {
  color: '#747775',
};

const noContentBodyStyles: SxProps = {
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

export default OneDashboard;
