import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import { Badge, Box, Paper, Stack, Typography, TypographyProps } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { Task } from '@operto/tasks-shared';
import { useMedia } from '@operto/ui';
import CopilotPanel, { copilotPanelRefType } from 'Common/CopilotPanel/CopilotPanel';
import SwipeableDrawer from 'Common/Drawer/SwipeableDrawer';
import FabIcon from 'Common/Icons/FabIcon';
import { OpertoLogger } from 'Logger/logger';
import {
  createMemberChannel,
  notifyGuestAction,
  updatePropertyAction,
} from 'Pages/Messaging/MessagingActions';
import SettingsTabPage from 'Pages/Messaging/Settings/index';
import TasksDetails from 'Pages/Tasks/TasksDetails';
import { TaskTableEmitter } from 'Pages/Tasks/TasksTable';
import { getMessengerSetting } from 'company/state/companyAction';
import { companySelector, messengerSettingSelector } from 'company/state/companySelectors';
import { ClientContext, streamChatClient } from 'helper/streamChatHelper';
import { useAppFeatures } from 'lib/app-features';
import { logger } from 'lib/logger';
import { cloneDeep } from 'lodash';
import { getCurrentMember } from 'member/state/memberActions';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchReservation } from 'redux/copilot-chat/api-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetGuestIdQuery } from 'redux/portal-guest-reservation/api-slice';
import { getReservationById } from 'reservation/state/reservationSelectors';
import { ChannelFilters, DefaultGenerics, Channel as TChannel } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import TabBar, { TabItems } from 'ui-library/Components/tabBar/TabBar';
import { userSelector, userStateSelector } from 'user/state/userSelectors';
import { formatConversation } from './AI-helpers/helper';
import ChannelMessagesList from './ChannelMessagesList';
import ChatMessages, { ChatMsgEmmitter } from './ChatMessages';
import { CustomChannelHeaderEmitter } from './CustomChannelHeader';
import CustomSearchAndFilter from './CustomSearchAndFilter';
import MessagePeopleListChatContainer from './MessagePeopleListChatContainer';
import './MessageStyles.css';
import { MIN_MESSAGES_DATE } from './MessagingConstants';

export enum MessageFilterType {
  GUESTS = 'Guests',
  MEMBERS = 'Members',
  TASKS = 'Tasks',
}

export enum MessageFilterIndex {
  GUESTS = 0,
  MEMBERS = 1,
  TASKS = 2,
  SETTINGS = 3,
}

const labelName = {
  GUESTS: 'Guests',
  MEMBERS: 'Members',
  TASKS: 'Tasks',
  SETTINGS: 'Settings',
};

const SidebarHeaderTitle = ({
  filterIndex,
  variant,
}: {
  filterIndex?: MessageFilterIndex;
  variant: TypographyProps['variant'];
}) => {
  let type = 'All';
  switch (filterIndex) {
    case MessageFilterIndex.GUESTS:
      type = 'Guest';
      break;
    case MessageFilterIndex.MEMBERS:
      type = 'Member';
      break;
    case MessageFilterIndex.TASKS:
      type = 'Task';
      break;
  }
  return <Typography variant={variant}>{`${type} messages`}</Typography>;
};

export const MessagingPage = () => {
  const { channelId } = useParams<{ channelId: string }>();
  const location = useLocation();
  const [isCopilotPanelOpen, setIsCopilotPanelOpen] = useState(true);
  const channelListeners = useRef<{ unsubscribe: () => void }[]>([]);
  const [isNewChat, setIsNewChat] = useState(false);
  const [currentChannel, setCurrentChannel] = useState<TChannel<DefaultGenerics>>();
  const [filterIndex, setFilterIndex] = useState(
    location.pathname.includes('members') ? MessageFilterIndex.MEMBERS : MessageFilterIndex.GUESTS,
  );
  const dispatch = useAppDispatch();
  const loggedInMember = useAppSelector(userSelector());
  const isGuestTab = filterIndex === MessageFilterIndex.GUESTS;
  const isTasksTab = filterIndex === MessageFilterIndex.TASKS;
  const chipFilters = useMemo(
    () => (!isTasksTab ? ['Unread', 'Favorites'] : ['Unread']),
    [isTasksTab],
  );

  const copilotPanelRef = useRef<copilotPanelRefType | null>(null);
  const chatInputRef: React.MutableRefObject<HTMLTextAreaElement> = useRef(null);

  const { isDesktop, isTablet, isMobile } = useMedia();

  const featuresSelector = useAppSelector(userStateSelector());

  const [previousChannelId, setPreviousChannelId] = useState<string>();

  const [searchValue, setSearchValue] = useState<string>();

  const [selectedChips, setSelectedChips] = useState([]);

  const [showFullSearchBar, setShowFullSearchBar] = useState(!isMobile);

  const [showMessagesTitle, setShowMessagesTitle] = useState(true);

  const [taskId, setTaskId] = useState<string>();

  const navigate = useNavigate();

  const [showTaskPanel, setShowTaskPanel] = useState(false);
  const [taskUnreadCount, setTaskUnreadCount] = useState(0);

  const { isFeatureEnabled } = useAppFeatures();
  const company = useAppSelector(companySelector());
  const currentReservationId = isGuestTab ? currentChannel?.id?.split('-')?.[1] : null;
  const reservation = useAppSelector(getReservationById(Number(currentReservationId)));
  const tasksEnabled = isFeatureEnabled('tasks', company.id);
  const featureCopilotEnabled = isFeatureEnabled('copilot', company.id);
  const messengerSetting = useAppSelector(messengerSettingSelector());

  const { clientObject } = useContext(ClientContext);

  const currentUserId = loggedInMember?.getStreamUser?.id;
  const guestMessengerEnabled = messengerSetting.mp_guest_messenger_enabled;
  const memberMessengerEnabled = messengerSetting.mp_member_messenger_enabled;
  const isCopilotEnabled = messengerSetting.mp_instant_reply_enabled && featureCopilotEnabled;
  const showFloatingFab = !isDesktop && !currentChannel;

  const isMobileChatOpened = isMobile && currentChannel;

  const isMessengerEnabled = useMemo(
    () =>
      (filterIndex === MessageFilterIndex.GUESTS && guestMessengerEnabled) ||
      (filterIndex === MessageFilterIndex.MEMBERS && memberMessengerEnabled) ||
      (filterIndex === MessageFilterIndex.TASKS && tasksEnabled),
    [filterIndex, guestMessengerEnabled, memberMessengerEnabled, tasksEnabled],
  );

  const { data: guestIdResponse, isError } = useGetGuestIdQuery(Number(currentReservationId), {
    skip: !isFeatureEnabled('guestProfile') || !currentReservationId,
  });

  const setTabIndex = (value: number) => {
    freeChannelListeners();
    setFilterIndex(value);
    setCurrentChannel(undefined);
    setIsCopilotPanelOpen(false);
    navigate('/messenger');
  };

  const handleChannelOnClick = (channel: TChannel) => {
    if (isGuestTab) {
      const [, reservationId] = channel.id.split('-');
      dispatch(updatePropertyAction(channel.id, reservationId, channel.data?.property_id + ''));
    }
    navigate(`/messenger/${channel.id}`);
  };

  const freeChannelListeners = () => {
    channelListeners.current.forEach(listener => listener.unsubscribe());
    channelListeners.current = [];
  };

  const upperTabItems = useMemo(() => {
    const tabsOrder = [
      labelName.GUESTS,
      labelName.MEMBERS,
      labelName.TASKS,
      labelName.SETTINGS,
    ] as const;
    const tabList: TabItems[] = [
      {
        label: labelName.MEMBERS,
        value: MessageFilterIndex.MEMBERS,
        isDropDown: false,
      },
      {
        label: labelName.SETTINGS,
        value: MessageFilterIndex.SETTINGS,
        isDropDown: false,
        icon: <SettingsIcon />,
      },
    ];
    if (featuresSelector.features?.mp_guest_portal_onboarding_enabled) {
      tabList.push({
        label: labelName.GUESTS,
        value: MessageFilterIndex.GUESTS,
        isDropDown: false,
      });
    }

    if (tasksEnabled) {
      tabList.push({
        label: labelName.TASKS,
        value: MessageFilterIndex.TASKS,
        isDropDown: false,
        badgeIcon: !!taskUnreadCount && (
          <Badge badgeContent={taskUnreadCount} color='primary' sx={{ ml: 2 }} />
        ),
      });
    }
    return tabList.sort((a, b) => tabsOrder.indexOf(a.label) - tabsOrder.indexOf(b.label));
  }, [
    featuresSelector.features?.mp_guest_portal_onboarding_enabled,
    taskUnreadCount,
    tasksEnabled,
  ]);

  const queryForChannel = useCallback(
    async (channelId: string) => {
      const channel = await clientObject.streamChatClient.getChannelById(
        'messaging',
        channelId,
        {},
      );
      await channel.watch();
      return channel;
    },
    [clientObject.streamChatClient],
  );

  const handleNewGuestMessage = useCallback(
    async (currentChannel: TChannel<DefaultGenerics>) => {
      const [companyId, reservationId] = channelId.split('-');
      const copilotReservationInformation = await fetchReservation(+companyId, +reservationId);

      if (!copilotReservationInformation || !currentChannel?.state?.messages) return;

      setIsCopilotPanelOpen(true);

      const { guides_info, reservation_info } = copilotReservationInformation;

      copilotPanelRef.current?.getInstantReply({
        reservationInfo: JSON.stringify(reservation_info),
        guidesInfo: JSON.stringify(guides_info),
        conversationData: formatConversation(currentChannel?.state?.messages),
      });
    },
    [channelId],
  );

  const handleLastChannelReply = useCallback(
    (channel: TChannel<DefaultGenerics>) => {
      const lastMessage = channel?.state?.messages?.[channel?.state?.messages?.length - 1];

      if (lastMessage?.user?.id?.includes('rid')) {
        handleNewGuestMessage(channel);
        return;
      }

      copilotPanelRef.current?.resetInstantReply();
    },
    [handleNewGuestMessage],
  );

  const getTaskUnreadChannels = useCallback(async () => {
    try {
      const channels = await streamChatClient.queryChannels({
        channel_data: 'tasks',
        members: { $in: [currentUserId] },
      });

      const totalUnreadCount = channels?.reduce(
        (total, channel) => total + channel.countUnread(),
        0,
      );
      setTaskUnreadCount(totalUnreadCount);
    } catch (error) {
      logger.error(`error: ${error}`);
    }
  }, [currentUserId]);

  const renderMobileAndTabletHeader = useCallback(() => {
    return (
      <Box
        sx={{
          padding: '12px 16px',
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {showMessagesTitle && (
            <Box>{(isMobile || isTablet) && <SidebarHeaderTitle variant='subtitle1' />}</Box>
          )}
          <Box sx={{ width: !showMessagesTitle ? '100%' : 'inherit' }}>
            <CustomSearchAndFilter
              onSelectedChipsChange={setSelectedChips}
              selectedChips={selectedChips}
              onSearchChange={handleSearch}
              searchValue={searchValue}
              chipFilters={chipFilters}
              showFullSearchBar={showFullSearchBar}
              onSearchIconClick={(value: boolean) => {
                setShowFullSearchBar(value);
              }}
              showMessagesTitle={(value: boolean) => {
                setShowMessagesTitle(value);
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }, [
    chipFilters,
    isMobile,
    isTablet,
    searchValue,
    selectedChips,
    showFullSearchBar,
    showMessagesTitle,
  ]);

  const guestFilters = useMemo(() => {
    let filters: ChannelFilters = {
      members: { $in: [loggedInMember?.getStreamUser?.id] },
      channel_data: 'guests',
      last_message_at: { $gt: MIN_MESSAGES_DATE },
    };
    if (searchValue) {
      filters = {
        ...filters,
        $or: [
          { name: { $autocomplete: searchValue } },
          { property_name: { $autocomplete: searchValue } },
          { property_id: { $eq: searchValue } },
        ],
      };
    }
    return filters;
  }, [loggedInMember?.getStreamUser?.id, searchValue]);

  const memberFilters = useMemo(
    () =>
      ({
        members: { $in: [loggedInMember?.getStreamUser?.id] },
        channel_data: 'members',
        name: searchValue ? { $autocomplete: searchValue } : undefined,
        last_message_at: { $gt: MIN_MESSAGES_DATE },
      } as ChannelFilters),
    [loggedInMember?.getStreamUser?.id, searchValue],
  );

  const tasksFilters = useMemo(
    () =>
      ({
        members: { $in: [loggedInMember?.getStreamUser?.id] },
        channel_data: 'tasks',
        name: searchValue ? { $autocomplete: searchValue } : undefined,
        $or: [
          { last_message_at: { $gt: MIN_MESSAGES_DATE } },
          { last_message_at: { $exists: false } }, // Include channels with no messages
        ],
      } as ChannelFilters),
    [loggedInMember?.getStreamUser?.id, searchValue],
  );

  const addChipFilters = useCallback(
    (filters: object) => {
      let chipFilters = cloneDeep(filters);

      if (selectedChips.includes('Unread')) {
        chipFilters = { ...chipFilters, has_unread: true };
      }
      if (selectedChips.includes('Favourites')) {
        chipFilters = { ...chipFilters, favourite: true };
      }

      return chipFilters;
    },
    [selectedChips],
  );

  const handleFilterToggle = (filterIndex: MessageFilterIndex) => {
    if (filterIndex === MessageFilterIndex.GUESTS) {
      return addChipFilters(guestFilters);
    } else if (filterIndex === MessageFilterIndex.TASKS) {
      return addChipFilters(tasksFilters);
    } else {
      return addChipFilters(memberFilters);
    }
  };

  const handleSearch = (search: string) => {
    setSearchValue(search);
  };

  const onCloseDrawer = () => {
    setIsNewChat(false);
  };

  const handleSendReply = (reply: string) => {
    currentChannel.sendMessage({
      text: reply,
      metadata: {
        guestId: isError ? undefined : guestIdResponse?.data?.guest_id,
        reservationExternalId: reservation?.external_id,
      },
    });

    copilotPanelRef.current?.resetInstantReply();
  };

  const handleInsertClick = (reply: string) => {
    if (!chatInputRef.current) return;

    let newValue = chatInputRef.current.value;

    if (newValue) newValue += '\n_______________________\n';

    newValue += reply;

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLTextAreaElement.prototype,
      'value',
    ).set;

    nativeInputValueSetter.call(chatInputRef.current, newValue);
    const event = new Event('input', { bubbles: true });
    chatInputRef.current.dispatchEvent(event);
  };

  const handleCopilotButtonClick = () => {
    if (!isCopilotPanelOpen) {
      setIsCopilotPanelOpen(true);
      handleLastChannelReply(currentChannel);
    }
  };

  const handleOnSendMessage = () => {
    copilotPanelRef.current?.resetInstantReply();
  };

  const handleTaskClick = (row?: GridRowParams<Task>): void => {
    setTaskId(row.id.toString());
    setShowTaskPanel(true);
  };

  const handleNewTaskClick = () => {
    setTaskId(undefined);
    setShowTaskPanel(true);
  };

  const handleViewTaskClick = (taskId: string) => {
    setTaskId(taskId);
    setShowTaskPanel(true);
  };

  const handleTaskPanelClose = () => {
    setTaskId(undefined);
    setShowTaskPanel(false);
  };

  const handleTaskUpdate = (task: Task) => {
    setTaskId(task.id);
    TaskTableEmitter.emit('refresh');
    CustomChannelHeaderEmitter.emit('refresh');
  };

  const renderDesktopHeader = useCallback(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRight: theme => `1px solid ${theme.palette.divider}`,
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '16px',
            padding: '12px 16px 12px 16px',
          }}
        >
          <SidebarHeaderTitle filterIndex={filterIndex} variant='h4' />

          {isMessengerEnabled && !isTasksTab ? (
            <FabIcon
              icon={<EditIcon />}
              size='small'
              color='primary'
              ariaLabel='add'
              onClick={() => setIsNewChat(true)}
            />
          ) : (
            <Box sx={{ width: '40px', height: '40px' }} /> // correct alignment
          )}
        </Box>

        <Box sx={{ paddingLeft: '8px', paddingRight: '15px' }}>
          <CustomSearchAndFilter
            onSelectedChipsChange={setSelectedChips}
            selectedChips={selectedChips}
            onSearchChange={handleSearch}
            searchValue={searchValue}
            chipFilters={chipFilters}
            showFullSearchBar={showFullSearchBar}
            onSearchIconClick={(value: boolean) => {
              setShowFullSearchBar(value);
            }}
            showMessagesTitle={(value: boolean) => {
              setShowMessagesTitle(value);
            }}
          />
        </Box>
      </Box>
    );
  }, [
    chipFilters,
    filterIndex,
    isMessengerEnabled,
    isTasksTab,
    searchValue,
    selectedChips,
    showFullSearchBar,
  ]);

  useEffect(() => {
    dispatch(getCurrentMember());
    dispatch(getMessengerSetting());
  }, [dispatch]);

  useEffect(() => {
    setTaskUnreadCount(0);
    getTaskUnreadChannels();
  }, [clientObject.clientUnreadCount, getTaskUnreadChannels]);

  useEffect(() => {
    const isNew = channelId?.includes('members-new');
    if (isNew) {
      const { state } = location;

      dispatch(createMemberChannel([+state.assigneeId, loggedInMember.user.id])).then(
        newChannelId => {
          navigate(newChannelId.replace('messaging:', ''));
        },
      );

      return;
    }

    if (!channelId) {
      return setCurrentChannel(undefined);
    }

    if (channelId !== undefined && channelId !== previousChannelId && clientObject.clientReady) {
      queryForChannel(channelId)
        .then(data => {
          freeChannelListeners();
          handleLastChannelReply(data);
          setCurrentChannel(data);

          if (channelId !== previousChannelId) setPreviousChannelId(channelId);

          if (channelId.includes('members')) {
            setFilterIndex(MessageFilterIndex.MEMBERS);
          } else if (channelId.includes('tid')) {
            setFilterIndex(MessageFilterIndex.TASKS);
          } else {
            setFilterIndex(MessageFilterIndex.GUESTS);
          }

          const chEventListener = data?.on(chatEvent => {
            if (chatEvent.type === 'message.new') {
              if (chatEvent.user?.id?.includes('mid')) {
                const reservationId: string = channelId?.split('-')?.[1];
                const chatMessage = chatEvent?.message?.text;

                if (/^\d+$/.test(reservationId) && chatMessage) {
                  dispatch(notifyGuestAction(reservationId, chatMessage));
                }
              }

              if (chatEvent.user?.id?.includes('rid')) {
                handleNewGuestMessage(data);
              }
            }
          });

          if (chEventListener && data) {
            channelListeners.current.push(chEventListener);
            data.markRead();
          }
        })
        .catch(err => {
          console.error(err);
          OpertoLogger.Log(err);
        });
    }
  }, [
    location,
    loggedInMember.getStreamUser.id,
    channelId,
    previousChannelId,
    clientObject.clientReady,
    dispatch,
    queryForChannel,
    handleLastChannelReply,
    navigate,
    loggedInMember.user.id,
    handleNewGuestMessage,
  ]);

  // Only for unmount logic
  useEffect(() => {
    return () => {
      freeChannelListeners();
    };
  }, []);

  useEffect(() => {
    const initRef = (inputRef: HTMLTextAreaElement) => {
      chatInputRef.current = inputRef;
    };
    ChatMsgEmmitter.on('msgInputInit', initRef);
    return () => {
      ChatMsgEmmitter.off('msgInputInit', initRef);
    };
  }, []);

  if (channelId?.includes('members-new') || !clientObject.clientReady) {
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}
      >
        <LoadingContainer loading={true} />
      </Box>
    );
  }

  return (
    <>
      <Box marginRight={showTaskPanel ? '500px' : 0}>
        {!isMobileChatOpened && (
          <TabBar tabItems={upperTabItems} tabIndex={filterIndex} onTabChange={setTabIndex} />
        )}

        {isMessengerEnabled && (
          <Stack sx={{ flexDirection: 'row', overflow: 'hidden', height: 'calc(100vh - 240px)' }}>
            <Paper
              sx={{
                width: '100%',
                boxShadow: !isDesktop && 'none',
              }}
            >
              {!isDesktop && !isMobileChatOpened && renderMobileAndTabletHeader()}
              <Stack direction='row' height='100%'>
                <Chat client={clientObject.streamChatClient}>
                  <ChannelMessagesList
                    activeChannel={currentChannel}
                    previousChannelId={previousChannelId}
                    handleNewChat={setIsNewChat}
                    handleChannelOnClick={handleChannelOnClick}
                    handleFilterToggle={handleFilterToggle}
                    filterIndex={filterIndex}
                  >
                    {isDesktop && renderDesktopHeader()}
                  </ChannelMessagesList>

                  <ChatMessages
                    setCurrentChannel={setCurrentChannel}
                    handleNewChat={setIsNewChat}
                    handleChannelOnClick={handleChannelOnClick}
                    isNewChat={isNewChat}
                    filterIndex={filterIndex}
                    isMessengerEnabled={isMessengerEnabled}
                    onCopilotButtonClick={handleCopilotButtonClick}
                    onSendMessage={handleOnSendMessage}
                    copilotButtonDisabled={!isGuestTab}
                    onTaskClick={handleTaskClick}
                    onNewTaskClick={handleNewTaskClick}
                    onViewTaskClick={handleViewTaskClick}
                  />

                  {isNewChat && !isDesktop && (
                    <SwipeableDrawer anchor='bottom' onClose={onCloseDrawer}>
                      <MessagePeopleListChatContainer
                        onNewChat={setIsNewChat}
                        peopleType={filterIndex}
                        user={loggedInMember}
                        lastClickedOnChannel={handleChannelOnClick}
                      />
                    </SwipeableDrawer>
                  )}

                  {isCopilotEnabled && isGuestTab && (
                    <CopilotPanel
                      isOpen={isCopilotPanelOpen}
                      onClose={() => setIsCopilotPanelOpen(false)}
                      onSendReply={handleSendReply}
                      onInsertReply={handleInsertClick}
                      ref={copilotPanelRef}
                    />
                  )}
                </Chat>
              </Stack>
            </Paper>
          </Stack>
        )}

        {tasksEnabled
          ? filterIndex === MessageFilterIndex.SETTINGS && <SettingsTabPage active />
          : filterIndex === MessageFilterIndex.SETTINGS - 1 && <SettingsTabPage active />}
        {/* -1 is required to shift Settings content left if Tasks is disabled */}

        {showFloatingFab && !isTasksTab && (
          <FabIcon
            icon={<EditIcon />}
            size='large'
            color='primary'
            ariaLabel='add'
            onClick={() => setIsNewChat(true)}
            styles={{ position: 'fixed', bottom: '16px', right: '24px', zIndex: 1000 }}
          />
        )}
      </Box>

      {tasksEnabled && showTaskPanel && (
        <TasksDetails
          taskId={taskId}
          incomingReservationId={currentReservationId}
          handleClose={handleTaskPanelClose}
          onTaskUpdate={handleTaskUpdate}
        />
      )}
    </>
  );
};

export default MessagingPage;
