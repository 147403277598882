import { Box, Stack, TextField } from '@mui/material';
import { MediaFile } from '@operto/media-shared';
import { trpc } from '@operto/trpc-client';
import ConfirmLeaveDialog, { CommNavigationType } from 'Common/Dialog/ConfirmLeaveDialog';
import LanguageSelector from 'Common/LanguageSelector/LanguageSelector';
import { companySelector } from 'company/state/companySelectors';
import useCommunications from 'hooks/useCommunications';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import { logger } from 'lib/logger';
import React, { Ref, useEffect, useState } from 'react';
import EmailEditor, { Editor, EditorRef, EmailEditorProps } from 'react-email-editor';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import { useUploadMediaMutation } from 'redux/media/api-slice';
import { EmailTemplateType, MAX_SUBJECT_LENGTH } from './CommunicationsEmailTemplatePage';

const PROJECT_ID = 234117;
const DEFAULT_TEMPLATE_ID = 513442;

const CommunicationsEmailTemplateBuilder = ({
  templateData = {},
  editorRef,
  onChange,
  subjectError,
  showSubjectRequired,
  isTemplateDirty,
}: {
  templateData: EmailTemplateType;
  editorRef: unknown;
  onChange: (template: EmailTemplateType) => void;
  subjectError: boolean;
  showSubjectRequired?: boolean;
  isTemplateDirty: boolean;
}) => {
  const { t, languages } = useTranslation();
  const navigate = useNavigate();
  const { data: communications } = useCommunications();
  const { snackbar } = useSnackbar();
  const company = useAppSelector(companySelector());
  const [uploadMedia] = useUploadMediaMutation();
  const { isFeatureEnabled } = useAppFeatures();

  const isCustomVariablesEnabled =
    isAdmin() || isFeatureEnabled('communicationsCustomVariablesInEmail', company.id);

  const [key, setKey] = useState(0);
  const [dialogState, setDialogState] = useState<CommNavigationType | undefined>(undefined);

  // Fetch all enabled variables for the company
  const { data: allVariables } = trpc.variables.getVariables.useQuery({
    type: isCustomVariablesEnabled ? undefined : 'system',
    companyId: company.id,
    enabled: true,
  });

  const { locale, id, subject, editorData } = templateData;
  const characterCount = subject?.length || 0;

  // Extract locales from the communication with the specified id, adding the new locale if necessary
  const communicationLocales = communications?.find(c => c.id === id)?.locales ?? [];
  const locales = [...new Set([...communicationLocales, locale])];

  const missingLocales = languages.filter(l => !locales.includes(l.locale));
  const arrayMissingLocales = missingLocales?.map(l => l.locale);

  const setMergeTags = (unlayerInstance: Editor) => {
    if (allVariables && unlayerInstance) {
      const mergeTags: Record<string, { name: string; value: string }> = {};

      allVariables.forEach(variable => {
        mergeTags[variable.name] = {
          name: `*{{${variable.name}}}*`,
          value: `*{{${variable.name}}}*`,
        };
      });

      unlayerInstance.setMergeTags(mergeTags);
    }
  };

  const onLoad: EmailEditorProps['onLoad'] = unlayerInstance => {
    if (id) {
      unlayerInstance.loadDesign(editorData);
    } else {
      unlayerInstance.loadTemplate(DEFAULT_TEMPLATE_ID);
    }
    setMergeTags(unlayerInstance);

    // Apply border-radius to the iframe
    const iframe = document.querySelector('iframe');
    if (iframe) {
      iframe.style.borderRadius = '16px';
    }
  };

  const onReady: EmailEditorProps['onReady'] = unlayerInstance => {
    unlayerInstance.registerCallback(
      'image',
      async (file: MediaFile, done: (data?: { progress: number; url?: string }) => void) => {
        try {
          done({ progress: 10 });
          const response = await uploadMedia({
            service: 'communications',
            media: file.attachments[0],
          }).unwrap();

          done({ progress: 100, url: response.data.mediaUrl });
        } catch (error) {
          // TODO: reset the image uploader state for error handling
          done();
          snackbar(t('error_uploading_image'));
          logger.error('Image upload failed:', error);
        }
      },
    );
  };

  const handleLanguageChange = (newLocale: string, type: 'create' | 'edit') => {
    if (isTemplateDirty) {
      setDialogState({ language: newLocale, navigationType: type });
    } else {
      handleLanguageNavigation(newLocale, type);
    }
  };

  const handleLanguageNavigation = (selectedLanguage: string, type: 'create' | 'edit') => {
    navigate(
      `/communications/${type}/email/${id}/${selectedLanguage !== 'en' ? selectedLanguage : ''}`,
    );
  };

  const handleConfirmDialogSubmit = () => {
    if (dialogState) {
      handleLanguageNavigation(dialogState.language, dialogState.navigationType);
    }
    setDialogState(undefined);
  };

  useEffect(() => {
    // Increment key to rerender the EmailEditor when
    // allVariables or selectedLanguage changes
    // A workaround as unable to rerender using Editor.onLoad callback
    setKey((prevKey: number) => prevKey + 1);
  }, [allVariables, editorData]);

  return (
    <>
      <Stack
        direction='row'
        spacing={3}
        sx={{ backgroundColor: 'white', padding: '24px', borderRadius: '16px' }}
      >
        <Box sx={{ width: '250px' }}>
          <LanguageSelector
            label={t('language_version')}
            value={locale}
            onChange={selectedLocale => {
              handleLanguageChange(selectedLocale, 'edit');
            }}
            onSelectNewLanguage={selectedLocale => {
              handleLanguageChange(selectedLocale, 'create');
            }}
            disableNewLanguage={!id}
            languageList={locales}
            newLanguageList={arrayMissingLocales}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          <TextField
            value={subject}
            onChange={e => {
              onChange({ ...templateData, subject: e.target.value });
            }}
            label={t('email_subject')}
            fullWidth
            required
            error={subjectError}
            data-testid='email-subject'
            helperText={
              showSubjectRequired
                ? t('subject_is_required')
                : `${characterCount}/${MAX_SUBJECT_LENGTH}`
            }
            sx={{
              '& > p': {
                textAlign: showSubjectRequired ? 'left' : 'right',
              },
            }}
          />
        </Box>
      </Stack>

      <Box sx={{ borderRadius: '16px', paddingTop: '24px' }} data-testid='editor-container'>
        <EmailEditor
          key={key}
          minHeight='calc(100vh - 200px)'
          ref={editorRef as Ref<EditorRef>}
          onLoad={onLoad}
          onReady={onReady}
          options={{
            projectId: PROJECT_ID,
            tools: {
              menu: { enabled: false },
              html: {
                properties: {
                  html: {
                    value: '<p>Enter your Source Code here</p>',
                  },
                },
              },
              image: {
                properties: {
                  src: {
                    value: {
                      url: '',
                    },
                  },
                },
              },
            },
            features: { audit: false, preheaderText: false },
            tabs: {
              uploads: {
                enabled: false,
              },
            },
            translations: {
              'en-US': {
                'labels.merge_tags': 'Variables',
              },
            },
          }}
        />
      </Box>

      <ConfirmLeaveDialog
        open={!!dialogState}
        onClose={() => setDialogState(undefined)}
        onSubmit={handleConfirmDialogSubmit}
      />
    </>
  );
};

export default CommunicationsEmailTemplateBuilder;
