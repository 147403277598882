import { trpc } from '@operto/trpc-client';
import { useCallback } from 'react';

const PREVIEW_TOKEN_KEY = 'previewToken';

export default function useGuestAuth() {
  const loginMutation = trpc?.guestAuth?.loginPassword?.useMutation<TokenInfo>()?.mutateAsync;

  const loginGuest = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      const tokenLocalStorage = localStorage.getItem(PREVIEW_TOKEN_KEY);

      if (tokenLocalStorage) {
        const token = JSON.parse(tokenLocalStorage) as TokenInfo;

        if (!isTokenExpired(token.expiresAt)) {
          return Promise.resolve(token);
        }
      }

      const newToken = await loginMutation({ username, password });

      localStorage.setItem(PREVIEW_TOKEN_KEY, JSON.stringify(newToken));

      return newToken;
    },
    [loginMutation],
  );

  const transformTokenInfoToQueryParams = useCallback((token: TokenInfo) => {
    return Object.entries(token)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  }, []);

  return {
    loginGuest,
    transformTokenInfoToQueryParams,
  };
}

export type TokenInfo = {
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  expiresAt?: number;
};

const isTokenExpired = (expiresAt: number) => {
  return Date.now() >= expiresAt * 1000;
};
