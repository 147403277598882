import FileCopyIcon from '@mui/icons-material/FileCopy';
import Skeleton from '@mui/lab/Skeleton';
import {
  Box,
  Button,
  CardMedia,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ShowMoreToggle } from '@operto/ui';
import { ICode } from 'code/codeType';
import { guestVerificationEnabledSelector } from 'company/state/companySelectors';
import differenceInDays from 'date-fns/differenceInDays';
import { utcStringToDate, utcToTimeZone } from 'helper/date';
import useTranslation from 'hooks/useTranslation';
import { Property } from 'property/propertyType';
import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IReservation, VerificationStatusText } from 'reservation/reservationType';
import { updateGuestReservationVerification } from 'reservation/state/reservationActions';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import MemberMenuItem from 'ui-library/Components/menu/MemberMenuItem';
import DateDisplay from 'ui-library/Components/widget/content/DateDisplay';
import { showAccessCodeSelector } from 'user/state/userSelectors';
import ReservationDropdownDrawer from './ReservationDropdownDrawer';

export interface ReservationContentMainProps {
  reservation: IReservation;
  property: Property;
  guestCode?: ICode;
  timezone: string;
  loading?: boolean;
  onCreate: () => void;
}

const ReservationContentMain = ({
  reservation,
  loading,
  guestCode,
  timezone,
  onCreate,
}: ReservationContentMainProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [openDetails, setOpenDetails] = React.useState(false);
  const guestVerificationEnabled = useAppSelector(guestVerificationEnabledSelector());
  const showAccessCode = useAppSelector(showAccessCodeSelector());
  const daysBetween = differenceInDays(
    utcStringToDate(reservation?.check_out),
    utcStringToDate(reservation?.check_in),
  );
  const noneText = t('n_a');

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  const handleVerify = (reservation: IReservation) => {
    dispatch(
      updateGuestReservationVerification(!reservation?.incident_payment_verified, reservation),
    );
  };

  return (
    <>
      {reservation || loading ? (
        <Box>
          <ShowMoreToggle onClick={toggleOpenDetails} open={openDetails} fill='primary.main' />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <MemberMenuItem fullName={reservation?.guest_name} />
            {reservation && (
              <ReservationDropdownDrawer
                accessCode={guestCode?.access_code}
                reservation={reservation}
                verifyOnClick={() => handleVerify(reservation)}
                verifyDisabled={showAccessCode}
                paymentInfo={reservation?.payment_info}
                showEdit
                isFillLight
              />
            )}
          </Box>
          {!loading ? (
            <Box
              sx={{
                display: 'flex',
                marginTop: '20px',
                div: {
                  width: '50%',
                },
                height: '128px',
              }}
            >
              <DateDisplay date={reservation?.check_in} timezone={timezone} />
              <Divider
                orientation='vertical'
                sx={{
                  backgroundColor: 'text.white',
                  height: '128px',
                  marginInline: '10px',
                }}
              />
              <DateDisplay date={reservation?.check_out} timezone={timezone} />
            </Box>
          ) : (
            <Skeleton width='100%' height={120} />
          )}
          <Box
            sx={{
              marginTop: '20px',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography variant='body2' sx={{ color: 'text.white' }}>
              {t('card_reservation_code_status', { status: guestCode?.code_status || noneText })}
            </Typography>
            <Typography variant='body2' sx={{ color: 'text.white' }}>
              {t('card_reservation_valid_from', {
                from: guestCode?.valid_from
                  ? utcToTimeZone(guestCode?.valid_from, timezone, 'MMM dd hh:mm a')
                  : noneText,
              })}
            </Typography>
            <Typography variant='body2' sx={{ color: 'text.white' }}>
              {t('card_reservation_valid_until', {
                until: guestCode?.valid_until
                  ? utcToTimeZone(guestCode?.valid_until, timezone, 'MMM dd hh:mm a')
                  : noneText,
              })}
            </Typography>
            <Divider
              orientation='horizontal'
              sx={{
                backgroundColor: 'text.white',
                with: '100%',
                margin: '10px 0',
              }}
            />
            <Stack direction='row'>
              <Typography variant='body2' sx={{ color: 'text.white' }}>
                {t('card_reservation_pms_reservation_code', {
                  code: reservation.external_id || noneText,
                })}
              </Typography>
              <CopyToClipboard
                text={reservation.external_id}
                onCopy={() => {
                  dispatch(
                    toggleSnackbar(SnackbarTypes.OPEN, {
                      message: t('copied_value', { text: reservation.external_id }),
                      variant: SnackbarVariant.INFO,
                    }),
                  );
                }}
              >
                <IconButton size='small' color='inherit'>
                  <FileCopyIcon data-testid='phone-copy-button' />
                </IconButton>
              </CopyToClipboard>
            </Stack>
            {openDetails && (
              <Collapse in={openDetails}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant='body2' sx={{ color: 'text.white', display: 'inline' }}>
                    {t('card_reservation_stay_duration', { count: daysBetween })}
                  </Typography>
                  {guestVerificationEnabled && (
                    <Typography variant='body2' sx={{ color: 'text.white' }}>
                      {t('card_reservation_verification_status', {
                        status: reservation?.verification_status
                          ? VerificationStatusText[reservation?.verification_status]
                          : noneText,
                      })}
                    </Typography>
                  )}
                </Box>
              </Collapse>
            )}
          </Box>
          <Box>
            <Chip
              label={`Access Code:  ${reservation?.guest_access_code || 'None'}`}
              sx={{
                display: 'flex',
                backgroundColor: '#0040CC',
                color: 'text.white',
                fontSize: 16,
                height: 44,
                borderRadius: '12px',
                padding: '10px',
                gap: '10px',
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            color: 'text.white',
            height: '328px',
          }}
        >
          <CardMedia sx={{ marginBottom: '24px' }}>
            <img src='/img/widgets/ICO_No_Reservation_Light.svg' />
          </CardMedia>
          <Typography variant='body1'>{t('no_pending_active_guests')}</Typography>
          <Button
            onClick={onCreate}
            sx={{ color: 'text.white', textTransform: 'none', fontWeight: 700 }}
          >
            {t('add_new_reservation')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default ReservationContentMain;
