export type GenericType =
  | 'undefined'
  | 'autoLocked'
  | 'battery'
  | 'codeReport'
  | 'codeLimit'
  | 'status';
export type CodeStateType =
  | 'codeQueued'
  | 'codeCreated'
  | 'codeUpdated'
  | 'codeDeleted'
  | 'codeFailed'
  | 'codeUsed'
  | 'codeUsedMember'
  | 'codeUsedGuest';
export type CheckoutStateType =
  | 'codeCheckoutQueued'
  | 'codeCheckoutCreated'
  | 'codeCheckoutDeleted'
  | 'codeCheckoutFailed';
export type CheckType = 'codeCheckIn' | 'codeCheckOut';
export type CleaningStateType = 'codeCleaningStarted' | 'codeCleaningComplete';
export type MaintenanceStateType = 'codeMaintenanceStarted' | 'codeMaintenanceComplete';
export type LockType =
  | 'lockedByKeypad'
  | 'lockedManually'
  | 'lockTamper'
  | 'lockOrphan'
  | 'lockOnline'
  | 'lockOffline'
  | 'lockOnlineInternal'
  | 'lockOfflineInternal'
  | 'lockQueued'
  | 'lockUnAuthorized'
  | 'lockFailed'
  | 'lockBoltLocked'
  | 'lockBoltUnLocked'
  | 'lockOrphan'
  | 'unlockAttemptByGuest'
  | 'unlockAttemptByOwner'
  | 'unlockedManually';
export type BatteryStateType = 'batteryOk' | 'batteryWarning' | 'batteryCritical' | 'batteryFatal';
export type HubStateType = 'hubOnline' | 'hubOffline';
export type KeypadStateType = 'keypadOk' | 'keypadWarning' | 'keyPadCritical';
export type ThermostatStateType =
  | 'thermostatReadingFailed'
  | 'thermostatSetbackSuccess'
  | 'thermostatSetbackFailed'
  | 'thermostatOccupiedSettingSuccess'
  | 'thermostatOccupiedSettingFailed'
  | 'thermostatVacantSettingSuccess'
  | 'thermostatVacantSettingFailed';
export type ReservationStateType =
  | 'reservationCreateAccessFailed'
  | 'reservationUpdateAccessFailed'
  | 'reservationRenewAccessFailed'
  | 'reservationUpdateAccesInfoFailed';
export type RoomStateType =
  | 'roomStatusGuestReservationEndingCleanup'
  | 'roomStatusGuestCheckingOutByExpiry'
  | 'roomStatusResetBackToCleanAvailable'
  | 'roomTypeGuestCheckinRequested';
export type RiskAlertType = 'environmentRiskAlert' | 'complianceRiskAlert';
export type SendEmailStateType = 'sendEmailFailed' | 'sendSmsFailed';
export type ApiType =
  | 'apiMemberList'
  | 'apiMemberListSimple'
  | 'apiMemberView'
  | 'apiMemberCreate'
  | 'apiMemberUpdate'
  | 'apiMemberUpdateEmail'
  | 'apiMemberUpdatePassword'
  | 'apiMemberStatusActive'
  | 'apiMemberStatusInActive'
  | 'apiMemberRefreshAccess'
  | 'apiMemberAddPropertyAlertExclusion'
  | 'apiMemberRemovePropertyAlertExclusion'
  | 'apiMemberBatchUpdatePropertyAlertExclusion'
  | 'apiBackupCodeRemove'
  | 'apiBackupCodeReplace'
  | 'apiGroupCreate'
  | 'apiGroupRemove'
  | 'apiGroupUpdate'
  | 'apiGroupPropertyAdd'
  | 'apiGroupPropertyRemove'
  | 'apiGroupMemberAdd'
  | 'apiGroupMemberRemove'
  | 'apiIntercomUpdate';
export type GuestPortalType =
  | 'guestPortalView'
  | 'guestPortalUnlockDoorSuccessful'
  | 'guestPortalUnlockDoorDenied'
  | 'guestPortalUnlockDoorNoLock';
export type PmsResultType =
  | 'pmsResError'
  | 'pmsResBlocked'
  | 'pmsResNew'
  | 'pmsResUnitTransfer'
  | 'pmsResReserved'
  | 'pmsResConfirmed'
  | 'pmsResCheckedOut'
  | 'pmsResCancelled';
export type SecurityStateType = 'securityDepositPending';
export type ActivityType =
  | GenericType
  | CodeStateType
  | CheckoutStateType
  | CheckType
  | CleaningStateType
  | MaintenanceStateType
  | LockType
  | BatteryStateType
  | HubStateType
  | KeypadStateType
  | ThermostatStateType
  | ReservationStateType
  | RoomStateType
  | RiskAlertType
  | SendEmailStateType
  | ApiType
  | GuestPortalType
  | PmsResultType
  | SecurityStateType;

export interface IEvent {
  id: number;
  message: string;
  company_id: number;
  property_id: number;
  created_at: string;
  updated_at: string;
  type: ActivityType;
  data: Record<string, unknown> &
    Record<
      string,
      {
        name: string;
      }
    >;
  activityType: string;
  title: string;
  timezone: string;
}

export interface EventDict {
  [Key: string]: IEvent;
}

export interface EventState {
  byId: EventDict;
  list: number[];
  searching?: boolean;
  searchResults?: EventDict;
  searchList?: number[];
  searchKeyword?: string;
  meta: EventCounts;
}

export enum FilterType {
  ALL_ACTIVITIES = 'all',
  TEAM = 'team',
  ACCESS = 'access',
  DEVICES = 'devices',
  GUESTS = 'guests',
  ALERTS = 'alerts',
}

export interface EventCounts {
  [FilterType.ALL_ACTIVITIES]: RecordTrack;
  [FilterType.TEAM]: RecordTrack;
  [FilterType.ACCESS]: RecordTrack;
  [FilterType.DEVICES]: RecordTrack;
  [FilterType.GUESTS]: RecordTrack;
  [FilterType.ALERTS]: RecordTrack;
}

export interface RecordTrack {
  pageNum: number;
  numPerPage: number;
  totalFetched: number;
}

export enum ActivityCodes {
  CODE_USED = 'codeUsed',
  CODE_ACCESS = 'accessCode',
  CODE_CREATED = 'codeCreated',
  CODE_DELETED = 'codeDeleted',
  CODE_QUEUED = 'codeQueued',
  CODE_FAILED = 'codeFailed',
  CODE_CHECKIN = 'codeCheckIn',
  CODE_CHECKOUT_QUEUED = 'codeCheckoutQueued',
  CODE_CHECKOUT_CREATED = 'codeCheckoutCreated',
  CODE_CHECKOUT_DELETED = 'codeCheckoutDeleted',
  CODE_CHECKOUT_FAILED = 'codeCheckoutFailed',
  RESERVATION_CHECKOUT = 'checkout',
  ROOM_STATUS_CLEANING_COMPLETE = 'cleaningComplete',
  ROOM_STATUS_CLEANING_START = 'startedCleaning',
  ROOM_STATUS_CLEANING_ENDING = 'roomStatusGuestReservationEndingCleanup',
  ROOM_STATUS_CLEANING_STARTED = 'roomStatusCleaningStarted',
  ROOM_STATUS_CLEANING_COMPLETED = 'roomStatusCleaningCompleted',
  ROOM_STATUS_GUEST_CHECK_IN = 'roomStatusGuestCheckIn',
  ROOM_STATUS_GUEST_CHECK_OUT = 'roomStatusGuestCheckOut',
  ROOM_STATUS_GUEST_CHECK_OUT_EXPIRY = 'roomStatusGuestCheckingOutByExpiry',
  ROOM_STATUS_RESET_CLEAN_AVAILABLE = 'roomStatusResetBackToCleanAvailable',
  LOCK_MANUALLY_LOCKED = 'lockedManually',
  LOCK_BOLT_LOCKED = 'lockBoltLocked',
  LOCK_BOLT_UNLOCKED = 'lockBoltUnLocked',
  LOCK_AUTO_LOCKED = 'autoLocked',
  LOCK_UNLOCKED_MANUALLY = 'unlockedManually',
  LOCK_KEYPAD_LOCKED = 'lockedByKeypad',
  LOCK_TAMPERED = 'lockTamper',
  LOCK_ONLINE = 'lockOnline',
  LOCK_OFFLINE = 'lockOffline',
  LOCK_ACTIVITY = 'lockActivity',
  BATTERY = 'battery',
  BATTERY_OK = 'batteryOk',
  BATTERY_WARNING = 'batteryWarning',
  BATTERY_CRITICAL = 'batteryCritical',
  BATTERY_FATAL = 'batteryFatal',
  PROPERTY_STATUS = 'status',
  THERMOSTAT_OCCUPIED_SETTING_SUCCESS = 'thermostatOccupiedSettingSuccess',
  THERMOSTAT_VACANT_SETTING_SUCCESS = 'thermostatVacantSettingSuccess',
  THERMOSTAT_SETBACK_SUCCESS = 'thermostatSetbackSuccess',
  RISK_ALERT_ENVIRONMENT = 'environmentRiskAlert',
  RISK_ALERT_COMPLIANCE = 'complianceRiskAlert',
  ROOM_TYPE_GUEST_CHECK_IN_REQUESTED = 'roomTypeGuestCheckinRequested',
}

export const types = [
  'codeUsed',
  'accessCode',
  'checkout',
  'lockedManually',
  'autoLocked',
  'unlockedManually',
  'battery',
  'codeLimit',
  'codeCreated',
  'codeDeleted',
  'lockedByKeypad',
  'status',
  'lockTamper',
  'lockOnline',
  'lockOffline',
  'batteryOk',
  'lockBoltUnLocked',
  'lockBoltLocked',
  'batteryWarning',
  'batteryCritical',
  'batteryFatal',
  'lockActivity',
  'thermostatOccupiedSettingSuccess',
  'thermostatVacantSettingSuccess',
  'roomTypeGuestCheckinRequested',
];
