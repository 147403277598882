import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton, SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export interface ConfirmDialogProps {
  open: boolean;
  loading?: boolean;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  submitButtonText?: string;
  submitButtonColor?: 'primary' | 'error';
  disabled?: boolean;
  onCancel?: () => void;
  onSubmit: () => void;
  onClose?: () => void;
  contentStyles?: SxProps;
  actionsStyles?: SxProps;
  rootStyles?: SxProps;
  cancelButtonText?: string;
  showCloseIcon?: boolean;
}

export const ConfirmDialog = ({
  open,
  loading,
  onSubmit,
  onCancel,
  onClose,
  title,
  children,
  submitButtonText = 'CONFIRM',
  submitButtonColor = 'primary',
  disabled,
  contentStyles,
  actionsStyles,
  rootStyles,
  cancelButtonText,
  showCloseIcon = false,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog maxWidth='md' open={open} fullWidth sx={rootStyles}>
      {title ? (
        typeof title === 'string' ? (
          <DialogTitle>
            {title}
            {onClose && showCloseIcon && (
              <IconButton
                aria-label='close'
                onClick={onClose}
                sx={({ palette }) => ({
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: palette.grey[500],
                })}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
        ) : (
          title
        )
      ) : null}
      <DialogContent sx={contentStyles}>{children}</DialogContent>
      <DialogActions sx={{ padding: 2, ...actionsStyles }}>
        {onCancel && (
          <Button data-testid='modal-cancel-btn' onClick={onCancel}>
            {cancelButtonText ?? t('cancel')}
          </Button>
        )}
        <LoadingButton
          data-testid='modal-confirm-btn'
          autoFocus
          variant='contained'
          onClick={onSubmit}
          color={submitButtonColor}
          disabled={disabled}
          loading={loading}
        >
          {submitButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
