import ErrorIcon from '@mui/icons-material/Error';
import { Badge, Box, Link, SxProps, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AutomateDataType, logger, TriggerDataType } from '@operto/communications-shared';
import { theme } from '@operto/ui';
import IOSSwitch from 'Common/IOSSwitch/IOSSwitch';
import { TriggerTypeOption } from 'hooks/useAutomate';
import useTranslation from 'hooks/useTranslation';
import { EmailChannelIcon, SmsChannelIcon } from 'Pages/Communications/CommunicationsIcons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCell } from 'ui-library/Components/table/TableCell';
import AutomateActionMenu from './AutomateActionMenu';

export default function useAutomateColumns({
  onToggleWorkflowEnabled,
  handleDeleteFlow,
}: {
  onToggleWorkflowEnabled: (workflowId: string, enabled: boolean) => void;
  handleDeleteFlow: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getChannelInfo = (triggers: TriggerDataType[]) => {
    const hasTriggers = !!triggers?.length;
    const hasNoTemplates = triggers?.some(
      trigger => !trigger.templates?.sms?.length && !trigger.templates?.email?.length,
    );
    const hasSmsTemplates = triggers?.some(trigger => trigger.templates?.sms?.length);
    const hasEmailTemplates = triggers?.some(trigger => trigger.templates?.email?.length);
    const showBigError = !hasTriggers || hasNoTemplates || (!hasSmsTemplates && !hasEmailTemplates);

    const hasEmailBeforeNoOffset = triggers?.some(
      trigger =>
        trigger.templates?.email?.length &&
        trigger.type === TriggerTypeOption.BEFORE_CHECK_IN &&
        !trigger.offset,
    );
    const hasEmailAfterNoOffset = triggers?.some(
      trigger =>
        trigger.templates?.email?.length &&
        trigger.type === TriggerTypeOption.AFTER_CHECK_IN &&
        !trigger.offset,
    );
    const hasEmailTriggerError = hasEmailBeforeNoOffset || hasEmailAfterNoOffset;

    const hasSmsBeforeNoOffset = triggers?.some(
      trigger =>
        trigger.templates?.sms?.length &&
        trigger.type === TriggerTypeOption.BEFORE_CHECK_IN &&
        !trigger.offset,
    );
    const hasSmsAfterNoOffset = triggers?.some(
      trigger =>
        trigger.templates?.sms?.length &&
        trigger.type === TriggerTypeOption.AFTER_CHECK_IN &&
        !trigger.offset,
    );
    const hasSmsTriggerError = hasSmsBeforeNoOffset || hasSmsAfterNoOffset;

    return {
      hasEmailTemplates,
      hasSmsTemplates,
      hasEmailTriggerError,
      hasSmsTriggerError,
      showBigError,
    };
  };

  const calculateWorkflowChannels = (triggers: TriggerDataType[]) => {
    const {
      hasEmailTemplates,
      hasSmsTemplates,
      hasEmailTriggerError,
      hasSmsTriggerError,
      showBigError,
    } = getChannelInfo(triggers);

    if (showBigError) {
      return (
        <Box sx={errorBoxStyle}>
          <Tooltip title={t('tooltip_missing_template')}>
            <ErrorIcon sx={errorIconStyle} />
          </Tooltip>
        </Box>
      );
    }

    return (
      <>
        {hasEmailTemplates && (
          <Badge
            badgeContent={
              hasEmailTriggerError && <ErrorIcon sx={{ ...errorIconStyle, fontSize: 18 }} />
            }
            sx={badgeStyle}
          >
            <EmailChannelIcon
              tooltip={hasEmailTriggerError ? t('workflow_minor_error') : t('email')}
            />
          </Badge>
        )}

        {hasSmsTemplates && (
          <Badge
            badgeContent={
              hasSmsTriggerError && <ErrorIcon sx={{ ...errorIconStyle, fontSize: 18 }} />
            }
            sx={badgeStyle}
          >
            <SmsChannelIcon tooltip={hasSmsTriggerError ? t('workflow_minor_error') : t('sms')} />
          </Badge>
        )}
      </>
    );
  };

  const handleNameClick = (workflowId: string) => {
    navigate(`/automate/edit/${workflowId}`);
  };

  const renderEnabled = (params: GridRenderCellParams) => {
    const triggers = params.row.triggers;
    const { hasEmailTriggerError, hasSmsTriggerError, showBigError } = getChannelInfo(triggers);
    const hasError = hasEmailTriggerError || hasSmsTriggerError || showBigError;
    const enabled = params.row.enabled;
    const tooltipText = hasError
      ? t('complete_required_fields')
      : enabled
      ? t('active')
      : t('inactive');

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <IOSSwitch
          active={enabled}
          disabled={hasError}
          onChange={(_, checked) => onToggleWorkflowEnabled?.(params.row.id, checked)}
          tooltip={tooltipText}
        />
      </Box>
    );
  };

  const renderChannel = (params: GridRenderCellParams) => {
    const triggers = params.row.triggers;
    const channel = calculateWorkflowChannels(triggers);
    return channel;
  };

  const renderActions = ({ row }: GridRenderCellParams<unknown, AutomateDataType>) => {
    return (
      <AutomateActionMenu
        workflowId={row.id}
        onClickEdit={() => navigate(`/automate/edit/${row.id}`)}
        onDeleteSuccess={handleDeleteFlow}
        onDeleteError={error => logger.error(error)}
      />
    );
  };

  const renderName = ({ row }: GridRenderCellParams<unknown, AutomateDataType>) => {
    return (
      <TableCell
        title={
          <Link onClick={() => handleNameClick(row.id)} sx={linkStyles} underline='none'>
            <Typography variant='body-sm-600'>{row.name}</Typography>
          </Link>
        }
      />
    );
  };

  const columns: GridColDef<AutomateDataType>[] = [
    {
      field: 'name',
      headerName: t('workflow_table_header'),
      sortable: true,
      flex: 5,
      renderCell: renderName,
    },
    {
      field: 'triggers',
      headerName: t('workflow_channel'),
      sortable: false,
      flex: 5,
      renderCell: renderChannel,
    },
    {
      field: 'enabled',
      headerName: t('active'),
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderEnabled,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      flex: 3,
      type: 'actions',
      renderCell: renderActions,
    },
  ];

  return columns;
}

const errorBoxStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  border: '2px dashed var(--light-other-outlined-border-23-p, rgba(8, 22, 62, 0.23))',
  borderRadius: '8px',
};

const errorIconStyle: SxProps = {
  color: theme.palette.error.main,
  backgroundColor: 'white',
  borderRadius: '500px',
  border: '1px',
};

const badgeStyle: SxProps = {
  '& .MuiBadge-badge': {
    top: '5px',
    right: '5px',
  },
  marginRight: '8px',
};

const linkStyles = {
  cursor: 'pointer',
};
