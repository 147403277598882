import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

export const getEventsByPropertyId = (
  propertyId: number,
  activityType = 'all',
  pageNum = 0,
  numPerPage = 50,
  groupByHourAndMinute = false,
) =>
  axios.get(
    `${api}/v2/companies/properties/${propertyId}/activities?numPerPage=${numPerPage}&pageNum=${pageNum}&type=${activityType}&groupByHourAndMinute=${groupByHourAndMinute}`,
  );

export const getEvents = (
  activityType = 'all',
  pageNum = 0,
  roomType?: string,
  numPerPage = 50,
) => {
  const params = {
    type: activityType,
    pageNum,
    roomType,
    numPerPage,
  };

  return axios.get(`${api}/v2/companies/activities`, { params });
};
