import AddIcon from '@mui/icons-material/Add';
import { Box, DialogContent, Fab, List, Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { BaseContainer } from 'Style/Components/BaseComponents';
import {
  LinkedAccountData,
  deleteAuthDataByEmail,
  getLinkedAccounts,
  setActiveAuthAccount,
} from 'auth/LinkedAccountsHelper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { currentUserSelector } from 'user/state/userSelectors';
import { AccountsListItemStatus } from './AccountsListItemStatus';

export interface Creds {
  username: string;
  password: string;
}

interface MultipleAccountsProps {
  isRoot?: boolean;
}

const LinkedAccounts = ({ isRoot }: MultipleAccountsProps) => {
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector());
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{ name: string; email: string | undefined }>(
    undefined,
  );
  const linkedAccountData: LinkedAccountData[] = getLinkedAccounts().sort(val => {
    return val.isLoggedIn ? -1 : 1;
  });

  useEffect(() => {
    if (user?.email) setActiveAuthAccount(user.email);
  }, [user?.email]);

  const onShowDeleteConfirmationDialog = () => {
    setShowDeleteDialog(true);
  };

  const onAccountDelete = () => {
    deleteAuthDataByEmail(itemToDelete.email);
    setShowDeleteDialog(false);
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `Account un-link successfull`,
        variant: SnackbarVariant.SUCCESS,
      }),
    );
  };

  const onAddLinkedAccountClicked = () => {
    window.location.href = process.env.REACT_APP_AMPLIFY_APP_AUTH_URL;
  };

  return (
    <BaseContainer
      style={{ padding: `${isRoot ? '0 10%' : '0'}`, display: 'flex', justifyContent: 'center' }}
    >
      <Box sx={{ width: '726px' }}>
        <AddCard
          title={'Linked Accounts'}
          noPaddingContent
          action={
            <Fab
              title='Link Account'
              data-testid='link-account-button'
              size='small'
              color='primary'
              onClick={onAddLinkedAccountClicked}
            >
              <AddIcon />
            </Fab>
          }
        >
          {linkedAccountData && (
            <List>
              {linkedAccountData.map((linkedAccount, index) => (
                <Box key={linkedAccount + '_' + index}>
                  <AccountsListItemStatus
                    key={linkedAccount + '_' + index}
                    companyName={linkedAccount.companyName}
                    name={linkedAccount.name ?? 'Please login to save company data'}
                    email={linkedAccount.email}
                    tokenExpired={linkedAccount.expired}
                    onDelete={(name, email) => {
                      setItemToDelete({ name, email });
                      onShowDeleteConfirmationDialog();
                    }}
                    isRoot={linkedAccount.isLoggedIn}
                  />
                </Box>
              ))}
            </List>
          )}
        </AddCard>
      </Box>

      <ConfirmDialog
        open={showDeleteDialog}
        title={'Un-link and sign out of the account?'}
        submitButtonText='Confirm'
        onSubmit={() => onAccountDelete()}
        onCancel={() => {
          setShowDeleteDialog(false);
          setItemToDelete(undefined);
        }}
      >
        <DialogContent>
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant='subtitle1'>
              Please confirm that you would like to un-link (and sign out) of this account. You can
              always add it again at a later time.
            </Typography>
          </Box>
          <Typography component='span' variant='body1'>
            <List sx={{ paddingLeft: '20px' }}>
              <Box sx={{ fontWeight: 500 }}>name:</Box> {itemToDelete?.name} <br />
              <Box sx={{ fontWeight: 500 }}>email:</Box> {itemToDelete?.email}
            </List>
          </Typography>
        </DialogContent>
      </ConfirmDialog>
    </BaseContainer>
  );
};

export default LinkedAccounts;
