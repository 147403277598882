import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export interface DeliveryLogsSendConfirmDialogProps {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onSend: () => void;
  sendDisabled: boolean;
  loading?: boolean;
  title: string;
}

export default function DeliveryLogsSendConfirmDialog({
  children,
  open,
  onClose,
  onSend,
  sendDisabled,
  loading,
  title,
}: DeliveryLogsSendConfirmDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      open={open}
      onCancel={onClose}
      onSubmit={onSend}
      disabled={sendDisabled}
      submitButtonText={t('send')}
      title={title}
      contentStyles={contentStyles}
      rootStyles={rootStyles}
      actionsStyles={actionsStyles}
      loading={loading}
    >
      {children}
    </ConfirmDialog>
  );
}

const actionsStyles = {
  padding: '0px 16px 16px 16px',
  '& button': { textTransform: 'capitalize' },
};

const rootStyles = { '& div[role=dialog]': { maxWidth: '444px', borderRadius: '10px' } };
const contentStyles = { padding: '0 24px 0 24px', display: 'flex', flexDirection: 'row' };
