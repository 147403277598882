import { trpc } from '@operto/trpc-client';
import { Variable } from '@operto/variables-shared';

export default function useVariables() {
  //TODO define proper export types and reuse here to enable retry
  //   const defaultOptions = {
  //     retry: (
  //       failureCount: number,
  //       error: {
  //         data: DefaultErrorData;
  //       },
  //     ) => {
  //       // retry if not unauth, forbidden or not found
  //       return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
  //     },
  //   };
  const createVariableMutation = trpc?.variables.createVariable.useMutation();
  const deleteVariableMutation = trpc?.variables.deleteVariable.useMutation();
  const updateVariableMutation = trpc?.variables.updateVariable.useMutation();

  const getVariables = trpc?.variables.getVariables.useQuery;
  const getVariable = trpc?.variables.getVariable.useQuery;
  const createVariable = async (variable: Variable) => {
    return await new Promise((resolve, reject) =>
      createVariableMutation.mutate(variable, {
        onSuccess: resolve,
        onError: reject,
      }),
    );
  };

  const deleteVariable = async (id: string) => {
    return await new Promise((resolve, reject) =>
      deleteVariableMutation.mutate(
        { id },
        {
          onSuccess: resolve,
          onError: reject,
        },
      ),
    );
  };

  const updateVariable = async (variable: Variable) => {
    return await new Promise((resolve, reject) =>
      updateVariableMutation.mutate(variable, {
        onSuccess: resolve,
        onError: reject,
      }),
    );
  };

  return {
    getVariables,
    getVariable,
    createVariable,
    deleteVariable,
    updateVariable,
  };
}
