import HelpIcon from '@mui/icons-material/Help';
import { Button, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import * as React from 'react';
import { FooterContainer, SmartCardIconButton } from 'ui-library/Styled/smartcard';

export interface LockFooterProps {
  onDetailsClick: () => void;
}

const LockFooter = ({ onDetailsClick }: LockFooterProps) => {
  const { t } = useTranslation();
  return (
    <FooterContainer justify='space-between'>
      <Button sx={{ color: 'white' }} onClick={onDetailsClick}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
          {t('lock_card_view_access')}
        </Typography>
      </Button>
      <SmartCardIconButton>
        <HelpIcon />
      </SmartCardIconButton>
    </FooterContainer>
  );
};

export default LockFooter;
