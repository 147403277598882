// @ts-nocheck
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { AppBar, Button, Grid, IconButton, Switch, SxProps, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { paletteStyles } from '@operto/ui';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { ICategory, IEntranceInstructions, IPage } from 'redux/guestportal';

export interface RightSideTopbarProps {
  title?: string;
  titleComponent?: React.ReactNode;
  editableTitle?: Element;
  handleClose?: () => void;
  handleBack?: () => void;
  onSave?: (page: IPage | ICategory | IEntranceInstructions) => void;
  preViewButton?: boolean;
  onPreview?: () => void;
  publish?: boolean;
  enabledCampaign?: boolean;
  handleEnabledCampaign?: (value: boolean) => void;
  page?: IPage | ICategory;
  buttonComponent?: React.ReactNode;
  buttonDisabled?: boolean;
  data?: IEntranceInstructions;
}

const RightSideTopbar = ({
  title,
  titleComponent,
  editableTitle,
  handleClose,
  handleBack,
  onSave,
  preViewButton,
  onPreview,
  publish,
  enabledCampaign,
  handleEnabledCampaign,
  page,
  buttonComponent,
  buttonDisabled = false,
  data,
}: RightSideTopbarProps) => {
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);

  const drawerIconStyles: SxProps = {
    width: '64px',
    height: '64px',
    borderRadius: 0,
    marginLeft: isMobile ? ' -16px' : '-24px',
  };

  return (
    <AppBar sx={appBarStyles}>
      <Toolbar sx={toolbarStyles}>
        {handleClose && (
          <IconButton
            sx={drawerIconStyles}
            aria-label='open drawer'
            edge='start'
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon style={{ color: paletteStyles.palette.text.gray }} />
          </IconButton>
        )}
        {handleBack && (
          <IconButton
            sx={drawerIconStyles}
            aria-label='open drawer'
            edge='start'
            onClick={() => {
              handleBack();
            }}
          >
            <ChevronLeftIcon style={{ color: paletteStyles.palette.text.white }} />
          </IconButton>
        )}
        {title && (
          <Typography variant='h6' noWrap component='div' sx={typographyStyles}>
            {title}
          </Typography>
        )}
        {titleComponent}
        {editableTitle && (
          <Typography variant='h6' noWrap component='div' sx={typographyStyles}>
            {editableTitle}
          </Typography>
        )}
        {publish && (
          <Grid container alignItems='center'>
            <Grid item>
              <Switch
                checked={enabledCampaign}
                onChange={handleEnabledCampaign}
                color='primary'
                name='enable email'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Grid item>Publish</Grid>
          </Grid>
        )}
        {preViewButton && (
          <Button sx={buttonStyles} variant='contained' onClick={() => onPreview()}>
            Preview
          </Button>
        )}
        {onSave && (
          <Button
            sx={buttonStyles}
            variant='contained'
            color='primary'
            startIcon={<SaveIcon />}
            onClick={() => {
              onSave(page ?? data);
              setButtonClicked(true);
              setTimeout(() => setButtonClicked(false), 2000);
            }}
            disabled={buttonDisabled || buttonClicked}
          >
            Save
          </Button>
        )}
        {buttonComponent}
      </Toolbar>
    </AppBar>
  );
};

const appBarStyles: SxProps = {
  borderRadius: 0,
  position: 'static',
  backgroundColor: paletteStyles.palette.topBar,
  boxShadow: 'rgba(0, 0, 0, 0.2) 2px 1px 3px',
};

const toolbarStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

const typographyStyles: SxProps = {
  marginLeft: '1.5rem',
  fontWeight: 'normal',
  color: paletteStyles.palette.text.primary,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flexGrow: 1,
  display: { xs: 'none', sm: 'block' },
};

const buttonStyles: SxProps = {
  marginLeft: '2rem',
};

export default RightSideTopbar;
