import { Box, Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { Loading } from 'Common/Loading';
import { ChannelType, useAutomate } from 'hooks/useAutomate';
import useCommunications from 'hooks/useCommunications';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';
import CommunicationsTemplateDeleteConfirmDialogWorkflowDetails from './CommunicationsTemplateDeleteConfirmDialogWorkflowDetails';

// Template dialog deletion types, determine dialog content
export const TemplateDeletionTypes = {
  ENGLISH_ONLY: 'EnglishOnly',
  SINGLE_LANGUAGE: 'SingleLanguage',
  ALL_LANGUAGES: 'AllLanguages',
} as const;

export type TemplateDeletionType =
  (typeof TemplateDeletionTypes)[keyof typeof TemplateDeletionTypes];

export interface CommunicationsTemplateDeleteConfirmDialogProps {
  deletionType: TemplateDeletionType;
  channel: string;
  locale?: string;
  templateName: string;
  templateId: string;
  contentId: string;
  onClose: () => void;
  onSuccess: () => void;
  onFailure: (error: string) => void;
}

const CommunicationsTemplateDeleteConfirmDialog = ({
  deletionType,
  channel,
  locale,
  templateName,
  templateId,
  contentId,
  onClose,
  onSuccess,
  onFailure,
}: CommunicationsTemplateDeleteConfirmDialogProps) => {
  const { t, languages } = useTranslation();
  const { deleteTemplate, deleteContent, communicationsRefetch } = useCommunications();
  const {
    workflows,
    isLoading: workflowLoading,
    isError: workflowError,
  } = useAutomate({
    channels: [channel as ChannelType],
    templateId: templateId,
  });
  const [error, setError] = useState<string | null>(
    workflowError ? t('delete_template_get_workflows_error') : null,
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const width = { standard: '444px', workflow: '600px' };
  const showLoading = deletionType !== TemplateDeletionTypes.SINGLE_LANGUAGE && workflowLoading;
  const showWorkflowsText =
    workflows?.length > 0 && deletionType !== TemplateDeletionTypes.SINGLE_LANGUAGE;
  const dialogWidth = showWorkflowsText ? width.workflow : width.standard;

  function replacePlaceholders(
    template: string,
    replacements: { channel: string; templateName: string; languageName?: string },
  ): string {
    return template
      .replace('[channel]', replacements.channel)
      .replace('[templateName]', replacements.templateName)
      .replace('[languageName]', replacements.languageName || '');
  }

  const languageName =
    !locale || locale === 'en'
      ? t('english_default')
      : languages.find(l => l.locale === locale)?.name ?? '';

  const deleteDialogContentMap = {
    [TemplateDeletionTypes.ENGLISH_ONLY]: {
      title: t('delete_template_english_only_title'),
      text: t('delete_template_english_only_text'),
    },
    [TemplateDeletionTypes.SINGLE_LANGUAGE]: {
      title: t('delete_template_single_language_title'),
      text: t('delete_template_single_language_text'),
    },
    [TemplateDeletionTypes.ALL_LANGUAGES]: {
      title: t('delete_template_all_language_title'),
      text: t('delete_template_all_language_text'),
    },
  };

  const { title, text } = getDeleteDialogContent(deletionType, {
    channel: channel === 'sms' ? 'SMS' : channel,
    templateName,
    languageName,
  });

  // dialog styles
  const rootStyles = { '& div[role=dialog]': { maxWidth: `${dialogWidth}`, borderRadius: '10px' } };
  const contentStyles = { padding: '8px 24px 8px 24px', display: 'flex', flexDirection: 'row' };
  const actionStyles = {
    padding: '8px 16px 16px 8px',
    '& button': { textTransform: 'capitalize' },
  };

  interface DialogContent {
    title: string;
    text: string;
  }

  // Gets dialog content based on deletion type and replace placeholders
  function getDeleteDialogContent(
    deletionType: TemplateDeletionType,
    replacements: { channel: string; templateName: string; languageName?: string },
  ): DialogContent {
    const { title, text } = deleteDialogContentMap[deletionType] || { title: '', text: '' };

    return {
      title: replacePlaceholders(title, replacements),
      text: replacePlaceholders(text, replacements),
    };
  }

  const handleCloseDialog = () => {
    onClose();
  };

  const handleSubmitDialog = async () => {
    setIsDeleting(true);
    setError(null);

    try {
      switch (deletionType) {
        case TemplateDeletionTypes.ENGLISH_ONLY:
        case TemplateDeletionTypes.ALL_LANGUAGES:
          await deleteTemplate({ templateId });
          break;
        case TemplateDeletionTypes.SINGLE_LANGUAGE:
          await deleteContent({ templateId, contentId, locale: locale ?? 'en' });
          break;
        default:
          break;
      }

      await communicationsRefetch();

      handleCloseDialog();
      onSuccess();
    } catch (err) {
      setError(t('delete_template_deleting_failure_text'));
      onFailure(err);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <ConfirmDialog
        open={true}
        onCancel={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        disabled={isDeleting || showLoading}
        submitButtonText={t('delete')}
        title={title}
        loading={isDeleting}
        contentStyles={contentStyles}
        rootStyles={rootStyles}
        actionsStyles={actionStyles}
      >
        {error ? (
          // Render only the error message if there's an error
          <Typography color='error'>{error}</Typography>
        ) : showLoading ? (
          // Render loading indicator if there is no error but loading
          <Box height='100%' width='100%'>
            <Loading />
          </Box>
        ) : (
          // Render the main content if no error and not loading
          <Box>
            <Typography variant='body1' gutterBottom>
              {text}
            </Typography>
            <Box sx={{ mb: 2 }} />
            {showWorkflowsText && (
              <CommunicationsTemplateDeleteConfirmDialogWorkflowDetails
                workflows={workflows}
                topText={t('delete_template_workflow_top_text')}
                bottomText={t('delete_template_workflow_bottom_text')}
              />
            )}
          </Box>
        )}
      </ConfirmDialog>
    </>
  );
};

export default CommunicationsTemplateDeleteConfirmDialog;
