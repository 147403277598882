import { Switch } from '@mui/material';
import { GridCellParams, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { PropertyFilterType } from 'property/propertyType';
import { commonPropertiesSelector } from 'property/state/commonPropertySelectors';
import { getProperties } from 'property/state/propertyActions';
import React, { useEffect, useState } from 'react';
import {
  getLinkedProperties,
  linkCommonProperty,
  unlinkCommonProperty,
} from 'redux/actions/commonProperties';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SearchToolbarProps } from 'ui-library/Components/table/SearchToolbar';
import { Table } from 'ui-library/Components/table/Table';

interface CommonDoorsTableProps {
  propertyId: number;
  loading?: boolean;
  searchToolbarProps?: SearchToolbarProps;
}

const CommonDoorsTable = ({ propertyId, loading, searchToolbarProps }: CommonDoorsTableProps) => {
  const dispatch = useAppDispatch();
  const commonProperties = useAppSelector(commonPropertiesSelector(propertyId));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getCommonDoorPropertiesAsync = async () => {
      setIsLoading(true);
      await dispatch(getProperties({ filterType: PropertyFilterType.COMMON }));
      await dispatch(getLinkedProperties(propertyId));
      setIsLoading(false);
    };

    getCommonDoorPropertiesAsync();
  }, [dispatch, propertyId]);

  const linkOrUnlinkAsCommonDoor = (unlink: boolean, commonPropertyId: number) => () => {
    if (unlink) {
      dispatch(unlinkCommonProperty(propertyId, commonPropertyId));
    } else {
      dispatch(linkCommonProperty(propertyId, commonPropertyId));
    }
  };

  const renderAddress = (params: GridRenderCellParams) => (
    <div>{params.row.address && params.row.address.line1}</div>
  );

  const renderLinkToggle = (params: GridCellParams) => (
    <Switch
      checked={params.row.linked === true}
      onChange={linkOrUnlinkAsCommonDoor(params.row.linked, params.row.id)}
    />
  );

  const renderLinkedStatus = (params: GridCellParams) => (
    <div>{params.row.linked ? 'Linked' : 'Unlinked'}</div>
  );

  const columnsDef: GridColumns = [
    { field: 'name', headerName: 'Door Name', width: 240, flex: 3 },
    {
      field: 'address',
      headerName: 'Address',
      renderCell: renderAddress,
      width: 130,
      flex: 1,
    },
    {
      field: 'linked',
      headerName: 'Status',
      width: 160,
      flex: 2,
      renderCell: renderLinkedStatus,
      sortable: false,
    },
    {
      field: 'linked-2',
      headerName: 'Linked',
      width: 242,
      flex: 1,
      renderCell: renderLinkToggle,
      sortable: false,
    },
  ];

  return (
    <Table
      searchToolbarProps={searchToolbarProps}
      loading={isLoading || loading}
      disableColumnMenu
      rows={commonProperties}
      columns={columnsDef}
      pagination
      pageSize={10}
    />
  );
};

export default CommonDoorsTable;
