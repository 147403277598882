import { Chip, createTheme, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { renderStringInEmptyCell } from '../Common/VariablesMoreInfoTable';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0036B2',
    },
    secondary: {
      main: '#BDBDBD',
    },
    warning: {
      main: '#FF9800',
    },
    error: {
      main: '#F44336',
    },
    info: {
      main: '#2196F3',
    },
  },
});

type PropertyNameProps = {
  text: string;
  isDeactivated: boolean;
  isDisabled: boolean;
};
const PropertyName = ({ text, isDeactivated, isDisabled }: PropertyNameProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        data-testid='id-row-cell'
        fontSize={14}
        color={isDisabled ? 'text.disabled' : 'inherit'}
        marginRight='8px'
      >
        {renderStringInEmptyCell(text)}
      </Typography>
      {isDeactivated && (
        <ThemeProvider theme={theme}>
          <Chip
            data-testid='deactive-chip'
            label={t('deactivated')}
            color='secondary'
            variant='outlined'
            size='small'
          />
        </ThemeProvider>
      )}
    </>
  );
};

export default PropertyName;
