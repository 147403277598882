import { Box, Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import React, { useEffect, useState } from 'react';
import useAutomateSaveConfirmDialogMap from './useAutomateSaveConfirmDialogMap';

export const DIALOG_ACTION_TYPE = {
  NONE: 'NONE',
  SWITCH: 'SWITCH',
  SAVE: 'SAVE',
} as const;

export type TDialogActionType = (typeof DIALOG_ACTION_TYPE)[keyof typeof DIALOG_ACTION_TYPE];

export const SECONDARY_BUTTON_ACTION = {
  CANCEL: 'CANCEL',
  CLOSE: 'CLOSE',
  SAVE: 'SAVE',
} as const;

export type TSecondaryButtonAction =
  (typeof SECONDARY_BUTTON_ACTION)[keyof typeof SECONDARY_BUTTON_ACTION];

export const PRIMARY_BUTTON_ACTION = {
  SAVE_AND_ACTIVATE: 'SAVE_AND_ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
} as const;

export type TPrimaryButtonAction =
  (typeof PRIMARY_BUTTON_ACTION)[keyof typeof PRIMARY_BUTTON_ACTION];

export interface AutomateSaveConfirmDialogProps {
  active: boolean;
  isDirty: boolean;
  actionType: TDialogActionType;
  onCancel: (source: TSecondaryButtonAction) => void;
  onClose: (source: TSecondaryButtonAction) => void;
  onSuccess: (source: TPrimaryButtonAction) => void;
}

const AutomateSaveConfirmDialog = ({
  active,
  isDirty,
  actionType,
  onClose,
  onCancel,
  onSuccess,
}: AutomateSaveConfirmDialogProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { dialogContentMap, getDialogContent } = useAutomateSaveConfirmDialogMap();

  // Set the dialog content based on the status, isDirty flag, and actionType
  const [dialogContent, setDialogContent] = useState<{
    title: string;
    text: string;
    primaryButtonTitle: string;
    secondaryButtonTitle: string;
    showCloseIcon: boolean;
  }>(() => getDialogContent(actionType, active, isDirty, dialogContentMap));

  useEffect(() => {
    setDialogContent(getDialogContent(actionType, active, isDirty, dialogContentMap));
  }, [active, isDirty, actionType, dialogContentMap, getDialogContent]);

  const handleCancelDialog = () => {
    let source: TSecondaryButtonAction = SECONDARY_BUTTON_ACTION.CANCEL;
    if (actionType === SECONDARY_BUTTON_ACTION.SAVE && !active) {
      source = SECONDARY_BUTTON_ACTION.SAVE;
    }
    onCancel(source);
  };

  const handleCloseDialog = () => {
    onClose(SECONDARY_BUTTON_ACTION.CLOSE);
  };

  const handleSubmitDialog = async () => {
    let source: TPrimaryButtonAction = PRIMARY_BUTTON_ACTION.SAVE_AND_ACTIVATE;
    if (actionType === DIALOG_ACTION_TYPE.SWITCH && active) {
      source = PRIMARY_BUTTON_ACTION.DEACTIVATE;
    }
    onSuccess(source);
    setIsSaving(true);
    setError(null);
  };

  // dialog styles
  const rootStyles = { '& div[role=dialog]': { maxWidth: '444px', borderRadius: '10px' } };
  const contentStyles = { padding: '8px 24px 8px 24px', display: 'flex', flexDirection: 'row' };
  const actionStyles = {
    padding: '8px 16px 16px 8px',
  };

  return (
    <>
      {(actionType === DIALOG_ACTION_TYPE.SWITCH || actionType === DIALOG_ACTION_TYPE.SAVE) &&
        !!dialogContent && (
          <ConfirmDialog
            open={true}
            onCancel={handleCancelDialog}
            onClose={handleCloseDialog}
            onSubmit={handleSubmitDialog}
            disabled={isSaving}
            submitButtonText={dialogContent.primaryButtonTitle}
            cancelButtonText={dialogContent.secondaryButtonTitle}
            title={dialogContent.title}
            loading={isSaving}
            contentStyles={contentStyles}
            rootStyles={rootStyles}
            actionsStyles={actionStyles}
            showCloseIcon={dialogContent.showCloseIcon}
          >
            {error ? (
              <Typography color='error'>{error}</Typography>
            ) : (
              <Box>
                <Typography variant='body1' gutterBottom>
                  {dialogContent.text}
                </Typography>
              </Box>
            )}
          </ConfirmDialog>
        )}
    </>
  );
};

export default AutomateSaveConfirmDialog;
