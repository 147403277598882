import { setupI18n } from '@operto/i18n';
import cs from '../assets/localizations/cs.json';
import de from '../assets/localizations/de.json';
import en from '../assets/localizations/en.json';
import es from '../assets/localizations/es.json';
import fr from '../assets/localizations/fr.json';
import it from '../assets/localizations/it.json';

export type TranslationKey = keyof typeof en;
export type SupportedLocale = keyof typeof translations;
export type SupportedLanguage = {
  name: string;
  locale: SupportedLocale;
};

const translations = {
  en,
  fr,
  de,
  it,
  es,
  cs,
  // TODO: Add more localizations in future ticket
};

// TODO: Add more languages in future ticket
export const languages: SupportedLanguage[] = [
  { name: 'English', locale: 'en' },
  { name: 'Français', locale: 'fr' },
  { name: 'Deutsch', locale: 'de' },
  { name: 'Italiano', locale: 'it' },
  { name: 'Español', locale: 'es' },
  { name: 'Čeština', locale: 'cs' },
];

const { useTranslation: useI18n, i18n } = setupI18n<TranslationKey, SupportedLocale>({
  defaultLocale: 'en',
  translations: translations as never,
});

export const useTranslation = () => {
  const { t, changeLocale } = useI18n();

  // TODO: Add browser language detection in future ticket

  return {
    t,
    locale: i18n.locale ?? 'en',
    languages,
    changeLocale,
  };
};

export default useTranslation;
export { i18n };
