import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { devicesFromPropertyDetailSelector } from 'device/state/deviceSelector';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from 'redux/hooks';
import { Table } from 'ui-library/Components/table/Table';

const ConnectedDevicesContainter = () => {
  const devices = useAppSelector(state => devicesFromPropertyDetailSelector(state));

  const renderDeviceName = (params: GridCellParams) => <Text>{params.row.name}</Text>;

  const columnsDef: GridColumns = [
    {
      field: 'name',
      headerName: 'Device Name',
      renderCell: renderDeviceName,
      flex: 1,
    },
  ];

  const columnsDefForMobile: GridColumns = [
    {
      field: 'name',
      headerName: 'Device Name',
      renderCell: renderDeviceName,
      flex: 1,
    },
  ];

  return (
    <Table
      sx={{ height: '400px' }}
      hideFooter
      rows={devices}
      searchToolbarProps={null}
      columns={isMobile ? columnsDefForMobile : columnsDef}
      initialState={{ pinnedColumns: { right: ['action'] } }}
    />
  );
};

export default ConnectedDevicesContainter;
