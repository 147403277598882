import { ErrorOutline, LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Popover,
  SxProps,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import LockFooter from 'device/SmartCards/LockFooter';
import { BatteryStatus, IDevice, ILock } from 'device/deviceType';
import { getPropertyEvents } from 'event/state/eventActions';
import useTranslation from 'hooks/useTranslation';
import { ILockProviders } from 'lock/LockProviderTypes';
import { doLockAction, lock, unlock } from 'lock/state/lockActions';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import {
  usePostAccessCompatibilityLocksLegacyLockIdLockMutation,
  usePostAccessCompatibilityLocksLegacyLockIdUnlockMutation,
} from 'services/novaApi';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import BatteryIcon from 'ui-library/Components/icon/BatteryIcon';
import Widget from 'ui-library/Components/widget/Widget';
import { BigSwitch } from 'ui-library/Styled/controls';

export interface LockCardProps {
  propertyId: number;
  device: IDevice;
  isLockUnlockDisabled: boolean;
  isOnUnitDashboard?: boolean;
}

const LockCard = ({
  propertyId,
  device,
  isLockUnlockDisabled,
  isOnUnitDashboard = false,
}: LockCardProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [bgColor, setBgColor] = React.useState('#0E33CC');
  const [pending, setPending] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [showActionButtons, setShowActionButtons] = React.useState(false);

  const lockDevice = device.data as ILock;
  const { t } = useTranslation();

  const onLockUnlockCallBack = (isSuccess: boolean) => {
    if (isSuccess && device.property_id) {
      dispatch(getPropertyEvents(lockDevice?.property_id));
    }
    handleAPICallBack(isSuccess);
  };

  const seamProvider: ILockProviders = 'seam:1';

  const [doUnlock] = usePostAccessCompatibilityLocksLegacyLockIdUnlockMutation();
  const [doLock] = usePostAccessCompatibilityLocksLegacyLockIdLockMutation();
  const batteryStatus: BatteryStatus = lockDevice.battery_status;
  const keypadStatus: BatteryStatus = lockDevice.battery_keypad_status;

  const powerStatusCritical =
    batteryStatus === BatteryStatus.CRITICAL ||
    batteryStatus === BatteryStatus.EMPTY ||
    keypadStatus === BatteryStatus.CRITICAL ||
    keypadStatus === BatteryStatus.EMPTY;

  const lockAction = (action: 'lock' | 'unlock') => async () => {
    if (action === 'lock') {
      return await doLock({ legacyLockId: parseInt(lockDevice?.device_id) }).unwrap();
    }

    return await doUnlock({ legacyLockId: parseInt(lockDevice?.device_id) }).unwrap();
  };

  const handleLockDevice = async () => {
    if (lockDevice?.is_locked) {
      if (lockDevice?.provider === seamProvider) {
        dispatch(
          doLockAction(
            lockAction('unlock'),
            'unlock',
            lockDevice?.device_external_id,
            onLockUnlockCallBack,
          ),
        );
      } else {
        dispatch(unlock(lockDevice?.device_external_id, onLockUnlockCallBack));
      }
    } else {
      if (lockDevice.provider === seamProvider) {
        dispatch(
          doLockAction(
            lockAction('lock'),
            'lock',
            lockDevice.device_external_id,
            onLockUnlockCallBack,
          ),
        );
      } else {
        dispatch(lock(lockDevice.device_external_id, onLockUnlockCallBack));
      }
    }
    handleAPICall();
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl) && isLockUnlockDisabled;

  const viewMoreDetails = () => {
    navigate(`/units/${propertyId}/access`);
  };

  const handleSwitchClicked = () => {
    setShowActionButtons(true);
  };

  const handleCancelAction = () => {
    setShowActionButtons(false);
  };

  const handleConfirmAction = () => {
    setShowActionButtons(false);
    handleLockDevice();
  };

  const handleAPICall = () => {
    setPending(true);
  };

  const handleAPICallBack = (isSuccess: boolean) => {
    setPending(false);
    const statusMessage = isSuccess ? 'was successful!' : 'has failed!';
    // This is the previous state we need to check not the updated one from the server
    const lockingMessage = lockDevice.is_locked ? 'Unlocking' : 'Locking';
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: `${lockingMessage} ${statusMessage}`,
        variant: isSuccess ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      }),
    );
  };
  const isOfflineLock = lockDevice?.provider?.includes('igloo');
  const isOnline = React.useMemo(() => {
    return lockDevice.is_lock_online;
  }, [lockDevice]);

  React.useEffect(() => {
    let color = '#0E33CC';
    if (!isOnline) {
      color = 'error.main';
    } else if (powerStatusCritical) {
      color = 'error.main';
    } else if (batteryStatus === BatteryStatus.LOW || keypadStatus === BatteryStatus.LOW) {
      color = 'warning.main';
    }
    setBgColor(color);
  }, [
    lockDevice.battery_status,
    lockDevice.battery_keypad_status,
    isOnline,
    powerStatusCritical,
    batteryStatus,
    keypadStatus,
  ]);

  return !lockDevice?.is_active || !isOnline ? (
    <Widget
      removeGutter
      title={
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          {t('lock_status_header')}
        </Typography>
      }
      isCarouselCard
      isOnUnitDashboard={isOnUnitDashboard}
      open={true}
      smartCardType='lock'
      footer={
        <LockFooter data-testid='lock-device-confirmation' onDetailsClick={viewMoreDetails} />
      }
      fill={bgColor}
    >
      <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }} gutterBottom>
        {!isOnline ? t('lock_offline_header') : t('lock_deactivated_header')}
      </Typography>
      {!isOnline && (
        <Box
          sx={{
            ...deviceRowStyles,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            position: 'relative',
          }}
        >
          <Box
            style={{
              width: 24,
              height: 24,
              position: 'relative',
            }}
          >
            <Avatar sx={lockAvatarStyles}>
              <BatteryAlertIcon
                style={{
                  width: 16,
                  height: 16,
                }}
              />
            </Avatar>
          </Box>

          <Typography variant='body2' sx={{ textTransform: 'capitalize', marginLeft: '8px' }}>
            {t('battery_offline_status') || 'N/A'}
          </Typography>
        </Box>
      )}
      {!lockDevice?.is_active && (
        <Alert
          icon={<ErrorOutline sx={{ color: '#D32F2F' }} />}
          severity='warning'
          sx={lockDeactivatedCardStyles}
        >
          {t('lock_deactivated_card')}
        </Alert>
      )}
    </Widget>
  ) : (
    <Widget
      removeGutter
      title={
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          {t('lock_status_header')}
        </Typography>
      }
      isCarouselCard
      isOnUnitDashboard={isOnUnitDashboard}
      open={true}
      smartCardType='lock'
      footer={
        <LockFooter data-testid='lock-device-confirmation' onDetailsClick={viewMoreDetails} />
      }
      fill={bgColor}
    >
      <Box
        sx={lockContainerStyles}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Popover
          sx={{ padding: '30px' }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          role=''
        >
          <Grid container justifyContent='center' sx={lockCardErrorStyles}>
            <ErrorIcon sx={errorIconStyles} />
            <Typography sx={{ height: '60px', width: '292px' }}>
              {t('lock_card_no_permissions')}
            </Typography>
          </Grid>
        </Popover>
        {isOnline && lockDevice?.is_active && (
          <BigSwitch
            data-testid='lock-switch'
            disabled={pending || isLockUnlockDisabled || isOfflineLock}
            checked={lockDevice?.is_locked && isOnline}
            onClick={handleSwitchClicked}
            switchcolor={powerStatusCritical ? '#8D3C3C' : '#FFFFFF8F'}
            switchthumbcolor='#F0F0F0'
          />
        )}
        {pending ? (
          <PendingContainer currentLockState={lockDevice?.is_locked} />
        ) : (
          <LoadedLockContainer
            device={lockDevice}
            showActionButtons={showActionButtons}
            onCancel={handleCancelAction}
            onConfirm={handleConfirmAction}
          />
        )}
      </Box>
      {!isOfflineLock && (
        <Collapse in={true}>
          <Box sx={{ paddingLeft: '0px', marginTop: '16px' }}>
            {lockDevice?.battery_status !== BatteryStatus.NOT_INSTALLED && (
              <Box sx={deviceRowStyles}>
                <Avatar sx={lockAvatarStyles}>
                  <BatteryIcon
                    disabled={!isOnline}
                    status={lockDevice?.battery_status}
                    customColor='white'
                  />
                </Avatar>
                <Typography sx={{ textTransform: 'capitalize', fontSize: '16px' }}>
                  Lock battery {lockDevice?.battery_status || 'N/A'}
                </Typography>
              </Box>
            )}
            {lockDevice?.battery_keypad_status !== BatteryStatus.NOT_INSTALLED && (
              <Box sx={deviceRowStyles}>
                <Avatar sx={lockAvatarStyles}>
                  <BatteryIcon
                    disabled={!isOnline}
                    status={lockDevice?.battery_keypad_status}
                    customColor='white'
                  />
                </Avatar>
                <Typography sx={{ textTransform: 'capitalize', fontSize: '16px' }}>
                  Keypad battery {lockDevice?.battery_keypad_status || 'N/A'}
                </Typography>
              </Box>
            )}
          </Box>
        </Collapse>
      )}
    </Widget>
  );
};

const deviceRowStyles: SxProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '16px',
  alignItems: 'center',
};

const PendingContainer = ({ currentLockState }: { currentLockState: boolean }) => (
  <Box sx={lockStatusContainerStyles}>
    <CircularProgress
      sx={{
        width: '28px !important',
        height: '28px !important',
        marginRight: '10px',
      }}
    />
    <Typography sx={{ color: 'white' }} variant='h6'>
      {currentLockState ? 'Unlocking...' : 'Locking...'}
    </Typography>
  </Box>
);

const LoadedLockContainer = ({
  device,
  showActionButtons,
  onCancel,
  onConfirm,
}: {
  device: ILock;
  showActionButtons: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {showActionButtons && (
        <Box sx={{ display: 'flex', gap: '16px', marginBottom: '16px', marginTop: '16px' }}>
          <Button
            variant='outlined'
            size='small'
            color='secondary'
            onClick={onCancel}
            sx={cancelLockButtonStyles}
          >
            {t('cancel')}
          </Button>
          <Button
            variant='contained'
            size='small'
            color='primary'
            data-testid='lock-device-confirmation-button'
            onClick={onConfirm}
            sx={lockButtonStyles}
          >
            {device?.is_locked ? t('lock_card_unlock_device') : t('lock_card_lock_device')}
          </Button>
        </Box>
      )}
      <Box sx={lockStatusContainerStyles}>
        {device?.is_lock_online && (
          <Avatar sx={lockAvatarStyles}>
            {device?.is_locked ? (
              <LockOutlined fontSize='small' />
            ) : (
              <LockOpenOutlined fontSize='small' />
            )}
          </Avatar>
        )}
        {device?.is_lock_online && (
          <Typography sx={{ color: 'white' }} variant='h6'>
            {device?.is_locked ? t('lock_card_locked') : t('lock_card_unlocked')}
          </Typography>
        )}
        {!device?.is_lock_online && (
          <Typography sx={{ color: 'white' }} variant='h6'>
            {t('lock_card_offline')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const errorIconStyles: SxProps = {
  height: '20px',
  width: '20px',
  fill: 'primary',
  marginRight: '14px',
};

const lockContainerStyles: SxProps = {
  color: 'text',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  transition: 'all 500ms ease-out',
};

const lockAvatarStyles: SxProps = {
  width: '28px',
  height: '28px',
  marginRight: '10px',
  backgroundColor: 'rgba(0, 0, 0, 0.38)',
  '.MuiSvgIcon-root': {
    width: '16px',
    height: '16px',
  },
};

const lockStatusContainerStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

const lockCardErrorStyles: SxProps = {
  height: '89px',
  width: '360px',
  padding: '15px',
  marginBottom: '15px',
};

const lockDeactivatedCardStyles: SxProps = {
  backgroundColor: '#FDECEA',
  color: '#5F1F1F',
  borderRadius: 2,
  mb: 2,
};

const cancelLockButtonStyles: SxProps = {
  fontWeight: 'bold',
  padding: '6px 12px',
  minWidth: '98px',
  borderRadius: '8px',
  border: '1px solid var(--Light-Text-White-Text, #FFF)',
  color: 'var(--Light-Primary-Contrast, #FFF)',
};

const lockButtonStyles: SxProps = {
  backgroundColor: '#D97706',
  color: 'white',
  fontWeight: 'bold',
  padding: '6px 12px',
  minWidth: '120px',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': { backgroundColor: '#B45309' },
};

export default LockCard;
