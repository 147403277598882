import AddIcon from '@mui/icons-material/Add';
import { Fab, ListItemText, Menu, MenuItem, SxProps, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { TableActionbar } from '@operto/ui-library';
import { Variable } from '@operto/variables-shared';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import useTranslation from 'hooks/useTranslation';
import { trackEvent } from 'lib/analytics';
import { logger } from 'lib/logger';
import isEqual from 'lodash/isEqual';
import { getAllPropertiesIdsWithNamesSelector } from 'property/state/propertySelectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { Table } from 'ui-library/Components/table/Table';
import { currentUserSelector, userPermissionSelector } from 'user/state/userSelectors';
import VariablesMoreInfoTable from '../Common/VariablesMoreInfoTable';
import {
  getAllVariables,
  getCustomVariableById,
  getPropertiesForCustomVariablesSet,
  VariableProperty,
} from '../helpers/VariableHelpers';
import useVariables from '../useVariables';
import { getColumns } from './TableColumns';

export const newVariableTemplateWithoutId = (username = '', override?: Partial<Variable>) =>
  Object({
    name: '',
    updatedAt: Date.now().valueOf(),
    createdAt: Date.now().valueOf(),
    updatedBy: username,
    description: '',
    enabled: true,
    properties: {},
    type: 'custom',
    ...override,
  }) as Variable;

const CustomVariablePage = () => {
  const MAX_VARIABLE_NAME_LENGTH = 40;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [openMode, setOpenMode] = useState(false);
  const [chosenVariableOnOpenMode, setChosenVariableOnOpenMode] = useState<Variable>();
  const [moreInfoRows, setMoreInfoRows] = useState<Record<number, VariableProperty>>({});
  const [newVariableName, setNewVariableName] = useState('');
  const [addAnchorEl, setAddAnchorEl] = useState(null);
  const [isAddVariableModalMode, setIsAddVariableModalMode] = useState(false);
  const [isDeleteVariableModalMode, setIsDeleteVariableModalMode] = useState(false);
  const [isChangeStatusModalMode, setIsChangeStatusModalMode] = useState(false);
  const [isVariableExist, setIsVariableExist] = useState(false);
  const [selectedVariableId, setSelectedVariableId] = useState<string | undefined>();
  const { getVariables, deleteVariable, createVariable, updateVariable } = useVariables();
  const isUserManager = useSelector(userPermissionSelector());
  const currentUser = useSelector(currentUserSelector());
  const propertiesIdWithNames = useSelector(getAllPropertiesIdsWithNamesSelector());
  const propertyVariableSet = useMemo(() => {
    return propertiesIdWithNames.reduce((acc, property) => {
      acc[property.id] = {
        id: property.id,
        name: property.name,
        value: '',
        enabled: true,
        group: '',
      };
      return acc;
    }, {} as Record<string, VariableProperty>);
  }, [propertiesIdWithNames]);

  const {
    data: customVariables,
    isLoading: isCustomLoading,
    isError: isCustomError,
    error: customError,
    refetch: refetchCustomVariables,
  } = getVariables({ type: 'custom' });
  const {
    data: systemVariables,
    isLoading: isSystemLoading,
    isError: isSystemError,
    error: systemError,
  } = getVariables({ type: 'system' });
  const isLoading = isCustomLoading || isSystemLoading;

  const onCreateVariable = (variable: Variable) => {
    createVariable({ ...variable, updatedBy: currentUser?.name })
      .then(() => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Variable created successfully.',
            variant: SnackbarVariant.SUCCESS,
          }),
        );
        refetchCustomVariables();
      })
      .catch(error => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Variable has not been created due to an error.',
            variant: SnackbarVariant.ERROR,
          }),
        );
        logger.error(error);
      });
  };

  const onUpdateVariable = (variable: Variable) => {
    updateVariable({ ...variable, updatedBy: currentUser?.name })
      .then(() => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Variable updated successfully.',
            variant: SnackbarVariant.SUCCESS,
          }),
        );
        refetchCustomVariables();
      })
      .catch(error => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Variable has not been updated due to an error.',
            variant: SnackbarVariant.ERROR,
          }),
        );
        logger.error(error);
      });
  };

  const onDeleteVariable = () => {
    if (selectedVariableId) {
      deleteVariable(selectedVariableId)
        .then(() => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable deleted successfully.',
              variant: SnackbarVariant.SUCCESS,
            }),
          );
          refetchCustomVariables();
        })
        .catch(error => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable has not been deleted due to an error.',
              variant: SnackbarVariant.ERROR,
            }),
          );
          logger.error(error);
        });
    }
    setIsDeleteVariableModalMode(false);
  };

  const mixVariablePropertiesWithRealProperties = useCallback(
    (variableId: string) => {
      const currentVariables = getPropertiesForCustomVariablesSet(variableId, customVariables);
      const newProperties = propertiesIdWithNames.filter(
        property => !currentVariables[property.id],
      );
      const deactivatedPropertiesIds = Object.keys(currentVariables).filter(
        propertyId => !propertiesIdWithNames.find(p => p.id === +propertyId),
      );
      const result: Record<number, VariableProperty> = { ...currentVariables };
      if (newProperties.length > 0) {
        newProperties.forEach(property => {
          result[property.id] = {
            name: property.name,
            id: property.id,
            value: '',
            group: '',
            enabled: false,
            deactivated: false,
          };
        });
      }

      if (deactivatedPropertiesIds.length > 0) {
        deactivatedPropertiesIds.forEach(propertyId => {
          result[+propertyId].deactivated = true;
        });
      }

      return result;
    },
    [customVariables, propertiesIdWithNames],
  );

  const onMoreInfoMode = useCallback(
    (variableId: string) => {
      setChosenVariableOnOpenMode(getCustomVariableById(variableId, customVariables));
      setMoreInfoRows(mixVariablePropertiesWithRealProperties(variableId));
      setOpenMode(true);
    },
    [customVariables, mixVariablePropertiesWithRealProperties],
  );

  const handleAddVariable = () => {
    setIsAddVariableModalMode(true);
  };

  const handleAddVariableSubmit = async () => {
    onCreateVariable(
      newVariableTemplateWithoutId(currentUser?.name, {
        name: newVariableName,
        properties: propertyVariableSet,
      }),
    );

    trackEvent({
      screen: 'Variables',
      event: 'CREATED',
      environment: 'settings',
    });
    refetchCustomVariables();

    setNewVariableName('');
    setAddAnchorEl(null);
    setIsAddVariableModalMode(false);
  };

  const handleChangeStatusVariableSubmit = () => {
    onUpdateVariable({ ...chosenVariableOnOpenMode, enabled: false });
    setIsChangeStatusModalMode(false);
  };

  useEffect(() => {
    // to make sure that the chosen variable to edit(open mode) is updated when the variables are updated
    const realVariable = customVariables?.find(
      (variable: Variable) => variable.id === chosenVariableOnOpenMode?.id,
    );
    if (realVariable && !isEqual(realVariable, chosenVariableOnOpenMode)) {
      onMoreInfoMode(realVariable.id);
    }
  }, [customVariables, chosenVariableOnOpenMode, onMoreInfoMode]);

  useEffect(() => {
    if (isCustomError) logger.error(customError);
  }, [isCustomError, customError]);

  useEffect(() => {
    if (isSystemError) logger.error(systemError);
  }, [isSystemError, systemError]);

  return (
    <>
      <ConfirmDialog
        data-testid='add-variable-modal'
        open={isAddVariableModalMode}
        title={t('enter_new_variable_title')}
        submitButtonColor='primary'
        onSubmit={handleAddVariableSubmit}
        onCancel={() => {
          setIsAddVariableModalMode(false);
          setAddAnchorEl(null);
          setNewVariableName('');
        }}
        submitButtonText={t('save')}
      >
        <TextField
          data-testid='add-variable-input'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newVarName = event.target.value;
            if (
              newVarName === '' ||
              (newVarName && newVarName.length <= MAX_VARIABLE_NAME_LENGTH)
            ) {
              if (
                getAllVariables(customVariables, systemVariables).find(
                  variable => variable.name.toLowerCase() === newVarName.toLowerCase().trim(),
                )
              ) {
                if (!isVariableExist) setIsVariableExist(true);
              } else {
                if (isVariableExist) setIsVariableExist(false);
              }
              setNewVariableName(newVarName);
            }
          }}
          value={newVariableName}
          label={t('variable_name')}
          sx={{ width: '100%', margin: '8px 0' }}
          error={isVariableExist}
          helperText={
            isVariableExist
              ? t('variable_name_already_exist')
              : t('variable_name_character_limit', {
                  limit: `${newVariableName.length}/${MAX_VARIABLE_NAME_LENGTH}`,
                })
          }
        />
      </ConfirmDialog>
      <ConfirmDialog
        data-testid='delete-variable-modal'
        open={isDeleteVariableModalMode}
        title={t('delete')}
        submitButtonColor='error'
        onSubmit={onDeleteVariable}
        submitButtonText={t('delete')}
        onCancel={() => {
          setIsDeleteVariableModalMode(false);
        }}
      >
        <Typography>{t('confirm_sure_to_delete_variable_title')}</Typography>
      </ConfirmDialog>
      <ConfirmDialog
        data-testid='deactive-variable-modal'
        open={isChangeStatusModalMode}
        title={t('deactivate_variable_title')}
        submitButtonColor='primary'
        onSubmit={handleChangeStatusVariableSubmit}
        onCancel={() => {
          setIsChangeStatusModalMode(false);
        }}
        submitButtonText={t('confirm')}
      >
        <Typography>{t('confirm_sure_to_delete_variable_body')}</Typography>
      </ConfirmDialog>
      <Menu
        data-test='menu'
        anchorEl={addAnchorEl}
        open={Boolean(addAnchorEl)}
        onClose={() => {
          setAddAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem data-testid='add-custom-variable-menu-item' onClick={handleAddVariable}>
          <ListItemText>{t('add_custom_variable')}</ListItemText>
        </MenuItem>
      </Menu>
      <TableActionbar
        startSection={<></>}
        endSection={
          <Fab
            data-testid='add-variable-button'
            size='small'
            color='primary'
            onClick={e => {
              setAddAnchorEl(e.currentTarget);
            }}
            key='fab'
          >
            <AddIcon />
          </Fab>
        }
        isMobile={false}
      />
      <Stack sx={contentStyles}>
        <Box sx={getTableWrapperStyles(openMode)} data-testid='custom-variable-table'>
          <Table
            disableColumnMenu
            disableVirtualization
            hideFooterRowCount
            rows={customVariables || []}
            columns={getColumns({
              isEdit: openMode,
              hideActionMenu: !isUserManager && !openMode,
              propertyVariableSet,
              callbacks: {
                createVariableMutator: createVariable,
                allVariables: customVariables,
                mixVariablePropertiesWithRealProperties,
                setChosenVariableOnOpenMode,
                setIsChangeStatusModalMode,
                setIsDeleteVariableModalMode,
                setMoreInfoRows,
                setOpenMode,
                setSelectedVariableId,
                updateVariableMutator: onUpdateVariable,
              },
            })}
            loading={isLoading}
            onCellClick={e => {
              if (e.field !== 'actions') {
                if (e.row.id === chosenVariableOnOpenMode?.id) {
                  setOpenMode(false);
                  setChosenVariableOnOpenMode(null);
                } else {
                  onMoreInfoMode(e.row.id);
                }
              }
            }}
          />
        </Box>
        {openMode && (
          <VariablesMoreInfoTable
            data-testid='more-info-table'
            variable={chosenVariableOnOpenMode ?? {}}
            rows={moreInfoRows}
            isEditable={isUserManager}
            sx={{ height: '100%', flex: 2 }}
            onCloseMoreTable={() => {
              refetchCustomVariables();
              setOpenMode(false);
              setChosenVariableOnOpenMode(null);
            }}
            onRefresh={refetchCustomVariables}
            systemVariables={systemVariables}
            customVariables={customVariables}
          />
        )}
      </Stack>
    </>
  );
};

export default CustomVariablePage;

const getTableWrapperStyles = (isEdit: boolean): SxProps => ({
  padding: 0,
  margin: 0,
  width: '100%',
  flex: 1,
  '.MuiPaper-root': {
    borderTop: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    ...(isEdit
      ? {
          borderBottomRightRadius: 0,
          borderRight: 0,
        }
      : {}),
  },
});

const contentStyles: SxProps = {
  width: '100%',
  height: '100%',
  gap: 0,
  flexDirection: 'row',
};
