import { MessageMetadataType } from '@operto/messaging-service-shared';
import { trpc } from '@operto/trpc-client';
import {
  ChannelAPIResponse,
  ChannelMemberResponse,
  ChannelResponse,
  DefaultGenerics,
  MessageResponse,
  PendingMessageResponse,
  ReadResponse,
  UserResponse,
} from 'stream-chat';

export default function useMessaging(): useMessagingReturnType {
  const createChannelMutation = trpc?.messaging?.createChannel?.useMutation();
  const createChannel = async ({
    channelId,
    memberIds, // to appease GetStream, at least one user is required
    channelType = 'messaging',
    channelName,
    channelData,
    propertyName,
    propertyId,
    assigneeName,
    assigneeId,
  }: createChannelInputType) => {
    try {
      return await createChannelMutation?.mutateAsync({
        channelId,
        channelType,
        memberIds,
        customChannelFields: {
          channelName,
          channelData,
          propertyName,
          propertyId,
          assigneeName,
          assigneeId,
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const removeMemberFromChannelMutation = trpc?.messaging?.removeMemberFromChannel?.useMutation();
  const removeMemberFromChannel = async ({ channelId, memberId }: removeMemberInputType) => {
    try {
      return await removeMemberFromChannelMutation?.mutateAsync({
        channelId,
        memberId,
      });
    } catch (error) {
      throw error;
    }
  };

  const addMembersToChannelMutation = trpc?.messaging?.addMembersToChannel?.useMutation();
  const addMembersToChannel = async ({ memberIds, channelId }: addMembersInputType) => {
    try {
      return await addMembersToChannelMutation?.mutateAsync({
        memberIds,
        channelId,
      });
    } catch (error) {
      throw error;
    }
  };

  const broadcastMessageMutation = trpc?.messaging?.broadcastToChannel?.useMutation();
  const broadcastMessage = async ({
    channelId,
    message,
    messageType,
    userId,
    metadata,
  }: broadcastMessageInputType) => {
    try {
      return await broadcastMessageMutation?.mutateAsync({
        channelId,
        message,
        messageType,
        userId,
        metadata,
      });
    } catch (error) {
      throw error;
    }
  };

  return {
    createChannel,
    removeMemberFromChannel,
    addMembersToChannel,
    broadcastMessage,
  };
}

export type createChannelInputType = {
  channelId: string;
  memberIds: string[];
  channelType?: string;
  channelName?: string;
  channelData?: 'guests' | 'members' | 'tasks';
  propertyName?: string;
  propertyId?: string;
  assigneeId?: string;
  assigneeName?: string;
};

export type removeMemberInputType = {
  channelId: string;
  memberId: string;
};

export type addMembersInputType = {
  memberIds: string[];
  channelId: string;
};

export type broadcastMessageInputType = {
  channelId: string;
  message: string;
  messageType?: string;
  userId?: string;
  metadata?: MessageMetadataType;
};

// return types are translated over from StreamChat and modified slightly to appease compiler
// see https://github.com/GetStream/stream-chat-js/blob/master/src/types.ts
export type createChannelReturnType = {
  duration?: string;
  channel?: ChannelAPIResponse<DefaultGenerics> | { [x: string]: unknown };
  members?: ChannelMemberResponse<DefaultGenerics>[];
  messages?: MessageResponse<DefaultGenerics>[] | { [x: string]: unknown }[];
  pinned_messages?: MessageResponse<DefaultGenerics>[] | { [x: string]: unknown }[];
  hidden?: boolean;
  membership?: ChannelMemberResponse<DefaultGenerics> | null;
  pending_messages?:
    | PendingMessageResponse<DefaultGenerics>[]
    | { message?: { [x: string]: unknown }; pending_message_metadata?: Record<string, string> }[];
  read?:
    | ReadResponse<DefaultGenerics>[]
    | { user?: { [x: string]: unknown }; last_read?: string; unread_messages?: number }[];
  watcher_count?: number;
  watchers?: UserResponse<DefaultGenerics>[] | { [x: string]: unknown }[];
};

export type removeMemberReturnType = {
  channel?: ChannelResponse<DefaultGenerics> | { [key: string]: unknown };
  member?: ChannelMemberResponse<DefaultGenerics>;
};

export type addMembersReturnType = {
  channel?: ChannelResponse<DefaultGenerics> | { [key: string]: unknown };
  members?: ChannelMemberResponse<DefaultGenerics>[];
};

export type broadcastMessageReturnType = {
  message?: MessageResponse<DefaultGenerics> | { [key: string]: unknown };
};

export type useMessagingReturnType = {
  createChannel: ({
    channelId,
    memberIds,
    channelName,
    channelType,
    assigneeId,
    assigneeName,
    channelData,
    propertyId,
    propertyName,
  }: createChannelInputType) => Promise<createChannelReturnType>;
  removeMemberFromChannel: ({
    channelId,
    memberId,
  }: removeMemberInputType) => Promise<removeMemberReturnType>;
  addMembersToChannel: ({
    memberIds,
    channelId,
  }: addMembersInputType) => Promise<addMembersReturnType>;
  broadcastMessage: ({
    channelId,
    message,
    messageType,
    userId,
    metadata,
  }: broadcastMessageInputType) => Promise<broadcastMessageReturnType>;
};
