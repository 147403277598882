import { Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import Stripe from 'assets/img/integrations/stripe.svg';
import React, { useEffect, useRef, useState } from 'react';
import { IntegrationsCard } from '../IntegrationsCard';
import { IntegrationsFormHandle, IntegrationsSideDrawer } from '../IntegrationsSideDrawer';
import { StripeIntegrationForm } from './StripeIntegrationForm';
import { useStripeIntegration } from './useStripeIntegration';

const STRIPE_INFO_URL = `${process.env.REACT_APP_CLIENT_SUPPORT_URL}/s/article/Security-Deposits-Setting-up-Security-Deposits-with-Stripe`;

export const StripeIntegrationCard = () => {
  const integrationFormRef = useRef<IntegrationsFormHandle>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data, save, isLoading, isSuccess } = useStripeIntegration();

  const handleToggle = (checked: boolean) => {
    if (checked) {
      save({ ...data, enabled: checked });
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleDisableConfirmation = () => {
    setIsDialogOpen(false);
    save({ ...data, enabled: !data.enabled });
  };

  const handleActionClick = () => {
    return setIsDrawerOpen(true);
  };

  const getActionTitle = () => {
    if (data && !data?.enabled) {
      return 'EDIT';
    }

    return undefined; // default to IntegrationsCard
  };

  useEffect(() => {
    if (isDrawerOpen && isSuccess) {
      setIsDrawerOpen(false);
    }
  }, [isDrawerOpen, isSuccess]);

  return (
    <>
      <IntegrationsCard
        title='Stripe'
        connected={data !== undefined}
        enabled={data?.enabled}
        loading={!isDrawerOpen && isLoading}
        actionTitle={getActionTitle()}
        onChange={handleToggle}
        onInfoClick={() => window.open(STRIPE_INFO_URL, '_blank')}
        onActionClick={handleActionClick}
      >
        <Stripe />
      </IntegrationsCard>
      <IntegrationsSideDrawer
        open={isDrawerOpen}
        title='Stripe Integration'
        onClose={() => !isLoading && integrationFormRef.current?.close()}
        onSave={() => !isLoading && integrationFormRef.current?.submit()}
      >
        <StripeIntegrationForm
          ref={integrationFormRef}
          data={data}
          loading={isLoading}
          onClose={() => setIsDrawerOpen(false)}
          onSave={save}
        />
      </IntegrationsSideDrawer>
      <ConfirmDialog
        open={isDialogOpen}
        title='Disable Stripe Integration'
        onCancel={() => setIsDialogOpen(false)}
        submitButtonText='Disable'
        onSubmit={handleDisableConfirmation}
      >
        <Typography variant='body1'>
          Are you sure you want to disable Stripe Integration?
        </Typography>
      </ConfirmDialog>
    </>
  );
};
