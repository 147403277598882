import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { theme } from '@operto/ui';
import { utcToZonedTime } from 'date-fns-tz';
import format from 'date-fns/format';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import { IReferenceAreaData } from 'device/deviceType';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import * as React from 'react';
import { createRef } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  TickFormatterFunction,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { useAppSelector } from 'redux/hooks';
import { getTimeZone } from 'redux/selectors/timeZone';
import styled from 'styled-components';
import { EnviromentalDataPoint } from 'types/netatmo';

interface AxisTickProps {
  y: string;
  x: string;
  payload: {
    value: number;
  };
}

//

export const CustomizedAxisTick = ({ payload, x, y }: AxisTickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize='11px' x={27} y={0} dy={16} textAnchor='end' fill='#a2aaad'>
        {format(new Date(payload.value * 1000), 'HH:mm a')}
      </text>
    </g>
  );
};
export const CustomizedAxisTickLong = ({ payload, x, y }: AxisTickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize='11px' x={20} y={0} dy={15} dx={0} textAnchor='end' fill='#a2aaad'>
        {format(new Date(payload.value * 1000), 'MMM d')}
      </text>
    </g>
  );
};

interface CustomHoverPopoverProps {
  isText?: boolean;
  text: string;
  name?: string;
  isIcon?: boolean;
}

export const CustomHoverPopover = ({ text, isText, name, isIcon }: CustomHoverPopoverProps) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  return (
    <>
      {isText && (
        <Typography
          {...bindHover(popupState)}
          variant='body1'
          sx={{ color: theme.palette.text.primary }}
        >
          {text}
        </Typography>
      )}
      {isIcon && <InfoIcon id={name} color='disabled' {...bindHover(popupState)} />}
      <HoverPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ backgroundColor: theme.palette.primary.main, padding: '16px' }}>
          <Typography variant='body1' sx={{ color: 'white' }}>
            {text}
          </Typography>
        </Box>
      </HoverPopover>
    </>
  );
};

export interface GraphWidgetProps {
  name: string;
  graphData: (filter: string) => EnviromentalDataPoint[];
  ReferenceAreaObject: IReferenceAreaData[];
  referenceLineHigh: number;
  referenceLineLow: number;
  suffix: string;
  yOffset: number;
}

type CustomTooltipProps = TooltipProps & {
  name: string;
  tickFormatter: TickFormatterFunction;
};

const CustomTooltip = ({ active, payload, tickFormatter, name, label }: CustomTooltipProps) => {
  const unixValue = label as number;
  if (active) {
    const value = tickFormatter(payload?.[0]?.value);
    return (
      <Paper
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          fontSize: '12px',
          padding: '16px',
        }}
      >
        <p>
          <Typography sx={{ textTransform: 'capitalize', color: 'white' }}>{name}:</Typography>
          {value || 0}
        </p>
        <p className='information'>{format(new Date(unixValue * 1000), 'MMM dd, h:mm a')}</p>
      </Paper>
    );
  }

  return null;
};

const GraphWidget = ({
  name,
  referenceLineHigh,
  referenceLineLow,
  graphData,
  yOffset,
  suffix,
  ReferenceAreaObject,
}: GraphWidgetProps) => {
  const [tabIdx, setTabIdx] = React.useState('Today');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [referenecAreaObj, setReferenceAreaObj] = React.useState<IReferenceAreaData[]>([]);
  const timezone = useAppSelector(getTimeZone());

  const [, setValue] = React.useState(0);

  const today = utcToZonedTime(new Date(), timezone);
  const startOfToday = startOfDay(today);

  // if daily, every 4 hours ticks, if weekly, everyday ticks
  const ticks =
    tabIdx === 'Today'
      ? [
          getUnixTime(subHours(today, 20)),
          getUnixTime(subHours(today, 16)),
          getUnixTime(subHours(today, 12)),
          getUnixTime(subHours(today, 8)),
          getUnixTime(subHours(today, 4)),
        ]
      : [
          getUnixTime(subDays(startOfToday, 6)),
          getUnixTime(subDays(startOfToday, 5)),
          getUnixTime(subDays(startOfToday, 4)),
          getUnixTime(subDays(startOfToday, 3)),
          getUnixTime(subDays(startOfToday, 2)),
          getUnixTime(subDays(startOfToday, 1)),
          getUnixTime(startOfToday),
        ];

  const firstTick = ticks[0];
  const lastTick = ticks[ticks.length - 1];

  const forceUpdate = () => {
    setTimeout(() => {
      setValue(value => value + 1);
    }, 10);
  };

  const findClosestReservation = (
    historicalData: EnviromentalDataPoint[],
    referenceNumber: number,
  ) => {
    // since the actual tick location might not be in the historical data, in order to
    // show the tick, we need to choose the closest available point among the data
    const historicalArray: number[] = [];
    historicalData?.map((historicalElement: EnviromentalDataPoint) => {
      historicalArray.push(historicalElement.created_at);
    });
    let closestTimeInUnix = 0;
    closestTimeInUnix = historicalArray?.reduce((a: number, b: number) => {
      return Math.abs(b - referenceNumber) < Math.abs(a - referenceNumber) ? b : a;
    }, 0);
    return closestTimeInUnix;
  };

  React.useEffect(() => {
    const data = graphData(tabIdx);
    let tempObj: IReferenceAreaData[] = [];
    tempObj = ReferenceAreaObject.slice();
    tempObj.map((referenceArea: IReferenceAreaData) => {
      referenceArea.x1 = findClosestReservation(data, referenceArea.x1);
      referenceArea.x2 = findClosestReservation(data, referenceArea.x2);
      referenceArea.ref = createRef();
    });
    setReferenceAreaObj(tempObj);
    forceUpdate();
  }, [ReferenceAreaObject, tabIdx, graphData]);

  const formattedTick = (props: AxisTickProps) => {
    return tabIdx === 'Today' ? (
      <CustomizedAxisTick {...props} />
    ) : (
      <CustomizedAxisTickLong {...props} />
    );
  };
  const CustomizedLabel = (props: { reference: string }) => {
    return <text ref={props.reference}></text>;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTab = (tabIdx: string) => () => {
    setTabIdx(tabIdx);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Paper
        sx={{
          height: '400px',
          backgroundColor: 'white',
          marginBottom: isMobile && '24px',
        }}
      >
        <AppBar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            position: 'static',
          }}
        >
          <Toolbar style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
            <HistoricalPullDownButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              isMobile={isMobile}
            >
              <HistoricalMenuButton
                color='primary'
                variant='contained'
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {tabIdx}
              </HistoricalMenuButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ display: 'flex', justifyContent: 'space-between', width: '312px' }}
              >
                <MenuItem
                  key='Today'
                  onClick={handleTab('Today')}
                  value='Today'
                  style={{ width: '312px' }}
                >
                  Today
                </MenuItem>
                <MenuItem key='Historical' onClick={handleTab('Historical')} value='Historical'>
                  Historical
                </MenuItem>
              </Menu>
            </HistoricalPullDownButton>
            <Typography variant='body1' sx={{ color: theme.palette.text.primary }}>
              {tabIdx === 'Today' ? '24 Hour Data' : 'Weekly Data'}
            </Typography>
            <HistoricalMoreButton aria-label='display more actions' edge='end' isMobile={isMobile}>
              {/* <MoreIcon /> */}
            </HistoricalMoreButton>
          </Toolbar>
          <GraphDiv>
            <ReservationDetailBar>
              {referenecAreaObj.map((areaObject: IReferenceAreaData, index: number) => (
                <InfoContainer
                  fill='primary.main'
                  left={areaObject?.ref?.current?.previousSibling?.getAttribute('x')}
                  width={areaObject?.ref?.current?.previousSibling?.getAttribute('width')}
                  theme={theme}
                  key={index}
                >
                  {areaObject?.ref?.current?.previousSibling?.getAttribute('width') > 40 && (
                    <CustomHoverPopover isText text={areaObject.name} />
                  )}
                  {areaObject?.ref?.current?.previousSibling?.getAttribute('width') < 40 && (
                    <CustomHoverPopover isIcon text={areaObject.name} />
                  )}
                </InfoContainer>
              ))}
            </ReservationDetailBar>
            <GraphContainer width='100%' height={250}>
              <AreaChart
                width={500}
                height={400}
                data={graphData(tabIdx)}
                margin={{ top: 0, right: 0, bottom: 30, left: 20 }}
              >
                <CartesianGrid horizontal={false} vertical={false} fill='#fff' />
                <XAxis
                  dataKey='created_at'
                  type='number'
                  ticks={ticks}
                  tick={props => formattedTick(props)}
                  domain={[firstTick, lastTick]}
                  axisLine={false}
                />
                <YAxis
                  orientation='right'
                  type='number'
                  domain={[`dataMin - ${yOffset}`, `dataMax + ${yOffset}`]}
                  ticks={[referenceLineHigh, referenceLineLow]}
                  tickFormatter={(value: number) => `${value} ${suffix}`}
                  tick={{ stroke: '#E9F0FD', fontSize: '11px', fontWeight: 500 }}
                  axisLine={false}
                  width={28}
                />
                <Area type='monotone' dataKey={name} fill='#E9F0FD' legendType='none' dot={false} />
                <ReferenceLine y={referenceLineHigh} fill='#E9F0FD' strokeDasharray='3 3' />
                <ReferenceLine y={referenceLineLow} stroke='#E9F0FD' strokeDasharray='3 3' />
                <Tooltip
                  content={
                    <CustomTooltip
                      name={name}
                      tickFormatter={(value: number) => `${value} ${suffix}`}
                    />
                  }
                />
                {referenecAreaObj.map((areaObject: IReferenceAreaData, index: number) => (
                  <ReferenceArea
                    label={<CustomizedLabel reference={areaObject?.ref} />}
                    x1={areaObject.x1}
                    x2={areaObject.x2}
                    fill='primary.main'
                    fillOpacity={0.04}
                    key={index}
                  />
                ))}
              </AreaChart>
            </GraphContainer>
          </GraphDiv>
        </AppBar>
      </Paper>
    </>
  );
};

const HistoricalMenuButton = styled(Button)`
  width: 312px;
  display: flex;
  justify-content: space-between;
`;
const HistoricalPullDownButton = styled(IconButton)<{ isMobile: boolean }>`
  && {
    width: ${props => props.isMobile && '160px'};
  }
`;

const GraphContainer = styled(ResponsiveContainer)`
  && {
    margin-top: 35px;
  }
`;

const HistoricalMoreButton = styled(IconButton)<{ isMobile: boolean }>`
  && {
    margin-right: ${props => props.isMobile && '24px'};
  }
`;

const GraphDiv = styled.div`
  && {
    width: 100%;
    display: block;
    position: relative;
  }
`;

const ReservationDetailBar = styled.div`
  && {
    height: 40px;
    position: absolute;
    display: block;
    top: 0;
    right: 38px;
    bottom: 0;
    left: 4px;
    margin-bottom: 40px;
  }
`;

const InfoContainer = styled.div<{
  fill: string;
  theme: Theme;
  width: number;
  left: number;
}>`
  && {
    background-color: ${props => props.fill}08;
    height: 100%;
    position: absolute;
    justify-content: center;
    left: ${props => props.left - 4}px;
    width: ${props => props.width}px;
    color: ${props => props.theme.textColor};
  }
`;

export default GraphWidget;
