import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ICategory,
  IFeatured,
  IPage,
  ISection,
  Order,
  useGuestPortal,
  useGuestPortalStatus,
} from 'redux/guestportal';
import { useGuidesCategories } from 'redux/guestportal/hooks/useGuidesCategories';
import { useGuidesPages } from 'redux/guestportal/hooks/useGuidesPages';
import { useGuidesSections } from 'redux/guestportal/hooks/useGuidesSections';
import { useAppSelector } from 'redux/hooks';
import { SortTable } from 'ui-library/Components/table/SortTable';
import { userPermissionSelector } from 'user/state/userSelectors';
import { GuidesDetailsCategoriesForm } from '../Guides/GuidesDetails/Categories/GuidesDetailsCategoriesForm';
import { GuidesDetailsPagesForm } from '../Guides/GuidesDetails/Pages/GuidesDetailsPagesForm';
import { GuidesForm } from '../Guides/GuidesTable/GuidesForm';

const columns = (
  onEdit: (featured: IFeatured) => void,
  hasGuestPortal: boolean,
): GridColDef<IFeatured>[] => [
  {
    field: 'title',
    headerName: 'Content Name',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, IFeatured>) => {
      return <Text>{row.name}</Text>;
    },
  },
  {
    field: 'category',
    headerName: 'Type',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, IFeatured>) => {
      let type = '';
      switch (row?.type) {
        case 'section':
          type = 'Guide';
          break;
        case 'content':
          type = 'Page';
          break;
        case 'category':
          type = 'Category';
          break;
        default:
          type = '';
      }
      return <Text>{type}</Text>;
    },
  },
  {
    field: 'guide',
    headerName: 'Guide Name',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, IFeatured>) => {
      return <Text>{row.guide_name}</Text>;
    },
  },
  {
    field: 'last_edit_at',
    headerName: 'Last Edited',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, IFeatured>) => {
      if (row?.last_edited_at) {
        return <Text>{format(fromUnixTime(row.last_edited_at), 'P')}</Text>;
      }

      if (row?.data?.last_edit_at) {
        return <Text>{format(fromUnixTime(row?.data?.last_edit_at), 'P')}</Text>;
      }

      if (row?.data?.created_at) {
        return <Text>{format(fromUnixTime(row?.data?.created_at), 'P')}</Text>;
      }

      return <Text>None</Text>;
    },
  },
  {
    field: 'published',
    headerName: 'Published',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams<unknown, IFeatured>) => {
      return row.published && <CheckCircleIcon color='primary' />;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    renderCell: ({ row }: GridRenderCellParams<unknown, IFeatured>) => {
      return (
        <>
          <IconButton onClick={() => onEdit(row)} disabled={!hasGuestPortal} size='large'>
            <EditIcon color={hasGuestPortal ? 'primary' : 'disabled'} />
          </IconButton>
        </>
      );
    },
  },
];

type GuidesDetailsFeaturedTableProps = {
  onEdit: (featured: IFeatured) => void;
};

export const GuidesDetailsFeaturedTable = ({ onEdit }: GuidesDetailsFeaturedTableProps) => {
  const hasGuestPortal = useAppSelector(userPermissionSelector());
  const { featuredList, setGuideFeaturedOrder } = useGuestPortal();
  const { isLoading } = useGuestPortalStatus();
  const [filteredList, setFilteredList] = useState<IFeatured[]>([]);

  const getRowId = (featured: IFeatured) => {
    switch (featured.type) {
      case 'section':
        return featured.section_id;

      case 'category':
        return featured.category_id;

      case 'content':
        return featured.content_id;
    }
  };

  const handleSearch = useCallback(
    (value?: string) => {
      if (!value?.length) {
        return setFilteredList(featuredList);
      }

      const filter = value.toLowerCase();
      setFilteredList(featuredList.filter(item => item.name?.includes(filter)));
    },
    [featuredList],
  );

  const handleSort = (order: IFeatured[]) => {
    const newOrder: Order[] = order.map(f => {
      let id = '';
      switch (f.type) {
        case 'section':
          id = f.section_id;
          break;
        case 'category':
          id = f.category_id;
          break;
        case 'content':
          id = f.content_id;
          break;
      }

      return {
        id,
        type: f.type,
      };
    });

    setGuideFeaturedOrder({ order: newOrder });
  };

  return (
    <SortTable
      title='Featured'
      initialState={{ pinnedColumns: { right: ['actions'] } }}
      loading={isLoading}
      rows={filteredList}
      getRowId={getRowId}
      columns={columns(onEdit, hasGuestPortal)}
      rowReordering
      onSearch={handleSearch}
      onSort={handleSort}
    />
  );
};

export const GuidesFeaturedPage = () => {
  const { fetchFeatures, fetchUnitFeatures } = useGuestPortal();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { propertyId: pidStr } = useParams();
  const propertyId = Number(pidStr);
  const { section, save: saveSection } = useGuidesSections();
  const { category, save: saveCategory } = useGuidesCategories();
  const { page, save: savePage } = useGuidesPages();

  const handleOnEditClicked = (featuredRow: IFeatured) => {
    switch (featuredRow?.type) {
      case 'section':
        navigate(`${pathname}/guides/${featuredRow.section_id}`);
        break;
      case 'category':
        navigate(
          `${pathname}/guides/${featuredRow.section_id}/categories/${featuredRow.category_id}`,
        );
        break;
      case 'content':
        navigate(
          `${pathname}/guides/${featuredRow.section_id}/categories/${featuredRow.category_id}/pages/${featuredRow.content_id}`,
        );
        break;
    }
  };

  const fetchGuidesFeatures = useCallback(() => {
    if (propertyId) {
      fetchUnitFeatures(propertyId);
    } else {
      fetchFeatures();
    }
  }, [fetchFeatures, fetchUnitFeatures, propertyId]);

  const handleSaveSection = async (section: ISection) => {
    await saveSection(section);
    fetchGuidesFeatures(); // unnecessary but only way coz of the mess data structure
  };

  const handleSaveCategory = async (category: ICategory) => {
    await saveCategory(category);
    fetchGuidesFeatures(); // unnecessary but only way coz of the mess data structure
  };

  const handleSavePage = async (page: IPage) => {
    await savePage(page);
    fetchGuidesFeatures(); // unnecessary but only way coz of the mess data structure
  };

  const handleGuidesFormOnClose = () => {
    navigate(pathname.split('/').slice(0, -1).join('/'));
  };

  useEffect(() => {
    fetchGuidesFeatures();
  }, [fetchGuidesFeatures]);

  return (
    <>
      <GuidesDetailsFeaturedTable onEdit={handleOnEditClicked} />

      <GuidesForm section={section} onSave={handleSaveSection} onClose={handleGuidesFormOnClose} />
      <GuidesDetailsCategoriesForm category={category} onSave={handleSaveCategory} />
      <GuidesDetailsPagesForm page={page} onSave={handleSavePage} />
    </>
  );
};

export default GuidesFeaturedPage;
