import { Add } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import { Task } from '@operto/tasks-shared';
import { TitlebarButtonExpanded, useMedia } from '@operto/ui';
import FavouriteIcon from 'Common/Icons/FavouriteIcon';
import { companySelector } from 'company/state/companySelectors';
import { FilterType } from 'guest/guestType';
import { getGuests } from 'guest/state/guestActions';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import { MemberFilterType } from 'member/memberType';
import { getMembersByCompany } from 'member/state/memberActions';
import { memberSelector } from 'member/state/memberSelectors';
import useTasks from 'Pages/Tasks/useTasks';
import { PropertyFilterType } from 'property/propertyType';
import { getProperties } from 'property/state/propertyActions';
import { getPropertiesByFilter } from 'property/state/propertySelectors';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { createEventEmmiter } from 'shared/event';
import { useChatContext } from 'stream-chat-react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';
import { updateFavouriteAction } from './MessagingActions';
import { StatusHeader } from './StatusHeader';
import { Tags } from './Tags';

interface ChannelHeaderProps {
  onChevronClick?: () => void;
  showFavouriteIcon?: boolean;
  showUnitName?: boolean;
  isGuestTab?: boolean;
  isTasksTab?: boolean;
  onTaskTabClick?: (displayTaskTab: number) => void;
  activeTab: number;
  resID?: string;
  propertyName?: string;
  onNewTaskClick?: () => void;
  onViewTaskClick?: (taskId: string) => void;
  onTaskAddPeopleClick?: () => void;
}

type HeaderEvent = 'refresh';
export const CustomChannelHeaderEmitter = createEventEmmiter<HeaderEvent>();

const CustomChannelHeader = (props: ChannelHeaderProps) => {
  const { isFeatureEnabled } = useAppFeatures();
  const company = useAppSelector(companySelector());
  const { isMobile } = useMedia();
  const { channel } = useChatContext();
  const { name } = channel.data || {};

  const members = channel.data.member_count;
  const showFavouriteIcon = props?.showFavouriteIcon;
  const showUnitName = props?.showUnitName && isFeatureEnabled('tasks', company.id);
  const isGuestTab = props?.isGuestTab;
  const isTasksTab = props?.isTasksTab;
  const online = channel.state.watcher_count;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onViewTaskClick = props?.onViewTaskClick;
  const onTaskAddPeopleClick = props?.onTaskAddPeopleClick;

  const membersList = useAppSelector(memberSelector());
  const { properties: unitList } = useAppSelector(
    getPropertiesByFilter({
      filterType: PropertyFilterType.ALL_PROPERTIES,
    }),
  );

  const taskId = channel?.id?.split('tid')[1];

  const { getTask, isFetching, isLoading } = useTasks({
    fetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const { data: task, refetchTask }: Task = getTask(taskId) || {};
  const assigneeName = membersList.find(member => +member?.id === +task?.assigneeId)?.name || '';

  const favourite = (channel?.data?.favourite as boolean) ?? false;
  const captionInfo = {
    title: channel?.data?.['property_name'] as string,
    url: `/units/${channel?.data?.['property_id'] as number}`,
  };
  const onTaskTabClick = props?.onTaskTabClick;
  const onNewTaskClick = props?.onNewTaskClick;
  const activeTab = props.activeTab;
  const resID = props.resID;
  const propertyName = props.propertyName;
  const { t } = useTranslation();

  const mainContainerStyles: SxProps<Theme> = {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    paddingTop: '12px',
    paddingLeft: isMobile ? 0 : '16px',
    paddingBottom: '12px',
    paddingRight: '24px',
    borderBottom: theme => `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  };

  const truncatedTextStyle: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  };

  const renderChevronIcon = () => {
    return (
      <IconButton
        sx={{ height: '48px', width: '48px' }}
        onClick={props.onChevronClick}
        data-testid='chevron-icon'
      >
        <ChevronLeftIcon sx={{ height: '100%', width: '100%' }} />
      </IconButton>
    );
  };

  let onClickFavouriteTimeout: NodeJS.Timeout;

  const onClickFavouriteIcon = async () => {
    await dispatch(updateFavouriteAction(channel.id, !favourite));
    clearTimeout(onClickFavouriteTimeout);
    onClickFavouriteTimeout = setTimeout(() => navigate(`/messenger/${channel.id}`), 300);
  };

  const handleClick = (e: React.MouseEvent) => {
    if (captionInfo?.url) {
      navigate(captionInfo.url);
      e.stopPropagation();
    }
  };

  const renderMessageTabs = () => {
    return (
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => onTaskTabClick(newValue)}
        aria-label='message channel tabs'
      >
        <Tab label='Messages' value={0} />
        <Tab label='Tasks' value={1} />
      </Tabs>
    );
  };

  const handleTaskUnitClick = (unitId: string) => {
    navigate(`/units/${unitId}`);
  };

  const renderTaskChatDetails = () => {
    return !isFetching && !isLoading && task ? (
      <Stack direction='column'>
        <Typography variant='subtitle-lg-600' sx={truncatedTextStyle}>
          {task?.title}
        </Typography>
        <Typography variant='caption-lg-400' noWrap>
          {task?.description}
        </Typography>

        <Stack direction='row' alignItems='center' spacing={1}>
          {task?.status && <StatusHeader status={task.status} />}

          {task?.unit && (
            <Link
              variant='caption-lg-700'
              onClick={() => {
                handleTaskUnitClick(task?.unit);
              }}
              sx={truncatedTextStyle}
              data-testid='tasks-unit-link'
            >
              {unitList.find(unit => +unit.id === +task.unit)?.name || ''}
            </Link>
          )}

          <Typography variant='caption-lg-400' noWrap>
            {`Task ID: ${task?.id}`}
          </Typography>

          {(task?.type || task?.priority) && <Tags type={task.type} priority={task.priority} />}
        </Stack>
      </Stack>
    ) : (
      <Typography variant='subtitle-lg-600' sx={truncatedTextStyle}>
        Loading...
      </Typography>
    );
  };

  const renderTaskChatButtons = () => {
    return (
      <Stack direction='row' spacing={1} sx={{ maxHeight: '36px' }}>
        <Tooltip title={t('add_member')} slotProps={tooltipSlotProps}>
          <IconButton
            size='large'
            color='primary'
            onClick={onTaskAddPeopleClick}
            sx={{ border: '1px solid #155EFF80', borderRadius: '6px' }}
          >
            <PersonAddOutlinedIcon />
          </IconButton>
        </Tooltip>

        <Button
          variant='contained'
          onClick={() => {
            onViewTaskClick(taskId);
          }}
          sx={{ textTransform: 'none', fontWeight: 700, color: 'white', borderRadius: '6px' }}
        >
          View Task Details
        </Button>
      </Stack>
    );
  };

  // FIXME: can move these to a utils file if desired
  useEffect(() => {
    const loadResources = async () => {
      dispatch(getGuests({ filterType: FilterType.ALL_GUESTS }));
      dispatch(getProperties());
      dispatch(
        getMembersByCompany({
          filterType: MemberFilterType.ALL_MEMBERS,
          pageNum: 0,
          numPerPage: 100000,
        }),
      );
    };

    loadResources();
  }, [dispatch]);

  useEffect(() => {
    CustomChannelHeaderEmitter.on('refresh', refetchTask);
    return () => {
      CustomChannelHeaderEmitter.off('refresh', refetchTask);
    };
  }, [refetchTask]);

  return isTasksTab ? (
    <Box sx={mainContainerStyles}>
      <Avatar>
        <ProfileIcon size={'medium'} fullName={assigneeName} />
      </Avatar>

      <Box sx={chatTitleStyles}>{renderTaskChatDetails()}</Box>

      <Box sx={chatButtonStyles}>{renderTaskChatButtons()}</Box>
    </Box>
  ) : (
    <>
      <Box sx={mainContainerStyles}>
        {isMobile && renderChevronIcon()}
        <Avatar>
          <ProfileIcon
            imageUrl={channel.data?.image as string}
            size={'medium'}
            fullName={channel.data?.name}
          />
        </Avatar>

        <Box sx={chatTitleStyles}>
          <Typography variant='subtitle1' sx={truncatedTextStyle}>
            {name}
          </Typography>
          {isFeatureEnabled('tasks', company.id) && (resID || propertyName) && (
            <Box sx={chatSubtitleStyles}>
              {propertyName && (
                <Typography variant='caption' sx={propertyNameStyling}>
                  {propertyName}
                </Typography>
              )}
              {resID && (
                <Typography variant='caption' sx={resIDStyling}>
                  {'Res ID: ' + resID}
                </Typography>
              )}
            </Box>
          )}
          <Typography variant='body2'>
            {members} members, {online} online
          </Typography>
          {isFeatureEnabled('tasks', company.id) && isGuestTab && renderMessageTabs()}
        </Box>

        <Box sx={chatButtonStyles}>
          {showUnitName && (
            <Link
              component='span'
              variant='caption2'
              color='primary'
              onClick={handleClick}
              sx={truncatedTextStyle}
            >
              {captionInfo?.title ? captionInfo.title : ''}
            </Link>
          )}
          {showFavouriteIcon && (
            <FavouriteIcon
              isFavourite={favourite}
              onClick={onClickFavouriteIcon}
              styles={{ padding: 0 }}
            />
          )}

          {isFeatureEnabled('tasks', company.id) && (
            <TitlebarButtonExpanded
              onClick={onNewTaskClick}
              style={{ borderRadius: '6px', padding: '8px 12px' }}
            >
              <Add />
              {t('task')}
            </TitlebarButtonExpanded>
          )}
        </Box>
      </Box>
    </>
  );
};

const propertyNameStyling: SxProps = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  textAlign: 'right',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  color: '#155EFF',
};

const resIDStyling: SxProps = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
};

const chatTitleStyles: SxProps = {
  flexGrow: 1,
  width: '215px',
};

const chatSubtitleStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

const chatButtonStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  ml: '16px',
};

const tooltipSlotProps = {
  popper: {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
        marginTop: '2px',
      },
    },
  },
};

export default CustomChannelHeader;
