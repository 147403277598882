import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import ActionSnackbar from 'Common/Snackbar/ActionSnackbar';
import { useTranslation } from 'hooks/useTranslation';
import { THostawayPayload } from 'integrations/integrationsTypes';
import React, { useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import AddCard from 'ui-library/Components/card/AdderCard';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import { FormHeader } from 'ui-library/Styled/sidebar';
import { IntegrationsFormHandle, IntegrationsFormProps } from '../IntegrationsSideDrawer';

const HOSTAWAY_CLIENT_SUPPORT_URL = `${process.env.REACT_APP_CLIENT_SUPPORT_URL}/s/article/Hostaway`;

type HostawayIntegrationFormProps = IntegrationsFormProps & {
  onSave: (data: Omit<THostawayPayload, 'provider'>) => void;
  onClose: () => void;
};

const HostawayIntegrationForm = React.forwardRef<
  IntegrationsFormHandle,
  HostawayIntegrationFormProps
>(({ onSave, onClose }, ref) => {
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<Omit<THostawayPayload, 'provider'>>({
    mode: 'all',
    defaultValues: {},
  });

  useImperativeHandle(
    ref,
    () => ({
      submit: handleSubmit(onSave),
      close: () => {
        setShowExitConfirmation(isDirty);
        if (!isDirty) {
          onClose?.();
        }
      },
    }),
    [handleSubmit, isDirty, onClose, onSave],
  );

  return (
    <Stack m={1}>
      <AddCard
        title={t('credentials')}
        subtitle={
          <IconButton
            color='primary'
            onClick={() => window.open(HOSTAWAY_CLIENT_SUPPORT_URL, '_blank')}
          >
            <Tooltip title={t('guide_article')}>
              <InfoOutlined />
            </Tooltip>
          </IconButton>
        }
      >
        <ActionSnackbar
          onClose={onClose}
          open={showExitConfirmation}
          setOpen={setShowExitConfirmation}
          severity='error'
          actionButtonText={t('yes_capitalized')}
          snackbarMessage={t(
            'you_have_unsaved_changes_that_will_be_lost_if_you_exit_are_you_sure_you_want_to_exit',
          )}
        />

        <FormHeader>
          {t('provide_the_following_info_from_your_account', { integration_name: 'Hostaway' })}
        </FormHeader>

        <FormTextField
          rules={{
            required: {
              value: true,
              message: t('account_id_required'),
            },
          }}
          field='client_id'
          label={t('account_id')}
          control={control}
          sx={{ mb: 4 }}
        />
        <FormTextField
          rules={{
            required: {
              value: true,
              message: t('api_key_required'),
            },
          }}
          field='api_secret'
          label={t('api_key')}
          control={control}
          sx={{ mb: 4 }}
        />
      </AddCard>
    </Stack>
  );
});

HostawayIntegrationForm.displayName = 'HostawayIntegrationForm'; //host

export default HostawayIntegrationForm; //host
