import useTranslation from 'hooks/useTranslation';
import { useMemo } from 'react';
import { DIALOG_ACTION_TYPE, TDialogActionType } from './AutomateSaveConfirmDialog';

const getDialogContent = (
  actionType: TDialogActionType,
  active: boolean,
  isDirty: boolean,
  dialogContentMap: Map<
    string,
    {
      title: string;
      text: string;
      primaryButtonTitle: string;
      secondaryButtonTitle: string;
      showCloseIcon: boolean;
    }
  >,
) => {
  if (actionType === DIALOG_ACTION_TYPE.SWITCH && active && !isDirty) {
    return dialogContentMap.get('deactivate');
  } else if (actionType === DIALOG_ACTION_TYPE.SWITCH && !active && !isDirty) {
    return dialogContentMap.get('activate');
  } else if (actionType === DIALOG_ACTION_TYPE.SWITCH && !active && isDirty) {
    return dialogContentMap.get('activate-dirty');
  } else if (actionType === DIALOG_ACTION_TYPE.SAVE && !active) {
    return dialogContentMap.get('save-inactive');
  }
  return undefined;
};

export default function useAutomateSaveConfirmDialogMap() {
  const { t } = useTranslation();
  const dialogContentMap = useMemo(() => {
    const map = new Map<
      string,
      {
        title: string;
        text: string;
        primaryButtonTitle: string;
        secondaryButtonTitle: string;
        showCloseIcon: boolean;
      }
    >();

    map.set('deactivate', {
      title: t('deactivate_flow_question'),
      text: t('deactivate_flow_active'),
      primaryButtonTitle: t('deactivate'),
      secondaryButtonTitle: t('cancel'),
      showCloseIcon: false,
    });

    map.set('activate', {
      title: t('activate_flow_question'),
      text: t('activate_flow_when_inactivate'),
      primaryButtonTitle: t('activate'),
      secondaryButtonTitle: t('cancel'),
      showCloseIcon: false,
    });

    map.set('activate-dirty', {
      title: t('activate_flow_question'),
      text: t('activate_flow_when_inactivate-and-dirty'),
      primaryButtonTitle: t('save_and_activate'),
      secondaryButtonTitle: t('cancel'),
      showCloseIcon: false,
    });

    map.set('save-inactive', {
      title: t('save_changes_question'),
      text: t('save_and_and_activate_or_keep_inactive'),
      primaryButtonTitle: t('save_and_activate'),
      secondaryButtonTitle: t('keep_inactive'),
      showCloseIcon: true,
    });

    return map;
  }, [t]);

  return { dialogContentMap, getDialogContent };
}
