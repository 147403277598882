import ErrorIcon from '@mui/icons-material/Error';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import useGuestAuth from 'hooks/useGuestAuth';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGuestPortal } from 'redux/guestportal';
import { PreviewDevice } from './PreviewDevice';
import { PreviewSearchList } from './PreviewSearchList';

const OPERTO_PREVIEW_GUEST_APP_EMAIL =
  process.env.REACT_APP_GUEST_AUTH_USER_POOL_PREVIEW_EMAIL ?? '';
const OPERTO_PREVIEW_GUEST_APP_PASSWORD =
  process.env.REACT_APP_GUEST_AUTH_USER_POOL_FIXED_PASSWORD ?? '';

export interface GPPreviewContainerProps {
  globalPropertyId?: string;
  onClose?: () => void;
  isEntranceInstructions?: boolean;
}

const GPPreviewContainer = ({
  globalPropertyId,
  onClose,
  isEntranceInstructions,
}: GPPreviewContainerProps) => {
  const {
    generateGPPreview,
    fetchAssignedProperties,
    gpLink,
    assignedPropertiesRecords,
    setGPLink,
  } = useGuestPortal();

  const [isLinkLoading, setIsLinkLoading] = React.useState(false);

  const firstPropertyId = String(assignedPropertiesRecords?.[0]?.propertyId) ?? '0';
  const propertiesCount = assignedPropertiesRecords.length;

  const guestToken = useRef('');

  let url = '';

  if (gpLink?.url) {
    url = `${gpLink.url.replace('guests', 'guest')}/?r=true${
      guestToken.current ? `&${guestToken.current}` : ''
    }`;
  }

  const { propertyId } = useParams<{ propertyId?: string }>();

  const { loginGuest, transformTokenInfoToQueryParams } = useGuestAuth();
  const { t } = useTranslation();

  const handlePropertyClick = useCallback(
    async (propertyId: string) => {
      try {
        setIsLinkLoading(true);
        setGPLink(null);
        guestToken.current = '';

        const token = await loginGuest({
          username: OPERTO_PREVIEW_GUEST_APP_EMAIL,
          password: OPERTO_PREVIEW_GUEST_APP_PASSWORD,
        });

        guestToken.current = transformTokenInfoToQueryParams(token);

        generateGPPreview({ property_id: Number(propertyId), skip_welcome_screens: true });
        setIsLinkLoading(false);
      } catch (error) {
        setIsLinkLoading(false);
        logger.error('Error logging in as guest', error);
      }
    },
    [generateGPPreview, loginGuest, transformTokenInfoToQueryParams, setGPLink],
  );

  // Fetch assigned properties
  useEffect(() => {
    fetchAssignedProperties({
      newPage: 0,
      numPerPage: 10000, // We are using a high number here to ensure that all properties are fetched, we need to return it to 100 when we have pagination
      append: true,
    });
  }, [fetchAssignedProperties]);

  // Get the first property preview
  useEffect(() => {
    const getFirstPreview = async () => {
      if (propertiesCount > 0) {
        await handlePropertyClick(propertyId ?? globalPropertyId ?? firstPropertyId);
      }
    };

    getFirstPreview();
  }, [propertyId, globalPropertyId, handlePropertyClick, propertiesCount, firstPropertyId]);

  // Clean up GP Link on unmount
  useEffect(() => {
    return () => {
      setGPLink(null);
    };
  }, [setGPLink]);

  return (
    <Box data-testid='gp-preview-container'>
      {onClose && (
        <RightSideTopbar
          title={'Preview'}
          handleClose={onClose}
          buttonComponent={
            <Tooltip
              title={t('preview_container_tooltip_message')}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton>
                <ErrorIcon />
              </IconButton>
            </Tooltip>
          }
        />
      )}

      <Stack direction='row' p={3} spacing={3} bgcolor='#F6F7FB'>
        <PreviewSearchList
          propertyId={propertyId ?? firstPropertyId}
          properties={assignedPropertiesRecords}
          onSelect={handlePropertyClick}
          isEntranceInstructions={isEntranceInstructions}
        />

        <PreviewDevice url={url} isLoading={isLinkLoading} />
      </Stack>
    </Box>
  );
};

export default GPPreviewContainer;
