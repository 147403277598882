import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Variable } from '@operto/variables-shared';
import React from 'react';
import VariableTableActions from '../Common/VariableTableActions';
import { downloadCustomVariableCSV } from '../helpers/CSVExportHelper';
import {
  generateDuplicateName,
  getAllVariableNamesList,
  getCustomVariableById,
} from '../helpers/VariableHelpers';

export type ActionProps = {
  row: GridRenderCellParams['row'];
  allVariables: Variable[];
  setChosenVariableOnOpenMode: (variable: Variable) => void;
  setIsChangeStatusModalMode: (state: boolean) => void;
  setMoreInfoRows: (state: unknown) => void;
  setOpenMode: (state: boolean) => void;
  mixVariablePropertiesWithRealProperties: (state: string) => void;
  updateVariableMutator: (state: unknown) => void;
  setSelectedVariableId: (id: string) => void;
  setIsDeleteVariableModalMode: (state: boolean) => void;
  createVariableMutator: (state: Variable) => void;
};
const Actions = ({
  row,
  allVariables,
  setChosenVariableOnOpenMode,
  setIsChangeStatusModalMode,
  setMoreInfoRows,
  setOpenMode,
  mixVariablePropertiesWithRealProperties,
  updateVariableMutator,
  setSelectedVariableId,
  setIsDeleteVariableModalMode,
  createVariableMutator,
}: ActionProps) => {
  const selectedCustomVariable = getCustomVariableById(row.id, allVariables);

  const onEdit = () => {
    setChosenVariableOnOpenMode(selectedCustomVariable);
    setMoreInfoRows(mixVariablePropertiesWithRealProperties(row.id));
    setOpenMode(true);
  };

  const onDuplicate = () => {
    const selectedCustomVariableCopy = { ...selectedCustomVariable };
    delete selectedCustomVariableCopy.id;
    const allVariableNames = getAllVariableNamesList(allVariables);

    createVariableMutator({
      ...selectedCustomVariableCopy,
      name: generateDuplicateName(selectedCustomVariableCopy.name, allVariableNames),
    });
  };

  return (
    <VariableTableActions
      data-testid='custom-variable-table-actions'
      variable={selectedCustomVariable}
      onEdit={onEdit}
      onDuplicate={onDuplicate}
      onExport={() => {
        downloadCustomVariableCSV(selectedCustomVariable);
      }}
      onChangeStatus={(variableStatusToUpdate: boolean) => {
        setChosenVariableOnOpenMode(selectedCustomVariable);

        if (!variableStatusToUpdate) {
          setIsChangeStatusModalMode(true);
        } else {
          updateVariableMutator({ ...selectedCustomVariable, enabled: variableStatusToUpdate });
        }
      }}
      onDelete={() => {
        setSelectedVariableId(row.id);
        setIsDeleteVariableModalMode(true);
      }}
    />
  );
};

export default Actions;
