import { Stack, SxProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TabBar, { TabBarProps, TabItems } from 'ui-library/Components/tabBar/TabBar';

export const VariableUrls = {
  SYSTEM: '/settings/variables/system',
  CUSTOM: '/settings/variables/custom',
} as const;
export const VariablesTabItem = {
  System: 0,
  Custom: 1,
} as const;
export type VariablesTabItemType = (typeof VariablesTabItem)[keyof typeof VariablesTabItem];
export type VariableUrlsType = (typeof VariableUrls)[keyof typeof VariableUrls];

const tabItems: TabItems[] = [
  {
    label: 'System',
    value: VariablesTabItem.System,
    isDropDown: false,
  },
  { label: 'Custom', value: VariablesTabItem.Custom, isDropDown: false },
];

export const Variables = () => {
  const [activeTab, setActiveTab] = useState<VariablesTabItemType>(VariablesTabItem.System);
  const navigate = useNavigate();
  const location = useLocation();

  const setTabIndex = (tabValue: number) => {
    // if sub-menu, needs to be rounded down
    selectFilter(tabValue);
  };

  const selectFilter = (index: number) => {
    switch (index) {
      case VariablesTabItem.System:
        navigate(VariableUrls.SYSTEM);
        break;
      case VariablesTabItem.Custom:
        navigate(VariableUrls.CUSTOM);
        break;
      default:
        navigate(VariableUrls.SYSTEM);
        break;
    }
  };

  useEffect(() => {
    const { pathname } = location;

    if (pathname.includes(VariableUrls.SYSTEM)) {
      setActiveTab(VariablesTabItem.System);
    } else {
      setActiveTab(VariablesTabItem.Custom);
    }
  }, [location]);

  return (
    <Stack sx={pageStyle}>
      <TabBar
        tabItems={tabItems as TabItems[]}
        tabIndex={activeTab}
        onTabChange={setTabIndex}
        onSubTabChange={selectFilter as TabBarProps['onSubTabChange']}
      />
      <Stack sx={contentStyles}>
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default Variables;

const contentStyles: SxProps = {
  position: 'relative',
  height: '100%',
};

const pageStyle: SxProps = {
  flex: 1,
};
