/* eslint-disable @typescript-eslint/no-explicit-any */
import { unwrapResult } from '@reduxjs/toolkit';
import { OpertoLogger } from 'Logger/logger';
import getUnixTime from 'date-fns/getUnixTime';
import debounce from 'lodash/debounce';
import { patchProperty } from 'property/state/propertyActions';
import React, { useCallback, useMemo } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { ICategory, IPage, ISection } from '..';
import {
  CustomText,
  GPProperty,
  Host,
  IEntranceInstructions,
  ImageUploadType,
  Order,
  PropertyAddress,
  Settings,
  Theme,
} from '../models';
import {
  selectFeatures,
  selectGuestPortal,
  selectGuideCategories,
  selectGuidePages,
  selectGuideSections,
  selectedGuideCategoriesOrder,
  selectedGuidePagesOrder,
  setAssignedProperty,
  setCustomText,
  setGPLink,
  setHost,
  setPropertyAddress,
  setPropertyCustomText,
  setPropertyHost,
  setPropertyPropertyAddress,
  setSettings,
  setTheme,
} from '../slice';
import {
  generateGuestPortal,
  getAssignedProperties,
  getCustomText,
  getEntranceInstructions,
  getFeatures,
  getGuidesCategories,
  getGuidesCategoriesOrder,
  getGuidesPages,
  getGuidesPagesOrder,
  getGuidesSections,
  getHostData,
  getInfoData,
  getPropertyCustomText,
  getPropertyGuidesCategories,
  getPropertyGuidesCategoriesOrder,
  getPropertyGuidesPages,
  getPropertyGuidesPagesOrder,
  getPropertyGuidesSection,
  getPropertyGuidesSectionsOrder,
  getPropertyHostData,
  getPropertyInfoData,
  getSettings,
  getTermsData,
  getThemeData,
  getUnitFeatures,
  movePageToNewCategory,
  movePageToNewCategoryWithOrder,
  patchActivateGPUnit,
  patchCustomText,
  patchEntranceInstructions,
  patchGuestPortalActivate,
  patchGuideCategoriesOrder,
  patchGuideCategory,
  patchGuideFeaturedOrder,
  patchGuideImages,
  patchGuidePages,
  patchGuidePagesOrder,
  patchGuideSection,
  patchGuideSectionsOrder,
  patchGuideUnset,
  patchHost,
  patchPropertyAddress,
  patchPropertyCustomText,
  patchPropertyGuideCategoriesOrder,
  patchPropertyGuideCategory,
  patchPropertyGuidePages,
  patchPropertyGuidePagesOrder,
  patchPropertyGuideSection,
  patchPropertyGuideSectionsOrder,
  patchPropertyHost,
  patchPropertyPropertyAddress,
  patchRandomImages,
  patchSettings,
  patchTheme,
  postGuestPortalActivate,
  postGuideCategory,
  postGuidePage,
  postGuideSection,
  postInitializeGuestPortalUnit,
} from '../thunks';

export const useGuestPortal = () => {
  const dispatch = useAppDispatch();
  const {
    guides,
    host,
    theme,
    address,
    customText,
    settings,
    gpLink,
    assignedProperties,
    sections,
    pages,
    categories,
    featured,
    entranceInstructions,
  } = useAppSelector(selectGuestPortal);
  const featuredList = useAppSelector(selectFeatures);
  const sectionsList = useAppSelector(selectGuideSections);
  const pagesList = useAppSelector(selectGuidePages);
  const categoriesList = useAppSelector(selectGuideCategories);
  const pagesOrder = useAppSelector(selectedGuidePagesOrder);
  const categoriesOrder = useAppSelector(selectedGuideCategoriesOrder);
  // const fetchGuestPortal = () => dispatch(getGuestPortal());
  const fetchThemeData = React.useCallback(() => dispatch(getThemeData()), [dispatch]);
  const fetchCustomText = React.useCallback(() => dispatch(getCustomText()), [dispatch]);
  const fetchInfoData = React.useCallback(() => dispatch(getInfoData()), [dispatch]);
  const fetchTermsData = React.useCallback(() => dispatch(getTermsData()), [dispatch]);
  const fetchHostData = React.useCallback(() => dispatch(getHostData()), [dispatch]);
  const fetchSettings = React.useCallback(() => dispatch(getSettings()), [dispatch]);

  const fetchPropertyHostData = React.useCallback(
    (propertyId: number) => dispatch(getPropertyHostData(propertyId)),
    [dispatch],
  );
  const fetchPropertyInfoData = React.useCallback(
    (propertyId: number) => dispatch(getPropertyInfoData(propertyId)),
    [dispatch],
  );
  const fetchPropertyCustomText = React.useCallback(
    (propertyId: number) => dispatch(getPropertyCustomText(propertyId)),
    [dispatch],
  );

  const fetchAssignedProperties = useCallback(
    ({
      keyword,
      newPage,
      numPerPage,
      append = false,
      filter = undefined,
    }: {
      keyword?: string;
      newPage: number;
      numPerPage: number;
      append?: boolean;
      filter?: string;
    }) => dispatch(getAssignedProperties({ keyword, newPage, numPerPage, append, filter })),
    [dispatch],
  );

  const enableGuestPortal = React.useCallback(
    () => dispatch(postGuestPortalActivate()),
    [dispatch],
  );
  const initializeGuestPortalForUnit = React.useCallback(
    (propertyId: string | number) => dispatch(postInitializeGuestPortalUnit(propertyId)),
    [dispatch],
  );
  const updateEnableGuestPortal = React.useCallback(
    (isEnabled: boolean) => dispatch(patchGuestPortalActivate(isEnabled)),
    [dispatch],
  );
  const fetchGuidePagesOrder = React.useCallback(
    (sectionId: string) => dispatch(getGuidesPagesOrder(sectionId)),
    [dispatch],
  );
  const fetchGuideCategoriesOrder = React.useCallback(
    (sectionId: string) => dispatch(getGuidesCategoriesOrder(sectionId)),
    [dispatch],
  );

  //   }) => {
  //     dispatch(getAssignedProperties({ keyword, newPage, numPerPage, append, filter }));
  //   },
  //   [dispatch],
  // );

  const updateAssignedProperty = async (assignedProperty: GPProperty) => {
    try {
      await dispatch(patchProperty(assignedProperty.propertyId, assignedProperty));
      dispatch(setAssignedProperty(assignedProperty));
    } catch (error) {
      OpertoLogger.Log(error);
    }
  };

  // const enableGuestPortal = () => dispatch(postGuestPortalActivate());
  // const initializeGuestPortalForUnit = (propertyId: string | number) =>
  //   dispatch(postInitializeGuestPortalUnit(propertyId));
  // const updateEnableGuestPortal = (isEnabled: boolean) =>
  //   dispatch(patchGuestPortalActivate(isEnabled));
  // const fetchGuidePagesOrder = (sectionId: string) => dispatch(getGuidesPagesOrder(sectionId));
  // const fetchGuideCategoriesOrder = (sectionId: string) =>
  //   dispatch(getGuidesCategoriesOrder(sectionId));

  const fetchPropertyGuideSectionsOrder = React.useCallback(
    (propertyId: string | number) => dispatch(getPropertyGuidesSectionsOrder(propertyId)),
    [dispatch],
  );
  const fetchPropertyGuidePagesOrder = React.useCallback(
    (data: { sectionId: string; propertyId: string | number }) =>
      dispatch(getPropertyGuidesPagesOrder(data)),
    [dispatch],
  );
  const fetchPropertyGuideCategoriesOrder = React.useCallback(
    (data: { sectionId: string; propertyId: string | number }) =>
      dispatch(getPropertyGuidesCategoriesOrder(data)),
    [dispatch],
  );

  const fetchGuideSections = React.useCallback(() => dispatch(getGuidesSections()), [dispatch]);
  const fetchPropertyGuideSections = React.useCallback(
    (propertyId: number) => dispatch(getPropertyGuidesSection(propertyId)),
    [dispatch],
  );
  const fetchGuideCategories = React.useCallback(
    async (sectionId: string) => {
      await dispatch(getGuidesSections());
      await dispatch(getGuidesCategories(sectionId));
    },
    [dispatch],
  );
  const fetchPropertyGuideCategories = React.useCallback(
    (propertyId: number, sectionId: string) =>
      dispatch(getPropertyGuidesCategories({ propertyId, sectionId })),
    [dispatch],
  );
  const fetchGuidePages = React.useCallback(
    async (sectionId: string) => {
      await dispatch(getGuidesSections());
      await dispatch(getGuidesCategories(sectionId));
      await dispatch(getGuidesPages(sectionId));
    },
    [dispatch],
  );
  const fetchPropertyGuidePages = React.useCallback(
    async (propertyId: number, sectionId: string) => {
      await dispatch(getPropertyGuidesSection(propertyId));
      await dispatch(getPropertyGuidesCategories({ propertyId, sectionId }));
      await dispatch(getPropertyGuidesPages({ propertyId, sectionId }));
    },
    [dispatch],
  );

  const fetchFeatures = React.useCallback(() => dispatch(getFeatures()), [dispatch]);
  const fetchUnitFeatures = React.useCallback(
    (propertyId: number) => dispatch(getUnitFeatures(propertyId)),
    [dispatch],
  );

  const fetchEntranceInstructions = React.useCallback(
    () => dispatch(getEntranceInstructions()),
    [dispatch],
  );

  const assignedPropertiesRecords = React.useMemo(() => {
    return assignedProperties?.list.map(propertyId => assignedProperties.byId[propertyId]);
  }, [assignedProperties]);

  const entranceInstructionsData = React.useMemo(() => {
    return entranceInstructions
      ? {
          entrance_instructions_title: entranceInstructions?.entrance_instructions_title || '',
          entrance_instructions_button_label:
            entranceInstructions?.entrance_instructions_button_label || '',
          entrance_instructions_details: entranceInstructions?.entrance_instructions_details || '',
          entrance_instructions_enabled:
            assignedPropertiesRecords?.map(property => ({
              propertyId: property.propertyId,
              enabled: property.entrance_instructions_enabled,
            })) || [],
        }
      : null;
  }, [assignedPropertiesRecords, entranceInstructions]);

  const assignedPropertyById = (propertyId: number) => assignedProperties.byId[propertyId];

  const generateGPPreview = React.useCallback(
    (data: { property_id: number; skip_welcome_screens: boolean }) =>
      dispatch(generateGuestPortal(data)),
    [dispatch],
  );

  // auto-save with 2s debounce
  const autoSaveTheme = useMemo(
    () => debounce((theme: Theme) => dispatch(patchTheme(theme)), 2000),
    [dispatch],
  );
  const autoSaveHost = useMemo(
    () => debounce((host: Host) => dispatch(patchHost(host)), 2000),
    [dispatch],
  );

  const autoSaveCustomText = useMemo(
    () => debounce((text: CustomText) => dispatch(patchCustomText(text)), 2000),
    [dispatch],
  );

  const autoSavePropertyAddress = useMemo(
    () => debounce((address: PropertyAddress) => dispatch(patchPropertyAddress(address)), 2000),
    [dispatch],
  );
  const autoSavePropertyHost = useMemo(
    () => debounce((host: Host) => dispatch(patchPropertyHost(host)), 2000),
    [dispatch],
  );

  const autoSavePropertyCustomText = useMemo(
    () =>
      debounce(
        (propertyId: string, text: CustomText) =>
          dispatch(patchPropertyCustomText({ propertyId, text })),
        2000,
      ),
    [dispatch],
  );

  const autoSavePropertyPropertyAddress = useMemo(
    () =>
      debounce((address: PropertyAddress) => dispatch(patchPropertyPropertyAddress(address)), 2000),
    [dispatch],
  );

  const autoSaveSettings = useMemo(
    () => debounce((settings: Settings) => dispatch(patchSettings(settings)), 2000),
    [dispatch],
  );

  const autoSaveGuideSectionsOrder = useCallback(
    async (order: Order[]): Promise<boolean> => {
      try {
        const resultAction = await dispatch(patchGuideSectionsOrder(order));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );
  const autoSaveGuideFeaturedOrder = useCallback(
    async (order: Order[]): Promise<boolean> => {
      try {
        const resultAction = await dispatch(patchGuideFeaturedOrder(order));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const autoSaveGuidePagesOrder = useCallback(
    async (data: { order: Order[]; section_id: string }): Promise<boolean> => {
      try {
        const resultAction = await dispatch(patchGuidePagesOrder(data));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const autoSaveGuideCategoriesOrder = useCallback(
    async (data: { order: Order[]; section_id: string }): Promise<boolean> => {
      try {
        const resultAction = await dispatch(patchGuideCategoriesOrder(data));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const moveContent = useCallback(
    async (data: { page: IPage; newCategoryId: string }): Promise<boolean> => {
      try {
        const resultAction = await dispatch(movePageToNewCategory(data));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );
  const moveContentWithOrder = useCallback(
    async (data: {
      page: IPage;
      newCategoryId: string;
      contents_order?: Order[];
      categories_order?: Order[];
    }): Promise<boolean> => {
      try {
        const resultAction = await dispatch(movePageToNewCategoryWithOrder(data));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const autoSavePropertyGuideSectionsOrder = useCallback(
    async (data: { order: Order[]; propertyId: number }): Promise<boolean> => {
      try {
        const resultAction = await dispatch(patchPropertyGuideSectionsOrder(data));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const autoSavePropertyGuidePagesOrder = useCallback(
    async (data: { order: Order[]; section_id: string; propertyId: string | number }) => {
      try {
        const resultAction = await dispatch(patchPropertyGuidePagesOrder(data));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const autoSavePropertyGuideCategoriesOrder = useCallback(
    async (data: { order: Order[]; section_id: string; propertyId: string | number }) => {
      try {
        const resultAction = await dispatch(patchPropertyGuideCategoriesOrder(data));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const updateGPActivated = useCallback(
    async (payload: {
      property_ids: number[];
      status: 'active' | 'inactive';
    }): Promise<boolean> => {
      try {
        const resultAction = await dispatch(patchActivateGPUnit(payload));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );
  // manual save by button pressed
  const saveSection = useCallback(
    async (section: ISection): Promise<boolean> => {
      try {
        const resultAction = await dispatch(patchGuideSection(section));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const addSection = useCallback(
    async (section: ISection): Promise<boolean> => {
      try {
        const resultAction = await dispatch(postGuideSection(section));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const removeSection = useCallback(
    async (data: {
      section_id: string;
      category_id?: string;
      content_id?: string;
    }): Promise<boolean> => {
      try {
        // unwrapping will return the action payload.
        // should also throw error that we can inform the caller for success/fail
        const resultAction = await dispatch(patchGuideUnset(data));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const saveCategory = useCallback(
    async (category: ICategory): Promise<boolean> => {
      try {
        const submitCategory = { ...category, last_edit_at: getUnixTime(new Date()) };
        const resultAction = await dispatch(patchGuideCategory(submitCategory));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const savePropertyCategory = useCallback(
    async (propertyId: string, category: ICategory): Promise<boolean> => {
      try {
        category.last_edit_at = Date.now() / 1000;
        // unwrapping will return the action payload.
        // should also throw error that we can inform the caller for success/fail
        const resultAction = await dispatch(patchPropertyGuideCategory({ propertyId, category }));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        const metadata = { propertyId, category };
        OpertoLogger.Log(error);
        OpertoLogger.LeaveBreadCrumb('save property category', metadata, 'state');
        return false;
      }
    },
    [dispatch],
  );

  const addCategory = useCallback(
    async (category: ICategory): Promise<boolean> => {
      try {
        const resultAction = await dispatch(postGuideCategory(category));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  // const removeCategory = useCallback(
  //   async (data: {
  //     section_id: string;
  //     category_id: string;
  //     content_id: string;
  //   }): Promise<boolean> => {
  //     try {
  //       // unwrapping will return the action payload.
  //       // should also throw error that we can inform the caller for success/fail
  //       const resultAction = await dispatch(patchGuideCategory(data));
  //       unwrapResult<any>(resultAction);
  //       return true;
  //     } catch (error) {
  //       return false;
  //     }
  //   },
  //   [patchGuideSection],
  // );

  // manual save by button pressed
  const addPage = useCallback(
    async (page: IPage): Promise<boolean> => {
      try {
        if (!page.category_id) {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Please Select a Category',
              variant: SnackbarVariant.ERROR,
            }),
          );
          return false;
        }
        page.last_edit_at = Date.now() / 1000;
        // unwrapping will return the action payload.
        // should also throw error that we can inform the caller for success/fail
        const resultAction = await dispatch(postGuidePage(page));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const savePage = useCallback(
    async (page: IPage, sectionId: string): Promise<boolean> => {
      try {
        const submitPage = {
          ...page,
          section_id: sectionId,
          last_edit_at: getUnixTime(new Date()),
        };

        // unwrapping will return the action payload.
        // should also throw error that we can inform the caller for success/fail
        const resultAction = await dispatch(patchGuidePages(submitPage));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );
  const savePropertyPage = useCallback(
    async (propertyId: number, page: IPage, sectionId: string): Promise<boolean> => {
      try {
        page.section_id = sectionId;
        page.last_edit_at = getUnixTime(new Date());
        // unwrapping will return the action payload.
        // should also throw error that we can inform the caller for success/fail
        const resultAction = await dispatch(patchPropertyGuidePages({ propertyId, page }));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const savePropertySection = useCallback(
    async (propertyId: number, section: ISection): Promise<boolean> => {
      try {
        section.last_edit_at = Date.now() / 1000;
        // unwrapping will return the action payload.
        // should also throw error that we can inform the caller for success/fail
        const resultAction = await dispatch(patchPropertyGuideSection({ propertyId, section }));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  const saveEntranceInstructions = useCallback(
    async (entranceInstructions: IEntranceInstructions): Promise<boolean> => {
      try {
        const resultAction = await dispatch(patchEntranceInstructions(entranceInstructions));
        unwrapResult<any>(resultAction);
        return true;
      } catch (error) {
        return false;
      }
    },
    [dispatch],
  );

  // const removePage = useCallback(
  //   async (page_id: string): Promise<boolean> => {
  //     try {
  //       // unwrapping will return the action payload.
  //       // should also throw error that we can inform the caller for success/fail
  //       const resultAction = await dispatch(patchGuideUnset(section_id));
  //       unwrapResult<any>(resultAction);
  //       return true;
  //     } catch (error) {
  //       return false;
  //     }
  //   },
  //   [patchGuideSection],
  // );

  const uploadGuideImages = useCallback(
    async (imagesBase64: string[]): Promise<string[]> => {
      try {
        // unwrapping will return the action payload with all the image urls
        const resultAction = await dispatch(patchGuideImages(imagesBase64));
        return unwrapResult<any>(resultAction);
      } catch (error) {
        return [];
      }
    },
    [dispatch],
  );
  const uploadRandomImages = useCallback(
    async (imagesBase64: string[], imageType: ImageUploadType): Promise<string[]> => {
      try {
        // unwrapping will return the action payload with all the image urls
        const resultAction = await dispatch(patchRandomImages({ imagesBase64, imageType }));
        return unwrapResult<any>(resultAction);
      } catch (error) {
        return [];
      }
    },
    [dispatch],
  );

  return {
    fetchInfoData,
    fetchPropertyInfoData,
    fetchTermsData,
    fetchThemeData,
    fetchCustomText,
    fetchHostData,
    fetchPropertyCustomText,
    fetchPropertyHostData,
    fetchSettings,
    fetchGuideSections,
    fetchPropertyGuideSections,
    fetchGuidePages,
    fetchEntranceInstructions,
    enableGuestPortal,
    updateEnableGuestPortal,
    initializeGuestPortalForUnit,
    fetchPropertyGuideSectionsOrder,
    fetchGuidePagesOrder,
    fetchPropertyGuidePagesOrder,
    fetchGuideCategoriesOrder,
    fetchPropertyGuideCategoriesOrder,
    fetchPropertyGuidePages,
    fetchGuideCategories,
    fetchPropertyGuideCategories,
    fetchFeatures,
    fetchUnitFeatures,
    updateGPActivated,
    pages,
    pagesList,
    pagesOrder,
    addPage,
    sections,
    sectionsList,
    categories,
    categoriesList,
    categoriesOrder,
    saveCategory,
    addCategory,
    savePropertyCategory,
    saveEntranceInstructions,
    // removeCategory,
    guides,
    host,
    theme,
    address,
    customText,
    settings,
    entranceInstructions,
    entranceInstructionsData,
    fetchAssignedProperties,
    updateAssignedProperty,
    assignedProperties,
    assignedPropertiesRecords,
    assignedPropertyById,
    saveSection,
    savePropertySection,
    addSection,
    savePage,
    savePropertyPage,
    removeSection,
    uploadRandomImages,
    uploadGuideImages,
    generateGPPreview,
    gpLink,
    featured,
    featuredList,
    moveContent,
    moveContentWithOrder,
    setTheme: (theme: Theme) => {
      dispatch(setTheme(theme));
      autoSaveTheme(theme);
    },
    setHost: (host: Host) => {
      dispatch(setHost(host));
      autoSaveHost(host);
    },
    setCustomText: (text: CustomText) => {
      dispatch(setCustomText(text));
      autoSaveCustomText(text);
    },
    setPropertyAddress: (address: PropertyAddress) => {
      dispatch(setPropertyAddress(address));
      autoSavePropertyAddress(address);
    },
    setPropertyHost: (host: Host) => {
      dispatch(setPropertyHost(host));
      autoSavePropertyHost(host);
    },
    setPropertyCustomText: (propertyId: string, text: CustomText) => {
      dispatch(setPropertyCustomText(text));
      autoSavePropertyCustomText(propertyId, text);
    },
    setPropertyPropertyAddress: (address: PropertyAddress) => {
      dispatch(setPropertyPropertyAddress(address));
      autoSavePropertyPropertyAddress(address);
    },
    setSettings: (settings: Settings) => {
      dispatch(setSettings(settings));
      autoSaveSettings(settings);
    },
    setGPLink: useCallback(
      (gpLink: any) => {
        dispatch(setGPLink(gpLink));
        // autoSaveSettings(settings);
      },
      [dispatch],
    ),
    setGuideSectionsOrder: (data: { order: Order[] }) => {
      return autoSaveGuideSectionsOrder(data.order);
    },
    setGuideFeaturedOrder: (data: { order: Order[] }) => {
      return autoSaveGuideFeaturedOrder(data.order);
    },
    setGuidePagesOrder: (data: { order: Order[]; section_id: string }) => {
      return autoSaveGuidePagesOrder(data);
    },
    setGuideCategoriesOrder: (data: { order: Order[]; section_id: string }) => {
      return autoSaveGuideCategoriesOrder(data);
    },
    setPropertyGuideSectionsOrder: (data: { order: Order[]; propertyId: number }) => {
      return autoSavePropertyGuideSectionsOrder(data);
    },
    setPropertyGuidePagesOrder: (data: {
      order: Order[];
      section_id: string;
      propertyId: string | number;
    }) => {
      return autoSavePropertyGuidePagesOrder(data);
    },
    setPropertyGuideCategoriesOrder: (data: {
      order: Order[];
      section_id: string;
      propertyId: string | number;
    }) => {
      return autoSavePropertyGuideCategoriesOrder(data);
    },
  };
};
