import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import AvatarLogo from 'assets/img/icons/avatar.svg';
import ShieldLogo from 'assets/img/icons/shield.svg';
import { useTranslation } from 'hooks/useTranslation';
import React, { ReactNode } from 'react';
import { isAdmin } from 'redux/actions/ui';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { avatarStyle, chipStyle, stackStyle, statusStyle } from './useStyles';

export type IntegrationsCardActionType = 'CONNECT' | 'EDIT';

export type IntegrationsCardProps = {
  children: ReactNode;
  title: string;
  actionTitle?: string;
  onActionClick: (action: IntegrationsCardActionType) => void;
  onInfoClick?: () => void;
  onDelete?: () => void;
  onChange?: (enabled: boolean) => void;
  connected?: boolean;
  enabled?: boolean;
  loading?: boolean;
  disableToggle?: boolean;
  dataTestId?: string;
  hideConnectButton?: boolean;
};

export const IntegrationsCard = ({
  children,
  title,
  actionTitle,
  onActionClick,
  onInfoClick,
  onDelete,
  onChange,
  enabled = false,
  connected = false,
  loading,
  disableToggle,
  dataTestId,
  hideConnectButton,
}: IntegrationsCardProps) => {
  const showDeleteButton = isAdmin() && connected && !enabled && onDelete;

  const { t } = useTranslation();
  const getActionTitle = () => {
    if (actionTitle) {
      return actionTitle;
    }

    if (!connected) {
      return 'CONNECT';
    }

    if (isAdmin() && !enabled) {
      return 'EDIT';
    }

    return undefined;
  };

  const actionButtonTitle = getActionTitle();

  const renderClientChip = () => {
    const label = connected ? (enabled ? 'Enabled' : 'Disabled') : 'Disconnected';

    return (
      <Chip
        size='small'
        variant={connected ? 'filled' : 'outlined'}
        label={label}
        sx={chipStyle(label)}
      />
    );
  };

  return (
    <Paper
      sx={{
        height: '198px',
        maxWidth: '368px',
        minWidth: '310px',
        paddingY: '5px',
        paddingX: '16px',
      }}
      data-testid={dataTestId}
    >
      <LoadingContainer loading={loading}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='subtitle1'>{title}</Typography>
          <IconButton color='primary' onClick={onInfoClick}>
            <Tooltip title={t('guide_article')}>
              <InfoOutlined />
            </Tooltip>
          </IconButton>

          <Box flex={1} />

          {isAdmin() ? (
            <>
              <Switch
                disabled={!connected || disableToggle}
                checked={enabled}
                onChange={(_e, checked) => onChange(checked)}
                color='primary'
                data-testid='integration-switch'
              />

              <Typography variant='subtitle1' sx={statusStyle(connected)}>
                {connected && enabled ? 'Enabled' : 'Disabled'}
              </Typography>
              <ShieldLogo />
              <Box sx={avatarStyle}>
                <AvatarLogo />
              </Box>
            </>
          ) : (
            renderClientChip()
          )}
        </Stack>

        <Stack sx={stackStyle(connected)}>{children}</Stack>

        {actionButtonTitle && !hideConnectButton && (
          <Button
            size='small'
            onClick={() => onActionClick(actionButtonTitle as IntegrationsCardActionType)}
            data-testid='integration-button'
          >
            {actionButtonTitle}
          </Button>
        )}

        {showDeleteButton && (
          <Button color='error' size='small' onClick={onDelete}>
            Delete
          </Button>
        )}
      </LoadingContainer>
    </Paper>
  );
};
