import { Circle } from '@mui/icons-material';
import { Box, SxProps } from '@mui/material';
import { OpertoTheme } from 'App/OpertoTheme';
import { CompleteIcon } from 'assets/svgs/CompleteIcon';
import { InProgressIcon } from 'assets/svgs/InProgressIcon';
import React from 'react';

const DEFAULT_STATUSES = ['requested', 'reviewed', 'closed', 'scheduled', 'canceled', 'declined'];

interface StatusHeaderProps {
  status: string;
}

export const StatusHeader = ({ status }: StatusHeaderProps) => {
  return (
    <Box sx={iconContainerStyles} data-testid={`status-header-${status}`}>
      {status === 'in progress' && <InProgressIcon fill={OpertoTheme.statusColors[status]} />}
      {status === 'completed' && <CompleteIcon fill={OpertoTheme.statusColors[status]} />}
      {DEFAULT_STATUSES.includes(status) && <Circle sx={defaultIconStyles} />}
    </Box>
  );
};

const iconContainerStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 16,
  height: 16,
};

const defaultIconStyles: SxProps = {
  width: 16,
  height: 16,
  borderRadius: 8,
  color: OpertoTheme.statusColors['pending'],
};
