import { Add, ExpandMore, SellOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarButtonExpanded,
  TitlebarRoot,
  TitlebarTitleIcon,
} from '@operto/ui';
import { getOfferTemplates } from 'api/addOnAPI';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import GuestPortalGlobalPreview from 'Pages/GuestPortal/GuestPortalGlobalPreview';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UpsellTemplate } from 'redux/upsell-service/types';
import UpsellNewMenu, { MenuOptionType } from './UpsellNewMenu';
import UpsellTemplateCard from './UpsellTemplate/UpsellTemplateCard';
import UpsellTemplateModal from './UpsellTemplate/UpsellTemplateModal';

export default function UpsellItemsTitlebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const [offerTemplates, setOfferTemplates] = useState([]);
  const [gpOpen, setGpOpen] = useState(false);

  const fetchOfferTemplates = useCallback(async () => {
    try {
      const result = await getOfferTemplates();
      setOfferTemplates(result.data.data);
    } catch (error) {
      logger.error('Error fetching template upsell list', error);
    }
  }, []);

  useEffect(() => {
    fetchOfferTemplates();
  }, [fetchOfferTemplates]);

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('offers'),
    },
  ];

  const handleNewMenuClose = (selectedOption?: MenuOptionType) => {
    if (selectedOption === 'template') {
      setOpen(!open);
    } else if (selectedOption === 'new') {
      navigate(`/offers/${selectedOption}`);
    }

    setAnchorEl(null);
  };

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <SellOutlined />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)} />

      <Button onClick={() => setGpOpen(true)} variant='outlined'>
        {t('preview')}
      </Button>

      <TitlebarButtonExpanded onClick={e => setAnchorEl(e.currentTarget)}>
        <Add />
        {t('offer')}
        <ExpandMore />
      </TitlebarButtonExpanded>
      <UpsellNewMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        handleClose={handleNewMenuClose}
      />
      {offerTemplates && (
        <UpsellTemplateModal open={open} setOpen={setOpen}>
          {offerTemplates?.map((child: UpsellTemplate) => (
            <UpsellTemplateCard
              key={child.id}
              id={child.id}
              title={child.name}
              description={child.description}
              category={child.category}
              image={child.imageUrl}
              price={child.price}
            />
          ))}
        </UpsellTemplateModal>
      )}
      <GuestPortalGlobalPreview
        isOpen={gpOpen}
        onClose={() => {
          setGpOpen(false);
        }}
      />
    </TitlebarRoot>
  );
}
