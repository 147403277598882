import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Menu, MenuItem, SxProps, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Property, Variable } from '@operto/variables-shared';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import InlineTextEditor from 'Common/Templates/Editors/InlineTextEditor';
import SearchField from 'Common/TextField/SearchField';
import useTranslation from 'hooks/useTranslation';
import { trackEvent } from 'lib/analytics';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import styled from 'styled-components';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { TableCellWithTooltip } from 'ui-library/Components/table/TableCell';
import { SmartCardIconButton } from 'ui-library/Styled/smartcard';
import { ActionDataGrid } from 'ui-library/Styled/tables';
import { currentUserSelector } from 'user/state/userSelectors';
import PropertyName from '../Custom/PropertyName';
import {
  getTotalVariablePropertiesCount,
  roundToNearestWholeNumber,
} from '../helpers/VariableHelpers';
import useVariables from '../useVariables';
import VariablesMoreInfoTopBar from './VariablesMoreInfoTopBar';

const MAX_GROUP_NAME_LENGTH = 24;
export interface VariablesMoreInfoTableProps {
  variable: Variable;
  rows: Property;
  isEditable: boolean;
  onCloseMoreTable: () => void;
  onRefresh: () => void;
  systemVariables: Variable[];
  customVariables: Variable[];
  sx?: SxProps;
}

export const renderStringInEmptyCell = (value: string | null) => (value.length > 0 ? value : '-');

const VariablesMoreInfoTable = ({
  variable,
  systemVariables,
  customVariables,
  rows,
  isEditable,
  onCloseMoreTable,
  onRefresh,
  sx = {},
}: VariablesMoreInfoTableProps) => {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(currentUserSelector());
  const { t } = useTranslation();
  const [editSelectionList, setEditSelectionList] = useState([]);
  const [infoSearchValue, setInfoSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isHideValuesModalMode, setIsHideValuesModalMode] = useState(false);
  const [isGroupModalMode, setIsGroupModalMode] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [isEditVariableModalMode, setIsEditVariableModalMode] = useState(false);
  const [editValueTo, setEditValueTo] = useState('');
  const [isDeleteItemModalMode, setDeleteItemModalMode] = useState(false);
  const { updateVariable } = useVariables();

  const onUpdateVariable = (mutatedVariable: Variable) => {
    updateVariable({ ...mutatedVariable, updatedBy: currentUser?.name })
      .then(() => {
        onRefresh();
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: t('variable_update_success'),
            variant: SnackbarVariant.SUCCESS,
          }),
        );
      })
      .catch(() => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: t('variable_update_error'),
            variant: SnackbarVariant.ERROR,
          }),
        );
      });
  };

  const handleInfoSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInfoSearchValue(event?.currentTarget?.value);
  };

  const onEditSelection = (selectionList: string[]) => {
    setEditSelectionList(selectionList);
  };

  const renderValueCell = (params: GridRenderCellParams) => {
    const isDisabled = !params.row?.enabled;

    return (
      <TableCellWithTooltip
        sx={{
          ...variableValueSxProps,
          color: isDisabled ? 'text.disabled' : 'inherit',
        }}
        tooltipText={params.row.value}
        title={params.row.value}
      />
    );
  };

  const variableTableCol: GridColDef[] = [
    {
      field: 'name',
      headerName: t('unit_name'),
      flex: 3,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <PropertyName text={row.name} isDisabled={!row.enabled} isDeactivated={row.deactivated} />
        );
      },
    },
    {
      field: 'group',
      headerName: t('group'),
      flex: 3,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const isDisabled = !params.row?.enabled;

        return (
          <Typography
            data-testid='group-row-cell'
            fontSize={14}
            color={isDisabled ? 'text.disabled' : 'inherit'}
          >
            {renderStringInEmptyCell(params.row.group)}
          </Typography>
        );
      },
    },
    {
      field: 'value',
      headerName: t('value'),
      flex: 3,
      type: 'string',
      sortable: false,
      renderCell: renderValueCell,
      editable: isEditable,
    },
  ];

  const handleHideShow = (isShowing: boolean) => {
    const variableCopy = { ...variable };
    const variablePropertiesCopy = { ...rows };

    editSelectionList.forEach((propertyId: string) => {
      variablePropertiesCopy[propertyId].enabled = isShowing;
    });

    variableCopy.properties = variablePropertiesCopy;

    onUpdateVariable(variableCopy);

    trackEvent({
      screen: 'Variables',
      event: 'EDITED',
      feature: isShowing ? 'Bulk Properties Enabled' : 'Bulk Properties Disabled',
      averagePropertySelectionCount: roundToNearestWholeNumber(
        editSelectionList.length / getTotalVariablePropertiesCount(variableCopy),
      ),
    });

    closeHideModal();
    setEditSelectionList([]);
  };

  const handleSetGroup = () => {
    const variableCopy = { ...variable };
    const variablePropertiesCopy = { ...rows };

    editSelectionList.forEach((propertyId: string) => {
      variablePropertiesCopy[propertyId].group = newGroupName;
    });

    onUpdateVariable(variableCopy);
    trackEvent({
      screen: 'Variables',
      event: 'EDITED',
      feature: 'Bulk Properties Group',
      averagePropertySelectionCount: roundToNearestWholeNumber(
        editSelectionList.length / getTotalVariablePropertiesCount(variableCopy),
      ),
    });
    setNewGroupName('');
    closeGroupModal();
    setEditSelectionList([]);
  };

  const handleVariableSelectionEdit = () => {
    const variableCopy = { ...variable };
    const variablePropertiesCopy = { ...rows };
    editSelectionList.forEach((propertyId: string) => {
      variablePropertiesCopy[propertyId].value = editValueTo;
    });
    variableCopy.properties = variablePropertiesCopy;

    onUpdateVariable(variableCopy);
    trackEvent({
      screen: 'Variables',
      event: 'EDITED',
      feature: 'Bulk Properties Value',
      averagePropertySelectionCount: roundToNearestWholeNumber(
        editSelectionList.length / getTotalVariablePropertiesCount(variableCopy),
      ),
    });
    setEditValueTo('');
    setIsEditVariableModalMode(false);
  };

  const handleVariableInlineUpdate = (propertyId: string, value: string) => {
    const variableCopy = { ...variable };
    const variablePropertiesCopy = { ...rows };

    variablePropertiesCopy[propertyId].value = value;
    variableCopy.properties = variablePropertiesCopy;

    onUpdateVariable(variableCopy);
    trackEvent({
      screen: 'Variables',
      event: 'EDITED',
      feature: ' Inline Value',
    });
  };

  const handleOnDeleteItem = (propertyIdList: string[]) => {
    const variableCopy = { ...variable };
    const variablePropertiesCopy = { ...rows };

    for (const propertyId of propertyIdList) {
      delete variablePropertiesCopy[propertyId];
    }
    variableCopy.properties = variablePropertiesCopy;

    onUpdateVariable(variableCopy);
    trackEvent({
      screen: 'Variables',
      event: 'EDITED',
      feature: ' Deleted Value',
    });
    setDeleteItemModalMode(false);
    setEditSelectionList([]);
  };

  const closeHideModal = () => {
    setIsHideValuesModalMode(false);
    setAnchorEl(null);
  };

  const closeGroupModal = () => {
    setIsGroupModalMode(false);
    setAnchorEl(null);
    setNewGroupName('');
  };

  const infoData = Object.keys(rows)
    .map((propertyId: string) => rows[propertyId])
    .filter(infoRow => infoRow.name?.toLowerCase().includes(infoSearchValue.toLowerCase()));

  return (
    <>
      <ConfirmDialog
        data-testid='hide-properties-modal'
        open={isHideValuesModalMode}
        title={t('hide_value')}
        submitButtonColor='primary'
        onSubmit={() => handleHideShow(false)}
        onCancel={closeHideModal}
        submitButtonText={t('confirm')}
      >
        <Typography>{t('confirm_sure_to_hide_units')}</Typography>
      </ConfirmDialog>
      <ConfirmDialog
        data-testid='set-group-modal'
        open={isGroupModalMode}
        title={t('set_group')}
        submitButtonColor='primary'
        onSubmit={handleSetGroup}
        onCancel={closeGroupModal}
        submitButtonText={t('save')}
      >
        <TextField
          data-testid='set-group-input'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target?.value;
            if (value.length <= MAX_GROUP_NAME_LENGTH) {
              setNewGroupName(value);
            }
          }}
          value={newGroupName}
          label={t('enter_group')}
          sx={{ width: '97%' }}
          helperText={t('variable_group_character_limit', {
            limit: `${newGroupName.length}/${MAX_GROUP_NAME_LENGTH}`,
          })}
        />
      </ConfirmDialog>
      <ConfirmDialog
        open={isEditVariableModalMode}
        title={t('enter_variable_value')}
        submitButtonColor='primary'
        onSubmit={handleVariableSelectionEdit}
        onCancel={() => {
          setIsEditVariableModalMode(false);
          setEditValueTo('');
        }}
        submitButtonText={t('save')}
      >
        <InlineTextEditor
          style={{ width: '535px', minHeight: '57px' }}
          value={editValueTo}
          onTextChange={text => {
            setEditValueTo(text);
          }}
          showScriptButton={false}
          disableToolbar
        />
      </ConfirmDialog>
      <ConfirmDialog
        data-testid='delete-properties-modal'
        open={isDeleteItemModalMode}
        title={t('delete_value')}
        submitButtonColor='primary'
        onSubmit={() => handleOnDeleteItem(editSelectionList)}
        onCancel={() => setDeleteItemModalMode(false)}
        submitButtonText={t('confirm')}
      >
        <Typography>{t('confirm_sure_to_delete_units')}</Typography>
      </ConfirmDialog>
      <Box sx={{ minHeight: 400, height: 400, width: '60%', ...sx }}>
        <Card
          sx={{
            height: '100%',
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <CardContent sx={{ height: '100%' }}>
            <TopBarContainer>
              <VariablesMoreInfoTopBar
                variable={variable}
                systemVariables={systemVariables}
                customVariables={customVariables}
                isEditable={isEditable}
                onCloseMoreTable={onCloseMoreTable}
              />
            </TopBarContainer>
            <Stack direction='column' height={'100%'}>
              <Stack
                direction='row'
                style={{
                  marginTop: 20,
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {editSelectionList.length > 0 && isEditable && (
                  <Box style={{ alignSelf: 'center' }}>
                    <Button
                      data-testid='edit-value-button'
                      startIcon={<EditIcon />}
                      onClick={() => {
                        setIsEditVariableModalMode(true);
                      }}
                      variant='contained'
                      style={{ height: 37 }}
                    >
                      {t('var_prop_action_edit_value')}
                    </Button>
                    <SmartCardIconButton
                      data-testid='more-button'
                      $show
                      size='small'
                      style={{ marginLeft: 5 }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreHorizIcon />
                    </SmartCardIconButton>
                    <Menu
                      data-testid='more-info-menu'
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem
                        data-testid='show-value-button'
                        onClick={() => handleHideShow(true)}
                      >
                        {t('var_prop_action_display_value')}
                      </MenuItem>
                      <MenuItem
                        data-testid='hide-value-button'
                        onClick={() => {
                          setIsHideValuesModalMode(true);
                        }}
                      >
                        {t('var_prop_action_hide_value')}
                      </MenuItem>
                      <MenuItem
                        data-testid='set-group-button'
                        onClick={() => {
                          setIsGroupModalMode(true);
                        }}
                      >
                        {t('var_prop_action_set_group')}
                      </MenuItem>
                      <MenuItem
                        data-testid='delete-item-button'
                        onClick={() => setDeleteItemModalMode(true)}
                      >
                        {t('var_prop_action_delete_value')}
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
                {editSelectionList.length === 0 && (
                  <Typography variant='body2' style={{ alignSelf: 'center' }}>
                    {t('select_units_to_edit')}
                  </Typography>
                )}
                <CustomSearchField
                  size='small'
                  onChange={handleInfoSearch}
                  value={infoSearchValue}
                  key='search-field'
                />
              </Stack>
              <Box style={{ height: '100%', padding: '0 0 32px 0' }}>
                <CustomActionDataGrid
                  disableColumnMenu
                  hideFooterRowCount
                  sx={{ border: 0, height: '100%' }}
                  rows={infoData}
                  columns={variableTableCol}
                  checkboxSelection={isEditable}
                  selectionModel={editSelectionList}
                  disableSelectionOnClick
                  onSelectionModelChange={onEditSelection}
                  onCellEditCommit={params => {
                    handleVariableInlineUpdate(params.id.toString(), params.value.toString());
                  }}
                />
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

const TopBarContainer = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const CustomActionDataGrid = styled(ActionDataGrid)`
  && {
    .MuiDataGrid-row .MuiDataGrid-cellCheckbox:first-child {
      padding: 0;
    }
  }
`;

const CustomSearchField = styled(SearchField)`
  && {
    .MuiFormControl-root css-e363es-MuiFormControl-root {
      margin: 0 !important;
    }
  }
`;

const variableValueSxProps: SxProps = {
  alignItems: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export default VariablesMoreInfoTable;
