import { Stack, Typography } from '@mui/material';
import { TemplateDataType, TriggerDataType, WorkflowDataType } from '@operto/communications-shared';
import { nanoid } from '@reduxjs/toolkit';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { Loading } from 'Common/Loading';
import { companySelector } from 'company/state/companySelectors';
import { TriggerType, TriggerTypeOption, useAutomate } from 'hooks/useAutomate';
import useCommunications from 'hooks/useCommunications';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import { logger } from 'lib/logger';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { INITIAL_STATE, useGetUnitsQuery } from 'redux/units/api-slice';
import { UnitFilterType } from 'redux/units/types';
import AutomateDeleteConfirmDialog from '../AutomateDeleteConfirmDialog';
import AutomateSaveConfirmDialog, {
  DIALOG_ACTION_TYPE,
  PRIMARY_BUTTON_ACTION,
  SECONDARY_BUTTON_ACTION,
  TDialogActionType,
} from '../AutomateSaveConfirmDialog';
import useTriggerSorting from '../useTriggerSorting';
import AutomateWorkflowDiagram from './AutomateWorkflowDiagram';
import AutomateWorkflowTitlebar from './AutomateWorkflowTitlebar';
import AutomateWorkflowTriggerPanel from './AutomateWorkflowTriggerPanel';
import AutomateWorkflowTriggerPanelCheckInOnly from './AutomateWorkflowTriggerPanelCheckInOnly';
import AutomateWorkflowUnits from './AutomateWorkflowUnits';

export default function AutomateWorkflowPage() {
  const { workflowId } = useParams();
  const {
    workflows,
    createWorkflow,
    updateWorkflow,
    automateRefetch,
    isLoading: isAutomateLoading,
  } = useAutomate();
  const { data: templates, isLoading: isTemplatesLoading } = useCommunications();
  const { t } = useTranslation();
  type translationKeyType = Parameters<typeof t>[0];
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const company = useAppSelector(companySelector());
  const { isFeatureEnabled } = useAppFeatures();
  const checkOutTriggersEnabled = isFeatureEnabled('communications-check-out-trigger', company.id);
  const MAX_TRIGGERS = checkOutTriggersEnabled ? 4 : 2;

  const { data: units = INITIAL_STATE, isFetching: unitsFetching } = useGetUnitsQuery({
    filterType: UnitFilterType.ALL_UNITS,
    numPerPage: 100000,
  });

  const [workflow, setWorkflow] = useState<WorkflowDataType>({
    name: initialName,
    enabled: false,
    conditions: {
      selectedProperties: [],
    },
    triggers: [],
  });

  const [errorName, setErrorName] = useState<string>();
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [assignToUnitsError, setAssignToUnitsError] = useState(false);
  const [selectedTriggerIndex, setSelectedTriggerIndex] = useState<number>();
  const [selectedTriggerId, setSelectedTriggerId] = useState<string>();
  const [errorDialog, setErrorDialog] = useState<{
    titleKey: translationKeyType;
    bodyKey: translationKeyType;
  }>();

  const isCreate = pathname.includes('create');
  const [showTriggerError, setShowTriggerError] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState(isCreate);
  const [showSaveConfirmDialog, setShowSaveConfirmDialog] = useState<boolean>(false);
  const [saveConfirmDialogAction, setSaveConfirmDialogAction] = useState<TDialogActionType>(
    DIALOG_ACTION_TYPE.NONE,
  );

  const invalidWorkflow = !!errorName;
  const selectedUnits = (workflow.conditions?.selectedProperties || []) as number[];
  const triggerCount = (workflow.triggers?.length || 0) as number;

  const sortedTriggers = useTriggerSorting(workflow.triggers);

  const atTriggers = [TriggerTypeOption.CHECK_IN, TriggerTypeOption.CHECK_OUT];
  const isAtTrigger = (trigger: TriggerDataType) => atTriggers.includes(trigger.type);
  const noSchedule = (trigger: TriggerDataType) => trigger.offset === 0;

  const hasTriggersNoSchedule = sortedTriggers.some(
    (trigger: TriggerDataType) => !isAtTrigger(trigger) && noSchedule(trigger),
  );
  const hasTriggersNoTemplate = sortedTriggers.some(
    (trigger: TriggerDataType) =>
      trigger.templates.sms.length === 0 && trigger.templates.email.length === 0,
  );
  const hasInvalidTriggers = hasTriggersNoSchedule || hasTriggersNoTemplate || !triggerCount;
  const isLoading = isAutomateLoading || isTemplatesLoading;

  // Store initial state
  const [initialState, setInitialState] = useState(null);
  useEffect(() => {
    if (!initialState && !isDirty) {
      const transformedWorkflow = {
        ...workflow,
        triggers: workflow.triggers.map((trigger: TriggerDataType) => ({
          ...trigger,
          templates: {
            email: trigger.templates.email.map((template: TemplateDataType) => template.id),
            sms: trigger.templates.sms.map((template: TemplateDataType) => template.id),
          },
        })),
      };
      setInitialState(transformedWorkflow);
    }
  }, [initialState, isDirty, workflow]);

  // Function to check if the current state matches the initial state
  // useCallback hook to prevent useEffect hook from running unnecessarily on every render.
  const isStateChanged = useCallback(
    (currentState: WorkflowDataType) => {
      return !isEqual(initialState, currentState);
    },
    [initialState],
  );

  const markDirty =
    (handler: (workflow: WorkflowDataType, ...args: unknown[]) => WorkflowDataType) =>
    (...args: unknown[]) => {
      setWorkflow((prevWorkflow: WorkflowDataType): WorkflowDataType => {
        const updatedWorkflow: WorkflowDataType = handler(prevWorkflow, ...args);
        const transformedWorkflow = transformWorkflow(updatedWorkflow);
        setIsDirty(isStateChanged(transformedWorkflow));
        return updatedWorkflow;
      });
    };

  const transformWorkflow = (workflow: WorkflowDataType) => ({
    ...workflow,
    conditions: {
      ...workflow.conditions,
      selectedProperties: workflow.conditions?.selectedProperties || [],
    },
    triggers: workflow?.triggers.map((trigger: TriggerDataType) => ({
      ...trigger,
      templates: {
        email: trigger.templates.email.map((template: TemplateDataType) => template.id),
        sms: trigger.templates.sms.map((template: TemplateDataType) => template.id),
      },
    })),
  });

  const validateWorkflow = () => {
    if (selectedUnits.length === 0) {
      setAssignToUnitsError(true);
      return false;
    }

    if (hasInvalidTriggers) {
      if (triggerCount) {
        setShowTriggerError(sortedTriggers.map((_: unknown, i: number) => i));

        setErrorDialog({
          titleKey: 'required_fields_are_missing',
          bodyKey: 'please_update_your_flow_to_run_the_template',
        });

        return false;
      }

      setErrorDialog({
        titleKey: 'event_trigger_required',
        bodyKey: 'at_least_one_trigger_to_activate',
      });

      return false;
    }

    return true;
  };

  const saveWorkflow = async (updates?: Partial<WorkflowDataType>) => {
    let newRecord = undefined;
    setShowSaveConfirmDialog(false);
    setIsSaving(true);

    try {
      const newWorkflow = {
        ...workflow,
        ...updates,
        triggers: sortedTriggers.map((trigger: TriggerDataType) => ({
          ...trigger,
          id: trigger.id.startsWith('id-') ? undefined : trigger.id,
          templates: {
            sms: trigger.templates.sms.map((template: TemplateDataType) => template.id),
            email: trigger.templates.email.map((template: TemplateDataType) => template.id),
          },
        })),
      };

      newRecord = await (isCreate ? createWorkflow : updateWorkflow)(newWorkflow);
      await automateRefetch();
      setIsDirty(false);
      return newRecord;
    } catch (error) {
      logger.debug(error);
      snackbar(t('error_saving'));
    }

    setIsSaving(false);
    setShowTriggerError([]);
    setAssignToUnitsError(false);

    return newRecord;
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteSuccess = () => {
    setIsDeleteDialogOpen(false);
    navigate('/automate');
    snackbar(t('deleted'));
  };

  const handleDeleteFailure = (error: Error) => {
    logger.error(error);
  };

  const handleSaveConfirmDialogCancel = (source: string) => {
    if (source === SECONDARY_BUTTON_ACTION.SAVE) {
      handleSaveConfirmDialogSuccess(source);
    }
    handleSaveConfirmDialogClose();
  };

  const handleSaveConfirmDialogClose = () => {
    setShowSaveConfirmDialog(false);
    setSaveConfirmDialogAction(DIALOG_ACTION_TYPE.NONE);
  };

  const handleSaveConfirmDialogSuccess = async (source: string) => {
    const isSaveAndActivate = source === PRIMARY_BUTTON_ACTION.SAVE_AND_ACTIVATE;
    if (isSaveAndActivate && !validateWorkflow()) {
      setIsSaving(false);
      setShowSaveConfirmDialog(false);
      return;
    }

    const active = workflow.enabled;
    const enabled = isSaveAndActivate;

    const newRecord = await saveWorkflow({ enabled: enabled });
    if (!newRecord) {
      return;
    }

    setEditMode(false);
    setInitialState(null);
    setIsSaving(false);

    if (isCreate) {
      navigate(`/automate/edit/${workflow.id || newRecord.id}`);
    }

    // enable workflow
    if (active !== enabled) {
      setWorkflow((workflow: WorkflowDataType) => ({ ...workflow, enabled: enabled }));
    }
    // show snackbar
    if (active !== enabled) {
      snackbar(enabled ? t('activated') : t('deactivated'));
    } else {
      snackbar(t('changes_saved'));
    }
  };

  const validateNameChange = useCallback(
    (name: string) => {
      const trimmedName = name.trim();

      if (workflows?.some(c => c.name.trim() === trimmedName && c.id !== workflow.id)) {
        setErrorName(t('name_taken'));
        return false;
      }

      if (trimmedName.length === 0) {
        setErrorName(t('name_required'));
        return false;
      }

      if (trimmedName.length > MAX_NAME_LENGTH) {
        setErrorName(`${trimmedName.length}/${MAX_NAME_LENGTH}`);
        return false;
      }

      setErrorName(undefined);
      return true;
    },
    [workflows, workflow.id, t],
  );

  const handleSaveConfirm = async () => {
    // if failed validation, show errors and do not proceed
    if (!validateNameChange(workflow.name) || invalidWorkflow) {
      snackbar(t('error_saving'));
      return;
    }

    if (isDirty && !showSaveConfirmDialog) {
      setSaveConfirmDialogAction(DIALOG_ACTION_TYPE.SAVE);
      setShowSaveConfirmDialog(true);
      return;
    }

    const newRecord = await saveWorkflow();
    if (!newRecord) {
      return;
    }

    setEditMode(false);
    setInitialState(null);
    setIsSaving(false);

    navigate(`/automate/edit/${workflow.id || newRecord.id}`);
    snackbar(t('changes_saved'));
  };

  const handleEnableWorkflowClick = async () => {
    if (!validateWorkflow()) {
      return;
    }

    if (!showSaveConfirmDialog) {
      setSaveConfirmDialogAction(DIALOG_ACTION_TYPE.SWITCH);
      setShowSaveConfirmDialog(true);
      return;
    }

    const newRecord = await saveWorkflow({ enabled: !workflow.enabled });
    if (!newRecord) {
      return;
    }

    snackbar(!workflow.enabled ? t('activated') : t('deactivated'));
    setWorkflow((workflow: WorkflowDataType) => ({ ...workflow, enabled: !workflow.enabled }));
  };

  const handleSelectingUnits = useCallback(
    (units: number[]) => {
      setAssignToUnitsError(false); // Set error state directly inside markDirty

      setWorkflow((prevWorkflow: WorkflowDataType): WorkflowDataType => {
        const updatedWorkflow: WorkflowDataType = {
          ...prevWorkflow,
          enabled: false,
          conditions: {
            ...prevWorkflow.conditions,
            selectedProperties: units, // Update selectedProperties with the new units
          },
        };
        const transformedWorkflow = transformWorkflow(updatedWorkflow);
        setIsDirty(isStateChanged(transformedWorkflow));
        return updatedWorkflow;
      });
    },
    [isStateChanged],
  );

  // Generate a temporary unique ID using nanoid with 7 alphanumeric characters.
  const generateTemporaryId = () => `id-${nanoid(7)}`;
  const handleClickBeforeCheckIn = markDirty((workflow: WorkflowDataType) => {
    const tempId = generateTemporaryId();
    setSelectedTriggerId(tempId);

    return {
      ...workflow,
      enabled: false,
      triggers: [
        ...workflow.triggers,
        {
          id: tempId,
          type: TriggerTypeOption.BEFORE_CHECK_IN,
          offset: 0,
          templates: {
            sms: [],
            email: [],
          },
        },
      ],
    };
  });

  const handleClickBeforeCheckOut = markDirty((workflow: WorkflowDataType) => {
    const tempId = generateTemporaryId();
    setSelectedTriggerId(tempId);

    return {
      ...workflow,
      enabled: false,
      triggers: [
        ...workflow.triggers,
        {
          id: tempId,
          type: TriggerTypeOption.BEFORE_CHECK_OUT,
          offset: 0,
          templates: {
            sms: [],
            email: [],
          },
        },
      ],
    };
  });

  const handleClickedAfterCheckIn = markDirty((workflow: WorkflowDataType) => {
    const tempId = generateTemporaryId();
    setSelectedTriggerId(tempId);

    return {
      ...workflow,
      enabled: false,
      triggers: [
        ...workflow.triggers,
        {
          id: tempId,
          type: TriggerTypeOption.CHECK_IN,
          offset: 0,
          templates: {
            sms: [],
            email: [],
          },
        },
      ],
    };
  });

  const handleClickedAfterCheckOut = markDirty((workflow: WorkflowDataType) => {
    const tempId = generateTemporaryId();
    setSelectedTriggerId(tempId);

    return {
      ...workflow,
      enabled: false,
      triggers: [
        ...workflow.triggers,
        {
          id: tempId,
          type: TriggerTypeOption.CHECK_OUT,
          offset: 0,
          templates: {
            sms: [],
            email: [],
          },
        },
      ],
    };
  });

  const handleDeleteTrigger = markDirty((workflow: WorkflowDataType) => {
    const newTriggers = [...workflow.triggers];
    const index = newTriggers.findIndex(trigger => trigger.id === selectedTriggerId);
    setSelectedTriggerId(index > 0 ? newTriggers[index - 1].id : undefined);
    setSelectedTriggerIndex(undefined);
    newTriggers.splice(index, 1);

    return {
      ...workflow,
      enabled: false,
      triggers: newTriggers,
    };
  });

  const handleOffsetChange = markDirty((workflow: WorkflowDataType, offset: number) => {
    const newTriggers = [...workflow.triggers];
    const index = newTriggers.findIndex(trigger => trigger.id === selectedTriggerId);
    setSelectedTriggerIndex(index);
    if (index !== -1) {
      newTriggers[index].offset = offset;
    }

    return {
      ...workflow,
      enabled: false,
      triggers: newTriggers,
    };
  });

  const handleTemplateChange = markDirty(
    (workflow: WorkflowDataType, channelType: string, template?: TemplateDataType) => {
      const newTriggers = [...workflow.triggers];
      const index = newTriggers.findIndex(trigger => trigger.id === selectedTriggerId);
      setSelectedTriggerIndex(index);

      if (index !== -1) {
        if (channelType === 'email') {
          newTriggers[index].templates.email = template ? [template] : [];
        }

        if (channelType === 'sms') {
          newTriggers[index].templates.sms = template ? [template] : [];
        }
      }

      return {
        ...workflow,
        enabled: false,
        triggers: newTriggers,
      };
    },
  );

  const handleTypeChange = markDirty((workflow: WorkflowDataType, newType: TriggerType) => {
    const newTriggers = [...workflow.triggers];
    const index = newTriggers.findIndex(trigger => trigger.id === selectedTriggerId);
    setSelectedTriggerIndex(index);
    if (index !== -1) {
      newTriggers[index].type = newType;
    }

    return {
      ...workflow,
      enabled: false,
      triggers: newTriggers,
    };
  });

  useEffect(() => {
    // Set selected units with all units as default
    if (isCreate && units.units.length > 0) {
      handleSelectingUnits(units.units.map(unit => unit.id));
    }
  }, [units, handleSelectingUnits, isCreate]);

  useEffect(() => {
    const loadWorkflow = async () => {
      if (!isCreate && templates && workflows && workflowId && !workflow.id) {
        const currentWorkflow = workflows?.find(
          (workflow: WorkflowDataType) => workflow.id === workflowId,
        );

        setWorkflow((workflow: WorkflowDataType) => ({
          ...workflow,
          ...(currentWorkflow || {}),
          triggers: currentWorkflow?.triggers.map((trigger: TriggerDataType) => ({
            ...trigger,
            templates: {
              sms:
                trigger.templates?.sms?.map((templateId: string) =>
                  templates.find(t => t.id === templateId),
                ) || [],
              email:
                trigger.templates?.email?.map((templateId: string) =>
                  templates.find(t => t.id === templateId),
                ) || [],
            },
          })),
        }));

        // Set initial state after loading workflow
        const transformedWorkflow = {
          ...currentWorkflow,
          triggers: currentWorkflow.triggers.map((trigger: TriggerDataType) => ({
            ...trigger,
          })),
        };
        setInitialState(transformedWorkflow);
      }
    };

    loadWorkflow();
  }, [templates, isCreate, workflowId, workflows, workflow.id]);

  if (!isCreate && isLoading) {
    return <Loading />;
  }

  return (
    <>
      <AutomateWorkflowTitlebar
        title={workflow.name}
        onTitleChange={markDirty((workflow: WorkflowDataType, name: string) => {
          validateNameChange(name);
          return { ...workflow, name, enabled: false };
        })}
        onSaveClick={handleSaveConfirm}
        onDeleteClick={handleDeleteClick}
        deleteDisabled={!workflow.id}
        saveDisabled={invalidWorkflow || isSaving || !isDirty}
        helperText={errorName}
        editMode={editMode}
        setEditMode={setEditMode}
        onEnableWorkflowClick={handleEnableWorkflowClick}
        isSaving={isSaving}
        isWorkflowEnabled={workflow.enabled && !hasInvalidTriggers}
      />

      <AutomateWorkflowUnits
        selectedUnits={selectedUnits}
        setSelectedUnits={handleSelectingUnits}
        assignToUnitsError={assignToUnitsError}
        units={units}
        unitsFetching={unitsFetching}
      />

      <Stack sx={{ flexDirection: 'row', gap: '24px' }}>
        <AutomateWorkflowDiagram
          triggers={sortedTriggers}
          onClickBeforeCheckIn={handleClickBeforeCheckIn}
          onClickAfterCheckIn={handleClickedAfterCheckIn}
          onClickBeforeCheckOut={handleClickBeforeCheckOut}
          onClickAfterCheckOut={handleClickedAfterCheckOut}
          hideAdd={triggerCount >= MAX_TRIGGERS}
          selectedCardId={selectedTriggerId}
          onCardClick={id => {
            const triggerIndex = sortedTriggers.findIndex(trigger => trigger.id === id);
            const trigger = sortedTriggers[triggerIndex];
            setSelectedTriggerId(trigger.id);
            setSelectedTriggerIndex(triggerIndex);
          }}
          showTriggerError={showTriggerError}
        />

        {selectedTriggerId !== undefined &&
          (checkOutTriggersEnabled ? (
            <AutomateWorkflowTriggerPanel
              onClose={() => setSelectedTriggerId(undefined)}
              onDelete={handleDeleteTrigger}
              trigger={
                sortedTriggers.find(trigger => trigger.id === selectedTriggerId) || [
                  {
                    offset: 0,
                  },
                ]
              }
              onOffsetChange={handleOffsetChange}
              onTypeChange={handleTypeChange}
              onTemplateChange={handleTemplateChange}
              showErrorMessages={showTriggerError.some((i: number) => i === selectedTriggerIndex)}
            />
          ) : (
            <AutomateWorkflowTriggerPanelCheckInOnly
              onClose={() => setSelectedTriggerIndex(undefined)}
              onDelete={handleDeleteTrigger}
              trigger={
                sortedTriggers.find(trigger => trigger.id === selectedTriggerId) || [
                  {
                    offset: 0,
                  },
                ]
              }
              onOffsetChange={handleOffsetChange}
              onTypeChange={handleTypeChange}
              onTemplateChange={handleTemplateChange}
              showErrorMessages={showTriggerError.some((i: number) => i === selectedTriggerIndex)}
            />
          ))}
      </Stack>

      <ConfirmDialog
        open={!!errorDialog}
        onSubmit={() => setErrorDialog(undefined)}
        title={
          <Typography variant='h3-700' sx={{ padding: '16px 24px' }}>
            {errorDialog ? t(errorDialog?.titleKey) : ''}
          </Typography>
        }
        submitButtonText={'OK'}
        rootStyles={{ '& div[role=dialog]': { maxWidth: '444px', borderRadius: '10px' } }}
        contentStyles={{ padding: '8px 24px' }}
        actionsStyles={{ padding: '8px 16px 16px 8px' }}
      >
        <Typography variant='body-lg-400'>{errorDialog ? t(errorDialog?.bodyKey) : ''}</Typography>
      </ConfirmDialog>

      {isDeleteDialogOpen && (
        <AutomateDeleteConfirmDialog
          workflowId={workflowId}
          onClose={handleDeleteClose}
          onSuccess={handleDeleteSuccess}
          onFailure={handleDeleteFailure}
        />
      )}

      {showSaveConfirmDialog && (
        <AutomateSaveConfirmDialog
          active={workflow.enabled}
          isDirty={isDirty}
          actionType={saveConfirmDialogAction}
          onClose={handleSaveConfirmDialogClose}
          onCancel={handleSaveConfirmDialogCancel}
          onSuccess={handleSaveConfirmDialogSuccess}
        />
      )}
    </>
  );
}

const initialName = 'Untitled flow';
const MAX_NAME_LENGTH = 50;
