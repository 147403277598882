import { DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import { deleteOffer } from 'api/addOnAPI';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import * as React from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

export interface DeleteOfferDialogProps {
  companyId: number;
  offerId: string;
  offerName: string;
  onClose?: () => void;
  onDelete: () => void;
  open: boolean;
}

const DeleteOfferDialog = ({
  companyId,
  offerId,
  offerName,
  onClose,
  onDelete,
  open,
}: DeleteOfferDialogProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const deleteOfferClicked = async () => {
    try {
      setLoading(true);
      const result = await deleteOffer(companyId, offerId);

      if (result.status === 200) {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: t('changes_saved'),
            variant: SnackbarVariant.SUCCESS,
          }),
        ),
          onDelete();
      } else {
        const errorMessage = `Failed to delete offer, status: ${result.status}`;
        logger.error(`errorMessage: ${errorMessage}, responseBody: ${result.data.body}`);
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: t('failed_to_delete_offer'),
            variant: SnackbarVariant.ERROR,
          }),
        );
      }
      onClose();
    } catch (error) {
      logger.error(`Failed to delete offer, ${error}`);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: t('failed_to_delete_offer'),
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  return (
    <ConfirmDialog
      open={open}
      onCancel={onClose}
      loading={loading}
      submitButtonText={t('delete')}
      onSubmit={deleteOfferClicked}
      title={
        <DialogTitle sx={{ padding: '16px 24px 16px 24px' }}>
          <Typography variant='h3-700'>{t('delete_offer')}</Typography>
        </DialogTitle>
      }
      contentStyles={{ padding: '16px 24px 0px 24px' }}
      rootStyles={{ '& div[role=dialog]': { maxWidth: '444px' } }}
      actionsStyles={{
        padding: '16px 16px 16px 16px',
        '& button': { textTransform: 'capitalize' },
      }}
    >
      <Typography variant='body1'>{t('this_will_delete') + offerName + "'."}</Typography>
    </ConfirmDialog>
  );
};

export default DeleteOfferDialog;
