import { NotifiableError } from '@bugsnag/js';
import * as api from 'api/eventAPI';
import { AxiosResponse } from 'axios';
import { EventDict, IEvent } from 'event/eventType';
import { OpertoLogger } from 'Logger/logger';
import { ApplicationState } from 'redux/reducers';
import { AppDispatch } from 'redux/store';
import { Actions } from 'types/actions';

export const getPropertyEvents =
  (propertyId: number, activityType = 'all', pageNum = 0, numPerPage = 50) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    return api
      .getEventsByPropertyId(propertyId, activityType, pageNum, numPerPage, true)
      .then((data: AxiosResponse) => {
        const events = data.data.data;

        if (events?.records?.length > 0) {
          const eventList: number[] = [];
          const eventDict: EventDict = {};
          const existingEventDict: EventDict = getState().events.byId;
          events.records.forEach((each: IEvent) => {
            if (!existingEventDict[each.id]) {
              eventList.push(each.id);
            }
            each.activityType = activityType;
            eventDict[each.id] = each;
          });
          dispatch({
            type: Actions.bulkAddEvents,
            eventList,
            eventDict,
          });
        }
        dispatch({
          type: Actions.updateEventsCounts,
          meta: {
            totalFetched: events?.records?.length || 0,
            numPerPage: events.numPerPage,
            pageNum: events.pageNum,
          },
          activityType,
        });
      })
      .catch((err: NotifiableError) => {
        OpertoLogger.Log(err);
      });
  };

export const getEvents =
  (activityType = 'all', pageNum = 0, roomType?: string, numPerPage = 50) =>
  (dispatch: AppDispatch, getState: () => ApplicationState) => {
    return api
      .getEvents(activityType, pageNum, roomType, numPerPage)
      .then((data: AxiosResponse) => {
        const events: Record<string, unknown> & Record<string, IEvent[]> = data.data.data;

        if (events?.records?.length > 0) {
          const existingEventDict: EventDict = getState().events.byId;
          const eventList: number[] = [];
          const eventDict: EventDict = {};

          events.records
            .filter((event: IEvent) => event.type !== 'battery')
            .filter(
              (event: IEvent) =>
                ((event.type === 'lockedManually' || event.type === 'unlockedManually') &&
                  event.data &&
                  event.data.method) ||
                (event.type !== 'lockedManually' && event.type !== 'unlockedManually'),
            )
            .forEach((event: IEvent) => {
              if (!existingEventDict[event.id]) {
                eventList.push(event.id);
              }
              event.activityType = activityType;
              eventDict[event.id] = event;
            });

          dispatch({
            type: Actions.bulkAddEvents,
            eventList,
            eventDict,
          });
        }
        dispatch({
          type: Actions.updateEventsCounts,
          meta: {
            totalFetched: events?.records?.length || 0,
            numPerPage: events.numPerPage,
            pageNum: events.pageNum,
          },
          activityType,
        });
      })
      .catch((err: NotifiableError) => {
        const metadata = {
          activityType,
          pageNum,
          numPerPage,
        };
        OpertoLogger.Log(err);
        OpertoLogger.LeaveBreadCrumb('get events', metadata, 'state');
      });
  };
