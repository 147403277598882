import { Box, SxProps } from '@mui/material';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import PropertyAlertsTable from 'Common/Tables/PropertyAlerts/PropertyAlertsTable';
import { getDevices } from 'device/state/deviceActions';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { MainContentContainer } from 'ui-library/Styled/sidebar';

export interface ProertyAlertsSlideProps {
  handleClose: () => void;
}

const ProertyAlertsSlide = ({ handleClose }: ProertyAlertsSlideProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDevices());
  }, [dispatch]);

  return (
    // TODO: refactor to use FormContainer.tsx to make it more consistent
    <MainContentContainer>
      <RightSideTopbar title='Recent Property Alerts' handleClose={handleClose} />
      <Box p={2} sx={PropertyAlertsTableStyles}>
        <PropertyAlertsTable />
      </Box>
    </MainContentContainer>
  );
};

const PropertyAlertsTableStyles: SxProps = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

export default ProertyAlertsSlide;
