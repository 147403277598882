import { Typography } from '@mui/material';
import { Task } from '@operto/tasks-shared';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { StreamMessage } from 'stream-chat-react';
import { BaseSystemMessage } from './BaseSystemMessage';

interface SystemMessageProps {
  task: Task;
  message: StreamMessage;
}

export const GuestSystemMessage = ({ task, message }: SystemMessageProps) => {
  const { t } = useTranslation();
  const content = (
    <Typography variant='caption'>
      {task.title} ({task.id}) - {task.status !== 'canceled' ? task.status : t('cancelled')}
    </Typography>
  );

  return <BaseSystemMessage date={message.created_at?.toLocaleString() || ''} content={content} />;
};
