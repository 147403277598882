import { Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICategory } from 'redux/guestportal';
import { useGuidesCategories } from 'redux/guestportal/hooks/useGuidesCategories';
import { GuidesActionsType } from '../../types';
import { GuidesDetailsCategoriesForm } from './GuidesDetailsCategoriesForm';
import { GuidesDetailsCategoriesTable } from './GuidesDetailsCategoriesTable';

export const GuidesDetailsCategoriesTab = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<ICategory>();
  const [openDialog, setOpenDialog] = useState(false);
  const { category, save, remove } = useGuidesCategories();

  const handleAction = (action: GuidesActionsType, category?: ICategory) => {
    switch (action) {
      case 'add':
        navigate(`${pathname}/add`);
        break;

      case 'edit':
        navigate(`${pathname}/${category?.category_id}`);
        break;

      case 'delete':
        setSelectedCategory(category);
        setOpenDialog(true);
        break;

      case 'feature':
        save({ ...category, featured: !category.featured });
        break;

      case 'publish':
        save({ ...category, published: !category.published });
        break;
    }
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        title='Delete Category'
        submitButtonColor='error'
        onSubmit={() => {
          remove(selectedCategory);
          setOpenDialog(false);
        }}
        onCancel={() => setOpenDialog(false)}
      >
        <Typography variant='body1'>
          Are you sure you want to delete the following Category and associated Pages?
        </Typography>
        <Typography variant='body2' marginTop={2}>{`"${selectedCategory?.category}"`}</Typography>
      </ConfirmDialog>

      <GuidesDetailsCategoriesTable onAction={handleAction} />

      <GuidesDetailsCategoriesForm category={category} onSave={save} />
    </>
  );
};

export default GuidesDetailsCategoriesTab;
