import { ArrowBack } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { Loading } from 'Common/Loading';
import useTranslation from 'hooks/useTranslation';
import { useVerification } from 'hooks/useVerification';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VerificationDocumentType } from 'reservation/reservationType';

const DocumentView = () => {
  const { reservationExternalId, documentType } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getVerificationDocuments } = useVerification();
  const { verificationDocuments, isLoading } = getVerificationDocuments({
    reservationExternalId,
  });

  const renderDocument = () => {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <Box
        style={{
          textAlign: 'center',
          padding: '20px',
        }}
      >
        <img
          src={
            documentType === VerificationDocumentType.PHOTO
              ? verificationDocuments?.customer_photo
              : verificationDocuments?.document_front_side
          }
          style={{ maxWidth: '90%', maxHeight: '90%' }}
        />
      </Box>
    );
  };

  return (
    <Box>
      <Button
        onClick={() => {
          navigate('/guests');
        }}
        startIcon={<ArrowBack />}
      >
        {t('back')}
      </Button>
      {renderDocument()}
    </Box>
  );
};

export default DocumentView;
