import { Grid } from '@mui/material';
import { defaultCurrency, makePriceLabel } from '@operto/payments-shared';
import React, { useCallback } from 'react';
import { UpsellFields } from '../UpsellPage';
import { AffiliateLinkPricing } from './Pricing/AffiliateLinkPricing';
import { FreeItemPricing } from './Pricing/FreeItemPricing';
import { SingleItemPricing } from './Pricing/SingleItemPricing';

export interface PricingProps {
  upsellFields: UpsellFields;
  updateUpsellFields: <K extends keyof UpsellFields>(field: K, value: UpsellFields[K]) => void;
}

export const Pricing = ({ upsellFields, updateUpsellFields }: PricingProps) => {
  const { pricing } = upsellFields;

  const updatePricingField = useCallback(
    (field: keyof UpsellFields['pricing'], value: string | number | boolean | null) => {
      updateUpsellFields('pricing', { ...pricing, [field]: value });
    },
    [pricing, updateUpsellFields],
  );

  const changePaymentType = useCallback(
    (paymentType: UpsellFields['pricing']['paymentType']) => {
      const update: UpsellFields['pricing'] = { ...pricing, paymentType };
      switch (paymentType) {
        case 'free':
          updateUpsellFields('priceSticker', 'FREE');
          break;
      }
      if (['link', 'free'].includes(paymentType)) {
        update.taxRate = update.price = 0;
      }
      updateUpsellFields('pricing', update);
    },
    [pricing, updateUpsellFields],
  );

  return (
    <Grid item xs={12} md={12}>
      <SingleItemPricing
        price={pricing?.price ?? 0}
        taxRate={pricing?.taxRate ?? 0}
        confirmationMethod={pricing?.confirmationMethod ?? ''}
        paymentType={pricing?.paymentType ?? 'single'}
        quantityStepperEnabled={pricing?.quantityStepperEnabled ?? false}
        maxQuantity={pricing?.maxQuantity ?? 1}
        quantityName={pricing?.quantityName ?? ''}
        actionButtonText={pricing?.customButtonText ?? ''}
        currencyCode={pricing?.currencyCode ?? defaultCurrency?.code}
        setPrice={(price: number) => {
          updatePricingField('price', price);
          updateUpsellFields(
            'priceSticker',
            makePriceLabel({
              amount: price,
              currencyCode: pricing?.currencyCode ?? defaultCurrency?.code,
              withCode: true,
              withTrailingZeros: true,
            }),
          );
        }}
        setTaxRate={(taxRate: number) => updatePricingField('taxRate', taxRate)}
        setConfirmationMethod={(confirmationMethod: string) =>
          updatePricingField('confirmationMethod', confirmationMethod)
        }
        setQuantityStepperEnabled={(quantityStepperEnabled: boolean) =>
          updatePricingField('quantityStepperEnabled', quantityStepperEnabled)
        }
        setMaxQuantity={(maxQuantity: number) => updatePricingField('maxQuantity', maxQuantity)}
        setQuantityName={(quantityName: string) => updatePricingField('quantityName', quantityName)}
        setPaymentType={changePaymentType}
        setActionButtonText={(actionButtonText: string) =>
          updatePricingField('customButtonText', actionButtonText)
        }
      />
      <AffiliateLinkPricing
        paymentType={pricing?.paymentType ?? 'single'}
        customButtonText={pricing?.customButtonText ?? ''}
        affiliateLink={pricing?.affiliateLink ?? ''}
        customAffiliateHeader={pricing?.affiliateCustomHeader ?? ''}
        customAffiliateHeaderEnabled={pricing?.affiliateCustomHeaderEnabled ?? false}
        setCustomButtonText={(customButtonText: string) =>
          updatePricingField('customButtonText', customButtonText)
        }
        setAffiliateLink={(affiliateLink: string) =>
          updatePricingField('affiliateLink', affiliateLink)
        }
        setCustomAffiliateHeader={(affiliateCustomHeader: string) =>
          updatePricingField('affiliateCustomHeader', affiliateCustomHeader)
        }
        setCustomAffiliateHeaderEnabled={(affiliateCustomHeaderEnabled: boolean) =>
          updatePricingField('affiliateCustomHeaderEnabled', affiliateCustomHeaderEnabled)
        }
        setPriceSticker={(priceSticker: string) => updateUpsellFields('priceSticker', priceSticker)}
        setPaymentType={changePaymentType}
      />
      <FreeItemPricing
        paymentType={pricing?.paymentType ?? 'single'}
        confirmationMethod={pricing?.confirmationMethod ?? ''}
        quantityStepperEnabled={pricing?.quantityStepperEnabled ?? false}
        maxQuantity={pricing?.maxQuantity ?? 1}
        quantityName={pricing?.quantityName ?? ''}
        actionButtonText={pricing?.customButtonText ?? ''}
        setConfirmationMethod={(confirmationMethod: string) =>
          updatePricingField('confirmationMethod', confirmationMethod)
        }
        setQuantityStepperEnabled={(quantityStepperEnabled: boolean) =>
          updatePricingField('quantityStepperEnabled', quantityStepperEnabled)
        }
        setMaxQuantity={(maxQuantity: number) => updatePricingField('maxQuantity', maxQuantity)}
        setQuantityName={(quantityName: string) => updatePricingField('quantityName', quantityName)}
        setPaymentType={changePaymentType}
        setActionButtonText={(actionButtonText: string) =>
          updatePricingField('customButtonText', actionButtonText)
        }
      />
    </Grid>
  );
};
