import { Box, Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import * as React from 'react';

type RightSideTopbarIntegrationTitleProps = {
  title: string;
};

export const RightSideTopbarIntegrationTitle = ({
  title,
}: RightSideTopbarIntegrationTitleProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ marginRight: 'auto', lineHeight: 0 }}>
      <Typography variant='subtitle2' noWrap component='div' color='black'>
        {t('integrations')}
      </Typography>
      <br />
      <Typography variant='h3-700' noWrap component='div' color='black'>
        {title}
      </Typography>
    </Box>
  );
};
