import { MoreVertOutlined } from '@mui/icons-material';
import { IconButton, PopoverOrigin } from '@mui/material';
import { TemplateData } from '@operto/communications-shared';
import useCommunications, { CommunicationType } from 'hooks/useCommunications';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommunicationsMoreMenu, { MENU_OPTIONS } from './CommunicationsMoreMenu';
import { MenuOptionType } from './CommunicationsNewMenu';
import CommunicationsTemplateDeleteConfirmDialog, {
  TemplateDeletionTypes,
} from './CommunicationsTemplateDeleteConfirmDialog';

export default function CommunicationsTableAction({
  templateId,
  channelType,
  communications,
  onDeleteTemplateSuccess,
}: {
  templateId: string;
  channelType: string;
  communications: CommunicationType[];
  onDeleteTemplateSuccess: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { t } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const menuOpen = Boolean(menuAnchorEl);

  const { getTemplate, createTemplate, createContents, templateRefetch, deleteTemplate } =
    useCommunications();
  const { data: templateData } = getTemplate(templateId);

  // Generates a unique name for a duplicated template by appending " copy N"
  const generateDuplicatedTemplateName = (
    name: string,
    communications: CommunicationType[],
  ): string => {
    let copyIndex = 1;
    let newName: string;
    do {
      newName = `${name} copy ${copyIndex == 1 ? '' : copyIndex}`;
      copyIndex++;
    } while (communications?.some((t: { name: string }) => t.name === newName));

    return newName;
  };

  const performDuplication = async (template: TemplateData): Promise<TemplateData> => {
    snackbar(t('copying'));
    const newName = generateDuplicatedTemplateName(template.name, communications);

    // Filter out contents that do not have the locale 'en'
    const enContents = template.contents[0].data.filter(
      (c: { locale: string }) => c.locale === 'en',
    );

    const otherContents = template.contents[0].data.filter(
      (c: { locale: string }) => c.locale !== 'en',
    );

    const templateWithDefaultLocale: TemplateData = {
      ...template,
      id: undefined,
      name: `${newName}`,
      contents: [{ ...template.contents[0], data: enContents }],
    };

    let createdTemplate: TemplateData;
    try {
      // Create the base template with only English content
      createdTemplate = await createTemplate(templateWithDefaultLocale);

      // Create all other contents
      for (const content of otherContents) {
        await createContents({
          templateId: createdTemplate.id,
          contentId: createdTemplate.contents[0].id,
          content: {
            locale: content.locale,
            body: content.body,
            editorData: content.editorData,
            subject: content.subject,
          },
        });
      }

      return createdTemplate;
    } catch (error) {
      console.error('Error duplicating template:', error);
      // Rollback - attempt to delete the partially created template
      if (createdTemplate) {
        try {
          await deleteTemplate({ templateId: createdTemplate.id });
        } catch (rollbackError) {
          console.error('Rollback failed:', rollbackError);
        }
      }

      throw error; // Re-throw to ensure the caller knows duplication failed
    }
  };

  const duplicateTemplate = async (): Promise<TemplateData> => {
    try {
      // If we already have template data, use it directly
      if (templateData) {
        return await performDuplication(templateData);
      }

      // Wait for template data to load if needed
      snackbar(t('loading_template'));
      await templateRefetch(templateId);

      if (!templateData) {
        snackbar(t('operation_failed_try_again'));
        const error = 'Template not found after refresh';
        logger.error(error);
        return null;
      }

      // Use the refetched template data
      return await performDuplication(templateData);
    } catch (error) {
      logger.error('Copying template failed:', error);
      snackbar(t('operation_failed_try_again'));
      return null;
    }
  };

  const menuAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };

  const menuTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  const handleCloseDeleteTemplateDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleSuccessDeleteTemplateDialog = async () => {
    onDeleteTemplateSuccess();
  };

  const handleFailureDeleteTemplateDialog = (error: string) => {
    // Implement failure logic, such as showing an error message
    logger.error('Deletion failed:', error);
  };

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMoreClose = async (selectedOption?: MenuOptionType) => {
    if (selectedOption === MENU_OPTIONS.EDIT) {
      navigate(`/communications/edit/${channelType}/${templateId}`);
    } else if (selectedOption === MENU_OPTIONS.DELETE) {
      setShowDeleteDialog(true);
    } else if (selectedOption === MENU_OPTIONS.DUPLICATE) {
      setMenuAnchorEl(null); // hide menu while duplicating
      const createdTemplate = await duplicateTemplate();
      if (createdTemplate) {
        snackbar(t('copied'));
        navigate(`/communications/edit/${channelType}/${createdTemplate.id}`);
      }
    }

    setMenuAnchorEl(null);
  };

  const handleNewLanguageSelect = (newLanguage: string) => {
    navigate(`/communications/create/${channelType}/${templateId}/${newLanguage}`);
  };

  return (
    <>
      <IconButton size='large' onClick={handleMoreClick} role='button' data-testid='more-button'>
        <MoreVertOutlined />
      </IconButton>

      <CommunicationsMoreMenu
        templateId={templateId}
        anchorEl={menuAnchorEl}
        open={menuOpen}
        handleClose={handleMoreClose}
        anchorOrigin={menuAnchorOrigin}
        transformOrigin={menuTransformOrigin}
        onSelectNewLanguage={handleNewLanguageSelect}
        communications={communications}
      />
      {showDeleteDialog && (
        <CommunicationsTemplateDeleteConfirmDialog
          deletionType={TemplateDeletionTypes.ALL_LANGUAGES}
          channel={channelType}
          templateName={communications.find(c => c.id === templateId)?.name}
          templateId={templateId}
          contentId={communications.find(c => c.id === templateId)?.contentId}
          onClose={handleCloseDeleteTemplateDialog}
          onSuccess={handleSuccessDeleteTemplateDialog}
          onFailure={handleFailureDeleteTemplateDialog}
        />
      )}
    </>
  );
}
