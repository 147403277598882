import { FilterDataType, FilterGroup, GuestsFilterOptionResponse } from 'types/filter-type';

export const activeFilters = [
  'guest_status',
  'access_code_status',
  'verification_status',
  'security_deposit_status',
  'access_approval',
  'check_in',
  'check_out',
];

const mapGuestsLabel = (label: string) => {
  switch (label) {
    case 'pre-stay':
      return 'Pre-stay';
    case 'in-stay':
      return 'In-stay';
    case 'post-stay':
      return 'Post-stay';
    case 'blocked':
      return 'Blocked';
    default:
      return null;
  }
};

const mapAccessCodeLabel = (label: string) => {
  switch (label) {
    case 'Blocked':
      return 'Blocked';
    case 'Removed':
      return 'Removed';
    case 'Scheduled':
      return 'Scheduled';
    case 'Not Installed':
      return 'Not installed';
    case 'Installed':
      return 'Installed';
    case 'Activated':
      return 'Activated';
    default:
      return null;
  }
};

const mapVerificationLabel = (label: string) => {
  switch (label) {
    case 'pending':
      return 'Pending';
    case 'confirmed':
      return 'Confirmed';
    case 'flagged':
      return 'Flagged';
    case 'manually_confirmed':
      return 'Manually confirmed';
    case 'manually_flagged':
      return 'Manually flagged';
    case 'confirmed_by_flow':
      return 'Welcome flow';
    case 'n/a':
      return 'N/A';
    default:
      return null;
  }
};

const mapSecurityDepositLabel = (label: string) => {
  switch (label) {
    case 'charged':
      return 'Charged';
    case 'declined':
      return 'Declined';
    case 'hold':
      return 'Hold';
    case 'manually_accepted':
      return 'Manually accepted';
    case 'pending':
      return 'Pending';
    case 'released':
      return 'Released';
    default:
      return null;
  }
};

const mapAccessApprovalLabel = (label: string) => {
  switch (label) {
    case 'true':
      return 'Show access code';
    case 'false':
      return 'Hide access code';
    default:
      return null;
  }
};

export const createGuestsOptions = (data: GuestsFilterOptionResponse) => {
  const filterGroups: FilterGroup[] = [];
  for (const [key, value] of Object.entries(data)) {
    if (!activeFilters.includes(key)) continue;

    if (key === 'guest_status') {
      const guestStatusOptions: FilterDataType[] = (value as string[]).map(option => ({
        filterType: 'checkbox',
        label: mapGuestsLabel(option),
        name: option,
        singleSelect: true,
      }));
      filterGroups.push({
        label: 'Guests',
        groupName: 'type',
        options: guestStatusOptions,
        order: 1,
      });
    }

    if (key === 'access_code_status') {
      const accessCodeStatusOptions: FilterDataType[] = (value as string[]).map(option => ({
        filterType: 'checkbox',
        label: mapAccessCodeLabel(option),
        name: option,
      }));
      filterGroups.push({
        label: 'Access Code',
        groupName: 'access_code_status',
        options: accessCodeStatusOptions,
        order: 2,
      });
    }

    if (key === 'check_in') {
      const checkInOptions: FilterDataType[] = [
        {
          filterType: 'datepicker',
          label: '',
          name: 'check_in',
          singleSelect: false,
        },
      ];
      filterGroups.push({
        label: 'Check-In',
        groupName: 'check_in',
        options: checkInOptions,
        order: 3,
      });
    }

    if (key === 'check_out') {
      const checkOutOptions: FilterDataType[] = [
        {
          filterType: 'datepicker',
          label: '',
          name: 'check_out',
          singleSelect: false,
        },
      ];
      filterGroups.push({
        label: 'Check-Out',
        groupName: 'check_out',
        options: checkOutOptions,
        order: 4,
      });
    }

    if (key === 'verification_status') {
      const verificationStatusOptions: FilterDataType[] = (value as string[]).map(option => ({
        filterType: 'checkbox',
        label: mapVerificationLabel(option),
        name: option,
      }));
      filterGroups.push({
        label: 'Verification',
        groupName: 'verification_status',
        options: verificationStatusOptions,
        order: 5,
      });
    }

    if (key === 'security_deposit_status') {
      const securityDepositStatusOptions: FilterDataType[] = (value as string[]).map(option => ({
        filterType: 'checkbox',
        label: mapSecurityDepositLabel(option),
        name: option,
      }));
      filterGroups.push({
        label: 'Security Deposit',
        groupName: 'security_deposit_status',
        options: securityDepositStatusOptions,
        order: 6,
      });
    }

    if (key === 'access_approval') {
      const accessApprovalOptions: FilterDataType[] = (value as string[]).map(option => ({
        filterType: 'checkbox',
        label: mapAccessApprovalLabel(option),
        name: option,
        singleSelect: true,
      }));
      filterGroups.push({
        label: 'Access approval',
        groupName: 'access_approval',
        options: accessApprovalOptions,
        order: 7,
      });
    }
  }

  return filterGroups.sort((a, b) => (a.order || 0) - (b.order || 0));
};
