import { TemplateData } from '@operto/communications-shared';
import { trpc } from '@operto/trpc-client';
import { SYSTEM_VARIABLES } from '@operto/variables-shared';
import { companySelector } from 'company/state/companySelectors';
import { addDays, format } from 'date-fns';
import { SupportedLocale } from 'hooks/useTranslation';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';

export type CommunicationType = {
  id: string;
  name: string;
  channelType: ChannelType;
  locales: SupportedLocale[];
  contentId: string;
};

export default function useCommunications(filters?: useCommunicationsProps) {
  const company = useAppSelector(companySelector());
  const trpcUtils = trpc.useUtils();
  const { channels, locales } = filters || {};

  const { data, isFetching, isLoading, refetch } = trpc.communications.getTemplates.useQuery(
    { companyId: `${company.id}`, filterParams: { channels, locales } },
    {
      retry(failureCount, error) {
        // retry if not unauth, forbidden or not found
        return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
      },
    },
  );

  // Transform function to convert TemplateData[] to CommunicationType[]
  const transformData = useCallback((rawData: TemplateData[] | undefined): CommunicationType[] => {
    if (!rawData) return [];
    return rawData.map(communication => {
      const channelTemplate = communication?.contents?.[0];
      return {
        id: communication.id,
        name: communication?.name,
        channelType: channelTemplate?.channelType,
        locales: channelTemplate?.locales,
        contentId: channelTemplate?.id,
      };
    });
  }, []);

  // Use the transform function in the useMemo
  const communications = useMemo(
    () => transformData(data as TemplateData[]),
    [data, transformData],
  );

  // Wrap the original refetch to transform its result
  const communicationsRefetch = useCallback(async () => {
    const result = await refetch();
    return {
      data: transformData(result.data as TemplateData[]),
    };
  }, [refetch, transformData]);

  const createTemplateMutation = trpc?.communications?.createTemplates?.useMutation();
  const createTemplate = async (template: TemplateData) => {
    return await new Promise((resolve: (result: TemplateData) => void, reject) => {
      createTemplateMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const createContentsMutation = trpc?.communications?.createContents?.useMutation();
  const createContents = async (template: TemplateData) => {
    return await new Promise((resolve, reject) => {
      createContentsMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const updateTemplateMutation = trpc?.communications?.updateTemplate?.useMutation();
  const updateTemplate = async (template: TemplateData) => {
    return await new Promise((resolve, reject) => {
      updateTemplateMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const deleteTemplateMutation = trpc?.communications?.deleteTemplate?.useMutation();
  const deleteTemplate = async (template: TemplateData) => {
    return await new Promise((resolve, reject) => {
      deleteTemplateMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const deleteContentMutation = trpc?.communications?.deleteContent?.useMutation();
  const deleteContent = async (template: TemplateData) => {
    return await new Promise((resolve, reject) => {
      deleteContentMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const updateContentsMutation = trpc?.communications?.updateContent?.useMutation();
  const updateContents = async (template: TemplateData) => {
    return await new Promise((resolve, reject) => {
      updateContentsMutation?.mutate(template, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const templateRefetch = (templateId: string) =>
    trpcUtils.communications.getTemplate.refetch({
      companyId: `${company.id}`,
      templateId: templateId,
    });

  const getTemplate = (templateId: string): TemplateData =>
    trpc.communications.getTemplate.useQuery(
      {
        companyId: `${company.id}`,
        templateId: templateId,
      },
      { enabled: !!templateId },
    );

  return {
    isFetching,
    isLoading,
    data: communications,
    createTemplate,
    createContents,
    updateTemplate,
    updateContents,
    communicationsRefetch,
    getTemplate,
    templateRefetch,
    deleteTemplate,
    deleteContent,
  };
}

// Hard coded mock data for testing
export const sendPreviewMockVariables: Record<string, string> = {
  [SYSTEM_VARIABLES.GUEST_NAME]: 'Jane Doe',
  [SYSTEM_VARIABLES.GUEST_EMAIL]: 'guest@email.com,',
  [SYSTEM_VARIABLES.GUEST_PHONE]: '+1234567890',
  [SYSTEM_VARIABLES.RESERVATION_CHECKIN_DATETIME]: format(new Date(), 'h:mm a MMMM d, yyyy')
    .replace('am', 'AM')
    .replace('pm', 'PM'),
  [SYSTEM_VARIABLES.RESERVATION_CHECKOUT_DATETIME]: format(
    addDays(new Date(), 3),
    'h:mm a MMMM d, yyyy',
  )
    .replace('am', 'AM')
    .replace('pm', 'PM'),
  [SYSTEM_VARIABLES.RESERVED_CHECKIN_DATE]: format(new Date(), 'MMMM d, yyyy'),
  [SYSTEM_VARIABLES.RESERVED_CHECKIN_TIME]: format(new Date(), 'h:mm a'),
  [SYSTEM_VARIABLES.RESERVED_CHECKOUT_DATE]: format(addDays(new Date(), 3), 'MMMM d, yyyy'),
  [SYSTEM_VARIABLES.RESERVED_CHECKOUT_TIME]: format(addDays(new Date(), 3), 'h:mm a'),
  [SYSTEM_VARIABLES.PROPERTY_NAME]: 'Property Name',
  [SYSTEM_VARIABLES.PROPERTY_ADDRESS]: '1234 Main St',
  [SYSTEM_VARIABLES.CONFIRMATION_CODE]: 'BX-1234A',
  [SYSTEM_VARIABLES.ACCESS_CODE]: '1234',
  [SYSTEM_VARIABLES.CHECKOUT_CODE]: '11234',
  [SYSTEM_VARIABLES.GUEST_PORTAL_URL]: 'https://guest.operto.com/portal/BX-1234A',
  [SYSTEM_VARIABLES.YOURKEY_URL]: 'https://guest.operto.com/yourkey/BX-1234A',
};

// Transform the message with the mock variables
export const transformMockVariables = (message: string): string => {
  let text = message;

  Object.keys(sendPreviewMockVariables).forEach(key => {
    text = text.replaceAll(`*{{${key}}}*`, sendPreviewMockVariables[key]);
    text = text.replaceAll(
      `*{{${key.replace(/\s+/g, '_').toLowerCase()}}}*`,
      sendPreviewMockVariables[key],
    );
  });

  return text;
};

type useCommunicationsProps = { channels?: ChannelType[]; locales?: SupportedLocale[] };
export type ChannelType = 'email' | 'sms';
