import { Chip, createTheme, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { hasVariableSomeEmptyValuesForProperties } from '../helpers/VariableHelpers';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0036B2',
    },
    secondary: {
      main: '#BDBDBD',
    },
    warning: {
      main: '#FF9800',
    },
    error: {
      main: '#F44336',
    },
    info: {
      main: '#2196F3',
    },
  },
});

const VariableName = (props: GridRenderCellParams) => {
  const renderIcon = (isRowEnabled: boolean) => {
    if (!isRowEnabled) {
      return (
        <Chip
          data-testid='deactive-chip'
          label={'Deactivate'}
          color='secondary'
          variant='outlined'
          size='small'
        />
      );
    } else if (hasVariableSomeEmptyValuesForProperties([props.row])) {
      return (
        <Chip
          data-testid='missing-chip'
          label={'Missing Value'}
          color='warning'
          variant='outlined'
          size='small'
        />
      );
    }
  };

  return (
    <>
      <Typography
        variant='body2'
        style={{
          textTransform: 'capitalize',
          margin: 0,
          marginRight: '8px',
        }}
        noWrap
      >
        {props.row.name}
      </Typography>
      <ThemeProvider theme={theme}>{renderIcon(props.row.enabled)}</ThemeProvider>
    </>
  );
};

export default VariableName;
