import { FormControlLabel, Stack, Switch } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import { Property } from 'property/propertyType';
import { getProperty } from 'property/state/propertySelectors';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useGuestPortal } from 'redux/guestportal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Actions } from 'types/actions';
import { PageHeader } from 'ui-library/Styled/containers';
import { FormHeader } from 'ui-library/Styled/sidebar';

const UnitDetails = () => {
  const { propertyId: pidStr } = useParams();
  const propertyId = Number(pidStr);
  const property: Property = useAppSelector(getProperty(propertyId));
  const { updateGPActivated } = useGuestPortal();
  const dispatch = useAppDispatch();

  return (
    <>
      <PageHeader>Unit Details</PageHeader>
      <AddCard title={'Guest Portal Status'}>
        <Stack>
          <FormHeader align='left' variant='subtitle1'>
            This unit is currently activated for use in your Guest Portal
          </FormHeader>
          <FormHeader align='left' variant='caption'>
            Disabling will not delete your unit&apos;s content.
          </FormHeader>
          <FormControlLabel
            control={
              <Switch
                checked={property?.guest_portal_status === 'active'}
                onChange={(e: React.ChangeEvent, checked: boolean) => {
                  const status = checked ? 'active' : 'inactive';
                  updateGPActivated({ property_ids: [property.id], status }).then(() => {
                    property.guest_portal_status = status;
                    dispatch({
                      type: Actions.upsertProperty,
                      property,
                    });
                  });
                }}
                disabled={!property}
                name='gp-activated'
                color='primary'
              />
            }
            label='Guest Portal Activated'
          />
        </Stack>
      </AddCard>
    </>
  );
};

export default UnitDetails;
