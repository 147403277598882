// @ts-nocheck
import { getHistoricalByDeviceId } from 'device/state/deviceActions';
import { devicesByPropertyIdSelector } from 'device/state/deviceSelector';
import useTranslation from 'hooks/useTranslation';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getThresholdsByPropertyId } from 'threshold/state/thresholdSelectors';
import { PageHeader } from 'ui-library/Styled/containers';
import DevicesContainer from './TabContainers/DevicesContainer';

const devicesType = [
  'noise',
  'occupancy',
  'temperature',
  'humidity',
  'energy',
  'connected',
] as const;
type UnitDevicesType = (typeof devicesType)[number];

const UnitDevicesPage = () => {
  const { propertyId: pidStr } = useParams();
  const propertyId = Number(pidStr);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const tabName = location.pathname.split('/devices/')[1] as UnitDevicesType;
  const thresholds = useAppSelector(getThresholdsByPropertyId(propertyId));
  const devices = useAppSelector(devicesByPropertyIdSelector(propertyId));

  const [deviceId, setDeviceId] = React.useState(0);
  const monitorDevice = devices.find(device => device.id === deviceId);

  const { t } = useTranslation();

  useEffect(() => {
    const monitorDeviceId = monitorDevice?.data?.device_id || undefined;
    if (monitorDeviceId !== deviceId && monitorDevice?.type === 'sensor') {
      dispatch(getHistoricalByDeviceId(monitorDevice?.data?.device_id));
      setDeviceId(monitorDevice?.data?.device_id);
    }
  }, [monitorDevice, deviceId, tabName, dispatch]);

  const sensorData = {
    noise: {
      title: t('noise'),
      name: 'noise',
      lowRef: thresholds?.noiseMed?.value,
      highRef: thresholds?.noiseHigh?.value,
    },
    occupancy: {
      title: t('occupancy'),
      name: 'co2',
      lowRef: thresholds?.co2Med?.value,
      highRef: thresholds?.co2High?.value,
    },
    temperature: {
      title: t('temperature'),
      name: 'temperature',
      lowRef: thresholds?.tempLow?.value,
      highRef: thresholds?.tempHigh?.value,
    },
    humidity: {
      title: t('humidity'),
      name: 'humidity',
      lowRef: thresholds?.humidityLow?.value,
      highRef: thresholds?.humidityHigh?.value,
    },
    energy: {
      title: t('energy_manager'),
      name: 'energy',
    },
    connected: {
      title: t('connected_devices'),
      name: 'connected',
    },
  };

  return (
    <>
      <PageHeader>{sensorData[tabName]?.title}</PageHeader>
      <DevicesContainer
        deviceId={deviceId}
        tabIdx={devicesType.indexOf(tabName as UnitDevicesType)}
        propertyId={propertyId}
        name={sensorData[tabName]?.name}
        referenceLineHigh={Number(sensorData[tabName]?.highRef)}
        referenceLineLow={Number(sensorData[tabName]?.lowRef)}
        thresholds={thresholds}
        devices={devices}
      />
    </>
  );
};

export default UnitDevicesPage;
