import { TextField } from '@mui/material';
import { ContentData, TemplateData } from '@operto/communications-shared';
import { theme } from '@operto/ui';
import { Loading } from 'Common/Loading';
import { Buffer } from 'buffer';
import useCommunications, { ChannelType, transformMockVariables } from 'hooks/useCommunications';
import useNotifications from 'hooks/useNotifications';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation, { SupportedLocale } from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { JSONTemplate } from 'state/types/types';
import { FormTextFieldRegexEmail } from 'ui-library/Components/input/FormTextField';
import { BUILDER_TYPE } from '../CommunicationsNewMenu';
import CommunicationsSendConfirmDialog from '../CommunicationsSendConfirmDialog';
import CommunicationsTemplateTitlebar from '../CommunicationsTemplateTitlebar';
import CommunicationsEmailTemplateBuilder from './CommunicationsEmailTemplateBuilder';
import CommunicationsEmailTemplateSourceCode from './CommunicationsEmailTemplateSourceCode';
import { defaultEmailTemplate } from './defaultEmailTemplate';

const initialName = 'Untitled email';

export const MAX_SUBJECT_LENGTH = 50;
const MAX_NAME_LENGTH = 50;

export type EmailTemplateType = {
  id?: string;
  name?: string;
  subject?: string;
  body?: string;
  locale?: SupportedLocale;
  channelType?: ChannelType;
  editorData?: JSONTemplate;
  contentId?: string;
};

const CommunicationsEmailTemplatePage = () => {
  const { templateId, language, builderType } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendEmail } = useNotifications();
  const { snackbar } = useSnackbar();

  const emailEditorRef = useRef(null);

  const existingHtml = Buffer.from(defaultEmailTemplate, 'base64').toString('utf-8');

  const { pathname } = useLocation();

  const isCreate = pathname.includes('create');

  const [showSubjectRequired, setShowSubjectRequired] = useState(false);
  const [isSendingTest, setIsSendingTest] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [sendDialogEmail, setSendDialogEmail] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorName, setErrorName] = useState<string>();
  const [isBodyDirty, setIsBodyDirty] = useState(false);
  const [isNameDirty, setIsNameDirty] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const isValidEmail = !sendDialogEmail || sendDialogEmail.match(FormTextFieldRegexEmail);
  const sendDialogDisabled = !sendDialogEmail || !isValidEmail;

  const [template, setTemplate] = useState<EmailTemplateType>({
    id: templateId,
    name: initialName,
    subject: '',
    body: existingHtml,
    locale: (language as SupportedLocale) ?? 'en',
    channelType: 'email',
    editorData: {} as JSONTemplate,
  });

  const {
    data: communications,
    createContents,
    createTemplate,
    communicationsRefetch,
    getTemplate,
    updateTemplate,
    updateContents,
    templateRefetch,
  } = useCommunications();

  const { data: templateData, isLoading } = getTemplate(templateId);

  const isSubjectError = template.subject.length > MAX_SUBJECT_LENGTH || showSubjectRequired;

  const invalidTemplate = !template.subject || !template.body || isSubjectError || !!errorName;

  const disableTemplateDeleteButton = !template.id || pathname.includes('create');
  const showTemplateDeleteMenu = (template?.locale ?? 'en') !== 'en';

  const validateNameChange = useCallback(
    (name: string) => {
      const trimmedName = name.trim();

      if (communications?.some(c => c.name.trim() === trimmedName && c.id !== template.id)) {
        setErrorName(t('name_taken'));
        return false;
      }

      if (trimmedName.length === 0) {
        setErrorName(t('name_required'));
        return false;
      }

      if (trimmedName.length > MAX_NAME_LENGTH) {
        setErrorName(`${trimmedName.length}/${MAX_NAME_LENGTH}`);
        return false;
      }

      setErrorName(undefined);
      return true;
    },
    [communications, template.id, t],
  );

  const handleTemplateChange = (updatedTemplate: EmailTemplateType) => {
    setShowSubjectRequired(false);
    setTemplate(updatedTemplate);
    setIsBodyDirty(true);
  };

  const displaySnackbar = (message: string) => {
    snackbar(message);
  };

  const handleExportHtml = async () => {
    return new Promise<{
      updatedTemplate: TemplateData;
      design: unknown;
      sourceCode: string;
      html: unknown;
    }>(resolve => {
      emailEditorRef.current?.editor?.exportHtml((data: { design: unknown; html: unknown }) => {
        const { design, html } = data;
        const sourceCode = Buffer.from(html as string).toString('base64');
        const updatedTemplate = {
          name: template.name.trim(),
          defaultLocale: template.locale,
          contents: [
            {
              channelType: template.channelType,
              data: [
                {
                  locale: template.locale,
                  subject: template.subject,
                  editorData: design,
                  body: sourceCode,
                },
              ],
            },
          ],
        };
        resolve({ updatedTemplate, design, sourceCode, html });
      });
    });
  };

  const handleSave = async () => {
    // if failed validation, show errors and do not proceed
    if (!validateNameChange(template.name) || invalidTemplate || !template.subject) {
      if (!template.subject) {
        setShowSubjectRequired(true);
      }
      return;
    }

    setIsSaving(true);

    const onSuccess = async (navigateTo: string) => {
      setIsRefetching(true);
      await templateRefetch(template.id);
      await communicationsRefetch();
      navigate(navigateTo);

      snackbar(t('changes_saved'));
      setIsSaving(false);
      setIsRefetching(false);
      setIsBodyDirty(false);
      setIsNameDirty(false);
      setEditMode(false);
    };

    try {
      if (isCreate) {
        if (!language) {
          let updatedTemplate: TemplateData;
          let design: unknown;
          let sourceCode: string;

          if (builderType === BUILDER_TYPE.SOURCE_CODE || !template.editorData) {
            sourceCode = Buffer.from(template.body as string).toString('base64');
            updatedTemplate = {
              name: template.name.trim(),
              defaultLocale: template.locale,
              contents: [
                {
                  channelType: template.channelType,
                  data: [
                    {
                      locale: template.locale,
                      subject: template.subject,
                      body: sourceCode,
                    },
                  ],
                },
              ],
            };
          } else {
            // default is design builder
            const result = await handleExportHtml();
            updatedTemplate = result.updatedTemplate;
            design = result.design;
            sourceCode = result.sourceCode;
          }

          const newRecord = await createTemplate(updatedTemplate);

          setTemplate({
            ...template,
            editorData: design ? (design as JSONTemplate) : template.editorData,
            body: Buffer.from(sourceCode, 'base64').toString('utf-8'),
          });

          onSuccess(`/communications/edit/email/${newRecord.id}`);
        }

        if (language) {
          if (isNameDirty) {
            await updateTemplate({
              templateId: template.id,
              name: template.name,
            });
          }

          let design: unknown;
          let sourceCode: string;

          if (builderType === BUILDER_TYPE.SOURCE_CODE || !template.editorData) {
            // creating with source code
            sourceCode = Buffer.from(template.body as string).toString('base64');
          } else {
            // creating with design builder
            const result = await handleExportHtml();
            design = result.design;
            sourceCode = result.sourceCode;
          }

          await createContents({
            templateId: template.id,
            contentId: template.contentId,
            content: {
              locale: template.locale,
              body: sourceCode,
              editorData: design,
              subject: template.subject,
            },
          });

          onSuccess(`/communications/edit/email/${template.id}/${template.locale}`);
        }
      } else {
        if (isNameDirty) {
          await updateTemplate({
            templateId: template.id,
            name: template.name,
          });
        }

        let design: undefined | unknown;
        let sourceCode: string;

        if (isBodyDirty || template.editorData) {
          const result = template.editorData
            ? await handleExportHtml() // editing in design builder
            : // editing in source code
              {
                sourceCode: Buffer.from(template.body as string).toString('base64'),
                design: undefined as unknown,
              };
          ({ design, sourceCode } = result);
        }

        await updateContents({
          contentId: template.contentId,
          templateId: template.id,
          locale: template.locale,
          body: sourceCode,
          editorData: design,
          subject: template.subject,
        });

        onSuccess(
          `/communications/edit/email/${template.id}/${
            template.locale === 'en' ? '' : template.locale
          }`,
        );
      }
    } catch (error) {
      setIsSaving(false);
      displaySnackbar(t('error_saving'));
      logger.debug(error);
    }
  };

  const handleSendClick = () => {
    if (!template.subject) {
      setShowSubjectRequired(true);
      return;
    }

    setSendDialogOpen(true);
  };

  const handleDeleteClick = async (deletionType: string) => {
    await communicationsRefetch();

    let navigation = '/communications';
    if (deletionType === 'SingleLanguage') {
      navigation = `/communications/edit/${template.channelType}/${template.id}`;
    }
    navigate(navigation);
    snackbar(t('deleted'));
  };

  const handleSendDialog = async () => {
    setIsSendingTest(true);

    try {
      let messageHtml: string;
      if (builderType === BUILDER_TYPE.SOURCE_CODE || !template.editorData) {
        messageHtml = template.body;
      } else {
        const { html } = await handleExportHtml();
        messageHtml = html as string;
      }
      const message = transformMockVariables(messageHtml as string);

      await sendEmail({
        content: [{ value: message, type: 'text/html' }],
        subject: template.subject,
        to: sendDialogEmail,
      });

      setSendDialogEmail('');
      setSendDialogOpen(false);
      setIsSendingTest(false);
      snackbar(t('test_sent'));
    } catch (error) {
      snackbar(t('error_sending'));
      setIsSendingTest(false);
      logger.debug(error);
    }
  };

  useEffect(() => {
    if (isRefetching) return;

    if (!isCreate && templateData) {
      const { id: templateId, name, contents } = templateData;
      const { channelType, data, id } = contents[0] as {
        id: string;
        channelType: ChannelType;
        data: ContentData[];
      };
      const { locale, subject, editorData, body } =
        data.find(d => d.locale === (language ?? 'en')) ?? data[0];
      const decodedBody = Buffer.from(body, 'base64').toString('utf-8');

      setTemplate({
        id: templateId,
        name,
        subject,
        body: decodedBody,
        locale: locale,
        channelType,
        editorData,
        contentId: id,
      });

      setIsBodyDirty(false);
      setIsNameDirty(false);
    }

    if (isCreate && templateData) {
      const { id: templateId, name, contents } = templateData;

      const { data, id } = contents[0] as {
        id: string;
        channelType: ChannelType;
        data: ContentData[];
      };

      // Set default english subject, editor data as new localized template
      const { body, editorData, subject } = data.find(d => d.locale === 'en') ?? data[0];
      const decodedBody = Buffer.from(body, 'base64').toString('utf-8');

      setTemplate(template => ({
        ...template,
        id: templateId,
        name: name ?? initialName,
        locale: (language as SupportedLocale) ?? 'en',
        body: decodedBody,
        subject,
        editorData,
        contentId: id,
      }));

      setIsBodyDirty(false);
      setIsNameDirty(false);
    }
  }, [
    templateData,
    language,
    validateNameChange,
    isCreate,
    templateId,
    existingHtml,
    isRefetching,
  ]);

  if (templateId && isLoading) {
    return <Loading />;
  }

  const renderContent = () => {
    if (builderType === BUILDER_TYPE.SOURCE_CODE || !template.editorData) {
      return (
        <CommunicationsEmailTemplateSourceCode
          templateData={template}
          subjectError={isSubjectError}
          onChange={handleTemplateChange}
          showSubjectRequired={showSubjectRequired}
          isTemplateDirty={isBodyDirty || isNameDirty}
        />
      );
    }

    return (
      <CommunicationsEmailTemplateBuilder
        templateData={template}
        editorRef={emailEditorRef}
        onChange={handleTemplateChange}
        subjectError={isSubjectError}
        showSubjectRequired={showSubjectRequired}
        isTemplateDirty={isBodyDirty || isNameDirty}
      />
    );
  };

  return (
    <>
      <CommunicationsTemplateTitlebar
        title={template.name}
        onTitleChange={name => {
          setIsNameDirty(true);
          validateNameChange(name);
          setTemplate({ ...template, name });
        }}
        onSaveClick={handleSave}
        deleteDisabled={disableTemplateDeleteButton}
        helperText={errorName}
        editMode={editMode}
        setEditMode={setEditMode}
        onSendClick={handleSendClick}
        isSaving={isSaving}
        showDeleteMenu={showTemplateDeleteMenu}
        channel={template.channelType}
        contentId={template.contentId}
        onDeleteClick={handleDeleteClick}
      />

      {renderContent()}

      <CommunicationsSendConfirmDialog
        open={sendDialogOpen}
        onClose={() => {
          setSendDialogEmail('');
          setSendDialogOpen(false);
        }}
        onSend={handleSendDialog}
        sendDisabled={sendDialogDisabled}
        loading={isSendingTest}
      >
        <TextField
          fullWidth
          autoFocus
          variant='outlined'
          label={t('email')}
          value={sendDialogEmail}
          onChange={e => setSendDialogEmail(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{ margin: '16px 0 8px 0', ...(!isValidEmail ? textFieldHelperTextStyles : {}) }}
          FormHelperTextProps={{
            sx: { ...(!isValidEmail ? helperTextStyles : {}) },
          }}
          helperText={!isValidEmail ? t('email_is_invalid') : undefined}
          data-testid='test-email-recipient'
        />
      </CommunicationsSendConfirmDialog>
    </>
  );
};

export default CommunicationsEmailTemplatePage;

const helperTextStyles = { color: theme.palette.error.main };

const textFieldHelperTextStyles = {
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
    },
    '& .MuiInputLabel-outlined': {
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
      '&.Mui-focused': { borderColor: theme.palette.error.main, borderWidth: '2px' },
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
    },
    '&:hover:not(.Mui-focused)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        borderWidth: '2px',
      },
    },
  },
  '& .MuiInputLabel-outlined': {
    color: theme.palette.error.main,
    '&.Mui-focused': {
      color: theme.palette.error.main,
    },
  },
};
