import ChatBubblOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Box, SxProps, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MessageChanneListItemDropdown from 'Pages/Messaging/MessageChannelListItemDropdown';
import { MIN_MESSAGES_DATE } from 'Pages/Messaging/MessagingConstants';
import { ClientContext } from 'helper/streamChatHelper';
import useTranslation from 'hooks/useTranslation';
import { trackEvent } from 'lib/analytics';
import { getMembers } from 'member/state/memberActions';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ChannelSort } from 'stream-chat';
import { ChannelList, Chat } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';

const options = { state: true, presence: true, limit: 5 };
const sort: ChannelSort<DefaultStreamChatGenerics> = { has_unread: -1 };

const MessageDropDown = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const {
    clientObject: { streamChatClient, clientUnreadCount },
    setClientObject,
  } = useContext(ClientContext);
  const loggedInMember: UserState = useAppSelector<UserState>(userSelector());
  const filters = {
    members: { $in: [loggedInMember?.getStreamUser?.id] },
    last_message_at: { $gt: MIN_MESSAGES_DATE },
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    trackEvent({
      screen: 'DASHBOARD',
      feature: 'MESSAGES DROP DOWN',
      event: 'CLICKED',
      unreadCount: clientUnreadCount,
    });
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMembers());
  }, [dispatch]);

  useEffect(() => {
    streamChatClient.on(event => {
      if (event.total_unread_count !== undefined) {
        setClientObject(prev => ({ ...prev, clientUnreadCount: event.total_unread_count }));
      }
    });
  }, [loggedInMember.getStreamUser.id, setClientObject, streamChatClient]);

  return (
    <Box>
      <IconButton onClick={handleClick} size='large'>
        <Badge badgeContent={clientUnreadCount} color='primary'>
          <ChatBubblOutlineIcon />
        </Badge>
      </IconButton>

      <Menu
        PaperProps={{ sx: menuStyles }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
      >
        <Typography sx={titleStyle} variant='button'>
          {t('messages')}
        </Typography>
        <Box sx={chatContentContainerStyles}>
          <Chat client={streamChatClient}>
            <ChannelList
              Preview={props => (
                <MessageChanneListItemDropdown
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  {...props}
                />
              )}
              filters={filters}
              options={options}
              sort={sort}
            />
          </Chat>
        </Box>
        <Box
          sx={viewAllContainerStyles}
          onClick={() => {
            navigate('/messenger');
            setAnchorEl(null);
          }}
        >
          <Typography color='primary' variant='body2'>
            View all Messages
          </Typography>
        </Box>
      </Menu>
    </Box>
  );
};

const chatContentContainerStyles: SxProps = {
  '.str-chat__load-more-button': {
    display: 'none',
  },
};

const viewAllContainerStyles: SxProps = {
  padding: '12px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  cursor: 'pointer',
};

const menuStyles: SxProps = {
  padding: '15px',
  width: '516px',
};

const titleStyle: SxProps = { marginLeft: '15px' };

export default MessageDropDown;
