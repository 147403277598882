import { Grid, Stack, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { AddCard } from '@operto/ui-library';
import { Role } from 'Common/Tables/helpers';
import { roleCanGrantSelectedPermission } from 'Pages/EditPeople/TabContainers/PermissionsContainer';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import { IMember, MemberSiteAccess } from 'member/memberType';
import React from 'react';
import { isAdmin } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import { FormHeader } from 'ui-library/Styled/sidebar';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';

interface PermissionsComponentProps {
  onChange: (field: string, value: unknown) => void;
  data: IMember;
}

const PermissionsComponent = ({ onChange, data }: PermissionsComponentProps) => {
  const { t } = useTranslation();
  const valueChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const field = 'role';
    onChange(field, value);
  };

  const { isFeatureEnabled } = useAppFeatures();
  const showStaffAppToggle = isFeatureEnabled('memberAccessStaffAppToggle') && isAdmin();

  const loggedInMember: UserState = useAppSelector(userSelector());
  const hasStaffAccess = data.site_access_id?.includes(MemberSiteAccess.STAFF) ?? false;

  const dashboardAccessHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const siteAccessIds = data.site_access_id ?? [];
    const updatedSiteAccessIds = e.target.checked
      ? [...siteAccessIds, MemberSiteAccess.DASHBOARD]
      : siteAccessIds.filter(id => id !== MemberSiteAccess.DASHBOARD);

    onChange('site_access_id', updatedSiteAccessIds);
    onChange(e.target.name, e.target.checked);
  };

  const staffAccessHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const staffAccessIds = data.site_access_id ?? [];
    const updatedStaffAccessIds = e.target.checked
      ? [...staffAccessIds, MemberSiteAccess.STAFF]
      : staffAccessIds.filter(id => id !== MemberSiteAccess.STAFF);

    onChange('site_access_id', updatedStaffAccessIds);
    onChange(e.target.name, e.target.checked);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AddCard title='User Permissions'>
          <Grid container direction='column'>
            <FormHeader>{t('select_user_access')}</FormHeader>
            <RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    value='account-owner'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'account-owner'}
                    data-testid='accountowner_radio'
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.AccountOwner)
                    }
                  />
                }
                label={t('account_owner')}
              />
              <FormControlLabel
                control={
                  <Radio
                    value='global-manager'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'global-manager'}
                    data-testid='globalmanager_radio'
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.GlobalManager)
                    }
                  />
                }
                label={t('global_manager')}
              />
              <FormControlLabel
                control={
                  <Radio
                    value='regional-manager'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'regional-manager'}
                    data-testid='regionalmanager_radio'
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.RegionalManager)
                    }
                  />
                }
                label={t('regional_manager')}
              />
              <FormControlLabel
                control={
                  <Radio
                    value='basic-user'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'basic-user'}
                    data-testid='basicuser_radio'
                    disabled={!roleCanGrantSelectedPermission(loggedInMember, data, Role.BasicUser)}
                  />
                }
                label={t('basic_access')}
              />
            </RadioGroup>
          </Grid>
        </AddCard>

        <AddCard title={t('access')}>
          <Stack gap={2}>
            <FormControlLabel
              control={
                <Switch
                  name='is_dashboard_access'
                  color='primary'
                  onChange={dashboardAccessHandler}
                  checked={data.is_dashboard_access}
                  data-testid='granddashboardaccess_switch'
                />
              }
              label={t('access_to_connect_dashboard')}
            />

            {showStaffAppToggle && (
              <FormControlLabel
                control={
                  <Switch
                    name='is_staff_access'
                    color='primary'
                    onChange={staffAccessHandler}
                    checked={hasStaffAccess}
                    data-testid='grandstaffaccess_switch'
                  />
                }
                label={t('access_to_staff_app')}
              />
            )}
          </Stack>
        </AddCard>
      </Grid>
    </Grid>
  );
};

export default PermissionsComponent;
