import { defaultCurrency } from '@operto/payments-shared';
import { createOffer, getOfferTemplateByID, updateCompanyOffer } from 'api/addOnAPI';
import { HttpStatusCode } from 'axios';
import DeleteOfferDialog from 'Common/Widgets/Upsell/DeleteOfferDialog';
import { getActiveAlerts, getCompanyInformation } from 'company/state/companyAction';
import { companySelector } from 'company/state/companySelectors';
import { enabledIfVariablesInitialized } from 'helper/reactHookEnabledHelper';
import { useGetCompanyContext } from 'hooks/usePayments';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { useGetOfferDetails } from 'hooks/useUpsells';
import { logger } from 'lib/logger';
import GuestPortalGlobalPreview from 'Pages/GuestPortal/GuestPortalGlobalPreview';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import TabBar from 'ui-library/Components/tabBar/TabBar';
import { userSelector } from 'user/state/userSelectors';
import UpsellDashboardContainer from './UpsellDashboardContainer';
import UpsellPageTitlebar from './UpsellPageTitlebar';

interface ValidationResult {
  isValid: boolean;
  message: string;
}

export interface UpsellFields {
  id: string;
  name: string | null;
  priceSticker: string;
  imageUrl: string;
  isActive: boolean;
  information: {
    name: string | null;
    header: string | null;
    subheader: string | null;
    description: string | null;
    category: string | null;
  };
  media: {
    files: string[];
  };
  date: {
    header: string;
    datePickerType: string;
    active: boolean;
  };
  preferredTime: {
    header: string;
    active: boolean;
  };
  serviceButton: {
    values: string;
  };
  postPurchase: {
    instructions: string | null;
    active: boolean;
  };
  pricing: {
    price: number | null;
    taxRate: number | null;
    paymentType: 'free' | 'single' | 'link'; // free, single, link
    confirmationMethod: string; // cash, instant, request
    quantityStepperEnabled: boolean;
    maxQuantity: number | null;
    quantityName: string | null;
    affiliateLink: string | null;
    affiliateCustomHeader: string | null;
    affiliateCustomHeaderEnabled: boolean | null;
    customButtonText: string | null;
    currencyCode?: string;
  };
  unitAssigned: {
    units: number[];
  };
  groupsAssigned: {
    groups: number[];
  };
  units: string[];
  groups: string[];
  isGloballyAssigned: boolean | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Add index signature
}

export enum TabName {
  Content,
  Pricing,
  UnitAssignment,
  Instructions,
}

type TabItem = { label: string; value: string; isDropDown: boolean };

const TabItems: { [key in keyof typeof TabName]: TabItem } = {
  Content: {
    label: 'Content',
    value: 'content',
    isDropDown: false,
  },
  Pricing: {
    label: 'Pricing and quantity',
    value: 'pricing',
    isDropDown: false,
  },
  UnitAssignment: {
    label: 'Unit assignment',
    value: 'unitAssignment',
    isDropDown: false,
  },
  Instructions: {
    label: 'Post-purchase instructions',
    value: 'instructions',
    isDropDown: false,
  },
};

const upperTabItems: Array<TabItem> = Object.values(TabItems);

const isEqual = (obj1: UpsellFields, obj2: UpsellFields) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const UpsellsPage = () => {
  const location = useLocation();
  const { snackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [filterType, setFilterType] = useState(upperTabItems[0].value);
  const { id } = useParams<{ id: string }>();
  const { accessToken } = useAppSelector(userSelector());
  const company = useAppSelector(companySelector());
  const [currentUpsell, setCurrentUpsell] = useState<UpsellFields>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [gpOpen, setGpOpen] = useState(false);

  const { data: upsellData } = useGetOfferDetails(
    {
      params: { companyId: company?.id, offerId: id },
      headers: { Authorization: `Bearer ${accessToken}` },
    },
    {
      enabled: !!company?.id && !!id && id !== 'new' && id.endsWith('template') === false,
    },
  );
  const { data: companyContext } = useGetCompanyContext(
    {
      params: { companyId: company?.id },
      headers: { Authorization: `Bearer ${accessToken}` },
    },
    enabledIfVariablesInitialized(accessToken, company?.id),
  );
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [upsellFields, setUpsellFields] = useState<UpsellFields>({
    id,
    name: null,
    priceSticker: null,
    imageUrl: null,
    isActive: false,
    information: {
      name: null,
      header: null,
      subheader: null,
      description: null,
      category: null,
    },
    media: {
      files: [],
    },
    date: {
      header: '',
      datePickerType: 'singleDate',
      active: false,
    },
    preferredTime: {
      header: '',
      active: false,
    },
    serviceButton: {
      values: '',
    },
    postPurchase: {
      instructions: null,
      active: false,
    },
    pricing: {
      price: null,
      taxRate: null,
      paymentType: null,
      confirmationMethod: null,
      quantityStepperEnabled: false,
      maxQuantity: null,
      quantityName: null,
      affiliateLink: null,
      affiliateCustomHeader: null,
      affiliateCustomHeaderEnabled: null,
      customButtonText: null,
    },
    unitAssigned: {
      units: [],
    },
    groupsAssigned: {
      groups: [],
    },
    units: [],
    groups: [],
    isGloballyAssigned: null,
  });
  const offerDefaultTitle = t('offer_empty_title');

  const fetchUpsellTemplateData = useCallback(async (templateId: string) => {
    try {
      const result = await getOfferTemplateByID(templateId);
      const data = result?.data?.data;
      setUpsellFields(data);
    } catch (error) {
      console.error('Error fetching template upsell data:', error);
    }
  }, []);

  const handleTabIndexChange = (value: number) => {
    setTabIndex(value);
    setFilterType(upperTabItems[value].value);
  };

  const updateUpsellFields = <K extends keyof UpsellFields>(field: K, value: UpsellFields[K]) => {
    setUpsellFields(prevFields => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const onDeleteOffer = async () => {
    navigate(`/offers/`);
  };

  const onDeleteClick = async () => {
    setOpenDelete(true);
  };

  function validateUpsellFields(upsellFields: UpsellFields): ValidationResult {
    const { postPurchase, pricing, information } = upsellFields;
    const {
      paymentType,
      affiliateLink,
      customButtonText,
      affiliateCustomHeaderEnabled,
      affiliateCustomHeader,
    } = pricing;

    if (!information.category || information.category === '') {
      return {
        isValid: true,
        message: t('category_is_required'),
      };
    }
    // Validate Post-purchase instructions
    if (postPurchase.active) {
      if (!postPurchase.instructions) {
        return {
          isValid: false,
          message: t('instructions_are_required'),
        };
      }

      if (postPurchase.instructions.length > 1000) {
        return {
          isValid: false,
          message: t('post_purchase_instructions_maximum_length'),
        };
      }
    }

    // Validate Pricing
    if (paymentType === 'link') {
      if (!affiliateLink || !customButtonText) {
        return {
          isValid: false,
          message: t('affiliate_link_is_required'),
        };
      }

      if (affiliateCustomHeaderEnabled && !affiliateCustomHeader) {
        return {
          isValid: false,
          message: t('affiliate_custom_header_is_required'),
        };
      }
    }

    return {
      isValid: true,
      message: '',
    };
  }

  const updateOffer = async () => {
    if (isSaving) return false; // already saving, so return a false since the action is not completed

    setIsSaving(true);

    try {
      if (!upsellFields.name && upsellFields.information?.name) {
        updateUpsellName(upsellFields.information.name);
        upsellFields.name = upsellFields.information.name;
      } else if (!upsellFields.name && !upsellFields.information?.name) {
        upsellFields.name = offerDefaultTitle;
        updateUpsellName(offerDefaultTitle);
      }

      const validated = validateUpsellFields(upsellFields);
      if (!validated.isValid) {
        snackbar(validated.message);
        return;
      }

      // Create a deep copy and filter out any null values
      const upsellFieldsWithNullsFiltered = JSON.parse(JSON.stringify(upsellFields));
      for (const key in upsellFieldsWithNullsFiltered) {
        if (upsellFieldsWithNullsFiltered[key] === null) {
          delete upsellFieldsWithNullsFiltered[key];
        } else if (typeof upsellFieldsWithNullsFiltered[key] === 'object') {
          for (const innerKey in upsellFieldsWithNullsFiltered[key]) {
            if (upsellFieldsWithNullsFiltered[key][innerKey] === null) {
              delete upsellFieldsWithNullsFiltered[key][innerKey];
            }
          }
        }
      }

      let response;
      if (upsellFields.id === 'new' || upsellFields.id === 'template') {
        response = await createOffer(company.id, upsellFieldsWithNullsFiltered);
      } else {
        response = await updateCompanyOffer(company.id, upsellFieldsWithNullsFiltered);
      }

      if ([HttpStatusCode.Ok, HttpStatusCode.Created].includes(response.status)) {
        const data = response.data;
        setCurrentUpsell(data.data);
        snackbar(t('changes_saved'));
      } else {
        throw new Error(`Unknown HttpStatus ${response.status}`);
      }
      setIsSaving(false); // saving is done at this point
      return true; // return true as the action is completed
    } catch (error) {
      const errorMessage = `${t('failed_to_save_offer')}, ${error}`;
      logger.error(errorMessage);
      snackbar(errorMessage);
      setIsSaving(false);
      return false;
    }
  };

  const updateOfferAndPreview = async () => {
    const response = await updateOffer();
    if (response) {
      setGpOpen(true);
    }
  };

  const updateUpsellName = (name: string) => {
    setUpsellFields(prevFields => ({
      ...prevFields,
      name,
    }));
  };

  const updateUpsellIsActive = (isActive: boolean) => {
    setUpsellFields(prevFields => ({
      ...prevFields,
      isActive,
    }));
  };

  useEffect(() => {
    if (location.state?.activeTab) {
      handleTabIndexChange(location.state.activeTab);
    }
  }, [location]);

  useEffect(() => {
    if (id && id.endsWith('template')) {
      fetchUpsellTemplateData(id);
    } else {
      updateUpsellFields('isGloballyAssigned', false);
      updateUpsellName(offerDefaultTitle);
    }
  }, [id, fetchUpsellTemplateData, offerDefaultTitle]);

  useEffect(() => {
    if (!!upsellData?.data) {
      setCurrentUpsell(upsellData?.data);
    }
  }, [upsellData]);

  useEffect(() => {
    if (!companyContext) return;
    const companyCurrency = companyContext?.data.currency || defaultCurrency.code;
    if (upsellFields.pricing.currencyCode !== companyCurrency) {
      updateUpsellFields('pricing', {
        ...upsellFields.pricing,
        currencyCode: companyCurrency,
      });
    }
  }, [companyContext, companyContext?.data, upsellFields.pricing]);

  useEffect(() => {
    if (currentUpsell) {
      setUpsellFields({
        id: currentUpsell?.id || id,
        name: currentUpsell?.name || null,
        priceSticker: currentUpsell?.priceSticker || '',
        imageUrl: currentUpsell?.imageUrl || '',
        isActive: currentUpsell?.isActive || false,
        information: {
          name: currentUpsell.information?.name || null,
          header: currentUpsell.information?.header || null,
          subheader: currentUpsell.information?.subheader || null,
          description: currentUpsell.information?.description || null,
          category: currentUpsell.information?.category || null,
        },
        media: {
          files: currentUpsell.media?.files || [],
        },
        date: {
          header: currentUpsell.date?.header || '',
          datePickerType: currentUpsell.date?.datePickerType || 'singleDate',
          active: currentUpsell.date?.active || false,
        },
        preferredTime: {
          header: currentUpsell.preferredTime?.header || '',
          active: currentUpsell.preferredTime?.active || false,
        },
        serviceButton: {
          values: currentUpsell.serviceButton?.values || '',
        },
        postPurchase: {
          instructions: currentUpsell.postPurchase?.instructions || null,
          active: currentUpsell.postPurchase?.active || false,
        },
        pricing: {
          price: currentUpsell.pricing?.price || null,
          currencyCode: currentUpsell.pricing?.currencyCode || null,
          taxRate: currentUpsell.pricing?.taxRate || null,
          paymentType: currentUpsell.pricing?.paymentType || null,
          confirmationMethod: currentUpsell.pricing?.confirmationMethod || null,
          quantityName: currentUpsell.pricing?.quantityName || null,
          quantityStepperEnabled: currentUpsell.pricing?.quantityStepperEnabled || null,
          maxQuantity: currentUpsell.pricing?.maxQuantity || null,
          affiliateLink: currentUpsell.pricing?.affiliateLink || null,
          affiliateCustomHeader: currentUpsell.pricing?.affiliateCustomHeader || null,
          affiliateCustomHeaderEnabled: currentUpsell.pricing?.affiliateCustomHeaderEnabled || null,
          customButtonText: currentUpsell.pricing?.customButtonText || null,
        },
        unitAssigned: {
          units: currentUpsell.unitAssigned?.units || [],
        },
        groupsAssigned: {
          groups: currentUpsell.groupsAssigned?.groups || [],
        },
        units: currentUpsell.units,
        groups: currentUpsell.groups,
        isGloballyAssigned: currentUpsell.isGloballyAssigned,
      });
    }
  }, [currentUpsell, id]);

  useEffect(() => {
    dispatch(getCompanyInformation());
    dispatch(getActiveAlerts());
  }, [dispatch]);

  return (
    <>
      <UpsellPageTitlebar
        title={upsellFields.name}
        isActive={upsellFields.isActive}
        onActiveChange={updateUpsellIsActive}
        onTitleChange={updateUpsellName}
        onSaveClick={updateOffer}
        onSaveAndPreviewClick={updateOfferAndPreview}
        onDeleteClick={onDeleteClick}
        saveDisabled={isEqual(upsellFields, currentUpsell)}
      />

      <TabBar tabItems={upperTabItems} tabIndex={tabIndex} onTabChange={handleTabIndexChange} />
      <UpsellDashboardContainer
        tabSelected={filterType}
        upsellFields={upsellFields}
        updateUpsellFields={updateUpsellFields}
      />
      <DeleteOfferDialog
        companyId={company?.id}
        offerId={upsellFields.id}
        offerName={upsellFields.name}
        open={openDelete}
        onDelete={onDeleteOffer}
        onClose={() => setOpenDelete(false)}
      />
      <GuestPortalGlobalPreview
        isOpen={gpOpen}
        onClose={() => {
          setGpOpen(false);
        }}
      />
    </>
  );
};

export default UpsellsPage;
