import { VERIFICATION_STATUS_TYPE } from 'reservation/reservationType';

export interface IGuest {
  id: number;
  guest_id: number;
  property_id: number;
  reservation_id: number;
  external_id?: string;
  name: string;
  email: string;
  phone: string;
  access_code: number;
  code_status: string;
  property_name: string;
  timezone: string;
  check_in: string;
  check_out: string;
  opt_in: boolean;
  type: string | undefined;
  avatar?: string;
  incident_payment_enabled?: boolean;
  incident_payment_verified?: boolean;
  room_type?: string;
  payment_info: IPaymentInfo;
  verification_status?: VERIFICATION_STATUS_TYPE;
  terms_agreed_at?: number;
  registered_guest_id: string;
}

export interface GuestDict {
  [Key: number]: IGuest;
}
export interface IPaymentInfo {
  status: PaymentStatus;
  deposit_amount: number;
  charge_amount: number;
  currency: string;
  notes: string;
}

export enum PaymentStatus {
  PENDING = 'pending',
  DECLINED = 'declined',
  CHARGED = 'charged',
  RELEASED = 'released',
  HOLD = 'hold',
  MANUALLY_ACCEPTED = 'manually_accepted',
}

export enum FilterType {
  ALL_GUESTS = 'all',
  PRE_STAY = 'pre-stay',
  POST_STAY = 'post-stay',
  IN_STAY = 'in-stay',
  BLOCKED = 'blocked',
}

export enum FilterTypeLabel {
  ALL_GUESTS = 'All Guests',
  PRE_STAY = 'Pre-stay',
  POST_STAY = 'Post-stay',
  IN_STAY = 'In-stay',
  BLOCKED = 'Blocked',
}

export enum FilterTypeIndex {
  ALL_GUESTS = 0,
  PRE_STAY = 1,
  IN_STAY = 2,
  POST_STAY = 3,
  BLOCKED = 4,
}

export interface GuestCounts {
  currentFilter: FilterType;
  isLoading: boolean;
  [FilterType.ALL_GUESTS]: RecordTrack;
  [FilterType.PRE_STAY]: RecordTrack;
  [FilterType.POST_STAY]: RecordTrack;
  [FilterType.IN_STAY]: RecordTrack;
  [FilterType.BLOCKED]: RecordTrack;
}

export interface RecordTrack {
  pageNum: number;
  numPerPage: number;
  total_records: number;
}

export interface GuestState {
  list: number[];
  byId: GuestDict;
  meta: GuestCounts;
}

export const GuestsFilterKeys = {
  TYPE: 'type',
  PAGE_NUMBER: 'pageNum',
  NUMBER_PER_PAGE: 'numPerPage',
  KEYWORD: 'keyword',
  ACCESS_CODE_STATUS: 'access_code_status',
  VERIFICATION_STATUS: 'verification_status',
  SECURITY_DEPOSIT_STATUS: 'security_deposit_status',
  ACCESS_APPROVAL: 'access_approval',
};
