// @ts-nocheck
import { Box, Button, Grid, Stack, SxProps } from '@mui/material';
import { useMedia } from '@operto/ui';
import { ReservationCard } from 'Common/Widgets';
import BackupCodeCard from 'code/SmartCards/BackupCodeCard';
import { ICode } from 'code/codeType';
import backupCodesSelector from 'code/state/backupCodesSelector';
import { getPropertyAccessCodes } from 'code/state/codeAction';
import { getCheckinCodeByResId } from 'code/state/guestCodesSelectors';
import { getCompanyInformation, getMessengerSetting } from 'company/state/companyAction';
import HumidityCard from 'device/SmartCards/HumidityCard';
import LockCard from 'device/SmartCards/LockCard';
import NetatmoNoiseCard from 'device/SmartCards/NetatmoNoiseCard';
import NoiseAwareCard from 'device/SmartCards/NoiseAwareCard';
import OccupancyCard from 'device/SmartCards/OccupancyCard';
import TemperatureCard from 'device/SmartCards/TemperatureCard';
import { devicesByPropertyIdSelector } from 'device/state/deviceSelector';
import MainActivityCard from 'event/SmartCards/MainActivityCard';
import { ActivityType, FilterType } from 'event/eventType';
import { getPropertyEvents } from 'event/state/eventActions';
import { EventsTotalCountSelector, SearchEventsByPIDSelector } from 'event/state/eventSelectors';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import UnitDetailsCard from 'property/SmartCards/UnitDetailsCard';
import { Property } from 'property/propertyType';
import { getProperty } from 'property/state/propertySelectors';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getCurrentReservation } from 'reservation/state/reservationSelectors';
import { SlidebarType } from 'types/ui';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import UnitDashboardDevices from './TabContainers/UnitDashboardDevices';

export interface UnitDashboardContainerProps {
  propertyId: number;
  property: Property;
  devices: IDevice[];
}

const UnitDashboardContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { propertyId: pid } = useParams();
  const propertyId = parseInt(pid, 10);
  const { isMobile, isTablet } = useMedia();
  const [keyword, setKeyword] = React.useState('');
  const [filterType, setFilterType] = React.useState(FilterType.ALL_ACTIVITIES);
  const [activitiesLoading, setActivitiesLoading] = React.useState(false);
  const [devicesCardLoading, setDevicesCardLoading] = React.useState(false);
  const reservation = useAppSelector(getCurrentReservation(propertyId));
  const meta = useAppSelector(EventsTotalCountSelector(filterType));
  const filteredEvents = useAppSelector(SearchEventsByPIDSelector(keyword, filterType, propertyId));

  const activityList = useMemo(() => {
    // https://operto.atlassian.net/browse/OPN1-15597
    // Product decided to hide this type of event from the operators completely
    // We want to keep original query intact, and simply hide the records matching on client side
    const hiddenActivityTypeList: ActivityType[] = ['codeCheckoutFailed'];

    return filteredEvents.filter(activity => !hiddenActivityTypeList.includes(activity.type));
  }, [filteredEvents]);

  const handleSearchChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    setKeyword(event.currentTarget.value);
  };

  const handleTabChange = (newValue: FilterType) => {
    setFilterType(newValue);
  };

  const handleFetchMore = useCallback(
    async (pageNum: number) => {
      setActivitiesLoading(true);
      try {
        await dispatch(getPropertyEvents(propertyId, filterType, pageNum));
      } catch (error) {
        logger.error(error);
      }

      setActivitiesLoading(false);
    },
    [filterType, dispatch, propertyId],
  );

  // PROPERTIES
  const property: Property = useAppSelector(getProperty(propertyId));
  const timezone = property?.timezone || 'America/Vancouver';

  // DEVICES
  const devices = useAppSelector(devicesByPropertyIdSelector(propertyId));
  const thermostatDevices = devices.filter(device => device.data.temperature);
  const humidityDevices = devices.filter(device => device.data.humidity);
  const lockDevice = devices.find(device => device.type === 'smart-lock');
  const noiseDevices = devices.filter(device => ['Netatmo'].includes(device.vendor));
  const noiseAwareDevices = devices.filter(device => ['NoiseAware'].includes(device.vendor));
  const occupancyDevices = devices.filter(device => device.data.co2);

  const isLockUnlockDisabled = property?.disable_lock_unlock || property?.smw_enabled;
  // CODES
  const guestCode: ICode = useAppSelector(getCheckinCodeByResId(reservation?.id));
  const backupCodes = useAppSelector(backupCodesSelector(propertyId));

  useEffect(() => {
    handleFetchMore(0);
  }, [handleFetchMore]);

  useEffect(() => {
    dispatch(getCompanyInformation());
    dispatch(getMessengerSetting());
  }, [dispatch, propertyId]);

  useEffect(() => {
    dispatch(getPropertyAccessCodes(propertyId));
  }, [dispatch, propertyId]);

  useEffect(() => {
    const isLoading = devices.length === 0;
    setDevicesCardLoading(isLoading);
  }, [devices]);

  return (
    <Grid container={!isMobile} direction={isMobile ? 'column' : 'row'}>
      {isMobile ? (
        <>
          <Stack direction='column'>
            <ReservationCard
              timezone={timezone}
              propertyId={propertyId}
              property={property}
              reservation={reservation}
              guestCode={guestCode}
            />
            <Stack direction='column' sx={columnSmartCardSmallStyles}>
              <UnitDashboardDevices propertyId={propertyId} property={property} devices={devices} />
            </Stack>
          </Stack>

          <Box sx={columnActivityCardSmallStyles}>
            <MainActivityCard
              meta={meta}
              filterIdx={filterType}
              events={activityList}
              onSearch={handleSearchChange}
              onTabChange={handleTabChange}
              onFetchMore={() => handleFetchMore(meta.pageNum + 1)}
              isMainActivity
              loading={activitiesLoading}
            />
          </Box>
          <Button
            sx={reservationButtonSmallStyles}
            variant='outlined'
            onClick={() => dispatch(toggleSlidebar(SlidebarType.ADD_NEW_RESERVATION, {}))}
          >
            {t('create_reservation')}
          </Button>
        </>
      ) : isTablet ? (
        <Grid container sx={columnContainerMediumStyles}>
          <Grid item sx={columnSmartCardMediumStyles}>
            <Stack spacing={2}>
              <ReservationCard
                timezone={timezone}
                propertyId={propertyId}
                reservation={reservation}
                guestCode={guestCode}
                property={property}
              />
              <UnitDashboardDevices propertyId={propertyId} property={property} devices={devices} />
            </Stack>
          </Grid>

          <Grid item sx={columnActivityCardMediumStyles}>
            <MainActivityCard
              meta={meta}
              filterIdx={filterType}
              events={activityList}
              onSearch={handleSearchChange}
              onTabChange={handleTabChange}
              onFetchMore={() => handleFetchMore(meta.pageNum + 1)}
              isMainActivity
              loading={activitiesLoading}
            />
          </Grid>

          <Button
            sx={reservationButtonMediumStyles}
            variant='outlined'
            onClick={() => dispatch(toggleSlidebar(SlidebarType.ADD_NEW_RESERVATION, {}))}
          >
            {t('create_reservation')}
          </Button>
        </Grid>
      ) : (
        <Grid container sx={columnContainerLargeStyles}>
          <Grid item sx={columnSmartCardLargeStyles}>
            <Stack spacing={2} direction='column'>
              <ReservationCard
                timezone={timezone}
                propertyId={propertyId}
                reservation={reservation}
                guestCode={guestCode}
                property={property}
              />
              {backupCodes.length > 0 && (
                <BackupCodeCard
                  propertyId={propertyId}
                  codes={backupCodes?.slice(0, 3)}
                  isOnUnitDashboard
                />
              )}
              <UnitDetailsCard property={property} isOnUnitDashboard />
            </Stack>
          </Grid>

          <Grid item sx={columnActivityCardLargeStyles}>
            <MainActivityCard
              meta={meta}
              filterIdx={filterType}
              events={activityList}
              onSearch={handleSearchChange}
              onTabChange={handleTabChange}
              onFetchMore={() => handleFetchMore(meta.pageNum + 1)}
              isMainActivity
              loading={activitiesLoading}
            />
          </Grid>

          <Grid item sx={columnSmartCardLargeStyles}>
            <Stack direction='column' spacing={3}>
              {lockDevice && (
                <LoadingContainer loading={devicesCardLoading}>
                  <LockCard
                    device={lockDevice}
                    propertyId={propertyId}
                    isLockUnlockDisabled={isLockUnlockDisabled}
                    isOnUnitDashboard
                  />
                </LoadingContainer>
              )}
              {noiseDevices?.length > 0 && (
                <LoadingContainer loading={devicesCardLoading}>
                  <NetatmoNoiseCard isOnUnitDashboard />
                </LoadingContainer>
              )}
              {noiseAwareDevices?.length > 0 && (
                <LoadingContainer loading={devicesCardLoading}>
                  <NoiseAwareCard isOnUnitDashboard />
                </LoadingContainer>
              )}
              {thermostatDevices?.length > 0 && (
                <LoadingContainer loading={devicesCardLoading}>
                  <TemperatureCard isOnUnitDashboard />
                </LoadingContainer>
              )}
              {occupancyDevices.length > 0 && (
                <LoadingContainer loading={devicesCardLoading}>
                  <OccupancyCard isOnUnitDashboard />
                </LoadingContainer>
              )}
              {humidityDevices.length > 0 && (
                <LoadingContainer loading={devicesCardLoading}>
                  <HumidityCard isOnUnitDashboard />
                </LoadingContainer>
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const columnContainerLargeStyles: SxProps = {
  // no need for L/R padding as parent container already has it
  gap: 3,
  paddingTop: 1,
  paddingBottom: 2,
};

const columnSmartCardLargeStyles: SxProps = {
  width: '300px',
};

const columnActivityCardLargeStyles: SxProps = {
  // if at max screen size: take up 599px
  // else, calculate width: 100% (parent) - 2 * 300px (smart cards) - 48px (padding)
  flexGrow: 1,
  maxWidth: '599px',
  width: 'calc(100% - 648px)',
};

const columnContainerMediumStyles: SxProps = {
  gap: 2,
  paddingTop: 1,
  paddingBottom: 2,
  paddingLeft: 3,
  paddingRight: 3,
};

const columnSmartCardMediumStyles: SxProps = {
  flexBasis: '268px',
  flexGrow: 1,
  maxWidth: '300px',
};

const columnActivityCardMediumStyles: SxProps = {
  // if at min screen size: 600px (parent) - 268px (smart cards) - 48px (padding) - 17px (scrollbar) = 251px
  // else, have max width be: 100% (parent) - 268px (smart cards) - 48px (padding)
  flexGrow: 1,
  minWidth: '251px',
  maxWidth: 'calc(100% - 316px)',
};

const reservationButtonMediumStyles: SxProps = {
  color: 'white',
  textTransform: 'none',
  position: 'fixed',
  bottom: '30px',
  right: '30px',
  borderRadius: 8,
  backgroundColor: 'black',
  borderColor: 'border',
  fontWeight: 700,
  '&:hover': {
    background: 'black',
  },
};

const reservationButtonSmallStyles: SxProps = {
  color: 'white',
  textTransform: 'none',
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  borderRadius: 8,
  borderColor: 'border',
  backgroundColor: 'black',
  fontWeight: 700,
  transform: 'translateX(-50%)',
  '&:hover': {
    background: 'black',
  },
};

const columnActivityCardSmallStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: 'inherit',
  padding: 2,
};

const columnSmartCardSmallStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  alignItems: 'center',
};

export default UnitDashboardContainer;
