import { Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { retrieveSmwCredentials } from 'api/smw';
import YourKeyPlus from 'assets/img/integrations/yourKeyPlus.svg';
import { updateCompany } from 'company/state/companyAction';
import { companySelector } from 'company/state/companySelectors';
import { logger } from 'lib/logger';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isAdmin, toggleSnackbar } from 'redux/actions/ui';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { IntegrationsCard, IntegrationsCardActionType } from '../IntegrationsCard';
import { IntegrationsSideDrawer } from '../IntegrationsSideDrawer';
import { SMWCreds, YourKeyPlusIntegrationForm } from './YourKeyPlusIntegrationForm';

const YKP_INFO_URL = 'https://operto.com/guest-access/';

export const YourKeyPlusIntegrationCard = () => {
  const company = useSelector(companySelector());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [smwCreds, setSmwCreds] = useState<SMWCreds>();
  const [smwIsLoading, setSmwIsLoading] = useState(true);

  const guestPortalActive = company?.guestPortalStatus === 'active';
  const connected = smwCreds?.apiId?.length > 0 && smwCreds?.apiKey?.length > 0;
  const enabled = connected && company?.smwEnabled && guestPortalActive;

  const handleDisableConfirmation = () => {
    handleToggle(false);
    setIsDialogOpen(false);
  };

  const handleToggle = async (enabled: boolean) => {
    if (!enabled && !isDialogOpen) {
      return setIsDialogOpen(true);
    }

    try {
      await dispatch(updateCompany({ ...company, smwEnabled: enabled }));
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `Successfully updated company's YourKey+ flag to ${enabled}`,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    } catch (e) {
      logger.error(e);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `Failed to update company's YourKey+ flag to ${enabled}`,
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  const handleFormOnChange = (creds: SMWCreds) => {
    setSmwCreds(creds);
    setIsDrawerOpen(false);
  };

  const handleActionClick = (action: IntegrationsCardActionType | 'CONTACT') => {
    if (isAdmin()) {
      return setIsDrawerOpen(true);
    }

    if (action === 'CONTACT') {
      navigate('/expansion');
    }
  };

  const getActionTitle = () => {
    if (!isAdmin() && !enabled) {
      return 'CONTACT';
    }

    return undefined; // default to IntegrationsCard
  };

  useEffect(() => {
    const getSMWCredentialsAsync = async () => {
      if (company?.id === undefined) {
        return;
      }

      try {
        const response = await retrieveSmwCredentials(company.id);
        if (response.status === 200) {
          const { api_id, api_key } = response.data;
          setSmwCreds({ apiKey: api_id ?? '', apiId: api_key ?? '' });
        } else {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Error retrieving YourKey+ credentials',
              variant: SnackbarVariant.ERROR,
            }),
          );
        }
      } catch (error) {
        logger.error(error);
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Error retrieving YourKey+ credentials',
            variant: SnackbarVariant.ERROR,
          }),
        );
      }
      setSmwIsLoading(false);
    };

    getSMWCredentialsAsync();
  }, [company.id, dispatch]);

  return (
    <>
      <IntegrationsCard
        title='YourKey+'
        connected={connected}
        enabled={enabled}
        loading={smwIsLoading}
        actionTitle={getActionTitle()}
        onActionClick={handleActionClick}
        onChange={handleToggle}
        onInfoClick={() => window.open(YKP_INFO_URL, '_blank')}
        disableToggle={!guestPortalActive}
        dataTestId='your-key-plus-integration-card'
      >
        <YourKeyPlus />
      </IntegrationsCard>
      <IntegrationsSideDrawer
        open={isDrawerOpen}
        title='Integration Settings'
        onClose={() => !smwIsLoading && setIsDrawerOpen(false)}
        dataTestId='your-key-plus-drawer'
      >
        <YourKeyPlusIntegrationForm
          loading={smwIsLoading}
          smwCreds={smwCreds}
          onChange={handleFormOnChange}
        />
      </IntegrationsSideDrawer>
      <ConfirmDialog
        open={isDialogOpen}
        title='Disable YourKey+ Integration'
        onCancel={() => setIsDialogOpen(false)}
        submitButtonText='Disable'
        onSubmit={handleDisableConfirmation}
      >
        <Typography variant='body1'>
          Are you sure you want to disable YourKey+ Integration?
        </Typography>
      </ConfirmDialog>
    </>
  );
};
