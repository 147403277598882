import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import {
  getTotalAvailablePropertiesCount,
  getTotalShownVariablePropertiesCount,
} from '../helpers/VariableHelpers';

const EnabledUnits = (props: GridRenderCellParams) => {
  const { t } = useTranslation();
  const totalActiveVariables = getTotalShownVariablePropertiesCount(props.row);
  const totalAvailableVariables = getTotalAvailablePropertiesCount(
    props.row.properties ?? {},
    props.row.totalProperties ?? {},
  );

  return (
    <Typography variant='body2' style={{ textTransform: 'capitalize', margin: 0 }}>
      {t('enabled_to_available_units', {
        active_count: totalActiveVariables,
        total_count: totalAvailableVariables,
      })}
    </Typography>
  );
};

export default EnabledUnits;
