import {
  Add,
  ArrowRight,
  DeleteOutlineOutlined,
  EditOutlined,
  FileCopyOutlined,
} from '@mui/icons-material';
import { Divider, ListItemIcon, Menu, MenuItem, PopoverOrigin, SxProps } from '@mui/material';
import { CommunicationType } from 'hooks/useCommunications';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export const MENU_OPTIONS = {
  EDIT: 'edit',
  DELETE: 'delete',
  NEW: 'new',
  DUPLICATE: 'duplicate',
};

export type MenuOptionType = (typeof MENU_OPTIONS)[keyof typeof MENU_OPTIONS];

export default function CommunicationsMoreMenu({
  templateId,
  anchorEl,
  open,
  handleClose,
  useWidthOfAnchor = false,
  anchorOrigin,
  transformOrigin,
  onSelectNewLanguage,
  communications,
}: {
  templateId: string;
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: (selectedOption?: MenuOptionType) => void;
  useWidthOfAnchor?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onSelectNewLanguage?: (newLanguage: string) => void;
  communications: CommunicationType[];
}) {
  const { t, languages } = useTranslation();
  const [newAnchorEl, setNewAnchorEl] = React.useState<null | HTMLElement>(null);

  const locales = communications?.find(c => c.id === templateId)?.locales || ['en'];
  const missingLocales = languages.filter(l => !locales.includes(l.locale));
  const arrayMissingLocales = missingLocales?.map(l => l.locale);

  const getLanguageName = (language: string) => {
    return languages.find(l => l.locale === language)?.name ?? language;
  };

  return (
    <Menu
      id='new-communication-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={() => handleClose(undefined)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
        sx: { width: useWidthOfAnchor ? anchorEl?.offsetWidth : undefined },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        ...(anchorOrigin || {}),
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        ...(transformOrigin || {}),
      }}
    >
      <MenuItem onClick={() => handleClose(MENU_OPTIONS.EDIT)} sx={menuItemStyles}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        {t('edit_template')}
      </MenuItem>

      <MenuItem onClick={() => handleClose(MENU_OPTIONS.DUPLICATE)} sx={menuItemStyles}>
        <ListItemIcon>
          <FileCopyOutlined />
        </ListItemIcon>
        {t('make_copy')}
      </MenuItem>

      <MenuItem onClick={() => handleClose(MENU_OPTIONS.DELETE)} sx={menuItemStyles}>
        <ListItemIcon>
          <DeleteOutlineOutlined />
        </ListItemIcon>
        {t('delete_template')}
      </MenuItem>

      <Divider />

      {arrayMissingLocales && arrayMissingLocales.length ? (
        <MenuItem onMouseEnter={e => setNewAnchorEl(e.currentTarget)} sx={menuItemStyles}>
          <ListItemIcon>
            <Add />
          </ListItemIcon>

          {t('add_language')}

          <ListItemIcon>
            <ArrowRight />
          </ListItemIcon>
        </MenuItem>
      ) : (
        <MenuItem value={''} disabled>
          {t('all_languages_added')}
        </MenuItem>
      )}

      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(newAnchorEl)}
        anchorEl={newAnchorEl}
        onClose={() => setNewAnchorEl(null)}
      >
        {arrayMissingLocales?.map(language => (
          <MenuItem key={language} onClick={() => onSelectNewLanguage?.(language)}>
            {getLanguageName(language)}
          </MenuItem>
        ))}
      </Menu>
    </Menu>
  );
}

const menuItemStyles: SxProps = {
  gap: '20px',
};
