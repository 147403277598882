import { FormControl, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import React, { useState } from 'react';
import styled from 'styled-components';

export interface EditSecurityDepositDialogProps {
  openDialog: boolean;
  setOpenDialog: (openDialog: boolean) => void;
  selectionList: GridSelectionModel;
  onSave: (price: string) => Promise<boolean>;
}

const EditSecurityDepositDialog = ({
  openDialog,
  setOpenDialog,
  selectionList,
  onSave,
}: EditSecurityDepositDialogProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [price, setPrice] = useState<string>('');

  const isPriceEmpty = price === '';
  const isErrorMessageEmpty = errorMessage === '';

  const handleSubmit = async () => {
    // check if price matches positive numbers to two decimal places.
    const twoDecimalPlacesRegexp = /^[0-9]*(\.[0-9]{0,2})?$/;
    const isValidTwoDecimalPlacesPrice = twoDecimalPlacesRegexp.test(price);

    if (!isValidTwoDecimalPlacesPrice) {
      setErrorMessage('Invalid number');
    } else {
      const success = await onSave(price);
      if (success) {
        setOpenDialog(false);
        setErrorMessage('');
        setPrice('');
      }
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setErrorMessage('');
    setPrice('');
  };

  const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(e.currentTarget.value);
  };

  return (
    <ConfirmDialog
      open={openDialog}
      title='Edit security deposit'
      onSubmit={handleSubmit}
      onCancel={handleClose}
      submitButtonText='Save'
      disabled={isPriceEmpty}
    >
      <>
        <Typography variant='body1'>
          Please enter security deposit amount.
          <NumberOfUnit>{selectionList.length}</NumberOfUnit> unit(s) will be updated.
        </Typography>
        <Stack direction='row' justifyContent='flex-start' alignItems='center' mt={2}>
          <FormControl sx={{ m: 1, width: '25ch' }} variant='outlined'>
            <TextField
              value={price}
              onChange={handleChangeEvent}
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              aria-describedby='outlined-weight-helper-text'
              error={!isErrorMessageEmpty}
              helperText={!isErrorMessageEmpty && errorMessage}
            />
          </FormControl>
          <Typography>USD</Typography>
        </Stack>
      </>
    </ConfirmDialog>
  );
};

export default EditSecurityDepositDialog;

const NumberOfUnit = styled.span`
  padding-left: 5px;
  font-weight: 900;
`;
