import { OfferDetails, TransactionDetails } from './OrderDetailSidebar';
import { Order } from './OrdersTable';

interface OrderStatusParams {
  order: Order;
  offerDetails: OfferDetails;
  transactionDetails?: TransactionDetails;
  companyLogoLink: string;
  status: string;
  declinedReason?: string;
}

export function getOrderStatusEmail({
  order,
  offerDetails,
  transactionDetails,
  companyLogoLink,
  status,
  declinedReason,
}: OrderStatusParams): string {
  const GUEST_EMAIL_TEMPLATE_OPERTO_LOGO =
    'https://cdn.operto.com/tech/assets/images/OPERTO-LOGO-BLACK.png';
  const guestPortalURL = process.env.REACT_APP_GUEST_PORTAL_URL;
  const totalPrice = order.totalPrice.toFixed(2);
  const orderStatus = status;
  const orderLink = `${guestPortalURL}/orders/${order.id}?companyId=${order.companyId}`;
  const approvedOrPendingContent = `
    <p style="margin: 0 0 8px 0; font-size: 16px; color: #000;"><strong>Instructions</strong></p>
    <p style="margin: 0 0 16px 0; font-size: 16px; color: #000;">${
      offerDetails.postPurchase?.instructions ?? '-'
    }</p>
    `;

  const declinedContent = `
    <p style="margin: 0 0 8px 0; font-size: 16px; color: #000;"><strong>Declined message</strong></p>
    <p style="margin: 0 0 16px 0; font-size: 16px; color: #000;">${declinedReason ?? '-'}</p>
  `;

  const taxContent = `
    <tr>
      <td align="left" style="padding: 4px 0; color: #000;">
        Tax ${order.taxRate.toFixed(2)}%
      </td>
      <td align="right" style="padding: 4px 0; color: #000;">
        $${order.taxPrice.toFixed(2)} USD
      </td>
    </tr>
  `;

  const paymentCardIcon = () => {
    const brand = transactionDetails?.paymentMethodBrand;
    if (!brand) return 'defaultPayment.png';

    const formattedBrand = brand
      .replace(/(?:^|_)(\w)/g, (_, c) => c.toUpperCase())
      .replace('ChinaUnionPay', 'ChinaUnion'); // Special case for ChinaUnionPay

    return `${formattedBrand}.png`;
  };

  return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Order Confirmation</title>
</head>
<body style="margin: 0; padding: 0; font-family: Roboto, sans-serif;">
  <table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color: #ffffff;">
    <tr>
      <td align="center">
        <table width="600" cellpadding="0" cellspacing="0" border="0" style="width: 600px;">

          <tr>
            <td style="padding-top: 24px; padding-bottom: 24px; text-align: center;">
              <img src="${companyLogoLink}" alt="Operto logo" width="120" height="34" style="display: block; margin: 0 auto">
              <h1 style="margin: 0; font-weight: 400; font-size: 36px; line-height: 44px; color: #000000;">${
                order.name
              }</h1>
              <h2 style="margin: 0; font-weight: 400; font-size: 28px; line-height: 36px; color: #000000;">Order ${orderStatus}</h2>
              <p style="margin: 0; font-size: 16px; font-weight: 400; line-height: 24px; letter-spacing: 0.5px; color: #000000;">Order ID ${
                order.id
              }</p>
            </td>
          </tr>

          <tr>
            <td style="padding: 8px 24px; text-align: center">
              <img src="${
                offerDetails.imageUrl
              }" alt="" width="552" height="334" style="display: block; border-radius: 28px; object-fit: cover;">
            </td>
          </tr>

          <tr>
            <td align="center" style="padding-top: 20px; padding-bottom: 20px;">
              <a href="${orderLink}" style="background-color: #000000; color: #ffffff; text-decoration: none; padding: 10px 24px; border-radius: 100px; display: inline-block; font-weight: 500; font-size: 14px; line-height: 20px;">View order</a>
            </td>
          </tr>

          <tr>
            <td style="padding: 16px 24px; border-top: 1px solid #cccccc;">
              <p style="margin: 0 0 16px 0; font-size: 16px; color: #000000;"><strong>Booking</strong></p>
              <p style="margin: 0 0 16px 0; font-size: 16px; color: #000000;">${
                offerDetails?.information.description ?? '-'
              }</p>
              ${orderStatus === 'rejected' ? declinedContent : approvedOrPendingContent}

              <p style="margin: 0 0 8px 0; font-size: 16px; color: #000000;"><strong>Payment method</strong></p>
              <table cellpadding="0" cellspacing="0" border="0" style="width: 100%;">
                <tr>
                  <td width="60" style="padding-right: 15px;">
                    <img src="https://cdn.operto-qa.com/upsells/payment-icons/${paymentCardIcon()}" width="60" height="38" alt="">
                  </td>
                  <td style="font-family: Roboto, sans-serif; font-size: 14px; color: #000000;">
                    <div>${transactionDetails?.paymentMethodBrand ?? '----'} ****${
    transactionDetails?.paymentMethodLastFour ?? '----'
  }</div>
                    <div>Expiration: ${transactionDetails?.paymentMethodExpirationMonth ?? '--'}/${
    transactionDetails?.paymentMethodExpirationYear ?? '----'
  }</div>
                  </td>
                </tr>
              </table>

              <table cellpadding="0" cellspacing="0" border="0" style="width: 100%; margin-top: 16px;">
                <tr>
                  <td style="font-weight: 500; border-bottom: 1px solid #cccccc; padding: 16px 0; color: #000000;">
                    <table cellpadding="0" cellspacing="0" border="0" width="100%">
                      <tr>
                        <td align="left"><strong>Total</strong></td>
                        <td align="right"><strong>$${totalPrice} USD</strong></td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table cellpadding="0" cellspacing="0" border="0" width="100%" style="border-bottom: 1px solid #cccccc;">
                      <tr>
                        <td align="left" style="padding: 4px 0; color: #000000;">${
                          order.quantitySelected ?? '1'
                        } item</td>
                        <td align="right" style="padding: 4px 0; color: #000000;">$${totalPrice} USD</td>
                      </tr>
                      ${order.taxRate ? taxContent : ''}
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td align="center" style="padding: 24px 0 0 0;">
              <p style="font-size: 11px; font-weight: 400; letter-spacing: 2px; margin: 0; color: #000000;">Powered by Operto</p>
              <img src="${GUEST_EMAIL_TEMPLATE_OPERTO_LOGO}" width="100" height="28" alt="Operto logo" style="display: block; margin-top: 8px;">
            </td>
          </tr>
          <tr>
            <td align="center" style="padding: 18px 0;">
              <p style="font-weight: 600; font-size: 14px; line-height: 20px; margin: 0; color: #000000;">
                Terms of Sale
                <span style="display: inline-block; width: 4px; height: 4px; background-color: #666666; border-radius: 50%; vertical-align: middle; margin: 0 8px;"></span>
                Privacy Policy
              </p>
            </td>
          </tr>

        </table>
      </td>
    </tr>
  </table>
</body>
</html>
`;
}
