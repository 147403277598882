import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import { IPaymentInfo } from 'guest/guestType';

export interface IReservation {
  id: number;
  name?: string;
  guest_name: string;
  external_guest_id?: string;
  external_id?: string;
  duration?: string;
  check_in: string;
  check_out: string;
  code?: number;
  code_status?: string;
  status?: string;
  checkoutCode?: string;
  property_id?: number;
  integration_id?: number;
  icon?: string;
  guest_count: number | undefined;
  incident_payment_enabled?: boolean;
  incident_payment_verified?: boolean;
  guest_access_code?: number;
  guest_checkout_code?: number;
  guest_email?: string;
  guest_phone?: string;
  guest_mobile?: string;
  room_status?: string;
  source: string;
  verification_status?: VERIFICATION_STATUS_TYPE;
  payment_info?: IPaymentInfo;
  booking_code?: string;
  deeplink_url?: string;
  timezone?: string;
}

export enum VERIFICATION_STATUS_TYPE {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  IN_REVIEW = 'in_review',
  FLAGGED = 'flagged',
  MANUALLY_CONFIRMED = 'manually_confirmed',
  MANUALLY_FLAGGED = 'manually_flagged',
  CONFIRMED_BY_FLOW = 'confirmed_by_flow',
}

export enum PROPERTY_NAME_TYPE {
  UNASSIGNED = 'unassigned',
  REQUESTED = 'requested',
}

export const VerificationStatusText = {
  [VERIFICATION_STATUS_TYPE.PENDING]: 'Pending',
  [VERIFICATION_STATUS_TYPE.CONFIRMED]: 'Confirmed',
  [VERIFICATION_STATUS_TYPE.IN_REVIEW]: 'In review',
  [VERIFICATION_STATUS_TYPE.FLAGGED]: 'Flagged',
  [VERIFICATION_STATUS_TYPE.MANUALLY_CONFIRMED]: 'Confirmed',
  [VERIFICATION_STATUS_TYPE.MANUALLY_FLAGGED]: 'Manually Flagged',
  [VERIFICATION_STATUS_TYPE.CONFIRMED_BY_FLOW]: 'Welcome flow',
};
export interface GuestVerification {
  guestVerificationUpdateStatus: string;
  guestUpdateReservationId: number;
  updatedAt: string;
}
export interface ReservationByPropertyId {
  [Key: number]: IReservation[];
}
export interface GuestNameMap {
  [Key: string]: string;
}
export interface ReservationDict {
  [Key: number]: IReservation;
}
export interface ReservationState {
  list: number[];
  byId: ReservationDict;
  guestNames: GuestNameMap;
  guestVerification: GuestVerificationByReservationId;
}
export interface GuestVerificationByReservationId {
  [Key: number]: GuestVerification;
}

export const EMPTY_RESERVATION = (): IReservation => {
  const now = Date.now();
  return {
    id: 0,
    guest_name: '',
    check_in: format(addHours(now, 1), "yyyy-MM-dd'T'HH:mm"),
    check_out: format(addHours(now, 24), "yyyy-MM-dd'T'HH:mm"),
    guest_phone: '',
    guest_email: '',
    guest_count: undefined,
    source: '',
  };
};

export const VerificationDocumentType = {
  ID: 'id',
  PHOTO: 'photo',
};

export type VerificationDocumentType =
  (typeof VerificationDocumentType)[keyof typeof VerificationDocumentType];
